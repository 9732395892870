const progressOptions = [
	{
		key: 1,
		value: 1,
		text: 'Not Started',
	},
	{
		key: 2,
		value: 2,
		text: 'In progress',
	},
	{
		key: 3,
		value: 3,
		text: 'Complete',
	},
]
const dueOptions = [
	{
		key: 4,
		value: 4,
		text: 'Overdue',
	},
	{
		key: 3,
		value: 3,
		text: 'Due within 2 weeks',
	},
]
const assignedOptions = [
	{
		key: 1,
		value: 1,
		text: 'Yes',
	},
	{
		key: 2,
		value: 2,
		text: 'No',
	},
	{
		key: 3,
		value: 3,
		text: 'Overdue',
	},
]
const YesNoOptions = [
	{
		key: 1,
		value: true,
		text: 'Yes',
	},
	{
		key: 2,
		value: false,
		text: 'No',
	},
]

export { progressOptions, YesNoOptions, dueOptions, assignedOptions }
