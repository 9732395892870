import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import OutcomesItem from './OutcomesItem'

import './styles.scss'

const ArchiveOutcomes = ({ data }) => (
	<div className="archive-outcomes">
		<h2 className="page-title">Archive Outcomes</h2>
		{_.map(data, (item, index) => {
			return <OutcomesItem data={item} key={index} />
		})}
	</div>
)

ArchiveOutcomes.propTypes = {
	data: PropTypes.array.isRequired,
}

export default ArchiveOutcomes
