import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Loader, Tab } from 'semantic-ui-react'
import { GET_USERS_SUBJECTS_ACTIVITY_LIST } from 'Queries/usersQueries'
import { EXPORT_ACTIVITY } from 'Mutations/exportMutations'
import { CustomDropdown } from 'Common/CustomDropdown'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { withContext } from 'Context/index'
import { ACTIVITY_ACTIONS } from 'Mutations/activityMutations'
import { EXPORT_SESSION_ACTIVITY, REMOVE_SESSION_ACTIVITY } from 'Mutations/settingMutations'
import { ACTIVITY_REPORT_SESSION } from 'Queries/settingQueries'
import { withToastManager } from 'react-toast-notifications'
import useExport from 'Hooks/useExport'
import ImportSubjects from 'Modals/users/ImportSubjects'
import UsersListData from './UsersListData'
import './style.scss'
import DeleteModal from './components/DeleteModal'
import UpdateDueDateModal from './components/UpdateDueDateModal'
import SessionList from './SessionList'

const UsersList = ({ filter, onFiltered, userRole, userRoleType, updateTabIndex, toastManager }) => {
	const [actionValue, setActionValue] = useState(null)
	const [activeActionsSelect, setActiveActionsSelect] = useState(true)
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedSessionUsers, setSelectedSessionUsers] = useState([])
	const [activeIndex, setActiveIndex] = useState(0)
	const [firstLoading, setFirstLoading] = useState(true)
	const [allToTargetComplitionBlank, setAllToTargetComplitionBlank] = useState(false)
	const [openImportModal, setOpenImportModal] = useState(false)
	const [refetchLoader, setRefetchLoader] = useState(false)

	const createSubjectImportModalCloseHandler = () => {
		setOpenImportModal(false)
		//window.location.reload()
	}

	const handleTabChange = (e, { activeIndex }) => {
		setActiveIndex(activeIndex)
		updateTabIndex(activeIndex)
		if (activeIndex == 0) {
			onFiltered({
				...filter,
				session: null,
				signed_off: '',
				attendance_status: null,
			})
		} else {
			onFiltered({
				...filter,
				subjects: null,
				attendance_status: null,
				signed_off: '',
			})
		}
	}

	const actionOptions =
		activeIndex == 0
			? [
					{
						key: 'delete',
						text: 'Delete',
						value: 1,
					},

					{
						key: 'update_due_date',
						text: 'Update Due Date',
						value: 2,
					},
			  ] // eslint-disable-line no-mixed-spaces-and-tabs
			: [
					{
						key: 'delete',
						text: 'Delete',
						value: 1,
					},
			  ] // eslint-disable-line no-mixed-spaces-and-tabs

	const queryOptions = useMemo(() => {
		if (activeIndex == 0) {
			return {
				variables: {
					filters: filter,
				},
				fetchPolicy: 'network-only',
			}
		}
	}, [filter, activeIndex])

	const sessionQueryOptions = useMemo(() => {
		if (activeIndex == 1 || firstLoading) {
			return {
				variables: {
					filters: filter,
				},
				fetchPolicy: 'network-only',
			}
		}
	}, [filter, activeIndex, firstLoading])

	useEffect(() => {
		setTimeout(() => {
			setFirstLoading(false)
		}, 500)
	}, [])

	const mutationOptions = useMemo(
		() => ({
			onCompleted: () => {
				setSelectedUsers([])
				setActionValue(null)
				if (allToTargetComplitionBlank) {
					onFiltered({
						...filter,
						target_date_set: '',
					})
				}
			},
			refetchQueries: [
				{
					query: GET_USERS_SUBJECTS_ACTIVITY_LIST,
					variables: {
						filters: filter,
					},
				},
			],
		}),
		[filter, allToTargetComplitionBlank, setSelectedUsers, setActionValue],
	)

	const { data: sessionData, loading: sessionLoading, refetch: refetchActivityReportSession } = useQuery(
		ACTIVITY_REPORT_SESSION,
		sessionQueryOptions,
	)
	useEffect(() => {
		if (sessionData) {
			setRefetchLoader(false)
		}
	}, [sessionData])
	const handleRefetchLoader = value => {
		setRefetchLoader(value)
	}

	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					<UsersListData
						data={data}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
						filter={filter}
						onFiltered={onFiltered}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Sessions',
			render: () => (
				<Tab.Pane>
					{sessionLoading || refetchLoader ? (
						<Loader active />
					) : (
						<SessionList
							sessionData={sessionData}
							selectedUsers={selectedSessionUsers}
							setSelectedUsers={setSelectedSessionUsers}
							loading={sessionLoading}
							filter={filter}
							onFiltered={onFiltered}
							refetchActivityReportSession={refetchActivityReportSession}
							setRefetchLoader={handleRefetchLoader}
						/>
					)}
				</Tab.Pane>
			),
		}, // eslint-disable-line no-mixed-spaces-and-tabs
	]

	const { data, error, loading } = useQuery(GET_USERS_SUBJECTS_ACTIVITY_LIST, queryOptions)
	const [usersMutations, { loading: updating }] = useMutation(ACTIVITY_ACTIONS, mutationOptions)

	const totalSelected = useMemo(() => {
		const dataTotal = data ? data.subjectsActivityList.data.total : 0

		return selectedUsers !== 'all' ? selectedUsers.length : dataTotal
	}, [data, selectedUsers])

	const exportFilters = useMemo(() => ({ ...filter, page: null }), [filter])
	const [exportActivity, exportLoading] = useExport(EXPORT_ACTIVITY, exportFilters)

	const [exportSessionActivity, exportSessionLoading] = useExport(EXPORT_SESSION_ACTIVITY, exportFilters)

	useEffect(() => {
		if (!!selectedUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedUsers])

	const handleConfirmationCancel = useCallback(() => {
		setActionValue(null)
	}, [setActionValue])

	const handleUpdateUsers = useCallback(
		params => {
			const isSelectedAll = selectedUsers === 'all'
			if (params.subject) {
				if (params.subject.target_completion == '' && isSelectedAll && filter.target_date_set == true) {
					setAllToTargetComplitionBlank(true)
				}
			}
			return usersMutations({
				variables: {
					actions: {
						...params,
						users_id: isSelectedAll ? null : selectedUsers,
						all: isSelectedAll,
					},
					filters: filter,
				},
			})
		},
		[usersMutations, selectedUsers, filter],
	)

	const mutationSessionOptions = useMemo(
		() => ({
			onCompleted: () => {
				setSelectedSessionUsers([])
				toastManager.add('Delete Successfully', {
					appearance: 'success',
					autoDismiss: true,
				})
				setActionValue(null)
			},
			refetchQueries: [
				{
					query: ACTIVITY_REPORT_SESSION,
					variables: {
						filters: filter,
					},
				},
			],
		}),
		[filter, setSelectedSessionUsers, setActionValue],
	)

	const totalSessionSelected = useMemo(() => {
		const dataTotal = sessionData ? sessionData.sessionsActivityList.data.total : 0

		return selectedSessionUsers !== 'all' ? selectedSessionUsers.length : dataTotal
	}, [sessionData, selectedSessionUsers])

	useEffect(() => {
		if (!!selectedSessionUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedSessionUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedSessionUsers])

	const [usersSessionMutations, { loading: updatingSession }] = useMutation(
		REMOVE_SESSION_ACTIVITY,
		mutationSessionOptions,
	)

	const handleSessionUpdateUsers = useCallback(
		params => {
			const isSelectedAll = selectedSessionUsers === 'all'
			return usersSessionMutations({
				variables: {
					actions: {
						...params,
						users_id: isSelectedAll ? null : selectedSessionUsers,
						all: isSelectedAll,
					},
					filters: filter,
				},
			})
		},
		[usersSessionMutations, selectedSessionUsers, filter],
	)

	if (loading || sessionLoading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<div className="users-list">
				<div className="page-title">
					<h1>Activity</h1>
					<div className="actions">
						{!isReviewer && (
							<CustomDropdown
								selection
								icon="angle down"
								placeholder="Actions"
								value={actionValue}
								options={actionOptions}
								selectOnBlur={false}
								disabled={activeActionsSelect}
								onChange={(e, { value }) => setActionValue(value)}
							/>
						)}
						{!isReviewer && (
							<Button
								type="button"
								size="medium"
								content="Import learning plan"
								onClick={() => setOpenImportModal(true)}
							/>
						)}
						{!isReviewer && (
							<Button
								type="button"
								size="medium"
								content="Export to CSV"
								loading={exportLoading || exportSessionLoading}
								onClick={activeIndex == 0 ? exportActivity : exportSessionActivity}
							/>
						)}
					</div>
				</div>
				<div className="tabs-view">
					<Tab panes={panes} activeIndex={activeIndex} onTabChange={handleTabChange} />
				</div>
			</div>
			{!isReviewer && (
				<>
					<DeleteModal
						opened={actionValue === 1}
						disabled={updating || updatingSession}
						loading={updating || updatingSession}
						total={activeIndex == 0 ? totalSelected : totalSessionSelected}
						onCancel={handleConfirmationCancel}
						onDelete={activeIndex == 0 ? handleUpdateUsers : handleSessionUpdateUsers}
						activeIndex={activeIndex}
					/>
					{activeIndex == 0 ? (
						<UpdateDueDateModal
							opened={actionValue === 2}
							disabled={updating}
							loading={updating}
							total={totalSelected}
							onCancel={handleConfirmationCancel}
							onUpdate={handleUpdateUsers}
						/>
					) : null}
				</>
			)}
			{<ImportSubjects open={openImportModal} onClose={createSubjectImportModalCloseHandler} />}
		</>
	)
}

UsersList.propTypes = {
	filter: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	updateTabIndex: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// state
		userRole: role,
		userRoleType: roleType,
	}),
	withToastManager(memo(UsersList)),
)
