import { getHeaders } from 'Utils/auth'

function getUrl(url) {
	return process.env.REACT_APP_API_PATH + url
}

export const get = url => {
	return fetch(getUrl(url), {
		method: 'GET',
		headers: new Headers({
			...getHeaders(),
		}),
	})
}

export const post = (url, bodyData) => {
	return fetch(getUrl(url), {
		body: bodyData,
		method: 'POST',
		headers: new Headers({
			...postAuthHeaders(),
		}),
	})
}

export function postAuthHeaders() {
	return {
		...getHeaders(),
		Accept: 'application/json ,text/plain, */*',
		ContentType: 'multipart/form-data',
	}
}
