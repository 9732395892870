import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

const CircleStatus = ({ className, status }) => <div className={classNames('circle-status', className, status)} />

CircleStatus.propTypes = {
	className: PropTypes.string,
	status: PropTypes.string,
}

CircleStatus.defaultProps = {
	className: '',
	status: 'default',
}

export default CircleStatus
