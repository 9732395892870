import React from 'react'
import RootRoute from 'Routes/RootRoute'
import Centered from 'Layouts/Centered'
import { withErrorBoundary, withProfiler } from '@sentry/react'
import ExceptionFallback from 'Common/ExceptionFallback'
import 'semantic-ui-css/semantic.min.css'
import 'Styles/main.scss'

const App = () => <RootRoute />

const fallback = <ExceptionFallback type="AppErrorBoundary" WrapperComponent={Centered} />

export default withProfiler(withErrorBoundary(App, { fallback, showDialog: true }))
