import { useParams } from 'react-router-dom'

export default function useObsModuleOutcomes({ /*learningOutcomes,*/ lessons }) {
	const { userId } = useParams()

	const getOutcomes = () => {
		const lessonsData = Array.isArray(lessons) ? lessons : []

		return lessonsData.reduce((acc, { content: lessons, id, title: content }, index) => {
			const outcomeProps = {
				index: index + 1,
				content,
			}

			if (!userId) {
				outcomeProps.lessonId = id
				outcomeProps.lessons = lessons
			}

			return [...acc, outcomeProps]
		}, [])
		/*window
			.jQuery(learningOutcomes)
			.find('li')
			.each((index, element) => {
				const outcomeProps = {
					index: index + 1,
					content: window.jQuery(element).text(),
				}

				if (!userId && lessons[index]) {
					const { id, content } = lessons[index]

					outcomeProps.lessonId = id
					outcomeProps.lessons = content
				}

				outcomes.push(outcomeProps)
			})*/
	}

	return {
		getOutcomes,
	}
}
