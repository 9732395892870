import gql from 'graphql-tag'

export const GET_USER_SUBJECTS_BY_ID = gql`
	query users($id: Int!) {
		byId(id: $id) {
			id
			subjectsByCurrentAccount {
				key
				pivot_id
				id
				name
				progress
				obs_course_id
				date_completed
				target_completion
				activity_status
				lastActive {
					id
					created_at
				}
				observation_required
			}
			availableForGroupSubjects {
				id
				name
				default_refresh_period
			}
		}
	}
`
