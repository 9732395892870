import React from 'react'
import PropTypes from 'prop-types'
import { PageContent, Table } from 'Common'
import { useSortTable } from 'Hooks/useSortTable'
import SessionItem from './SessionItem'

const SessionTable = ({ coursesList }) => {
	const { list, activeParam, handleSortTable } = useSortTable(coursesList)
	if (!coursesList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="title"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Date"
								sortParam="date"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="session_type"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Status"
								sortParam="status"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Due Date</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Progress"
								sortParam="progress"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Signed off"
								sortParam="date_completed"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{list.map(course => (
						<SessionItem key={course.id} {...course} />
					))}
				</Table.Body>
			</Table>
		</>
	)
}

SessionTable.propTypes = {
	coursesList: PropTypes.array.isRequired,
}

export default SessionTable
