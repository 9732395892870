import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const DropdownItem = ({ item }) => {
	const [isAccountPhotoLoaded, setIsAccountPhotoLoaded] = useState(true)

	if (item.image) {
		item.image.onError = () => setIsAccountPhotoLoaded(false)

		if (!isAccountPhotoLoaded) {
			delete item.image
		}
	}

	return <Dropdown.Item {...item} />
}

DropdownItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default DropdownItem
