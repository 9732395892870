import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal } from 'semantic-ui-react'

const EvidenceShow = ({ open, onClose, videoEvidence }) => {
	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	return (
		<div className="task-form">
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Evidence</Modal.Header>
				<Modal.Content>
					<Grid>
						<Grid.Row>
							<Grid.Column computer={16}>
								<Grid.Row className="users-list">
									{' '}
									<div style={{ textAlign: 'center' }}>
										<iframe src={videoEvidence} title="W3Schools Free"></iframe>
									</div>
								</Grid.Row>
								<Grid className="group-fields">
									<Grid.Column computer={8}>
										<Button
											className="reverse"
											size="medium"
											type="button"
											content="Cancel"
											fluid
											onClick={modalCloseHandler}
										/>
									</Grid.Column>
									<Grid.Column computer={8}>
										<Button
											type="button"
											size="medium"
											content="Ok"
											onClick={modalCloseHandler}
											fluid
										/>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			</Modal>
		</div>
	)
}

EvidenceShow.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	videoEvidence: PropTypes.string.isRequired,
}

EvidenceShow.defaultProps = {
	open: false,
}

export default EvidenceShow
