import React, { useContext, useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import classNames from 'classnames'
import { Button } from 'semantic-ui-react'

import { Routes, userGroupRoles, userRoles } from 'Constants'

import { UserPhoto, Table } from 'Common'
import FormComponent from 'Helpers/form'
import { StateContext } from 'Context'
import { determineLastActivityLabel } from 'Utils'

const UserItem = ({ user, isSelect, selectUser }) => {
	const userRole = user.groupName ? _.find(userGroupRoles, ['id', user.role]) : _.find(userRoles, ['id', user.role])
	const tableData = {
		userName: user.name,
		userRole: userRole && userRole.role,
		groupName: user.groupName,
		lastActive: user.lastActive ? determineLastActivityLabel(user.lastActive.created_at) : '-',
		created: user.created_at != '' && user.created_at != null ? moment(user.created_at).format('DD/MM/YYYY') : '-',
		learnerNotifications: user.account ? user.account.learner_notifications : false,
	}

	const [
		{
			user: {
				user: {
					account: { name: accountName },
				},
			},
		},
	] = useContext(StateContext)

	const userLink = useMemo(
		() =>
			generatePath(Routes.SINGLE_USER, {
				id: user.id,
			}),
		[user.id],
	)

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.userName}>
					<UserPhoto className="user-photo" user={user} size="mini" />
					<Link className="link" to={userLink} title={user.name}>
						{user.name}
					</Link>
				</Table.Cell>
				<Table.Cell title={tableData.userRole || 'Manager'}>{tableData.userRole || 'Manager'}</Table.Cell>
				<Table.Cell title={tableData.groupName || accountName}>{tableData.groupName || accountName}</Table.Cell>
				<Table.Cell title={tableData.lastActive}>{tableData.lastActive}</Table.Cell>
				<Table.Cell title={tableData.created}>{tableData.created}</Table.Cell>
				<Table.Cell title={`Learner notifications switched ${tableData.learnerNotifications ? 'on' : 'off'}`}>
					<i className={classNames('far', tableData.learnerNotifications ? 'fa-bell' : 'fa-bell-slash')} />
				</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" as={Link} to={userLink} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

UserItem.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
}

UserItem.defaultProps = {
	isSelect: false,
}

export default UserItem
