import React, { useEffect, useRef, useMemo } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button, Icon, Loader } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_SUBJECT_SECTION_OUTCOMES, GET_SUBJECT_SECTION_BY_ID } from 'Queries/subjectsQueries'
import { Routes } from 'Constants'
import { ADD_IFRAME_ACTIVITY } from 'Mutations/outcomeMutations'
import { withContext } from 'Context/index'
import { setSelectedSection } from 'Actions/subjectActions'
import { PageMetaTags } from 'Common'

import OutcomesMenu from '../OutcomesMenu'
import OutcomeContent from '../OutcomeContent'
import './style.scss'

const LearnSection = ({ match, location: { state: routeState }, accessibility, _setSelectedSection }) => {
	const {
		params: { pivotId, sectionId, outcomeId, userId },
	} = match

	const iframeRef = useRef()

	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_OUTCOMES, {
		variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId) },
		fetchPolicy: 'network-only',
	})

	const [loadSubjectSection, { data: subjectData, error: subjectError, loading: subjectLoading }] = useLazyQuery(
		GET_SUBJECT_SECTION_BY_ID,
		{
			variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId), limit: 1, user_id: parseInt(userId) },
			fetchPolicy: 'network-only',
		},
	)

	const [addIframeActivity] = useMutation(ADD_IFRAME_ACTIVITY)
	const onIframeUrlChange = ({ data: { href } }) => {
		if (href) {
			const baseUrl = process.env.REACT_APP_BUCKET_URL
			const path = href.replace(baseUrl, '')
			addIframeActivity({
				variables: {
					href: path,
					pivot_id: parseInt(pivotId),
				},
			})
		}
	}

	const assessmentButton = useMemo(() => {
		if (subjectData) {
			return subjectData.byId.retake
				? 'Retake Assessment'
				: subjectData.byId.is_completed
				? 'Assessment Completed'
				: subjectData.byId.is_start
				? 'Continue Assessment'
				: 'Start Assessment'
		}
	}, [subjectData])

	useEffect(() => {
		if (data) _setSelectedSection({ ...data.byId, pivotId })
	}, [loading])

	const sendDataToIframe = () => {
		iframeRef.current.contentWindow.postMessage({ accessibility }, '*')
	}

	useEffect(() => {
		loadSubjectSection()
	}, [])

	useEffect(() => {
		window.addEventListener('message', onIframeUrlChange)

		return () => {
			window.removeEventListener('message', onIframeUrlChange)
		}
	}, [])

	if (loading || subjectLoading) return <Loader active />
	if (error || subjectError) return <span>Something went wrong :(</span>

	const { name, type, outcomes, link } = data.byId
	const sectionLink = link !== '' ? process.env.REACT_APP_BUCKET_URL + link : ''
	const activeOutcomeIndex = !_.isEmpty(outcomes) ? _.findIndex(outcomes, ['id', parseInt(outcomeId)]) : null
	const learningProgressStats = outcomes => {
		return outcomes.reduce(
			(learningStats, outcome) => {
				if (outcome.learning_link) {
					learningStats.learning_link_count++
				}
				if (outcome.lastActive) {
					learningStats.learning_view_count++
				}
				return learningStats
			},
			{ learning_link_count: 0, learning_view_count: 0 },
		)
	}
	const learningStats = learningProgressStats(subjectData.byId.allOutcomes)
	let isForceLearning = false
	const isLearnerReadyForAssessment = () => {
		if (
			subjectData.byId.force_learning &&
			subjectData.byId.outcomesLearningLinkCount > 0 &&
			learningStats.learning_link_count !== learningStats.learning_view_count
		) {
			isForceLearning = true
		}
	}
	isLearnerReadyForAssessment()
	return (
		<div className="section-learn">
			<PageMetaTags title={name + ' learning'} />
			<div className="page-title">
				<Link className="button-link primary" to={`${Routes.SUBJECTS.path}/${pivotId}/${sectionId}`}>
					Back to course
				</Link>
				{subjectData.byId.is_completed || isForceLearning ? (
					<Button className="btn-not-allowed btn-assessment">
						{assessmentButton}
						{subjectData.byId.is_completed && <Icon name="check" />}
					</Button>
				) : (
					<Link
						className="button-link secondary"
						to={Routes.ASSESSMENT.path}
						disabled={subjectData.byId.is_completed}
						onClick={e => {
							subjectData.byId.is_completed
								? e.preventDefault()
								: _setSelectedSection({ ...data.byId, pivotId })
						}}
					>
						{assessmentButton}
					</Link>
				)}
			</div>

			<div className="outcomes-wrap">
				{type === 'Text blocks' && (
					<>
						<OutcomesMenu outcomes={outcomes} />
						<OutcomeContent activeOutcomeIndex={activeOutcomeIndex} outcomes={outcomes} />
					</>
				)}
				{type === 'Iframe' && (
					<iframe
						src={routeState && routeState.learning_link ? routeState.learning_link : sectionLink}
						width="100%"
						ref={iframeRef}
						id="mainframe"
						title="learning"
						height="600px"
						onLoad={sendDataToIframe}
					/>
				)}
			</div>
		</div>
	)
}

LearnSection.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	accessibility: PropTypes.any,
	_setSelectedSection: PropTypes.func.isRequired,
}

LearnSection.defaultProps = {
	accessibility: false,
}

export default withContext(
	([
		{
			user: {
				user: { accessibility },
			},
		},
		dispatch,
	]) => ({
		accessibility,

		_setSelectedSection: data => setSelectedSection(data, dispatch),
	}),
	withRouter(LearnSection),
)
