import React, { useContext, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Confirm, Grid, Modal } from 'semantic-ui-react'
import _ from 'lodash'

import { userGroupRoles } from 'Constants/userGroupRoles'
import { StateContext } from 'Context/index'
import { useForm } from 'Hooks/useForm'
import { withContext } from 'Context'
import FormComponent from 'Helpers/form'
import { userRoles } from 'Constants'
import { GroupSelect } from 'Common/GroupSelect'

const validate = values => {
	const errors = {}
	let requiredFields = ['first_name', 'last_name']

	if (values.isCreating) requiredFields = [...requiredFields, 'password', 'password_confirmation']

	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = true
		}
	})

	if (isNaN(values.group)) {
		errors.group = true
	}

	if (values.group !== 0 && !values.group_role) {
		errors.group_role = true
	}

	if (!values.email && !values.username) {
		errors.emailOrUsername = true
	}

	if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
		errors.password_confirmation = true
	}

	return errors
}

const CreateUserModal = ({ open, userId, initialFormData, mutation, onClose, currentUserRole }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [
		{
			user: {
				user: {
					account: { name: accountName, role: accountRole, roleType: accountRoleType },
				},
			},
		},
	] = useContext(StateContext)

	const {
		values,
		values: {
			first_name,
			last_name,
			username,
			email,
			secondary_email,
			password,
			password_confirmation,
			active,
			group,
			group_role,
			subjects,
			uid,
		},
		changed,
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
		setValues,
	} = useForm(
		() => {
			const variables = {
				first_name,
				last_name,
				email,
				secondary_email,
				username,
				password,
				password_confirmation,
				group,
				group_role,
				uid,
			}

			setSubmitting(true)

			if (userId) {
				variables.id = userId
			} else {
				variables.subjects = _.map(subjects, subject => {
					if (subject.target_completion) {
						subject.target_completion = subject.target_completion
							.split('/')
							.reverse()
							.join('-')
					}

					return subject
				})
				variables.active = active
			}

			mutation({ variables }).finally(() => onClose())
		},
		validate,
		initialFormData,
	)

	const groupsOptions = useMemo(() => {
		const groupsOptions = []

		if (accountRole === 1 && accountRoleType === 'account') {
			groupsOptions.unshift({
				key: 0,
				value: 0,
				text: accountName,
			})
		}

		return groupsOptions
	}, [accountRole, accountRoleType, accountName])

	const rolesOptions = useMemo(() => {
		if (currentUserRole == 6 && (group == null || group == '')) {
			return _.map(group === 0 ? userRoles : userGroupRoles, ({ id, role }) => ({
				key: id,
				value: id,
				text: role,
			}))
		} else {
			return _.map(
				group === 0 ? userRoles.filter(el => el.id != 6) : userGroupRoles.filter(el => el.id != 6),
				({ id, role }) => ({
					key: id,
					value: id,
					text: role,
				}),
			)
		}
	}, [group])

	const modalCloseHandler = (skipConfirm = false) => {
		if (changed && !skipConfirm) {
			setConfirmOpen(true)
		} else {
			onClose()
		}
	}

	useEffect(() => {
		if (group_role == 6 && group != null && group != '') {
			setValues({ ...values, group_role: 1 })
		}
	}, [group])

	return (
		<>
			<Modal
				className="form-modal create-user-modal"
				open={open}
				size="tiny"
				closeOnDimmerClick={false}
				onClose={() => modalCloseHandler()}
			>
				<Modal.Header>{userId ? 'Update user' : 'Create user'}</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent
										customlabel="First name"
										name="first_name"
										value={first_name}
										disableAutocompleate
										onChange={handleChange}
										error={errors.first_name}
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent
										customlabel="Last name"
										name="last_name"
										value={last_name}
										disableAutocompleate
										onChange={handleChange}
										error={errors.last_name}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>

						{!initialFormData.email && (
							<FormComponent
								customlabel="Username"
								name="username"
								value={username}
								disableAutocompleate
								onChange={handleChange}
								error={errors.emailOrUsername}
							/>
						)}
						<FormComponent
							customlabel="Email"
							name="email"
							value={email}
							disableAutocompleate
							onChange={handleChange}
							error={errors.emailOrUsername}
						/>
						<FormComponent
							customlabel="Secondary Email"
							name="secondary_email"
							value={secondary_email}
							disableAutocompleate
							onChange={handleChange}
							error={errors.emailOrUsername}
						/>

						<Grid>
							<Grid.Row>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent.Password
										label="Password"
										name="password"
										value={password}
										disableAutocompleate
										onChange={handleChange}
										error={errors.password}
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent.Password
										label="Confirm password"
										name="password_confirmation"
										value={password_confirmation}
										onChange={handleChange}
										error={errors.password_confirmation}
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<GroupSelect
										label="Group"
										name="group"
										additionalOptions={groupsOptions}
										value={!isNaN(group) ? group : ''}
										icon="angle down"
										selectOnBlur={false}
										fluid
										error={errors.group}
										onChange={handleSelectChange}
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent.Dropdown
										label="Role"
										name="group_role"
										value={group_role || ''}
										options={rolesOptions}
										onChange={handleSelectChange}
										icon="angle down"
										selection
										selectOnBlur={false}
										error={errors.group_role}
										fluid
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<FormComponent
										customlabel="UID"
										name="uid"
										value={uid}
										onChange={handleChange}
										error={errors.uid}
										selection
										disableAutocompleate
										size="large"
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>

						<div className="actions text-right">
							<Button
								type="submit"
								loading={submitting}
								disabled={submitting}
								content={userId ? 'Update' : 'Create'}
							/>
							<Button
								className="reverse"
								type="button"
								disabled={submitting}
								content="Cancel"
								onClick={() => modalCloseHandler()}
							/>
						</div>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				header={userId ? 'Closing update user window' : 'Closing create user window'}
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>
		</>
	)
}

CreateUserModal.propTypes = {
	open: PropTypes.bool,
	userId: PropTypes.string,
	initialFormData: PropTypes.object.isRequired,
	mutation: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired,
	currentUserRole: PropTypes.number.isRequired,
}
CreateUserModal.defaultProps = {
	open: false,
	userId: null,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role },
				},
			},
		},
	]) => ({ currentUserRole: role }),
	CreateUserModal,
)
