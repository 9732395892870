import React, { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { GET_ALL_TOPICS } from 'Queries/settingQueries'
import FormComponent from 'Helpers/form'
import PropTypes from 'prop-types'

const TopicSelect = ({ additionalOptions, selectedOptions, customeName, ...props }) => {
	const [loadGroups, { error, data }] = useLazyQuery(GET_ALL_TOPICS, {
		variables: {
			filters: {
				name: '',
				page: 1,
				perPage: 1000,
			},
		},
		fetchPolicy: 'network-only',
	})

	const debounceSearch = useCallback(
		_.debounce(query => {
			loadGroups({ variables: { search: query } })
		}, 300),
		[],
	)

	const handleSearchChange = useCallback((e, { searchQuery }) => {
		debounceSearch(searchQuery)
	}, [])

	const options = useMemo(() => {
		const array = _.map(data ? data.accountTopics.data.data : [], ({ id, name }) => ({
			key: id,
			value: id,
			text: name,
		}))
			.concat(additionalOptions)
			.concat(selectedOptions)
		return [...new Set(array)]
	}, [additionalOptions, selectedOptions, data])

	useEffect(() => {
		// eslint-disable-next-line react/prop-types
		loadGroups({ variables: { require_id: props.value } })
	}, [selectedOptions])

	if (error) return null

	return (
		<FormComponent
			label="Topics"
			name={customeName ? customeName : 'topic'}
			type="dropdown"
			options={options}
			placeholder="Topics"
			icon="angle down"
			selection
			search
			onSearchChange={handleSearchChange}
			clearable
			{...props}
			fluid
		/>
	)
}

TopicSelect.propTypes = {
	additionalOptions: PropTypes.arrayOf(PropTypes.object),
	selectedOptions: PropTypes.array,
	customeName: PropTypes.string,
}

TopicSelect.defaultProps = {
	additionalOptions: [],
	selectedOptions: [],
	customeName: null,
}

export { TopicSelect }
