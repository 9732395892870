import * as Types from 'Types'
import usersState from '../initialState/usersState'

const usersReducer = (users, action) => {
	switch (action.type) {
		case Types.USERS_FILTER:
			return {
				...users,
				filter: {
					...users.filter,
					...action.payload,
				},
			}
		case Types.CUSTOM_FILTER:
			return {
				...users,
				customFilter: {
					...users.customFilter,
					...action.payload,
				},
			}
		case Types.CLEAR_CONTEXT:
			return usersState
		case Types.GET_USERS_DETAILS:
			return {
				...users,
				learnerDetails: action.payload,
			}
		case Types.GET_AVAILABLE_FOR_GROUP_SUBJECTS:
			return {
				...users,
				checkAvailableForGroupSubjects: action.payload,
			}
		default:
			return users
	}
}

export default usersReducer
