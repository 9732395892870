import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Grid, Table, Button } from 'semantic-ui-react'
import moment from 'moment'
import './style.scss'

const SessionDetails = ({
	match,
	// userId,
	learnerSignOffStatus,
	showEviModalOpen,
	manager,
	// , isAssessor,
	systemAdmin,
	// userName,
	sessionTopicId,
	sessionDetails,
	sessionLoading,
	learnerTargetCompletionDate,
}) => {
	const history = useHistory()
	const [sessionData, setSessionData] = useState({
		title: '-',
		description: '-',
		// tags: [],
		status: '-',
		date: '-',
		session_type: '-',
		venue: '-',
		// notes: '-',
		// places: '-',
		document: '-',
		document_link: '-',
		document_name: null,
		location_type: 1,
		target_completion: '-',
		refresh_period: '-',
	})
	const {
		params: { sessionId },
	} = match
	const learnerId = match.params.id || 0
	useEffect(() => {
		if (sessionDetails && !sessionLoading) {
			const {
				id,
				title,
				description,
				// tags,
				date,
				session_type,
				venue,
				notes,
				document,
				document_link,
				document_name,
				location_type,
				users,
				// places,
				// users_count,
			} = sessionDetails.byId
			let sessionType, sessionStatus
			if (session_type && session_type === 1) {
				sessionType = 'Group session'
			} else if (session_type && session_type === 2) {
				sessionType = '1:1 session'
			} else if (session_type && session_type === 3) {
				sessionType = 'Self Directed learning'
			} else {
				sessionType = '-'
			}

			if (learnerSignOffStatus != undefined && learnerSignOffStatus == true) {
				sessionStatus = 'Completed'
			} else if (learnerSignOffStatus != undefined && learnerSignOffStatus == false) {
				sessionStatus = 'Active'
			} else {
				sessionStatus = '-'
			}
			let learner = null
			if (learnerId) {
				learner = users.find(user => parseInt(user.id) === parseInt(learnerId))
			}
			setSessionData({
				title: title ? title : '-',
				description: description ? description : '-',
				// tags: tags ? tags : [],
				status: sessionStatus,
				date: date ? moment(date).format('DD/MM/YYYY') : '-',
				session_type: sessionType,
				venue: venue ? venue : '-',
				notes: notes ? notes : '-',
				document: document ? document : '-',
				document_link: document_link ? document_link : '-',
				document_name: document_name,
				location_type: location_type ? location_type : 1,
				refresh_period:
					(learner &&
						learner.sessions &&
						learner.sessions.find(res => res.id === id).refresh_period_formatted) ||
					'-',
				target_completion: learnerTargetCompletionDate
					? moment(learnerTargetCompletionDate).format('DD/MM/YYYY')
					: '-',
				// places: places ? places : '-',
				// users_count: users_count ? users_count : '-',
			})
		}
	}, [sessionDetails, sessionLoading])

	const redirectToSession = () => {
		if (systemAdmin) {
			history.push(`/settings/session/${sessionTopicId}/view/${sessionId}`)
		} else {
			history.push(`/manager/session/${sessionTopicId}/view/${sessionId}`)
		}
	}

	// Guys, author of this crap Sourabh Chourasiya, I am just adding buttons ;)
	return (
		<div className="session-details-list">
			<div className="page-title">
				<Grid>
					<Grid.Row>
						<Grid.Column width={12}>
							{/* {userName ? <h2>{userName}</h2> : null} */}
							<h2>{sessionData.title}</h2>
						</Grid.Column>
						<Grid.Column width={4}>
							{systemAdmin || manager ? (
								<Button size="medium" onClick={redirectToSession} fluid content="View Full Session" />
							) : null}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
			<Table celled className="descriptions-table">
				<Table.Body>
					<Table.Row>
						<Table.Cell colSpan="2">{sessionData.description}</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Date:</b>
							<span>{sessionData.target_completion}</span>
						</Table.Cell>
						<Table.Cell>
							<b>Type:</b>
							<span>{sessionData.session_type}</span>
						</Table.Cell>
						{/* <Table.Cell>
							<b>Tags:</b>
							<br></br>
							<br></br>
							{sessionData.tags && sessionData.tags.length > 0
								? _.map(sessionData.tags, list => list.name).join(', ')
								: '-'}
						</Table.Cell> */}
					</Table.Row>
					{/* <Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Status:</b>
							<span>{sessionData.status}</span>
						</Table.Cell>
					</Table.Row> */}
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Notes:</b>
							<span>{sessionData.notes}</span>
						</Table.Cell>
						<Table.Cell>
							<b>Refresher Period:</b>
							<span>{sessionData.refresh_period}</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell colSpan="2">
							<div className="flex align-items-center w-100">
								<b className="no-wrap">Attached resource:</b>
								<span className="flex justify-content-between align-items-center w-100">
									{sessionData.document_name ? (
										<>
											<div
												role="button"
												tabIndex="0"
												onKeyDown={() => showEviModalOpen(sessionData.document)}
												onClick={() => showEviModalOpen(sessionData.document)}
												className="clr-blue"
											>
												{sessionData.document_name}
											</div>
											<Button
												size="small"
												content="View"
												onClick={() => showEviModalOpen(sessionData.document)}
											/>
										</>
									) : (
										'-'
									)}
								</span>
							</div>
						</Table.Cell>
					</Table.Row>
					{Boolean(sessionDetails && sessionDetails.byId.document_link) && (
						<>
							<Table.Row className="spacer" />
							<Table.Row>
								<Table.Cell colSpan="2">
									<div className="flex align-items-center w-100">
										<b className="no-wrap">Online resource:</b>
										<div className="flex justify-content-between align-items-center w-100">
											<span
												onClick={() => window.open(sessionData.document_link)}
												className="clr-blue"
												role="button"
												tabIndex="0"
												onKeyDown={() => window.open(sessionData.document_link)}
											>
												{sessionData.document_link}
											</span>
											<Button
												size="small"
												content="View"
												onClick={() => window.open(sessionData.document_link)}
											/>
										</div>
									</div>
								</Table.Cell>
							</Table.Row>
						</>
					)}
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell colSpan="2">
							<div className="flex align-items-center w-100">
								<b className="no-wrap">Location:</b>
								<div className="flex justify-content-between align-items-center w-100">
									{sessionData.location_type == 1 ? (
										<>
											<span
												onClick={() => window.open(sessionData.venue)}
												className="clr-blue"
												role="button"
												tabIndex="0"
												onKeyDown={() => window.open(sessionData.venue)}
											>
												{sessionData.venue}
											</span>
											<Button
												size="small"
												content="View"
												onClick={() => window.open(sessionData.venue)}
											/>
										</>
									) : (
										<span>{sessionData.venue}</span>
									)}
								</div>
							</div>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			{/* <table className="table-session table-bordered">
				<thead>
					<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Location</th>
						<th>Notes</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{sessionData.date}</td>
						<td>{sessionData.session_type}</td>
						<td>{sessionData.venue}</td>
						<td>{sessionData.notes}</td>
					</tr>
				</tbody>
			</table> */}
		</div>
	)
}

SessionDetails.propTypes = {
	match: PropTypes.object.isRequired,
	// userId: PropTypes.number,
	manager: PropTypes.bool.isRequired,
	// isAssessor: PropTypes.bool,
	learnerSignOffStatus: PropTypes.bool,
	learnerTargetCompletionDate: PropTypes.any,
	showEviModalOpen: PropTypes.func.isRequired,
	// userName: PropTypes.any,
	systemAdmin: PropTypes.bool,
	sessionTopicId: PropTypes.any,
	sessionDetails: PropTypes.any,
	sessionLoading: PropTypes.bool.isRequired,
}

SessionDetails.defaultProps = {
	// userId: null,
	// isAssessor: false,
	learnerSignOffStatus: null,
	learnerTargetCompletionDate: null,
	// userName: null,
	systemAdmin: false,
	sessionTopicId: null,
	sessionDetails: null,
}

export { SessionDetails as Session }
