import useFetchResource from 'Hooks/useFetchResource'
import { getObsLearningResource, removeAssessmentLesson } from 'Utils/obs'

export default function useObsLesson({ lessonId }) {
	const { resource: lesson, ...rest } = useFetchResource(() => {
		return getObsLearningResource('lessons', lessonId)
	}, [lessonId])

	return {
		...rest,
		lesson: {
			...lesson,
			content: removeAssessmentLesson(lesson.content),
		},
	}
}
