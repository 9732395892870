import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { ProgressBar, Table } from 'Common'
import { Button } from 'semantic-ui-react'
import { determineLastActivityLabel } from 'Utils/index'
import CircleStatus from '../../../Activity/CircleStatus'

const SubjectItem = ({ match, subject }) => {
	const { params } = match
	const history = useHistory()
	const status =
		subject.activity_status !== 'success' && !subject.target_completion ? 'default' : subject.activity_status

	const daysLeft = subject.target_completion
		? Math.abs(moment(subject.target_completion).diff(moment(), 'days'))
		: 'Not Set'

	const date =
		status === 'success'
			? moment(subject.date_completed).format('DD/MM/YYYY')
			: typeof daysLeft === 'string'
			? daysLeft
			: daysLeft === 0
			? 'Today'
			: `${daysLeft} day${daysLeft > 1 ? 's' : ''}`

	const tableData = {
		userName: subject.name ? subject.name : subject.title,
		userTitle: subject.title,
	}

	const viewCourse = () => {
		const { obs_course_id, pivot_id } = subject
		const { id } = params

		const obsLinkExtension = obs_course_id ? [OBS_SUBJECT_PREFIX, obs_course_id] : []

		history.push(`${Routes.MANAGER}/${id}${Routes.SUBJECTS.path}/${pivot_id}/${obsLinkExtension.join('/')}`)
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell title={tableData.userName}>{tableData.userName}</Table.Cell>
				<Table.Cell>
					{subject.lastActive ? determineLastActivityLabel(subject.lastActive.created_at) : '-'}
				</Table.Cell>
				<Table.Cell>
					<ProgressBar
						percents={subject.progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				<Table.Cell>
					<div className="subject-status-wrap">
						<CircleStatus status={status} />
						<span>{date}</span>
					</div>
				</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={viewCourse} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

SubjectItem.propTypes = {
	match: PropTypes.object.isRequired,
	subject: PropTypes.object.isRequired,
}

export default withRouter(SubjectItem)
