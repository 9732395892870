import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button, Grid, Table, Loader, Icon, Popup, Confirm } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { PageMetaTags } from 'Common'
import FormComponent from 'Helpers/form'
import { TagsSelect } from 'Common/TagsSelect'
import { withToastManager } from 'react-toast-notifications'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
import { useHistory, generatePath } from 'react-router-dom'
import { Routes } from 'Constants'
import { GET_TOPIC_BYID, GET_SESSION_BYID } from 'Queries/settingQueries'
import { CREATE_SESSION_MUTATION, UPDATE_SESSION_MUTATION, REMOVE_SESSION_DOC } from 'Mutations/settingMutations'
import moment from 'moment'
import { TimeInput } from 'semantic-ui-calendar-react'
import './style.scss'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'
import { GET_ACCOUNT_ALL_TOPICS } from '../../../../store/queries/settingQueries'

const location1 = [
	{
		key: 1,
		value: 1,
		text: 'Online',
	},
	{
		key: 2,
		value: 2,
		text: 'Face to face',
	},
]

const sessionTypes = [
	{
		key: 1,
		value: 1,
		text: 'Group session',
	},
	{
		key: 2,
		value: 2,
		text: '1:1 session',
	},
	{
		key: 3,
		value: 3,
		text: 'Self Directed learning',
	},
]

const statusOptionsAll = [
	{
		key: 1,
		value: 1,
		text: 'Active',
	},
	{
		key: 2,
		value: 2,
		text: 'Cancelled',
	},
	{
		key: 3,
		value: 3,
		text: 'Complete',
	},
	{
		key: 4,
		value: 4,
		text: 'Planned',
	},
]
const statusOptionsCopy = [
	{
		key: 1,
		value: 1,
		text: 'Active',
	},
	{
		key: 3,
		value: 3,
		text: 'Complete',
	},
]
const statusOptionsOne = [
	{
		key: 1,
		value: 1,
		text: 'Active',
	},
]

const CreateSession = ({ toastManager, match, location }) => {
	const history = useHistory()
	const [uuId, setuuId] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [topicId, setTopicId] = useState('')
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [currentEviId, setCurrentEviId] = useState(0)
	const [removeSessionDoc] = useMutation(REMOVE_SESSION_DOC)
	const [createSessionMutation] = useMutation(CREATE_SESSION_MUTATION)
	const [updateSessionMutation] = useMutation(UPDATE_SESSION_MUTATION)
	const [documentName, setDocumentName] = useState(null)
	const [documentDownloadLink, setDocumentDownloadLink] = useState(null)
	const [getSessionDataQuery, { data: getSessionDetails, loading: sessionLoading }] = useLazyQuery(GET_SESSION_BYID, {
		fetchPolicy: 'network-only',
	})
	const { data: topicsData, loading } = useQuery(GET_ACCOUNT_ALL_TOPICS, {
		fetchPolicy: 'network-only',
	})
	const { error: refreshPeriodError, loading: refreshPeriodLoading, data: refreshPeriodData } = useQuery(
		GET_REFRESH_PERIOD_OPTIONS,
	)
	const { state: state } = location
	const {
		params: { id, sessionId },
	} = match
	//query to get topic details
	const { data: topicDetails, loading: topicLoading } = useQuery(GET_TOPIC_BYID, {
		variables: {
			id: parseInt(id),
		},
		fetchPolicy: 'network-only',
	})

	if (topicDetails && !topicLoading) {
		var { name, description } = topicDetails.byId
	}
	const sessionRefreshedPeriodValues = [0, 3, 6, 12, 24, 36]
	let finalStatusOptions = state ? statusOptionsCopy : sessionId ? statusOptionsAll : statusOptionsOne
	const validate = values => {
		const errors = {}

		const requiredFields = state
			? [
					'sessionName',
					// 'sessionDate',
					'venue',
					'sessionType',
					'status',
					'locationStatus',
					'sessionRefreshedPeriod',
			  ] // eslint-disable-line no-mixed-spaces-and-tabs
			: sessionId
			? [
					'sessionName',
					// 'sessionDate',
					'venue',
					'sessionType',
					'status',
					'locationStatus',
					'sessionRefreshedPeriod',
			  ] // eslint-disable-line no-mixed-spaces-and-tabs
			: [
					'sessionName',
					//  'sessionDate',
					'venue',
					'sessionType',
					'locationStatus',
					'sessionRefreshedPeriod',
			  ] // eslint-disable-line no-mixed-spaces-and-tabs

		requiredFields.forEach(field => {
			if (!values[field]) {
				if (
					(field === 'sessionRefreshedPeriod' &&
						!sessionRefreshedPeriodValues.includes(values.sessionRefreshedPeriod)) ||
					field !== 'sessionRefreshedPeriod'
				) {
					errors[field] = true
					if (document.querySelector('input[name="' + field + '"]') != null) {
						document.querySelector('input[name="' + field + '"]').focus()
					}
					if (document.querySelector('div[name="' + field + '"]') != null) {
						document.querySelector('div[name="' + field + '"]').scrollIntoView()
					}
				}
			}
		})

		// if (values.duration && /^\d+$/.test(values.duration.trim()) && values.duration.trim() > 0) {
		// 	console.log('')
		// } else {
		// 	errors.duration = true
		// }

		if (values.duration && values.duration != parseInt(values.duration)) {
			errors['duration'] = true
		}

		if (values.places && values.places != parseInt(values.places)) {
			errors['places'] = true
		}
		return errors
	}

	useEffect(() => {
		if (sessionId) {
			getSessionDataQuery({ variables: { id: parseInt(sessionId) } })
		}
	}, [sessionId])
	const topicsList = useMemo(() => {
		let data = []
		if (topicsData) {
			topicsData.accountAllTopics.forEach(res => {
				data.push({
					key: res.id,
					value: res.id,
					text: res.name,
				})
			})
		}
		return data
	}, [topicsData])
	useEffect(() => {
		if (topicsData && topicDetails) {
			let topic = topicsData.accountAllTopics.find(res => res.name === name)
			if (topic) {
				setTopicId(topic.id)
			}
		}
	}, [topicsData, topicDetails])
	useEffect(() => {
		if (getSessionDetails) {
			let copySessionValues = {
				sessionName: getSessionDetails.byId.title,
				sessionDescription: getSessionDetails.byId.description,
				places:
					getSessionDetails.byId.places && getSessionDetails.byId.places != 0
						? String(getSessionDetails.byId.places)
						: null,
				duration: getSessionDetails.byId.duration ? String(getSessionDetails.byId.duration) : null,
				venue: getSessionDetails.byId.venue,
				notes: getSessionDetails.byId.notes ? getSessionDetails.byId.notes : '',
				locationStatus: getSessionDetails.byId.location_type,
				sessionType: getSessionDetails.byId.session_type,
				sessionRefreshedPeriod: getSessionDetails.byId.default_refresher_period,
				status: getSessionDetails.byId.status,
				sessionDate: getSessionDetails.byId.date
					? moment(moment(getSessionDetails.byId.date, 'YYYY-MM-DD')).format('DD/MM/YYYY')
					: null,
				startTime: getSessionDetails.byId.start_time
					? moment(getSessionDetails.byId.start_time, 'HH:mm:ss').format('HH:mm')
					: '',
				endTime: getSessionDetails.byId.end_time
					? moment(getSessionDetails.byId.end_time, 'HH:mm:ss').format('HH:mm')
					: '',
				group: _.map(
					getSessionDetails.byId.tags != undefined ? getSessionDetails.byId.tags : [],
					({ id }) => id,
				),
				documentLink: getSessionDetails.byId.document_link ? getSessionDetails.byId.document_link : '',
			}
			setDocumentDownloadLink(getSessionDetails.byId.document ? getSessionDetails.byId.document : null)
			setValues(copySessionValues)
			setDocumentName(getSessionDetails.byId.document_name ? getSessionDetails.byId.document_name : null)
			setuuId(getSessionDetails.byId.uuid ? getSessionDetails.byId.uuid : null)
		}
	}, [getSessionDetails])

	const isVideo = filename => {
		var ext = filename.split('.').pop()
		switch (ext.toLowerCase()) {
			case 'm4v':
			case 'oog':
			case 'mp4':
			case 'webm':
			case '3gp':
			case 'mp3':
				// etc
				return true
		}
		return false
	}

	const showSessionModalOpen = sessionValue => {
		var ext = sessionValue.split('.').pop()
		if (isVideo(sessionValue)) {
			let a = document.createElement('a')
			a.href = sessionValue
			a.target = '_blank'
			a.download = `Evidence. ${ext}`
			a.click()
		} else {
			let a = document.createElement('a')
			a.href = sessionValue
			a.target = '_blank'
			a.download = `Evidence. ${ext}`
			a.click()
		}
	}

	const onDelConfirm = useCallback(() => {
		removeSessionDoc({
			variables: { session_ids: parseInt(currentEviId) },
		})
			.then(({ data }) => {
				if (data.deleteSessionDocument) {
					toastManager.add('Deleted Successfully.', { appearance: 'success', autoDismiss: true })
					getSessionDataQuery({ variables: { id: parseInt(currentEviId) } })
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(error => {
				console.log('error', error)
			})
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const onDelCancel = useCallback(() => {
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const {
		values: {
			sessionName,
			sessionDescription,
			group,
			status,
			notes,
			venue,
			startTime,
			endTime,
			duration,
			sessionDate,
			places,
			locationStatus,
			sessionType,
			sessionRefreshedPeriod,
			documentLink,
			upload,
		},
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
		handleImageChange,
		setValues,
	} = useForm(
		() => {
			if (upload != undefined) {
				if (upload.file.type) {
					var imgAllowed = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'image/gif', '.docx']
					var docAllowed = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
					var pptAllowed = ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
					var videoAllowed = ['application/msword', 'video/mp4', 'audio/mpeg']
					var allowedTypes = imgAllowed.concat(docAllowed, pptAllowed, videoAllowed)
					if (!allowedTypes.includes(upload.file.type)) {
						var fileErr = 'Please upload a JPEG, PNG, GIF, PDF, MP3, MP4, PPT, DOC, DOCX file.'
						toastManager.add(fileErr, { appearance: 'error', autoDismiss: true })
						return false
					}
				}
				if (upload.file.size > 5 * 1000 * 1024) {
					toastManager.add('Maximum size of 5MB is allowed', { appearance: 'error', autoDismiss: true })
					return false
				}
			}
			const variables = {
				title: sessionName ? sessionName : '',
				description: sessionId ? name : sessionDescription ? sessionDescription : description,
				tag_ids: group ? group : [],
				status: status ? parseInt(status) : 1,
				notes: notes ? notes : '',
				venue: venue,
				start_time: startTime ? moment(startTime, 'HH:mm').format('HH:mm:ss') : null,
				end_time: endTime ? moment(endTime, 'HH:mm').format('HH:mm:ss') : null,
				duration: duration ? parseInt(duration) : null,
				date: sessionDate ? moment(moment(sessionDate, 'DD/MM/YYYY')).format('YYYY-MM-DD') : null,
				places: places ? parseInt(places) : null,
				location_type: parseInt(locationStatus),
				session_type: parseInt(sessionType),
				default_refresher_period: sessionRefreshedPeriod,
				location_info: venue,
				document: upload ? upload.file : undefined,
				document_link: documentLink ? documentLink : '',
				topic_id: topicId ? parseInt(topicId) : 0,
			}
			state ? createMutation(variables) : sessionId ? updateMutation(variables) : createMutation(variables)
		},
		validate,
		{},
	)

	// create session mutation
	const createMutation = variables => {
		setSubmitting(true)
		createSessionMutation({
			variables: state
				? {
						topic_id: parseInt(id),
						uuid: uuId,
						clone_form: parseInt(sessionId),
						clone_document_exist: upload ? false : documentName ? true : false,
						...variables,
				  } // eslint-disable-line no-mixed-spaces-and-tabs
				: { topic_id: parseInt(id), ...variables },
		})
			.then(({ data }) => {
				if (data.createSession) {
					setSubmitting(false)
					toastManager.add('Created Successfully', { appearance: 'success', autoDismiss: true })
					onCancel()
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	// update session mutation
	const updateMutation = variables => {
		setSubmitting(true)
		updateSessionMutation({
			variables: { id: parseInt(sessionId), ...variables },
		})
			.then(({ data }) => {
				if (data.updateSession) {
					setSubmitting(false)
					toastManager.add('Updated Successfully', { appearance: 'success', autoDismiss: true })
					onCancel()
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}
	const onTopicChange = topicId => {
		setTopicId(topicId)
	}
	const onCancel = () => {
		if (state) {
			history.push({
				pathname: `${Routes.SESSION.path}`,
			})
		} else {
			if (sessionId) {
				history.push(`${Routes.SESSION.path}/${topicId}/view/${sessionId}`)
			} else {
				history.push({
					pathname: generatePath(Routes.TOPIC_VIEW, {
						id: id,
					}),
				})
			}
		}
	}

	const onUploadCancel = () => {
		setDocumentName(null)
		setValues({
			sessionName,
			sessionDescription,
			group,
			status,
			notes,
			venue,
			startTime,
			endTime,
			duration,
			sessionDate,
			places,
			locationStatus,
			sessionType,
			sessionRefreshedPeriod,
			documentLink,
			upload: null,
		})
	}
	if (refreshPeriodLoading) return null
	if (refreshPeriodError) return 'Error'
	if (sessionLoading) return <Loader active />
	return (
		<div className="session-list">
			<PageMetaTags title="Create Session" />
			<div className="margin-30">
				<h2>
					{state ? 'Copy Session' : sessionId ? 'Update Session' : 'Create Session'} : {name ? name : '-'}
				</h2>
				{state ? (
					<>
						<Popup hoverable position="bottom center" trigger={<Icon name="info circle" size="large" />}>
							Copying a session should only be used to create another version of the session. For example,
							copying the annual refresher for Health and Safety. Each copy is a version of the original
							session and will be used to track completion on the training matrix for this specific
							session as your learners complete the session each year. If you wish to create a brand new
							session that is tracked separately then please contact your system administrator or create a
							new session in the Settings tab.
						</Popup>
						Do you need to copy or create a new session?
					</>
				) : null}
				<div style={{ width: '48.8%' }}>
					{loading && <Loader className="session-topic-loader" active inline size="small" />}
					<FormComponent.Dropdown
						value={topicId}
						name="topic"
						onChange={(e, { value }) => onTopicChange(value)}
						placeholder="Topic"
						options={topicsList}
						disabled={loading}
						selectOnBlur={false}
						selection
						sort={false}
						fluid
					/>
				</div>
				<Table celled>
					<Table.Body>
						<Table.Row className="custom-border">
							<Table.Cell className="custom-border">{name ? name : '-'}</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<Grid>
						<Grid.Row>
							<Grid.Column tablet={16} computer={8}>
								<Grid.Row className="users-list">
									<FormComponent
										customlabel={<b>Session name *</b>}
										name="sessionName"
										placeholder="Session Name"
										value={sessionName || ''}
										onChange={handleChange}
										error={errors.sessionName}
									/>

									<FormComponent.Dropdown
										label={<b>Location *</b>}
										name="locationStatus"
										value={locationStatus}
										onChange={handleSelectChange}
										placeholder="Location"
										options={location1}
										selectOnBlur={false}
										selection
										fluid
										error={errors.locationStatus}
									/>

									<FormComponent
										customlabel={locationStatus == 1 ? <b>URL *</b> : <b>Venue *</b>}
										name="venue"
										placeholder={locationStatus == 1 ? 'URL' : 'Venue'}
										value={venue || ''}
										onChange={handleChange}
										error={errors.venue}
									/>

									<FormComponent.Dropdown
										label={<b>Session type *</b>}
										name="sessionType"
										value={sessionType}
										onChange={handleSelectChange}
										placeholder="Session type"
										options={sessionTypes}
										selectOnBlur={false}
										selection
										fluid
										error={errors.sessionType}
									/>

									{state ? (
										<FormComponent.Dropdown
											label={<b>Status *</b>}
											name="status"
											value={status}
											onChange={handleSelectChange}
											placeholder="Status"
											options={finalStatusOptions}
											selectOnBlur={false}
											selection
											fluid
											error={errors.status}
										/>
									) : sessionId ? (
										<FormComponent.Dropdown
											label={<b>Status *</b>}
											name="status"
											value={status}
											onChange={handleSelectChange}
											placeholder="Status"
											options={finalStatusOptions}
											selectOnBlur={false}
											selection
											fluid
											error={errors.status}
										/>
									) : null}

									<FormComponent.Date
										label={<b>Date</b>}
										name="sessionDate"
										value={sessionDate || null}
										placeholder="Date"
										minDate={sessionDate || moment()}
										icon={false}
										clearIcon="close"
										dateFormat="DD/MM/YYYY"
										popupPosition="bottom left"
										clearable
										closable
										error={errors.sessionDate}
										onChange={handleSelectChange}
									/>
									<FormComponent.Dropdown
										label={<b>Session Refreshed Period *</b>}
										name="sessionRefreshedPeriod"
										type="dropdown"
										placeholder="Refreshed Period"
										value={sessionRefreshedPeriod}
										options={refreshPeriodData.refreshPeriodOptions}
										onChange={handleSelectChange}
										selection
										selectOnBlur={false}
										fluid
										error={errors.sessionRefreshedPeriod}
									/>
									<FormComponent
										customlabel={<b>Learning hours</b>}
										name="duration"
										placeholder="Duration"
										value={duration || ''}
										onChange={handleChange}
										error={errors.duration}
										errorMsg={
											errors.duration ? 'Please enter valid Learning hours in number.' : null
										}
									/>

									<FormComponent
										customlabel={<b>Description</b>}
										name="sessionDescription"
										placeholder="Description"
										value={sessionDescription || ''}
										onChange={handleChange}
										error={errors.sessionDescription}
									/>
									<FormComponent
										customlabel={<b>Places</b>}
										name="places"
										placeholder="Places"
										value={places || ''}
										onChange={handleChange}
										clearable="true"
										error={errors.places}
										errorMsg={errors.places ? 'Please enter valid places in number.' : null}
									/>
									<TimeInput
										label={<b>Start Time</b>}
										name="startTime"
										placeholder="Start Time"
										value={startTime || ''}
										iconPosition="left"
										onChange={handleSelectChange}
										icon={false}
										closable
									/>
									<TimeInput
										label={<b>End Time</b>}
										name="endTime"
										placeholder="End Time"
										value={endTime || ''}
										iconPosition="left"
										onChange={handleSelectChange}
										icon={false}
										closable
									/>
									<FormComponent.Textarea
										rows={3}
										label={<b>Notes</b>}
										name="notes"
										placeholder="Notes"
										value={notes || ''}
										repeats
										onChange={handleChange}
										fluid
									/>
									<TagsSelect
										label={<b>Tags</b>}
										value={group || []}
										onChange={handleSelectChange}
										selectOnBlur={false}
										clearable
										multiple
									/>
									<FormComponent
										customlabel={
											<>
												<b>Link</b>{' '}
												<Popup
													hoverable
													position="bottom center"
													trigger={<Icon name="info circle" size="large" />}
												>
													Use this to link any URL that you would like - eg. Google map,
													Website page.
												</Popup>
											</>
										}
										name="documentLink"
										placeholder="Link"
										value={documentLink || ''}
										onChange={handleChange}
									/>

									<div className="field-wrap">
										<span className="field-label">
											<b>Upload Document</b>
										</span>
										<label className="upload" htmlFor="Upload">
											<input name="upload" id="upload" type="file" onChange={handleImageChange} />
											{state && (upload || documentName) ? (
												<i
													name="upload"
													id="upload"
													aria-hidden="true"
													className="close"
													onClick={() => {
														onUploadCancel()
													}}
												></i>
											) : null}
											<span className="upload-custom"></span>
											{upload && upload.file ? (
												<b>File name: {upload.file.name}</b>
											) : documentName ? (
												<b>File name: {documentName}</b>
											) : (
												'-'
											)}
										</label>
									</div>
									<div className="field-wrap">
										<p>
											{documentName ? (
												<>
													<div
														role="button"
														tabIndex="0"
														onKeyDown={() => showSessionModalOpen(documentDownloadLink)}
														onClick={() => showSessionModalOpen(documentDownloadLink)}
														className="clr-black"
													>
														{documentName}
													</div>
													<Icon
														key={sessionId}
														className="cursor-pointer"
														name="trash"
														onClick={() => {
															setOpenDeleteModal(true)
															setCurrentEviId(sessionId)
														}}
													/>
												</>
											) : null}
										</p>
										<Confirm
											open={openDeleteModal}
											size="mini"
											content="You are about to delete the Document?"
											cancelButton={<Button className="reverse" content="Cancel" />}
											confirmButton={<Button loading={false} content="Confirm" />}
											onCancel={onDelCancel}
											onConfirm={onDelConfirm}
										/>
									</div>
								</Grid.Row>
								<br />
								<Grid className="group-fields">
									<Grid.Column tablet={16} computer={8}>
										<Button
											className="reverse"
											type="button"
											loading={submitting}
											disabled={submitting}
											content="Cancel"
											onClick={() => {
												onCancel()
											}}
										/>
									</Grid.Column>
									<Grid.Column tablet={16} computer={8}>
										<Button
											type="submit"
											loading={submitting}
											disabled={submitting}
											content={
												state
													? 'Create Session'
													: sessionId
													? 'Update Session'
													: 'Create Session'
											}
											floated="right"
										/>
									</Grid.Column>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</form>
			</div>
		</div>
	)
}

CreateSession.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
}

export default withToastManager(CreateSession)
