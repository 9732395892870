import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { Table } from 'Common'
import { Icon, Confirm, Button } from 'semantic-ui-react'

const TagItem = ({ user, confirmModal, viewLearner, onConfirm: onConfirmModal, onCancel }) => {
	const tableData = {
		id: user.id,
		name: user.name,
		tags: user.tags && user.tags.length > 0 ? _.map(user.tags, list => list.name).join(', ') : '-',
		refresher:
			user.refresh_period_formatted != undefined && user.refresh_period_formatted != null
				? user.refresh_period_formatted
				: '-',
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell title={tableData.name}>{tableData.name}</Table.Cell>
				<Table.Cell title={tableData.tags}>{tableData.tags}</Table.Cell>
				<Table.Cell title={tableData.refresher}>{tableData.refresher}</Table.Cell>
				<Table.Cell onClick={() => viewLearner(tableData.id)}>
					<Icon name="trash" />
				</Table.Cell>
				<Confirm
					open={confirmModal}
					size="mini"
					content="You are about to delete the Course?"
					cancelButton={<Button className="reverse" disabled={false} content="Cancel" />}
					confirmButton={<Button loading={false} disabled={false} content="Confirm" />}
					onCancel={onCancel}
					onConfirm={onConfirmModal}
				/>
			</Table.Row>
		</>
	)
}

TagItem.propTypes = {
	user: PropTypes.object.isRequired,
	confirmModal: PropTypes.bool.isRequired,
	viewLearner: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

export default TagItem
