// import moment from 'moment'

const Greeting = ({ impersonate }) => {
	// const currentHour = moment().hours()
	// const split_afternoon = 12
	// const split_evening = 17
	//
	// if (currentHour >= split_afternoon && currentHour <= split_evening) {
	// 	return 'Good afternoon'
	// } else if (currentHour >= split_evening) {
	// 	return 'Good evening'
	// }

	return !impersonate ? 'Hello' : 'Impersonating'
}

export { Greeting }
