import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './styles.scss'

const OutcomeCard = ({ title, titleClassName, cardData }) => {
	return (
		<div className="outcome-card-wrapper">
			<div className={classnames('title', titleClassName)}>{title}</div>
			<div className="outcome-card-body">
				{_.map(cardData, row => {
					if (!_.isEmpty(row.value)) {
						return (
							<div key={row.id} className="outcome-card-item">
								<span>{row.rowName}</span>
								<span>{_.isArray(row.value) ? row.value.join(', ') : row.value}</span>
							</div>
						)
					}
				})}
			</div>
		</div>
	)
}

OutcomeCard.propTypes = {
	title: PropTypes.string.isRequired,
	cardData: PropTypes.array.isRequired,
	titleClassName: PropTypes.string,
}

OutcomeCard.defaultProps = {
	titleClassName: PropTypes.string,
}

export default OutcomeCard
