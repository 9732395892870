import React, { useState } from 'react'

import { PageContent } from 'Common'

import User from './User'
import Activity from './Activity'

import './style.scss'

const SingleUser = () => {
	const [hasLogs, setHasLogs] = useState(true)

	return (
		<PageContent className="users-page" rightBarIcon="history">
			<PageContent.Content>
				<User />
			</PageContent.Content>

			{hasLogs && (
				<PageContent.RightBar>
					<Activity onLogsEmpty={() => setHasLogs(false)} />
				</PageContent.RightBar>
			)}
		</PageContent>
	)
}

export default SingleUser
