export const App = {
	Host: 'app.click-learning.co.uk',

	get Origin() {
		return `//${this.Host}`
	},

	Support: {
		Origin: '//support.click-learning.co.uk',

		Email: 'support@click-learning.co.uk',

		get General() {
			return `${this.Origin}/portal/en-gb/kb/online-learning/general`
		},

		get Migration() {
			return `${this.Origin}/portal/en-gb/kb/articles/changing-from-grey-matter-learning-to-flourish-learning-ltd`
		},
	},
}
