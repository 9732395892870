import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import FormComponent from 'Helpers/form'

import { Table, PageContent, CustomPaginator } from 'Common'

import { Icon, Popup } from 'semantic-ui-react'
import UserItem from './UserItem'

const UsersTable = ({
	usersList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	selectedUsers,
	setSelectedUsers,
}) => {
	const selectAllUsersOnPage = () => {
		setSelectedUsers(usersList.length !== selectedUsers.length ? _.map(usersList, ({ id }) => id) : [])
	}

	const selectAllMatchingUsers = () => {
		setSelectedUsers(selectedUsers === 'all' ? [] : 'all')
	}

	const selectUser = userId => {
		setSelectedUsers(
			_.includes(selectedUsers, userId)
				? _.filter(selectedUsers, id => id !== userId)
				: [...selectedUsers, userId],
		)
	}

	if (!usersList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Popup
								content={
									<div className="select-all-popup">
										<FormComponent.Checkbox
											className="select-all customCheck"
											name="multichoice"
											label="Select all on page"
											checked={
												usersList.length === selectedUsers.length || selectedUsers === 'all'
											}
											onChange={selectAllUsersOnPage}
										/>
										<FormComponent.Checkbox
											className="select-all customCheck"
											name="multichoice"
											label="Select all matching"
											checked={selectedUsers === 'all'}
											onChange={selectAllMatchingUsers}
										/>
									</div>
								}
								on="click"
								pinned
								trigger={
									<FormComponent.Checkbox
										className="select-all customCheck"
										name="multichoice"
										checked={usersList.length === selectedUsers.length || selectedUsers === 'all'}
									/>
								}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="role"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Group"
								sortParam="groupName"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Last Active"
								sortParam="last_active"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Created"
								sortParam="created_at"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>
								<span>Alerts </span>
								<Popup
									hoverable
									position="bottom center"
									trigger={<Icon name="info circle" size="large" />}
								>
									Learners receive emails when new courses are added to their profile, go overdue or
									are due soon. Use the actions button to set or pause these notifications for your
									learners.
								</Popup>
							</div>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(usersList, user => (
						<UserItem
							key={user.id}
							user={user}
							isSelect={_.includes(selectedUsers, user.id) || selectedUsers === 'all'}
							selectUser={() => selectUser(user.id)}
						/>
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

UsersTable.propTypes = {
	usersList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
}

UsersTable.defaultProps = {
	activeSortParam: null,
}

export default UsersTable
