import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'

import { withContext } from 'Context'
import { getFilteredUsers } from 'Actions/usersActions'
import { allRoles, isReviewerRole } from 'Utils/roles'

const GroupItem = ({ item, userRole, userRoleType, collapseIcon, checked }) => {
	const isReviewer = isReviewerRole(userRole, userRoleType)

	const handleFirstExpand = useCallback(() => {
		if (checked === false) {
			item.loadGroups({ variables: { parent_id: item.id, flag: 'course' } })
		} else {
			item.loadGroups({ variables: { parent_id: item.id } })
		}
	}, [item])

	const handleEachExpand = useCallback(() => {
		item.toggleActiveIds(item.id, _.map(item.children, 'id'))
	}, [item])
	let coursesCompliance
	let sessionCompliance
	coursesCompliance = (((item.users_count - item.overdue_courses) / item.users_count) * 100).toFixed(2)
	if (isNaN(coursesCompliance)) {
		coursesCompliance = 0
	}
	sessionCompliance = (((item.total_session - item.overdue_session) / item.total_session) * 100).toFixed(2)
	if (isNaN(sessionCompliance)) {
		sessionCompliance = 0
	}
	useEffect(() => {
		var elem = document.querySelector('.nestable-list')
		elem.querySelectorAll('[draggable="true"]').forEach(e => e.setAttribute('draggable', false))
		var ec = document.querySelector('.nestable-item--with-children')
		if (ec) {
			ec.querySelectorAll('[draggable="true"]').forEach(e => e.setAttribute('draggable', false))
		}
	}, [])
	return (
		<>
			<div
				className={classNames(
					'group-item',
					{ 'not-active': item.query && item.query.length > 2 && !item.search_match },
					{ 'no-control': isReviewer },
				)}
			>
				{!isReviewer}
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
				<div className="expand-wrapper" onClick={handleEachExpand}>
					{(item.children.length && collapseIcon) ||
						(item.is_children_exists && (
							<button type="button" className="expand first-handler" onClick={handleFirstExpand}>
								<i className="fas fa-plus-square" />
							</button>
						))}
				</div>
				<div className="group-item-content">
					<span className="comp-group-name-sub" title={item.name}>
						{item.name}
					</span>
					<span className="comp-group-elearning-sub">
						<span className="smallCoulmWidth">
							<span className="group-t">{item.overdue_courses}</span>
							<span>|</span>
							<span className="group-t">{item.users_count}</span>
							<span>|</span>
							<span className="group-t">{coursesCompliance}%</span>
						</span>
					</span>
					<span className="comp-group-sessions-sub">
						<span className="smallCoulmWidth">
							<span className="group-t">{item.overdue_session}</span>
							<span>|</span>
							<span className="group-t">{item.total_session}</span>
							<span>|</span>
							<span className="group-t">{sessionCompliance}%</span>
						</span>
					</span>
				</div>
			</div>
		</>
	)
}

GroupItem.propTypes = {
	item: PropTypes.object.isRequired,
	//getUsers: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	collapseIcon: PropTypes.object,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	checked: PropTypes.bool.isRequired,
}

GroupItem.defaultProps = {
	collapseIcon: null,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	GroupItem,
)
