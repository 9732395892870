import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageContent, PageMetaTags } from 'Common'
import List from './List'
import Filter from './Filter'
import './style.scss'

const PAGE_NAME = 'Sessions'

const SessionList = ({ setBreadcrumbs }) => {
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Topics',
				path: '/settings/topics',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="users-page">
				<PageContent.Content>
					<List />
				</PageContent.Content>

				<PageContent.RightBar>
					<Filter />
				</PageContent.RightBar>
			</PageContent>
		</>
	)
}

SessionList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(SessionList)
