import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageContent, PageMetaTags } from 'Common'

import CoursesList from './CoursesList'

import './style.scss'

const SubjectsList = ({ setBreadcrumbs }) => {
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Courses',
			},
		])
	}, [])

	return (
		<>
			<PageMetaTags title="Courses" />

			<PageContent>
				<PageContent.Content>
					<CoursesList />
				</PageContent.Content>
			</PageContent>
		</>
	)
}

SubjectsList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(SubjectsList)
