import useFetchResource from 'Hooks/useFetchResource'
import { getObsLearningResource } from 'Utils/obs'

export default function useObsAssessment({ userSubjectSectionId }) {
	const { resource: assessment, ...rest } = useFetchResource(() => {
		if (!userSubjectSectionId) {
			return Promise.resolve({ data: {} })
		}

		return getObsLearningResource(userSubjectSectionId, 'assessment')
	}, [userSubjectSectionId])

	return {
		...rest,
		assessment,
	}
}
