import React from 'react'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withContext } from 'Context'
import { getFilteredSettings } from 'Actions/usersActions'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { TagsSelect } from 'Common/TagsSelect'

const statusOptions = [
	{
		key: true,
		value: true,
		text: 'Visible',
	},
	{
		key: false,
		value: false,
		text: 'Inactive',
	},
]

const Filter = ({ customFilter, getUsers, type }) => {
	const {
		values: { username, group, status },
		handleSubmit,
		handleChange,
		handleSelectChange,
		clearValues,
	} = useForm(() => getUsers({ username, tag_id: group, page: 1, status }), null, {
		username: customFilter.username,
		group: customFilter.group,
		status: customFilter.status,
	})

	const clearValuesHandler = () => {
		clearValues(['username', 'group', 'status'])
	}

	return (
		<div className="users-filter">
			<span className="custom-label">Filter</span>
			<form onSubmit={handleSubmit}>
				<FormComponent
					inputClassName="search"
					label={
						<button
							className={classNames({ active: !!username.length })}
							type="button"
							onClick={() => clearValues(['username'])}
						>
							<i className="fal fa-times-circle" />
						</button>
					}
					name="username"
					value={username || ''}
					onChange={handleChange}
					placeholder="Search"
					labelPosition="right"
				/>
				<TagsSelect value={group} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<FormComponent.Dropdown
					name="status"
					value={status}
					onChange={handleSelectChange}
					placeholder="Status"
					options={statusOptions}
					selectOnBlur={false}
					selection
					clearable
					fluid
				/>
				<div className="actions text-right">
					<Button type="button" basic content="Clear" onClick={clearValuesHandler} />
					<Button type={type} content="Submit" />
				</div>
			</form>
		</div>
	)
}

Filter.propTypes = {
	customFilter: PropTypes.object.isRequired,
	getUsers: PropTypes.func,
	type: PropTypes.string,
}

Filter.defaultProps = {
	getUsers: () => {},
	type: 'submit',
}

export default withContext(
	([
		{
			users: { customFilter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		// Actions
		getUsers: data => getFilteredSettings(data, dispatch),
	}),
	Filter,
)
