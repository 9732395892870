import React from 'react'

import PagesRoute from 'Routes/PagesRoute'
import { Header, LeftMenu, Recite } from 'Common'
import EmailConfirmationModal from 'Modals/common/EmailConfirmation'

const Main = () => {
	return (
		<>
			<Recite />
			<EmailConfirmationModal
				fieldName="secondary_email"
				content="Please add a recovery email address to your profile.
				This will allow you to continue to access your profile should you
				lose access to your primary email address for any reason."
			/>
			<EmailConfirmationModal
				fieldName="email"
				content="It seems you don't have an email address, please provide one now."
			/>
			<Header />
			<div className="main-content">
				<LeftMenu />
				<PagesRoute />
			</div>
		</>
	)
}

export default Main
