import gql from 'graphql-tag'

export const IMPORT_CSV = gql`
	mutation import($file: String!) {
		importCSV(file: $file)
	}
`
export const IMPORT_PLAN_CSV = gql`
	mutation import($file: String!, $accountSubscriptionId: Int!) {
		importPlanCSV(file: $file, accountSubscriptionId: $accountSubscriptionId)
	}
`
export const IMPORT_SESSION_CSV = gql`
	mutation import($file: String!) {
		importSessionCSV(file: $file)
	}
`
export const IMPORT_BULK_SESSION_CSV = gql`
	mutation import($file: String!) {
		importBulkSessionCSV(file: $file)
	}
`
