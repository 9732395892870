import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from 'Constants'
import TopicsList from './TopicsList'
import CreateTopic from './CreateTopic'
import TopicDetails from './TopicDetails'
import './style.scss'

const Topics = () => (
	<Switch>
		<Route exact path={Routes.TOPICS} component={TopicsList} />
		<Route exact path={Routes.CREATE_TOPICS} component={CreateTopic} />
		<Route exact path={Routes.EDIT_TOPICS} component={CreateTopic} />
		<Route exact path={Routes.TOPIC_VIEW} component={TopicDetails} />
	</Switch>
)

export default Topics
