import React, { useState, useEffect } from 'react'
import { Button, Loader, Tab } from 'semantic-ui-react'

import AddSubject from 'Modals/users/AddSubject'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import { withContext } from 'Context/index'
import { getLearnerDetails, getAvailableForGroupSubjects } from 'Actions/usersActions'
import { Routes } from 'Constants'
import { GET_USER_SESSION_BY_ID } from 'Queries/settingQueries'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { GET_USER_SUBJECTS_BY_ID } from './queries'
import Table from './Table'
import SessionTable from './SessionTable'

const UserSubjects = ({
	match,
	switchCoursesView,
	userRole,
	userRoleType,
	learnerDetails,
	checkAvailableForGroupSubjects,
}) => {
	const history = useHistory()
	const [subjectModal, setSubjectModal] = useState(false)
	const [sessionList, setSessionList] = useState([])
	const state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
	const user = state ? state.user : null
	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					<Table subjects={subjectsByCurrentAccount} />
				</Tab.Pane>
			),
		},
		sessionList.length > 0
			? {
					menuItem: 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionTable subjects={sessionList} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]

	const onAddCourseClick = () => {
		learnerDetails({ selectedUsers: [id] })
		checkAvailableForGroupSubjects(availableForGroupSubjects.length)
		history.push(`${Routes.LERANER_COURSE_BUNDLES}`)
	}

	const {
		params: { id },
	} = match

	const { loading, error, data } = useQuery(GET_USER_SUBJECTS_BY_ID, {
		variables: { id: parseInt(id) },
		fetchPolicy: 'cache-and-network',
	})

	const { data: sessionData, loading: sessionLoading } = useQuery(GET_USER_SESSION_BY_ID, {
		variables: {
			filters: {
				user_id: parseInt(id),
				account_id: user && user.account ? parseInt(user.account.id) : null,
			},
		},
		fetchPolicy: 'cache-and-network',
	})

	useEffect(() => {
		if (sessionData) {
			const tempArr = sessionData.delegateSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			setSessionList(tempArr)
		}
	}, [sessionData])

	if (loading || sessionLoading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const { subjectsByCurrentAccount, availableForGroupSubjects } = data.byId

	const isReviewer = isReviewerRole(userRole, userRoleType)
	// const isAssessor = isAssessorRole(userRole, userRoleType)

	return (
		<div className="user-subjects">
			<div className="header">
				<div className="left-part">
					<h3>Learner courses</h3>
					{/* eslint-disable-next-line max-len */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<span className="link" onClick={switchCoursesView}>
						View learning archive
					</span>
				</div>
				<div className="left-part">
					{!isReviewer && <Button onClick={onAddCourseClick} content="Add course" />}
				</div>
			</div>
			<div className="tabs-view">
				<Tab panes={panes} />
			</div>

			{!isReviewer && subjectModal && (
				<AddSubject
					open={subjectModal}
					subjectsList={availableForGroupSubjects}
					onClose={() => setSubjectModal(false)}
				/>
			)}
		</div>
	)
}

UserSubjects.propTypes = {
	match: PropTypes.object.isRequired,
	switchCoursesView: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	learnerDetails: PropTypes.func,
	checkAvailableForGroupSubjects: PropTypes.func,
}

UserSubjects.defaultProps = {
	learnerDetails: () => {},
	checkAvailableForGroupSubjects: () => {},
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		learnerDetails: data => getLearnerDetails(data, dispatch),
		checkAvailableForGroupSubjects: data => getAvailableForGroupSubjects(data, dispatch),
	}),
	withRouter(UserSubjects),
)
