import gql from 'graphql-tag'

export const GET_CSV_IMPORT_LOGS = gql`
	query csvImportLogs($limit: Int) {
		csvImportLogs(limit: $limit) {
			full_data {
				id
				account {
					name
				}
				account_id
				details {
					success {
						index
						key
						val
						message
					}
					errors {
						index
						message
						key
					}
				}
				created_at
			}
		}
	}
`
