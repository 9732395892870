import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Grid, Loader, Modal } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'
import FormComponent from 'Helpers/form'
import { withContext } from 'Context'
import { GET_SESSION_BY_TOPICID } from '../../../../store/queries/settingQueries'
import { BOOKED_SESSION } from '../../../../store/mutations/settingMutations'
import './style.scss'

const BookSession = ({ open, onClose, currentSessionId, topicId }) => {
	const [submitting, setSubmitting] = useState(false)
	const [sessionList, setSessionList] = useState([])
	const [sessionId, setSessionId] = useState(null)
	const [bookedSessionMutation] = useMutation(BOOKED_SESSION)
	const filter = {
		topic_id: topicId,
	}
	// query for list data
	const { data: sessionsData, loading } = useQuery(GET_SESSION_BY_TOPICID, {
		variables: {
			filters: filter,
		},
		fetchPolicy: 'network-only',
	})
	useEffect(() => {
		if (sessionsData) {
			let parentSessionId = 0
			sessionsData.accountSessions.data.data.forEach(data => {
				if (currentSessionId === data.id && data.parent_session_id > 0) {
					parentSessionId = data.parent_session_id
				}
			})
			let filteredData = sessionsData.accountSessions.data.data.filter(
				data => !(data.status === 5) && data.id !== parentSessionId,
			)
			let response = filteredData.map(res => {
				return {
					key: res.id,
					value: res.id,
					text: res.title + ' (VSN -' + res.version + ') ' + res.date || '-',
				}
			})
			setSessionList(response)
		}
	}, [sessionsData])

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])
	const onBookedSession = () => {
		setSubmitting(true)
		bookedSessionMutation({
			variables: {
				current_session_id: currentSessionId || 0,
				booked_session_id: sessionId || 0,
			},
		})
			.then(res => {
				onClose(true)
				console.log(res)
			})
			.catch(error => {
				console.log('value in error', error)
				onClose()
			})
			.finally(() => {
				setSubmitting(false)
			})
	}
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Book Sessions</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<p>
										Select the session you would like to book this learner onto. Note this list will
										only show active sessions from the current topic e.g. only first aid sessions.
										If you cannot see the session you want please check it is not in the pending or
										planned state
									</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									{loading ? (
										<Loader active />
									) : sessionList.length > 0 ? (
										<FormComponent.Dropdown
											value={sessionId}
											name="topic"
											onChange={(e, { value }) => setSessionId(value)}
											placeholder="Select Session to book"
											options={sessionList}
											disabled={loading}
											selectOnBlur={false}
											selection
											sort={false}
											fluid
										/>
									) : (
										<p>No Sessions are Available</p>
									)}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields book-session-btns">
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												content="Close"
												fluid
												disabled={submitting}
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										{sessionList.length > 0 && (
											<Grid.Column tablet={16} computer={8}>
												<Button
													className="reverse"
													type="button"
													content="Confirm"
													disabled={submitting || loading}
													fluid
													onClick={onBookedSession}
												/>
											</Grid.Column>
										)}
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</>
	)
}

BookSession.propTypes = {
	open: PropTypes.bool,
	currentSessionId: PropTypes.number.isRequired,
	topicId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
}
BookSession.defaultProps = {
	open: false,
}

// export default

export default withToastManager(
	withContext(
		([
			{
				user: {
					user: {
						account: { role },
					},
				},
			},
		]) => ({ currentUserRole: role }),
		BookSession,
	),
)
