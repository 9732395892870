import gql from 'graphql-tag'

export const GET_USERS_MATRIX_DATA = gql`
	query users($filters: TrainingMatrixFilters) {
		trainingMatrix(filters: $filters) {
			data {
				data {
					key
					id
					first_name
					last_name
					email
					username
					account {
						start_date
						id
						key
					}
					groups {
						id
						name
					}
					subjects {
						key
						pivot_id
						id
						name
						target_completion
						date_completed
						refresh_period
						progress
						training_status
					}
				}
				per_page
				current_page
				last_page
				total
			}
		}
	}
`

export const GET_SUBJECTS = gql`
	query users($filters: TrainingMatrixFilters) {
		trainingMatrixStats(filters: $filters) {
			key
			id
			name
			course_completed_count
			course_compliance_count
			course_allocated_count
			__typename
		}
	}
`

export const GET_USERS_MATRIX_DATA_TOPIC = gql`
	query users($filters: TrainingMatrixFilters) {
		topicTrainingMatrix(filters: $filters) {
			data {
				data {
					key
					id
					first_name
					last_name
					email
					username
					topics {
						key
						id
						name
						target_completion
						date_completed
						training_status
						__typename
					}
					account {
						start_date
						id
						key
						__typename
					}
					groups {
						id
						name
						__typename
					}

					__typename
				}
				per_page
				current_page
				last_page
				total
				__typename
			}
			__typename
		}
	}
`
export const GET_SUBJECTS_TOPIC = gql`
	query users($filters: TrainingMatrixFilters) {
		topicTrainingMatrixStats(filters: $filters) {
			key
			id
			name
			topic_completed_count
			topic_compliance_count
			topic_allocated_count
			topic_overdue_count
			__typename
		}
	}
`
