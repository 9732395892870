import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import OutcomeCard from '../OutcomeCard'
import OutcomeQuestions from '../OutcomeQuestions'

import { buildCardData, evidenceDataFields, learningDataFields, observationDataFields } from './helper'

import './styles.scss'

const OutcomesItem = ({ data: { outcome, question, evidence, learning_links, observations } }) => {
	const learning_data = buildCardData(learning_links, learningDataFields)
	const evidence_data = buildCardData(evidence, evidenceDataFields)
	const observation_data = buildCardData(observations, observationDataFields)

	return (
		<div className="archive-outcome">
			<OutcomeQuestions outcome={outcome} question={question} />

			{!_.isEmpty(evidence) && (
				<OutcomeCard titleClassName="evidence" title="Evidence" cardData={evidence_data} />
			)}
			{!_.isEmpty(learning_links) && (
				<OutcomeCard titleClassName="viewed" title="Learning Viewed" cardData={learning_data} />
			)}
			{!_.isEmpty(observations) && (
				<OutcomeCard titleClassName="observation" title="Observation" cardData={observation_data} />
			)}
		</div>
	)
}

OutcomesItem.propTypes = {
	data: PropTypes.object.isRequired,
}

export default OutcomesItem
