import React, { useContext, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Button, Confirm, Grid, Message, Modal } from 'semantic-ui-react'
import _ from 'lodash'

import { userGroupRoles } from 'Constants/userGroupRoles'
import { StateContext } from 'Context/index'
import { useForm } from 'Hooks/useForm'

import FormComponent from 'Helpers/form'
import { withContext } from 'Context'
import { INVITE_USERS } from 'Mutations/inviteMutations'
// import { AttachSubjectList, GroupSelect } from 'Common'
import { GroupSelect } from 'Common'
// import { filterSubjectList } from 'Utils'
import { userRoles } from 'Constants/userRoles'

import './style.scss'

const checkRequireFields = (requiredFields, values, errors) => {
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = true
		}
	})
}
const checkEmailField = (name, value, errors) => {
	// eslint-disable-next-line max-len
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (!re.test(String(value).toLowerCase())) {
		errors[name] = true
	}
}

const validate = values => {
	const errors = {}

	const requiredFields = ['users', 'group_role']

	checkRequireFields(requiredFields, values, errors)

	if (values.users) {
		const userRequiredFields = ['first_name', 'last_name', 'email']

		values.users.forEach(item => {
			checkRequireFields(userRequiredFields, item, errors)
			checkEmailField('email', item.email, errors)
		})
	}

	if (values.subjects) {
		const subjectRequiredFields = ['id']

		values.subjects.forEach(item => {
			checkRequireFields(subjectRequiredFields, item, errors)
		})
	}

	// if (isNaN(values.group)) {
	// 	errors.group = true
	// }

	return errors
}

const AddUsers = ({ open, onClose, currentUserRole }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [existingUsersEmail, setExistingUsersEmail] = useState([])
	const [billingIncreaseConfirmationMessage, setBillingIncreaseConfirmationMessage] = useState(null)

	const [inviteUsers] = useMutation(INVITE_USERS)

	const [
		{
			user: {
				user: {
					account: { name: accountName, role: accountRole, roleType: accountRoleType },
				},
			},
		},
	] = useContext(StateContext)

	const {
		values: { group, group_role, subjects, users, billing_increase_confirmed },
		changed,
		errors,
		handleSubmit,
		handleSelectChange,
		handleRepeaterChange,
		handleChange,
		setNewValue,
	} = useForm(
		() => {
			const variables = {
				group: group != undefined ? parseInt(group) : null,
				group_role: parseInt(group_role),
				users,
				billing_increase_confirmed,
			}

			// variables.subjects = filterSubjectList(subjects)
			variables.subjects = []

			setSubmitting(true)

			inviteUsers({ variables })
				.then(() => onClose())
				.catch(error => {
					if (error.graphQLErrors[0].message === 'confirmation modal needed') {
						setBillingIncreaseConfirmationMessage(error.graphQLErrors[0].validation.message)
					} else {
						setExistingUsersEmail(error.graphQLErrors[0].validation.users_id)
					}
				})
				.finally(() => {
					setSubmitting(false)
				})
		},
		validate,
		{ users: [{}] },
	)

	// const changeGlobalSubjectValue = useCallback(
	// 	value => {
	// 		setNewValue('subjects', value)
	// 	},
	// 	[setNewValue],
	// )

	// const handleSubjectsChange = useCallback(
	// 	(index, e, { name, checked, value = checked }) => {
	// 		subjects[index][name] = value

	// 		handleChange(e, { name: 'subjects', value: subjects })
	// 	},
	// 	[handleChange, subjects],
	// )

	const handleGroupChange = useCallback(
		(e, { name, value }) => {
			handleSelectChange(e, { name, value })

			if ((group === 0 && value !== 0) || (group !== 0 && value === 0)) {
				setNewValue('group_role', undefined)
			}
		},
		[handleChange, subjects, setNewValue, group_role],
	)

	const groupsOptions = useMemo(() => {
		const groupsOptions = []

		if (accountRole === 1 && accountRoleType === 'account') {
			groupsOptions.unshift({
				key: 0,
				value: 0,
				text: accountName,
			})
		}

		return groupsOptions
	}, [accountRole, accountRoleType, accountName])

	const rolesOptions = useMemo(() => {
		if (currentUserRole == 6 && (group == null || group == '')) {
			return _.map(group === 0 ? userRoles : userGroupRoles, ({ id, role }) => ({
				key: id,
				value: id,
				text: role,
			}))
		} else {
			return _.map(
				group === 0 ? userRoles.filter(el => el.id != 6) : userGroupRoles.filter(el => el.id != 6),
				({ id, role }) => ({
					key: id,
					value: id,
					text: role,
				}),
			)
		}
	}, [group])

	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (changed && !skipConfirm) {
				setConfirmOpen(true)
			} else {
				onClose()
			}
		},
		[changed, setConfirmOpen, onClose],
	)

	// const subjectsList = useMemo(
	// 	() => (
	// 		<AttachSubjectList
	// 			subjects={subjects}
	// 			changeGlobalSubjectValue={changeGlobalSubjectValue}
	// 			handleSubjectsChange={handleSubjectsChange}
	// 			errors={errors}
	// 		/>
	// 	),
	// 	[subjects, changeGlobalSubjectValue, handleSubjectsChange, errors],
	// )

	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Add Learners</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<Grid.Row className="users-list">
										<FormComponent.Repeater
											name="users"
											values={users}
											error={errors.users}
											onChange={handleRepeaterChange}
											addButtonText="Add Another Learner"
											existingFields={existingUsersEmail}
										>
											<FormComponent
												customlabel="First name"
												name="first_name"
												disableAutocompleate
												error={errors.first_name}
											/>
											<FormComponent
												customlabel="Last name"
												name="last_name"
												disableAutocompleate
												error={errors.last_name}
											/>
											<FormComponent
												customlabel="Email"
												name="email"
												disableAutocompleate
												error={errors.email}
											/>
										</FormComponent.Repeater>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}>
											<GroupSelect
												label="Group"
												name="group"
												additionalOptions={groupsOptions}
												value={!isNaN(group) ? group : ''}
												icon="angle down"
												selectOnBlur={false}
												onChange={handleGroupChange}
												fluid
												placeholder="Group"
											/>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<FormComponent.Dropdown
												label="Role"
												name="group_role"
												value={group_role || ''}
												options={rolesOptions}
												onChange={handleSelectChange}
												icon="angle down"
												selection
												selectOnBlur={false}
												error={errors.group_role}
												fluid
												placeholder="Role"
											/>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="submit"
												loading={submitting}
												disabled={submitting}
												fluid
												content="Create & Send"
											/>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										{!_.isEmpty(errors) && (
											<Grid.Column textAlign="center" tablet={16} computer={16}>
												<Message negative>
													<Message.Header>Please fill in all fields</Message.Header>
												</Message>
											</Grid.Column>
										)}
									</Grid>
								</Grid.Column>
								{/* <Grid.Column tablet={16} computer={8}>
									<Grid.Row>{subjectsList}</Grid.Row>
								</Grid.Column> */}
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				className="confirm"
				header="Closing add learners window"
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>

			<Confirm
				open={Boolean(billingIncreaseConfirmationMessage) && !submitting}
				className="confirm"
				size="mini"
				confirmButton="Confirm"
				content={billingIncreaseConfirmationMessage}
				onConfirm={e => {
					setNewValue('billing_increase_confirmed', true)
					handleSubmit(e)
				}}
				onCancel={() => setBillingIncreaseConfirmationMessage(null)}
			/>
		</>
	)
}

AddUsers.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	currentUserRole: PropTypes.number.isRequired,
}
AddUsers.defaultProps = {
	open: false,
}

// export default

export default withContext(
	([
		{
			user: {
				user: {
					account: { role },
				},
			},
		},
	]) => ({ currentUserRole: role }),
	AddUsers,
)
