import React from 'react'
import { Modal } from 'semantic-ui-react'

const ManagerSignOffModalContent = () => (
	<Modal.Content>
		<p>
			<b>Your goal is to achieve the assessment.</b>
		</p>
		<p>
			<b>Is this course new to you?</b>
		</p>
		<ul>
			<li>Yes - then choose Learning.</li>
			<li>No - you already know something, go straight to the Assessment.</li>
			<li>Some courses only have the Assessment.</li>
		</ul>
		<p>
			<b>Your assessment results</b> will appear under Outcomes so you can review your answers. If the course has
			learning, you can view learning to improve your knowledge.
		</p>
		<p>
			<b>Now add evidence</b> by using the tick boxes to select the outcomes you got wrong. You can select more
			than one outcome. Add your explanation in the form at the bottom of the page. Your manager will use this to
			decide if you meet this outcome.
		</p>
		<p>
			<b>Top tips:</b>
		</p>
		<ul>
			<li>Use the filter to only “Show outcomes requiring attention”.</li>
			<li>Use the + symbol to expand and read the questions you need to work on.</li>
			<li>“Learning viewed” shows what learning you have viewed and when.</li>
		</ul>
		<p>
			<b>The Certificate</b> will appear in “My Certificates” when you have completed the course.
		</p>
	</Modal.Content>
)

export default ManagerSignOffModalContent
