import React, { useState } from 'react'
import { Loader, Button, Grid } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import LearnerAddEvidence from 'Modals/session/LearnerAddEvidence'
import CrossReference from 'Modals/session/CrossReference'
import EvidenceItem from './EvidenceItem'
import './style.scss'

const EvidencesList = ({
	loading,
	error,
	data,
	sessionId,
	pivotId,
	manager,
	evidenceRefetch,
	delegateSessionById,
	showEviModalOpen,
	setOpenEviModal,
	userId,
}) => {
	const [openModal, setOpenModal] = useState(false)
	const [openCrossReferenceModal, setOpenCrossReferenceModal] = useState(false)
	const [currentEviId, setCurrentEviId] = useState(null)

	const openModalHandler = eviId => {
		setCurrentEviId(eviId)
		setOpenModal(true)
	}

	const createUserModalCloseHandler = () => {
		setOpenModal(false)
	}

	const openCrossReferenceModalHandler = eviId => {
		setCurrentEviId(eviId)
		setOpenCrossReferenceModal(true)
	}

	const crossReferenceModalCloseHandler = () => {
		setOpenCrossReferenceModal(false)
	}

	if (loading) return <Loader active inline="centered" style={{ marginTop: '30px' }} />
	if (error) return null
	const { session } = data

	return (
		<div className="evidences-list-wrap">
			<Grid columns={4}>
				<Grid.Row>
					<Grid.Column>
						<h2>Evidence</h2>
					</Grid.Column>
					<Grid.Column />
					<Grid.Column />
					<Grid.Column>
						{delegateSessionById && !delegateSessionById.is_completed ? (
							<Button size="small" onClick={() => setOpenEviModal(true)} fluid content="Add Evidence" />
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<ul className="evidences-list">
				{session && session.length > 0 ? (
					<>
						{_.map(session, evidence => (
							<EvidenceItem
								sectionId={parseInt(sessionId)}
								pivotId={parseInt(pivotId)}
								isManager={manager}
								key={evidence.id}
								{...evidence}
								evidenceRefetch={evidenceRefetch}
								openModalHandler={openModalHandler}
								openCrossReferenceModalHandler={openCrossReferenceModalHandler}
								delegateSessionById={delegateSessionById}
								showEviModalOpen={showEviModalOpen}
							/>
						))}
					</>
				) : (
					<div className="evidence-item" style={{ textAlign: 'center' }}>
						No Evidence Found.
					</div>
				)}
			</ul>
			{openModal && (
				<LearnerAddEvidence
					open={openModal}
					onClose={createUserModalCloseHandler}
					evidenceRefetch={evidenceRefetch}
					evidenceId={currentEviId}
				/>
			)}
			{openCrossReferenceModal && (
				<CrossReference
					open={openCrossReferenceModal}
					onClose={crossReferenceModalCloseHandler}
					evidenceRefetch={evidenceRefetch}
					evidenceId={currentEviId}
					userId={userId}
				/>
			)}
		</div>
	)
}

EvidencesList.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	data: PropTypes.object,
	manager: PropTypes.bool.isRequired,
	sessionId: PropTypes.string.isRequired,
	pivotId: PropTypes.string.isRequired,
	evidenceRefetch: PropTypes.func.isRequired,
	delegateSessionById: PropTypes.object,
	showEviModalOpen: PropTypes.func.isRequired,
	setOpenEviModal: PropTypes.func.isRequired,
	userId: PropTypes.any,
}

EvidencesList.defaultProps = {
	error: null,
	data: { session: [] },
	delegateSessionById: null,
	userId: null,
}

export default EvidencesList
