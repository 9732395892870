import gql from 'graphql-tag'

export const GET_SUBJECTS_LIST = gql`
	query subjects($name: String, $activity_status: Int) {
		all(name: $name, activity_status: $activity_status) {
			key
			id
			pivot_id
			name
			img
			due
			target_completion
			progress
			is_completed
			is_start
			date_completed
			obs_course_id
		}
	}
`
