import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Button, Confirm, Grid, Modal, Icon, Popup } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'

import { withContext } from 'Context'
import { IMPORT_BULK_SESSION_CSV } from 'Mutations/importMutations'
import { post } from '../../../../store/queries/rest/client'

import './style.scss'

const ImportSession = ({ open, onClose, toastManager }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [showcsv, setShowcsv] = useState(false)
	const instQuoteOne = 'The Help Centre has a detailed explanation of the import process. '
	const instQuoteTwo = 'This includes what codes to put in the different columns. '
	const instQuoteThree = instQuoteOne + instQuoteTwo
	const [importBulkSessionCSV] = useMutation(IMPORT_BULK_SESSION_CSV)
	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (!skipConfirm) {
				setConfirmOpen(true)
			} else {
				onClose()
			}
		},
		[setConfirmOpen, onClose],
	)

	const handleShowcsv = () => {
		setShowcsv(true)
	}

	const handleImageChange = e => {
		const body = new FormData()
		body.append('import_sheet', e.target.files[0])
		//console.log(e.target.files[0])
		setSubmitting(true)
		post('/import-user', body)
			.then(response => {
				return response.json()
			})
			.then(data => {
				const variables = { file: data.url }
				importBulkSessionCSV({ variables: variables })
					.then(msg => {
						onClose()
						console.log(msg)
						toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
					})
					.catch(error => {
						console.log('value in error', error)
						onClose()
					})
					.finally(() => {
						setSubmitting(false)
					})
			})
	}
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Import Bulk Sessions</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<p>
										Use the import bulk sessions option to quickly create multiple session against a
										topic.
									</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid>
										<Grid.Column tablet={16} computer={8}>
											<a
												rel="noopener noreferrer"
												href={require('../../../../assets/bulk_session_import.csv')}
												target="_blank"
												className="ui fluid button"
											>
												Download import template <i className="fas fa-download btn-color" />
											</a>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="button"
												disabled={submitting}
												content="Upload Sessions"
												fluid
												onClick={handleShowcsv}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										{showcsv && (
											<div className="field-wrap">
												<span className="field-label">Upload CSV</span>
												<label className="upload" htmlFor="Upload">
													<input
														name="upload"
														id="upload"
														type="file"
														accept=".csv, text/csv"
														onChange={handleImageChange}
													/>
													<span className="upload-custom"></span>
												</label>
											</div>
										)}
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<div style={{ marginTop: '-30px' }}>
											<span>{instQuoteThree}</span>
											<br />
											<br />
											<span>
												Click on the information icons for details of each field to enter on the
												import spreadsheet. Note: the spreadsheet must be saved as a CSV
											</span>
											<br />
											<br />
											<span>Topic ID </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Topic ID</h4>
														<p>The topic ID can be found on: Settings &gt; Topics</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Session Name </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Session Name</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Location</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Required</p>
														<h4>Below are the options</h4>
														<p>For Face to face value = 1</p>
														<p>For Online value = 2</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Venue</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Venue</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Session Type</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Required</p>
														<h4>Below are the options</h4>
														<p>For Group session value = 1</p>
														<p>For 1.1 session value = 2</p>
														<p>For Self Directed Learning value = 3</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Status</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Required</p>
														<h4>Below are the options</h4>
														<p>For Active value = 1</p>
														<p>For Cancelled value = 2</p>
														<p>For Complete value = 3</p>
														<p>For Planned value = 4</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Date</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: 28/02/2024</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
										</div>
									</Grid.Row>
									<Grid.Row className="session-fields-info">
										<div>
											<span>Learning Hours</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: 8</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Description</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: This is a description</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Places</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: 4</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Start Time</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Provide time in 24hr format</p>
														<p>Example: 14:02:00</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>End Time</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Provide time in 24hr format</p>
														<p>Example: 18:02:00</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Link</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Valid URL</p>
														<p>Example: https://click-learning.co.uk/</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Default Refresh Period</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Below are the options</h4>
														<p>3 years = 36 </p>
														<p>2 years = 24 </p>
														<p>1 year = 12 </p>
														<p>6 months = 6 </p>
														<p>3 months = 3 </p>
														<p>None = 0 </p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
										</div>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}></Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Close"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				className="confirm"
				header="Closing add learning plan window"
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>
		</>
	)
}

ImportSession.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}
ImportSession.defaultProps = {
	open: false,
}

// export default

export default withToastManager(
	withContext(
		([
			{
				user: {
					user: {
						account: { role },
					},
				},
			},
		]) => ({ currentUserRole: role }),
		ImportSession,
	),
)
