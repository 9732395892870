import React, { memo, useCallback } from 'react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import IrreversibleConfirmation from 'Modals/common/IrreversibleConfirmation'

const DeleteModal = ({ total, opened, loading, disabled, onCancel, onDelete, activeIndex }) => {
	const handleDelete = useCallback(() => onDelete({ delete: true }), [onDelete])

	const subject = [pluralize('course', total, true), pluralize('session', total, true)][activeIndex]

	const renderContent = () => {
		return `You are about to delete ${subject}. Please note this is permanent and
		their is no undelete option.`
	}

	const renderConfirmationHelperContent = () => {
		return `To confirm the delete please enter the number of ${subject} you are deleting into
		the box below.`
	}

	const renderCancelButton = () => {
		return <Button className="reverse" size="tiny" disabled={disabled} content="Cancel" onClick={onCancel} />
	}

	const renderConfirmButton = confirmInputValue => {
		const isDisabled = Number(confirmInputValue) !== Number(total)

		return (
			<Button
				loading={loading}
				disabled={isDisabled || disabled}
				size="tiny"
				content="Confirm"
				onClick={handleDelete}
			/>
		)
	}

	return (
		<IrreversibleConfirmation
			opened={opened}
			content={renderContent()}
			confirmationHelperContent={renderConfirmationHelperContent()}
			renderCancelButton={renderCancelButton}
			renderConfirmButton={renderConfirmButton}
		/>
	)
}

DeleteModal.propTypes = {
	total: PropTypes.number,
	opened: PropTypes.bool,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	activeIndex: PropTypes.bool,
}

DeleteModal.defaultProps = {
	total: 0,
	opened: false,
	loading: false,
	disabled: false,
	activeIndex: 0,
}

export default memo(DeleteModal)
