import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { withContext } from 'Context'

import { GET_SUBJECT_SECTION_EVIDENCES } from 'Queries/evidenceQueries'
import { setExportToPdf } from 'Actions/appActions'
import EvidenceItem from './EvidenceItem'

const EvidencesList = ({ match, user, manager, matchForExport, setExportPdf, exportToPdf, autoSignEnabled }) => {
	const {
		params: { pivotId, sectionId, userId },
	} = matchForExport ? matchForExport : match

	const { loading, error, data } = useQuery(GET_SUBJECT_SECTION_EVIDENCES, {
		variables: {
			pivot_id: parseInt(pivotId),
			subject_section_id: parseInt(sectionId),
			user_id: parseInt(manager ? userId : user.id),
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (matchForExport && !loading) {
			setExportPdf({ ...exportToPdf, evidenceReady: exportToPdf.evidenceReady + 1 })
		}
	}, [loading])

	if (loading) return <Loader active inline="centered" style={{ marginTop: '30px' }} />
	if (error || !data.subjectSection.length) return null

	const { subjectSection } = data

	return (
		<div className="evidences-list-wrap">
			<h2>Evidence</h2>
			<ul className="evidences-list">
				{_.map(subjectSection, evidence => (
					<EvidenceItem
						sectionId={parseInt(sectionId)}
						pivotId={parseInt(pivotId)}
						isManager={manager}
						key={evidence.id}
						isPdfExport={!!matchForExport}
						{...evidence}
						autoSignEnabled={autoSignEnabled}
					/>
				))}
			</ul>
		</div>
	)
}

EvidencesList.propTypes = {
	match: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	manager: PropTypes.bool.isRequired,
	matchForExport: PropTypes.object,
	exportToPdf: PropTypes.object.isRequired,
	setExportPdf: PropTypes.func.isRequired,
	autoSignEnabled: PropTypes.bool,
}

EvidencesList.defaultProps = {
	matchForExport: null,
	autoSignEnabled: false,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
				app: { export_to_pdf },
			},
			dispatch,
		]) => ({
			user,
			exportToPdf: export_to_pdf,
			// actions
			setExportPdf: data => setExportToPdf(data, dispatch),
		}),
		EvidencesList,
	),
)
