import * as Types from 'Types'
import initialState from '../initialState/userState'

const userReducer = (prevState, action) => {
	let state = prevState
	switch (action.type) {
		case Types.SIGN_IN:
		case Types.SIGN_UP:
			state = {
				...prevState,
				user: action.payload,
				loggedIn: true,
			}
			localStorage.setItem('user', JSON.stringify(state))
			return state
		case Types.SET_ACCOUNT:
			state = {
				...prevState,
				user: {
					...prevState.user,
					account: action.payload,
				},
			}
			localStorage.setItem('user', JSON.stringify(state))
			return state
		case Types.SIGN_OUT:
			localStorage.removeItem('isSigninToken')
			localStorage.removeItem('isEmailExist')
			return initialState
		case Types.RESTORE_USER:
			return {
				...prevState,
				...action.payload,
				loggedIn: Boolean(action.payload && action.payload.user && action.payload.user.token),
			}
		case Types.PROFILE_UPDATE_SUCCESS:
			state = {
				...prevState,
				user: { ...prevState.user, ...action.payload },
			}
			localStorage.setItem('user', JSON.stringify(state))
			return state
		case Types.IMPERSONATE:
			state = {
				...prevState,
				user: action.payload,
				loggedIn: true,
				impersonate: {
					active: true,
					backupData: prevState.user,
				},
			}
			localStorage.setItem('user', JSON.stringify(state))
			return state
		case Types.IMPERSONATE_QUIT:
			state = {
				...prevState,
				...initialState,
				user: prevState.impersonate.backupData,
				loggedIn: Boolean(prevState.impersonate.backupData),
			}
			localStorage.setItem('user', JSON.stringify(state))
			return state
		default:
			return prevState
	}
}

export default userReducer
