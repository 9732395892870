import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { GET_SUBJECT_BY_ID } from 'Pages/Subjects/SingleSubject/queries'
import { withContext } from 'Context/index'
import { setExportToPdf } from 'Actions/appActions'
import SubjectSectionInfo from '../Subjects/SubjectSectionInfo'

const SectionsToExport = ({ match, exportToPdf }) => {
	const {
		params: { pivotId, userId },
	} = match

	const { activityReady, evidenceReady } = exportToPdf

	const { data, loading } = useQuery(GET_SUBJECT_BY_ID, {
		variables: { pivot_id: parseInt(pivotId), user_id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (
			!loading &&
			data.byId &&
			activityReady === data.byId.sections.length &&
			evidenceReady === data.byId.sections.length
		) {
			window.parent.postMessage({ exportReady: true }, '*')
		}
	}, [loading, activityReady, evidenceReady])

	if (loading) return null

	const { sections } = data.byId

	return (
		<div id="sections-to-export" className="sections-to-export">
			{sections.map(section => (
				<SubjectSectionInfo
					key={section.id}
					matchForExport={{ ...match, params: { ...match.params, sectionId: section.id } }}
				/>
			))}
		</div>
	)
}

SectionsToExport.propTypes = {
	match: PropTypes.object.isRequired,
	exportToPdf: PropTypes.shape({
		activityReady: PropTypes.number.isRequired,
		evidenceReady: PropTypes.number.isRequired,
	}).isRequired,
}

export default withContext(
	([
		{
			app: { export_to_pdf },
		},
		dispatch,
	]) => ({
		exportToPdf: export_to_pdf,
		setExportPdf: data => setExportToPdf(data, dispatch),
	}),
	SectionsToExport,
)
