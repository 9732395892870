import gql from 'graphql-tag'

export const GET_USER_LEARNING_ARCHIVE_BY_ID = gql`
	query users($id: Int!) {
		byId(id: $id) {
			id
			learningArchive {
				id
				module_name
				course_name
				completion_date
				type
				signed_off
			}
		}
	}
`
