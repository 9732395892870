import { getRequestHeaders } from '.'

/**
 * You idiot who set up ES LINT
 */
const getScormRegistrationsUrl = ({ userSubjectId, userSubjectSectionId, userId }, path) => {
	const url = [`${process.env.REACT_APP_API_PATH}/scorm/registrations/${userSubjectId}/${userSubjectSectionId}`, path]
		.filter(Boolean)
		.join('/')
	const queryParams = userId ? `?userId=${userId}` : ''

	return `${url}${queryParams}`
}

export const createRegistration = async ({ userSubjectId, userSubjectSectionId }) => {
	const scromRegistrationUrl = getScormRegistrationsUrl({ userSubjectId, userSubjectSectionId })
	const requestOptions = {
		method: 'POST',
		headers: getRequestHeaders(),
	}

	const response = await fetch(scromRegistrationUrl, requestOptions)

	if (!response.ok) {
		throw response
	}
}

export const buildRegistrationLaunchLink = async ({ userSubjectId, userSubjectSectionId, redirectOnExitUrl }) => {
	const scromRegistrationUrl = getScormRegistrationsUrl({ userSubjectId, userSubjectSectionId }, 'launchLink')
	const requestOptions = {
		method: 'POST',
		headers: getRequestHeaders(),
		body: JSON.stringify({ redirectOnExitUrl }),
	}

	const response = await fetch(scromRegistrationUrl, requestOptions)

	if (!response.ok) {
		throw response
	}

	return response.json()
}

export const getRegistrationProgress = async ({ userSubjectId, userSubjectSectionId, userId, method = 'GET' }) => {
	const scromRegistrationUrl = getScormRegistrationsUrl({ userSubjectId, userSubjectSectionId, userId })
	const requestOptions = {
		method,
		headers: getRequestHeaders(),
	}

	const response = await fetch(scromRegistrationUrl, requestOptions)

	if (!response.ok) {
		throw response
	}

	return response.json()
}

export const syncRegistrationProgress = ({ userSubjectId, userSubjectSectionId }) => {
	return getRegistrationProgress({ userSubjectId, userSubjectSectionId, method: 'PUT' })
}

export const isScorm = subjectSection => {
	const { scorm_course_id: scormCourseId } = subjectSection

	return Boolean(scormCourseId)
}

export const isCompleted = subjectSection => {
	const { is_completed: isCompleted } = subjectSection

	return isCompleted
}

export const isNotStarted = subjectSection => {
	const { progress, is_start, ...restSubject } = subjectSection

	if (isScorm(subjectSection)) {
		return !is_start
	}

	return progress === 0 && !isCompleted(restSubject)
}

export const isInProgress = subjectSection => {
	const { progress, is_start, ...restSubject } = subjectSection

	if (isScorm(subjectSection)) {
		return Boolean(is_start)
	}

	return progress > 0 && progress <= 100 && !isCompleted(restSubject)
}
