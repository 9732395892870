import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withContext } from 'Context'
import { navigationRoutes } from 'Constants'
import { Breadcrumbs } from 'Common'
import { allRoles, resolveRole } from 'Utils/roles'

const PagesRoute = ({ userRole, userRoleType }) => {
	const filteredRoutes = navigationRoutes.filter(route => _.find(route.access[userRoleType], ['id', userRole]))

	return (
		<div className="container" id="pages-container">
			<Breadcrumbs />
			<Switch>
				{filteredRoutes.map(route => {
					const Component = route.component
					return <Route key={route.path} {...route} component={Component} />
				})}
				<Redirect to={filteredRoutes[0].path} />
			</Switch>
		</div>
	)
}

PagesRoute.propTypes = {
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => resolveRole(user),
	PagesRoute,
)
