import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Loader, Tab } from 'semantic-ui-react'
import { CoursesTabsNav } from 'Common'
import queryString from 'query-string'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router'
import classNames from 'classnames'
import FormComponent from 'Helpers/form'
import { GET_USER_SESSION_BY_ID } from 'Queries/settingQueries'
import { SUBJECT_ACTIVITY_STATUSES } from 'Constants/subject'
import { GET_SUBJECTS_LIST } from './queries'
import CoursesTable from './CoursesTable'
import { HELP_TEXT } from './constants'
import SessionTable from './SessionTable'
import './style.scss'

const UsersList = () => {
	const { search, pathname } = useLocation()
	const history = useHistory()
	const [sessionVisible, setSessionVisible] = useState(false)
	const [sessionList, setSessionList] = useState([])

	const { activity_status: searchActivityStatus } = queryString.parse(search)
	const [name, setName] = useState('')
	const debounceSetName = useMemo(() => _.debounce(value => setName(value), 500), [setName])
	const inputRef = useRef(null)

	const { data, error, loading } = useQuery(GET_SUBJECTS_LIST, {
		variables: {
			activity_status: +searchActivityStatus || SUBJECT_ACTIVITY_STATUSES.CURRENT,
			name: name || undefined,
		},
		fetchPolicy: 'network-only',
	})

	const { data: sessionData, loading: sessionLoading } = useQuery(GET_USER_SESSION_BY_ID, {
		variables: {
			filters: {
				activity_status: +searchActivityStatus || SUBJECT_ACTIVITY_STATUSES.CURRENT,
			},
		},
		fetchPolicy: 'cache-and-network',
	})

	const onClearSearch = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.inputRef.value = ''
			debounceSetName('')
		}
	}, [inputRef, debounceSetName])
	const handleChangeSearch = useCallback(
		e => {
			debounceSetName(e.target.value)
		},
		[debounceSetName],
	)

	useEffect(() => {
		if (!searchActivityStatus) {
			history.replace({
				pathname,
				search: queryString.stringify({ activity_status: SUBJECT_ACTIVITY_STATUSES.CURRENT }),
			})
		}
	}, [searchActivityStatus])

	useEffect(() => {
		if (sessionData) {
			if (sessionData.delegateSessions.data.data.length > 0) setSessionVisible(true)
			const tempArr = sessionData.delegateSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			setSessionList(tempArr)
		}
	}, [sessionData])

	const panes = [
		{
			menuItem: data ? 'Courses: (' + data.all.length + ')' : 'Courses',
			render: () => (
				<Tab.Pane>
					<CoursesTable coursesList={data.all} />
				</Tab.Pane>
			),
		},
		sessionVisible
			? {
					menuItem: sessionList ? 'Sessions: (' + sessionList.length + ')' : 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionTable coursesList={sessionList} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]

	if (loading || sessionLoading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	return (
		<>
			<div className="subjects-list">
				<CoursesTabsNav />
				<Grid>
					<Grid.Row>
						<Grid.Column mobile={16} tablet={8} computer={8} largeScreen={12} widescreen={12}>
							{HELP_TEXT[searchActivityStatus]}
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
							<FormComponent
								inputClassName="search"
								label={
									<button
										className={classNames({ active: name.length })}
										type="button"
										onClick={onClearSearch}
									>
										{!!name.length && <i className="fal fa-times-circle" />}
									</button>
								}
								name="username"
								defaultValue={name}
								onChange={handleChangeSearch}
								placeholder="Search"
								labelPosition="right"
								inputRef={inputRef}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{(loading || sessionLoading) && <Loader active />}
				{error && <span>Something went wrong :(</span>}
				<div className="tabs-view">
					<Tab panes={panes} />
				</div>
			</div>
		</>
	)
}

export default UsersList
