import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Divider } from 'semantic-ui-react'
import { ProgressBar } from '../../../common'

const ProgressInfo = ({
	outcomes_remaining,
	firstAssessment,
	auto_sign_off_enabled,
	assessment,
	isManager,
	learning_stats,
	progress,
}) => {
	return (
		<div>
			<div className={classNames('section-progress', { manager: isManager })}>
				Progress: <span>{outcomes_remaining} outcomes remaining to complete the course</span>
				<br />
				{firstAssessment && (
					<>
						Date started: <span>{moment(firstAssessment.created_at).format('DD/MM/YYYY')}</span>
						<br />
					</>
				)}
				{auto_sign_off_enabled && assessment && (
					<>
						Number of retakes: <span>{assessment.retake}</span>
					</>
				)}
			</div>
			<div className="mb_18">
				<Divider />
			</div>
			{learning_stats.learning_link_count > 0 && (
				<div className="margin-top-props">
					{learning_stats.learning_link_count > 0 && learning_stats.learning_view_count > 0 ? (
						<ProgressBar
							title="Learning Progress"
							color="blue"
							infoIcon
							percents={Math.ceil(
								(learning_stats.learning_view_count / learning_stats.learning_link_count) * 100,
							).toString()}
						/>
					) : (
						<ProgressBar infoIcon title="Learning Progress" color="blue" />
					)}
				</div>
			)}
			<div>
				<ProgressBar infoIcon title="Assessment Progress" percents={progress} />
			</div>
		</div>
	)
}

ProgressInfo.propTypes = {
	outcomes_remaining: PropTypes.number.isRequired,
	progress: PropTypes.number,
	learning_stats: PropTypes.object,
	firstAssessment: PropTypes.object,
	assessment: PropTypes.object,
	auto_sign_off_enabled: PropTypes.bool.isRequired,
	isManager: PropTypes.bool.isRequired,
}

ProgressInfo.defaultProps = {
	firstAssessment: null,
	assessment: null,
	learning_stats: {},
	progress: 0,
}

export default ProgressInfo
