import gql from 'graphql-tag'

export const GET_SUBJECTS_FOR_INVITES = gql`
	query subjects {
		currentAccount {
			id
			name
			target_completion
			refresh_period
		}
	}
`
