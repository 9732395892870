import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table } from 'Common'
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { Routes } from 'Constants'
import moment from 'moment'

const TopicSessionItem = ({ dataList, match }) => {
	const history = useHistory()
	const {
		params: { id },
	} = match

	let sessionType
	if (dataList.session_type && dataList.session_type === 1) {
		sessionType = 'Group session'
	} else if (dataList.session_type && dataList.session_type === 2) {
		sessionType = '1:1 session'
	} else if (dataList.session_type && dataList.session_type === 3) {
		sessionType = 'Self Directed learning'
	} else {
		sessionType = '-'
	}

	let sessionStatus
	if (dataList.status && dataList.status === 1) {
		sessionStatus = 'Active'
	} else if (dataList.status && dataList.status === 2) {
		sessionStatus = 'Cancelled'
	} else if (dataList.status && dataList.status === 3) {
		sessionStatus = 'Complete'
	} else if (dataList.sessionStatus && dataList.sessionStatus === 4) {
		sessionStatus = 'Planned'
	} else if (dataList.status && dataList.status === 5) {
		sessionStatus = 'Pending'
	} else {
		sessionStatus = '-'
	}

	const tableData = {
		id: dataList.id,
		title: dataList.title,
		date: dataList.date ? moment(dataList.date).format('DD/MM/YYYY') : '-',
		sessionType: sessionType,
		venue: dataList.venue ? dataList.venue : '-',
		notes: dataList.notes ? dataList.notes : '-',
		tags: dataList.tags && dataList.tags.length > 0 ? _.map(dataList.tags, list => list.name).join(', ') : '-',
		places: dataList.places ? dataList.places : '-',
		count: dataList.users_count ? dataList.users_count : '-',
	}
	const viewSession = () => {
		history.push(`${Routes.SESSION.path}/${id}/view/${dataList.id}`)
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell title={tableData.id}>{tableData.id}</Table.Cell>
				<Table.Cell title={tableData.title}>
					{tableData.title} {dataList.version ? ` (VSN - ${dataList.version})` : null}
				</Table.Cell>
				<Table.Cell title={tableData.date}>{tableData.date}</Table.Cell>
				<Table.Cell title={sessionType}>{sessionType}</Table.Cell>
				<Table.Cell title={tableData.venue}>{tableData.venue}</Table.Cell>
				<Table.Cell title={tableData.notes}>{tableData.notes}</Table.Cell>
				<Table.Cell title={sessionStatus}>{sessionStatus}</Table.Cell>
				<Table.Cell title={tableData.count}>{tableData.count}</Table.Cell>
				<Table.Cell title={tableData.places}>{tableData.places}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View Session" onClick={viewSession} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

TopicSessionItem.propTypes = {
	dataList: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
}

export default TopicSessionItem
