import React, { useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withContext } from 'Context'
import { GET_ASSESSOR_USERS_LIST } from 'Queries/usersQueries'
import { getFilteredUsers } from 'Actions/usersActions'

import FormComponent from 'Helpers/form'
import UsersTable from './UsersTable'

import './style.scss'

const List = ({ filter, handleSetPage, handleSortTable, getUsers }) => {
	const { data, error, loading } = useQuery(GET_ASSESSOR_USERS_LIST, {
		variables: {
			filters: {
				page: filter.page,
				username: filter.username,
				sort_direction: filter.sort_direction,
				sort_param: filter.sort_param,
			},
		},
		fetchPolicy: 'network-only',
	})

	const handleSearchChange = useCallback(
		_.debounce((e, { value }) => {
			getUsers({
				page: filter.page,
				username: value,
				sort_direction: filter.sort_direction,
				sort_param: filter.sort_param,
			})
		}, 1000),
		[filter],
	)

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	return (
		<div className="users-list assessor">
			<div className="page-title">
				<h1>Learners ({data.allForAssessor.total_count})</h1>

				<div className="actions">
					<FormComponent
						inputClassName="search"
						name="username"
						defaultValue={filter.username}
						onChange={handleSearchChange}
						input={{
							autoFocus: !!filter.username.length,
						}}
						placeholder="Search"
					/>
				</div>
			</div>

			{loading ? (
				<Loader active />
			) : (
				<UsersTable
					usersList={data.allForAssessor.data.data}
					page={filter.page}
					setPage={handleSetPage}
					perPage={data.allForAssessor.data.per_page}
					totalCount={data.allForAssessor.total_count}
					length={data.allForAssessor.data.total}
					handleSortTable={handleSortTable}
					activeSortParam={{
						name: filter.sort_param || '',
						order: filter.sort_direction || 'asc',
					}}
				/>
			)}
		</div>
	)
}

List.propTypes = {
	filter: PropTypes.object.isRequired,
	handleSetPage: PropTypes.func.isRequired,
	handleSortTable: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			users: { filter },
		},
		dispatch,
	]) => ({
		// state
		filter,
		// actions
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	List,
)
