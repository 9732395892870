import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import PropsTypes from 'prop-types'
import { Table, Button } from 'semantic-ui-react'
import _ from 'lodash'

import { Routes } from 'Constants'
import { ProgressBar } from 'Common'

import './style.scss'

const SectionItem = ({ getSubjectSectionLink, ...restSectionProps }) => {
	const { match, pivotId, id, name, progress, is_start, is_verified } = restSectionProps
	const history = useHistory()
	const {
		params: { userId },
	} = match
	const sectionActions = {
		start: {
			condition: !userId && !is_start && !is_verified,
			text: 'Start',
		},
		continue: {
			condition: !userId && is_start && !is_verified,
			text: 'Continue',
		},
		view: {
			condition: userId || is_verified,
			text: 'View',
		},
	}
	const activeAction = _.find(sectionActions, action => action.condition).text
	const managerLink = userId ? `${Routes.MANAGER}/${userId}` : ''
	const redirectLink = () => {
		const subjectSectionLink =
			typeof getSubjectSectionLink === 'function'
				? getSubjectSectionLink(restSectionProps)
				: [`${managerLink}${Routes.SUBJECTS.path}`, pivotId, id].join('/')

		history.push(subjectSectionLink)
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr section-item">
				<Table.Cell>{name}</Table.Cell>
				<Table.Cell textAlign="center">
					<ProgressBar percents={String(progress)} inactiveBg="#f4f6fc" position="bottom" />
				</Table.Cell>
				<Table.Cell>{is_verified ? 'Signed off' : 'Not Signed Off'}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" onClick={redirectLink}>
						{activeAction}
					</Button>
				</Table.Cell>
			</Table.Row>
		</>
	)
}

SectionItem.propTypes = {
	match: PropsTypes.object.isRequired,
	pivotId: PropsTypes.number.isRequired,
	id: PropsTypes.number.isRequired,
	name: PropsTypes.string,
	progress: PropsTypes.number,
	is_start: PropsTypes.bool.isRequired,
	is_verified: PropsTypes.bool.isRequired,
	getSubjectSectionLink: PropsTypes.func,
}

SectionItem.defaultProps = {
	name: '',
	progress: 0,
	getSubjectSectionLink: null,
}

export default withRouter(SectionItem)
