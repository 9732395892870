import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { Table, ProgressBar } from 'Common'
import moment from 'moment'

const SessionItem = ({
	pivot_id,
	id,
	due,
	date_completed,
	target_completion,
	is_completed,
	progress,
	title,
	date,
	venue,
	session_type,
	status,
	version,
}) => {
	const history = useHistory()
	const isCompleted = Boolean(date_completed) && is_completed
	const isNotStarted = progress === 0 && !isCompleted
	const isInProgress = progress > 0 && progress <= 100 && !isCompleted && !isNotStarted
	const targetCompletionExists = Boolean(target_completion)
	const targetCompletion = moment(target_completion)
	const targetCompletionToDisplay = targetCompletion.isAfter(moment()) ? targetCompletion.format('DD/MM/YYYY') : due

	let sessionType, sessionStatus
	if (session_type && session_type === 1) {
		sessionType = 'Group session'
	} else if (session_type && session_type === 2) {
		sessionType = '1:1 session'
	} else if (session_type && session_type === 3) {
		sessionType = 'Self Directed learning'
	} else {
		sessionType = '-'
	}

	if (status && status === 1) {
		sessionStatus = 'Active'
	} else if (status && status === 2) {
		sessionStatus = 'Cancelled'
	} else if (status && status === 3) {
		sessionStatus = 'Complete'
	} else if (status && status === 4) {
		sessionStatus = 'Planned'
	} else if (status && status === 5) {
		sessionStatus = 'Pending'
	} else {
		sessionStatus = '-'
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row
				className="custom-tr customCursor"
				positive
				onClick={() => {
					history.push('/course/session/' + id + '/' + pivot_id)
				}}
			>
				<Table.Cell>
					{title} {version ? ` (VSN - ${version})` : null}
				</Table.Cell>
				<Table.Cell>{date ? moment(date).format('DD/MM/YYYY') : '-'}</Table.Cell>
				<Table.Cell>{sessionType}</Table.Cell>
				<Table.Cell>{venue ? venue : '-'}</Table.Cell>
				<Table.Cell>{sessionStatus}</Table.Cell>
				<Table.Cell>
					{isCompleted ? '-' : targetCompletionExists ? targetCompletionToDisplay : 'None'}
				</Table.Cell>
				<Table.Cell>
					{isNotStarted && 'Not started'}
					{isInProgress && (
						<ProgressBar percents={progress} style={{ textAlign: 'center' }} inactiveBg="#f4f6fc" />
					)}
					{isCompleted && 'Completed'}
				</Table.Cell>
				<Table.Cell>{isCompleted ? moment(date_completed).format('DD/MM/YYYY') : '-'}</Table.Cell>
				<Table.Cell>
					<Button
						type="button"
						size="small"
						onClick={() => {
							history.push('/course/session/' + id + '/' + pivot_id)
						}}
						content="View"
					/>
				</Table.Cell>
			</Table.Row>
		</>
	)
}

SessionItem.propTypes = {
	pivot_id: PropTypes.number,
	id: PropTypes.number.isRequired,
	is_completed: PropTypes.bool.isRequired,
	date_completed: PropTypes.string,
	target_completion: PropTypes.string,
	progress: PropTypes.number.isRequired,
	due: PropTypes.string,
	title: PropTypes.string,
	date: PropTypes.string,
	venue: PropTypes.string,
	status: PropTypes.number,
	session_type: PropTypes.number,
	version: PropTypes.number,
}

SessionItem.defaultProps = {
	date_completed: null,
	target_completion: null,
	due: null,
	title: null,
	date: null,
	venue: null,
	status: null,
	session_type: null,
	pivot_id: null,
	version: null,
}

export default SessionItem
