export default {
	filter: {
		username: '',
		role: '',
		group: '',
		subject: '',
		status_evidence: '',
		active: '',
		page: 1,
	},
	customFilter: {
		username: '',
		role: '',
		group: '',
		subject: '',
		status_evidence: '',
		active: '',
		page: 1,
		tag_id: '',
		status: '',
	},
	learnerDetails: {},
}
