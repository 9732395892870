import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Loader } from 'semantic-ui-react'
import _ from 'lodash'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { GET_USER_CERTIFICATES } from 'Queries/usersQueries'

import { CertificateItem } from 'Common/CertificateItem'

import './style.scss'
import { PageMetaTags } from 'Common'

const PAGE_NAME = 'My Certificates'

const Certificates = ({ setBreadcrumbs }) => {
	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const { data, error, loading } = useQuery(GET_USER_CERTIFICATES, {
		fetchPolicy: 'network-only',
	})

	if (loading) return <Loader active />
	if (error) return 'Error'

	return (
		<div className="certificates-list">
			<PageMetaTags title={PAGE_NAME} />
			{!_.isEmpty(data.certificates) ? (
				<div className="available-certificates">
					<h2>{PAGE_NAME}</h2>
					<Grid>
						<Grid.Row>
							{_.map(data.certificates, certificate => (
								<Grid.Column key={certificate.id} mobile={16} table={8} computer={4}>
									<CertificateItem {...certificate} />
								</Grid.Column>
							))}
						</Grid.Row>
					</Grid>
				</div>
			) : (
				'No certificates yet'
			)}
		</div>
	)
}

Certificates.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(Certificates)
