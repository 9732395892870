import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Button, Confirm, Grid, Modal, Icon, Popup } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'

import { withContext } from 'Context'
import { IMPORT_SESSION_CSV } from 'Mutations/importMutations'
import { post } from '../../../../store/queries/rest/client'

import './style.scss'

const ImportSession = ({ open, onClose, toastManager }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [showcsv, setShowcsv] = useState(false)
	const instQuoteOne = 'The Help Centre has a detailed explanation of the import process. '
	const instQuoteTwo = 'This includes what codes to put in the different columns. '
	const instQuoteThree = instQuoteOne + instQuoteTwo
	const [importSessionCSV] = useMutation(IMPORT_SESSION_CSV)
	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (!skipConfirm) {
				setConfirmOpen(true)
			} else {
				onClose()
			}
		},
		[setConfirmOpen, onClose],
	)

	const handleShowcsv = () => {
		setShowcsv(true)
	}

	const handleImageChange = e => {
		const body = new FormData()
		body.append('import_sheet', e.target.files[0])
		//console.log(e.target.files[0])
		setSubmitting(true)
		post('/import-user', body)
			.then(response => {
				return response.json()
			})
			.then(data => {
				const variables = { file: data.url }
				importSessionCSV({ variables: variables })
					.then(msg => {
						onClose()
						console.log(msg)
						toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
					})
					.catch(error => {
						console.log('value in error', error)
						onClose()
					})
					.finally(() => {
						setSubmitting(false)
					})
			})
	}
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Import Sessions</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<p>
										Use the import sessions option to quickly allocate sessions to multiple learners
										at the same time. If you are importing legacy training data then set the
										completion date to match the training certificate date. This will ensure the
										session appears on the training matrix as signed off with this date.
									</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid>
										<Grid.Column tablet={16} computer={8}>
											<a
												rel="noopener noreferrer"
												href={require('../../../../assets/session_import.csv')}
												target="_blank"
												className="ui fluid button"
											>
												Download import template <i className="fas fa-download btn-color" />
											</a>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="button"
												disabled={submitting}
												content="Upload Sessions"
												fluid
												onClick={handleShowcsv}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										{showcsv && (
											<div className="field-wrap">
												<span className="field-label">Upload CSV</span>
												<label className="upload" htmlFor="Upload">
													<input
														name="upload"
														id="upload"
														type="file"
														accept=".csv, text/csv"
														onChange={handleImageChange}
													/>
													<span className="upload-custom"></span>
												</label>
											</div>
										)}
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<div style={{ marginTop: '-30px' }}>
											<span>{instQuoteThree}</span>
											<br />
											<br />
											<span>
												Click on the information icons for details of each field to enter on the
												import spreadsheet. Note: the spreadsheet must be saved as a CSV
											</span>
											<br />
											<br />
											<span>Learner Email </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Learner Email</h4>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Session Id </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>
															The session ID can be found on: Settings &gt; Topics &gt;
															View all Sessions
														</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Session due date</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: 28/02/2024</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Session completion date</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: 28/02/2024</p>
														<p>Optional</p>
													</Grid.Column>
												</Grid>
											</Popup>
										</div>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}></Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Close"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				className="confirm"
				header="Closing add learning plan window"
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>
		</>
	)
}

ImportSession.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}
ImportSession.defaultProps = {
	open: false,
}

// export default

export default withToastManager(
	withContext(
		([
			{
				user: {
					user: {
						account: { role },
					},
				},
			},
		]) => ({ currentUserRole: role }),
		ImportSession,
	),
)
