import React from 'react'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { CertificateItem } from 'Common'

import './style.scss'

const Certificate = props => {
	return (
		<div className="certificate">
			<div className="page-title">
				<h1>Certificate</h1>
			</div>
			<Grid>
				<Grid.Row>
					<Grid.Column mobile={16} table={8} computer={4}>
						<CertificateItem {...props} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

CertificateItem.propTypes = {
	subject_name: PropTypes.string.isRequired,
	issuer: PropTypes.string.isRequired,
	created_at: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	subject: PropTypes.object,
}

CertificateItem.defaultProps = {
	subject: {},
}

export default Certificate
