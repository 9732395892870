import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import OutcomeItem from './OutcomeItem'
import './style.scss'

const OutcomesList = ({ outcomes, subjectSectionType, selectedItems, manager, setSelectedItems, isExport }) => {
	const [expandItems, setExpandItems] = useState([])
	if (!outcomes || outcomes.length === 0 || outcomes == null) return false

	return (
		<ul className="list-outcomes">
			{_.map(outcomes, outcome => {
				const isSelected = _.includes(selectedItems, outcome.id)

				return (
					<OutcomeItem
						key={outcome.id}
						isExpand={_.includes(expandItems, outcome.id)}
						isSelected={isSelected}
						subjectSectionType={subjectSectionType}
						openItem={() => setExpandItems([...expandItems, outcome.id])}
						closeItem={() => setExpandItems(_.filter(expandItems, expandItem => expandItem !== outcome.id))}
						manager={manager}
						isExport={isExport}
						selectItem={
							isSelected
								? () =>
										setSelectedItems(
											_.filter(selectedItems, selectedItem => selectedItem !== outcome.id),
										)
								: () => setSelectedItems([...selectedItems, outcome.id])
						}
						outcome={outcome}
					/>
				)
			})}
		</ul>
	)
}

OutcomesList.propTypes = {
	outcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
	setSelectedItems: PropTypes.func.isRequired,
	manager: PropTypes.bool.isRequired,
	subjectSectionType: PropTypes.string.isRequired,
	isExport: PropTypes.bool.isRequired,
}

export default OutcomesList
