import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import FormComponent from 'Helpers/form'

const UserSubjectSelect = ({ subjects, ...props }) => {
	const options = _.map(
		_.filter(subjects, function(subject) {
			return subject.observation_required
		}),
		subject => ({
			key: subject.name,
			value: subject.pivot_id,
			text: subject.name,
		}),
	)

	return (
		<FormComponent
			label="Course"
			name="subject"
			type="dropdown"
			options={options}
			placeholder="Course"
			icon="angle down"
			selection
			{...props}
		/>
	)
}

UserSubjectSelect.propTypes = {
	subjects: PropTypes.arrayOf(PropTypes.object),
}

UserSubjectSelect.defaultProps = {
	subjects: [],
}

export { UserSubjectSelect }
