import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'
import classNames from 'classnames'

const Password = field => {
	const [readOnly, setReadOnly] = useState(Boolean(field.disableAutocompleate))

	const fieldProps = { ...field }

	if (field.disableAutocompleate) {
		delete fieldProps.disableAutocompleate
		fieldProps.onFocus = () => setReadOnly(false)
	}

	return (
		<div className={classNames('field-wrap', { error: fieldProps.error })}>
			{fieldProps.label && <span className="field-label">{fieldProps.label}</span>}
			<Input {...fieldProps} type="password" label={false} readOnly={readOnly} autoComplete="off" />
		</div>
	)
}

export default Password
