import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Routes } from 'Constants'

import MenuItem from './MenuItem'
import './style.scss'

const OutcomesMenu = ({ outcomes }) => {
	return (
		<ul className="menu-wrap">
			{_.map(outcomes, item => (
				<MenuItem key={item.id} {...item} />
			))}
			<li className="bookmarks">
				<Link to={Routes.BOOKMARKS}>
					<i className="far fa-bookmark" />
					<span>Your Bookmarks</span>
				</Link>
			</li>
		</ul>
	)
}

OutcomesMenu.propTypes = {
	outcomes: PropTypes.arrayOf(Object).isRequired,
}

export default OutcomesMenu
