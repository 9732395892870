import * as Types from 'Types'
import { client } from '..'

const authMiddleware = ({ type }) => {
	switch (type) {
		case Types.SIGN_OUT:
			client.resetStore()
			localStorage.removeItem('user')
			return { type: Types.CLEAR_CONTEXT }
		default:
			break
	}
}

export default authMiddleware
