import { ASSESSOR } from './rolesSubtypes'

const accountlessRoles = [
	{
		id: 1,
		role: 'Assessor',
		type: [ASSESSOR],
	},
]

export { accountlessRoles }
