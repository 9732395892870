import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import FormComponent from 'Helpers/form'
import { Table } from 'Common'
import { Button } from 'semantic-ui-react'
import { Routes } from 'Constants'

const TopicsItem = ({ user, isSelect, selectUser }) => {
	const history = useHistory()
	const tableData = {
		id: user.id,
		userName: user.name,
		Tags: user.tags && user.tags.length > 0 ? _.map(user.tags, list => list.name).join(', ') : '-',
		Refresher:
			user.refresh_period_formatted != undefined && user.refresh_period_formatted != null
				? user.refresh_period_formatted
				: '-',
		// Description: user.description ? user.description : '-',
	}

	const viewTopic = () => {
		history.push({
			pathname: generatePath(Routes.TOPIC_VIEW, {
				id: user.id,
			}),
		})
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.id}>{tableData.id}</Table.Cell>
				<Table.Cell title={tableData.userName}>{tableData.userName}</Table.Cell>
				{/* <Table.Cell title={tableData.Description}>{tableData.Description}</Table.Cell> */}
				<Table.Cell title={tableData.Tags}>{tableData.Tags}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={viewTopic} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}
TopicsItem.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
}

TopicsItem.defaultProps = {
	isSelect: false,
}

export default TopicsItem
