import React, { useMemo } from 'react'
import { legacyAnswers } from 'Constants'
import PropTypes from 'prop-types'
import _ from 'lodash'

import './styles.scss'

const OutcomeQuestions = ({ outcome, question }) => {
	const { outcome_reference, evidence_added, outcome_state } = outcome
	const { question_text, answer_text, outcome_text, answer_given } = question
	const selectedAnswers = answer_given ? answer_given.split('/') : []

	const answers = useMemo(() => {
		return _.map(answer_text, (item, index) => {
			let answer = {}

			if (answer_text[index] === 'True' || answer_text[index] === 'False') {
				answer = {
					text: answer_text[index],
					variant: answer_text[index],
				}
			} else {
				answer = {
					text: answer_text[index],
					variant: legacyAnswers[index],
				}
			}
			return answer
		})
	}, [answer_text])

	return (
		<>
			<div className="outcome-labels">
				<span className="outcome-label">{outcome_reference}</span>
				<span className="outcome-label">{outcome_state}</span>
				<span className="outcome-label">{evidence_added ? 'Evidence Added' : 'Evidence Not Added'}</span>
			</div>
			<div className="outcome-card">
				<div className="outcome-card-item">
					<span>Question</span>
					<span>{question_text}</span>
				</div>
				<div className="outcome-card-item">
					<span>Answer(s)</span>
					<ol className="card-item-list">
						{_.map(answers, (option, index) => {
							return (
								<li key={index} className={selectedAnswers.includes(option.variant) ? 'bold' : null}>
									{option.text}
								</li>
							)
						})}
					</ol>
				</div>
				<div className="outcome-card-item">
					<span>Outcome</span>
					<span>{outcome_text}</span>
				</div>
			</div>
		</>
	)
}

OutcomeQuestions.propTypes = {
	question: PropTypes.object.isRequired,
	outcome: PropTypes.object.isRequired,
}

export default OutcomeQuestions
