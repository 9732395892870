import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table } from 'Common'
import moment from 'moment'
import FormComponent from 'Helpers/form'

const SessionItem = ({ list, isSelect, selectSession, handleSessionsChange, refreshPeriodData, listIndex }) => {
	let sessionType, sessionStatus
	if (list.session_type && list.session_type === 1) {
		sessionType = 'Group session'
	} else if (list.session_type && list.session_type === 2) {
		sessionType = '1:1 session'
	} else if (list.session_type && list.session_type === 3) {
		sessionType = 'Self Directed learning'
	} else {
		sessionType = '-'
	}
	if (list.status && list.status === 1) {
		sessionStatus = 'Active'
	} else if (list.status && list.status === 2) {
		sessionStatus = 'Cancelled'
	} else if (list.status && list.status === 3) {
		sessionStatus = 'Complete'
	} else if (list.status && list.status === 4) {
		sessionStatus = 'Planned'
	} else if (list.status && list.status === 5) {
		sessionStatus = 'Pending'
	} else {
		sessionStatus = '-'
	}
	let tempDateHold
	if (moment(list.date, 'YYYY-MM-DD', true).isValid()) {
		tempDateHold = moment(moment(list.date, 'YYYY-MM-DD')).format('DD-MM-YYYY')
	} else {
		tempDateHold = list.date
	}
	const tableData = {
		userName: list.name ? list.name : list.title,
		Tags: list.tags && list.tags.length > 0 ? _.map(list.tags, list => list.name).join(', ') : '-',
		// ShowDate: list.date ? moment(list.date).format('DD/MM/YYYY') : '-',
		Venue: list.venue ? list.venue : '-',
		Places: list.places ? list.places : '-',
		Date: tempDateHold,
		minDate: moment(tempDateHold, 'DD-MM-YYYY').isBefore(moment()) ? moment(tempDateHold, 'DD-MM-YYYY') : moment(),
		Refresher:
			list.default_refresher_period != undefined && list.default_refresher_period != null
				? list.default_refresher_period
				: '-',
	}
	const thisHandleSessionsChange = (index, e, data) => {
		if (data.value == '') {
			tableData.Date = moment()
		} else {
			handleSessionsChange(index, e, data)
		}
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectSession} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.userName}>
					{tableData.userName} {list.version ? `(VSN - ${list.version})` : null}
				</Table.Cell>
				{/* <Table.Cell title={tableData.ShowDate}>{tableData.ShowDate}</Table.Cell> */}
				<Table.Cell>
					{isSelect && (
						<>
							<FormComponent.Date
								name="date"
								icon="calendar alternate outline"
								// minDate={tableData.minDate}
								dateFormat="DD-MM-YYYY"
								// popupPosition="top right"
								clearable={false}
								clearIcon="calendar alternate outline"
								onChange={(e, data) => thisHandleSessionsChange(listIndex, e, data)}
								value={tableData.Date}
								error={tableData.Date}
							/>
						</>
					)}
					{!isSelect && <>{tableData.Date}</>}
				</Table.Cell>
				<Table.Cell title={tableData.Refresher} className="refresh-period-td">
					{isSelect && (
						<FormComponent.Dropdown
							name="default_refresher_period"
							type="dropdown"
							options={refreshPeriodData.refreshPeriodOptions}
							value={tableData.Refresher}
							onChange={(e, data) => handleSessionsChange(listIndex, e, data)}
							icon="angle down"
							selection
							selectOnBlur={false}
						/>
					)}
				</Table.Cell>
				<Table.Cell title={tableData.Tags}>{tableData.Tags}</Table.Cell>
				<Table.Cell>{sessionType}</Table.Cell>
				<Table.Cell title={tableData.Venue}>{tableData.Venue}</Table.Cell>
				<Table.Cell>{sessionStatus}</Table.Cell>
				<Table.Cell title={tableData.Places}>{tableData.Places}</Table.Cell>
			</Table.Row>
		</>
	)
}
SessionItem.propTypes = {
	list: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectSession: PropTypes.func.isRequired,
	handleSessionsChange: PropTypes.func.isRequired,
	refreshPeriodData: PropTypes.object.isRequired,
	listIndex: PropTypes.any.isRequired,
}

SessionItem.defaultProps = {
	isSelect: false,
}

export default SessionItem
