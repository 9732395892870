import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Routes } from 'Constants/Routes'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'

import SingleSubject from 'Pages/Subjects/SingleSubject'
import SubjectSectionInfo from 'Pages/Subjects/SubjectSectionInfo'
import LearningArchive from 'Pages/Subjects/LearningArchive'

import { ObsCourse, ObsModule } from 'Pages/Subjects/ObsLearning'

const SubjectsManagerRoutes = () => {
	const obsCoursePath = [
		Routes.MANAGER,
		':userId',
		Routes.SUBJECTS.path.slice(1),
		':userSubjectId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
	].join('/')

	const obsModulePath = [
		Routes.MANAGER,
		':userId',
		Routes.SUBJECTS.path.slice(1),
		':userSubjectId',
		':subjectSectionId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
		':obsModuleId',
	].join('/')

	return (
		<Switch>
			<Route exact path={`${Routes.MANAGER}/:userId${Routes.SUBJECTS.path}/:pivotId`} component={SingleSubject} />
			<Route exact path={obsCoursePath} component={ObsCourse} />
			<Route
				exact
				path={`${Routes.MANAGER}/:userId${Routes.SUBJECTS.path}/:pivotId/:sectionId`}
				component={SubjectSectionInfo}
			/>
			<Route exact path={obsModulePath} component={ObsModule} />
			<Route
				exact
				path={`${Routes.MANAGER}/:userId${Routes.SUBJECTS.learningArchive}`}
				component={LearningArchive}
			/>
			<Redirect to="/" />
		</Switch>
	)
}

export default SubjectsManagerRoutes
