import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { GET_BOOKMARKS } from 'Queries/outcomesQueries'
import { Routes } from 'Constants'

import './style.scss'
import { withContext } from 'Context/index'
import { restoreSelectedSection } from 'Actions/subjectActions'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags } from 'Common'

const Bookmarks = ({ selectedSection, _restoreSelectedSection, setBreadcrumbs }) => {
	const { data, error, loading } = useQuery(GET_BOOKMARKS, { fetchPolicy: 'network-only' })

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Bookmarks',
			},
		])
		if (selectedSection.isFetching) _restoreSelectedSection()
	}, [])

	if (selectedSection.isFetching) return <Loader active />

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const getLink = ({ id }, outcome_id) =>
		`${Routes.SUBJECTS.path}/${selectedSection.pivotId}/${id}/learn/${outcome_id}`

	return (
		<>
			<PageMetaTags title="Bookmarks" />
			<Grid columns="4">
				{_.map(data.all, ({ id, name, description, outcome_id, subjectSection }) => (
					<Grid.Column key={id}>
						<div className="bookmark-item">
							<h4>{name}</h4>
							<div className="info-item">
								<span>Course</span>
								<span>{subjectSection.subject.name}</span>
							</div>
							<div className="info-item">
								<span>Section</span>
								<span>{subjectSection.name}</span>
							</div>
							<p>{`${description.slice(0, 100)}...`}</p>
							<Link className="link" to={getLink(subjectSection, outcome_id)}>
								<i className="fal fa-arrow-square-right" />
							</Link>
						</div>
					</Grid.Column>
				))}
			</Grid>
		</>
	)
}

Bookmarks.propTypes = {
	selectedSection: PropTypes.object,
	_restoreSelectedSection: PropTypes.func,
	setBreadcrumbs: PropTypes.func.isRequired,
}

Bookmarks.defaultProps = {
	selectedSection: {},
	_restoreSelectedSection: () => {},
}

export default withBreadcrumbs(
	withContext(
		([
			{
				subject: { selectedSection },
			},
			dispatch,
		]) => ({
			// state
			selectedSection,
			// actions
			_restoreSelectedSection: () => restoreSelectedSection(dispatch),
		}),
		Bookmarks,
	),
)
