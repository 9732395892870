import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Routes } from 'Constants/Routes'

import SingleUser from 'Pages/Users/SingleUser'

import ActivityList from './ActivityList'

import './style.scss'

const Activity = () => (
	<Switch>
		<Route exact path={Routes.ACTIVITY} component={ActivityList} />
		<Route exact path={Routes.ACTIVITY_SINGLE_USER} component={SingleUser} />
	</Switch>
)

export default Activity
