import { useEffect } from 'react'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'

export default function useBreadcrumbs({
	subjectName,
	userSubjectId,
	subjectSectionId,
	setBreadcrumbs,
	obsCourseId,
	obsModuleId,
	name,
	lastBreadcrumbSegment,
}) {
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Courses',
				path: Routes.SUBJECTS.path,
			},
			{
				name: subjectName,
				path: [Routes.SUBJECTS.path, userSubjectId, OBS_SUBJECT_PREFIX, obsCourseId].join('/'),
			},
			{
				name,
				path: [
					Routes.SUBJECTS.path,
					userSubjectId,
					subjectSectionId,
					OBS_SUBJECT_PREFIX,
					obsCourseId,
					obsModuleId,
				].join('/'),
			},
			{
				name: lastBreadcrumbSegment,
			},
		])
	}, [subjectName, userSubjectId, subjectSectionId, obsCourseId, obsModuleId, name, lastBreadcrumbSegment])
}
