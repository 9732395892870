import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Dimmer, Dropdown, Loader, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withContext } from 'Context'
import { profileUpdateAction } from 'Actions/profileActions'
import { signOutSuccessAction, quitImpersonateAction } from 'Actions/authActions'
import { openLeftNav } from 'Actions/appActions'

import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import { SIGN_OUT_MUTATION } from 'Mutations/authMutations'

import { Greeting, CustomerLogo, UserPhoto } from 'Common'
import FormComponent from 'Helpers/form'

import './style.scss'
import { allRoles, isAccountlessRole, isManagerRole, resolveRole } from 'Utils/roles'
import SwitchDashboard from './SwitchDashboard'
import useCloseRecite from '../Recite/useCloseRecite'
import ManagerNotificationsToggle from './ManagerNotificationsToggle'

const Header = ({
	openMenu,
	user,
	updateProfileSuccess,
	signOutSuccess,
	openLeftNav,
	quitImpersonate,
	impersonate,
	userRole,
	userRoleType,
}) => {
	const [accessibilitySaving, setAccessibilitySaving] = useState(false)
	const [signOutLoading, setSignOutLoading] = useState(false)
	const [signOutMutation] = useMutation(SIGN_OUT_MUTATION)
	const [updateProfileMutation] = useMutation(UPDATE_PROFILE_MUTATION)
	const thirdPartyURL = user && user.account && user.account.third_party_url
	const thirdPartyButtonLabel = user && user.account && user.account.third_party_button_label
	const isSigninToken = localStorage.getItem('isSigninToken')
	const handleToggleAccessibility = useCallback(
		(accessibility = false) => {
			setAccessibilitySaving(true)
			if (!accessibility) {
				setSignOutLoading(true)
			}

			updateProfileMutation({
				variables: {
					id: parseInt(user.id),
					first_name: user.first_name,
					last_name: user.last_name,
					email: user.email,
					username: user.username,
					accessibility,
				},
			})
				.then(({ data: { updateProfile } }) => {
					if (Boolean(updateProfile) === true) {
						updateProfileSuccess({ ...user, accessibility })

						setAccessibilitySaving(false)
						if (!accessibility) {
							setSignOutLoading(false)
						}
					}
				})
				.finally(() => window.location.reload())
		},
		[user, updateProfileMutation, updateProfileSuccess, setAccessibilitySaving],
	)
	const closeRecipe = useCloseRecite(handleToggleAccessibility)

	const changeAccessibilityHandler = useCallback(
		(e, { checked }) => {
			if (checked) {
				handleToggleAccessibility(true)
			} else {
				closeRecipe()
			}
		},
		[handleToggleAccessibility, closeRecipe],
	)

	const changeAccessibilityHandlerBtn = useCallback(() => {
		handleToggleAccessibility(!user.accessibility)
	}, [handleToggleAccessibility])

	const signOutHandler = () => {
		setSignOutLoading(true)

		signOutMutation()
			.then(({ data: { signOut } }) => {
				if (signOut) signOutSuccess()
			})
			.finally(() => {
				setSignOutLoading(false)
			})
	}

	return (
		<div className="app-header">
			<div className="account-logo-section">
				{!isAccountlessRole(userRoleType) && <CustomerLogo pointing="left" />}
				{isSigninToken &&
					isSigninToken == 'true' &&
					thirdPartyURL &&
					thirdPartyURL !== '' &&
					thirdPartyButtonLabel &&
					thirdPartyButtonLabel !== '' && (
						<a
							className="button-link primary third-party-link-lg"
							href={thirdPartyURL}
							target="_blank"
							rel="noopener noreferrer"
						>
							{thirdPartyButtonLabel}
						</a>
					)}
			</div>

			<div className="container container-section">
				<div className="header-inner">
					<button
						className="menu-btn"
						type="button"
						style={{ marginRight: isSigninToken && isSigninToken === 'true' ? '10px' : 'auto' }}
						onClick={() => openLeftNav(!openMenu)}
					>
						<i className={`far fa-${openMenu ? 'times' : 'bars'}`} />
					</button>
					{isSigninToken &&
						isSigninToken == 'true' &&
						thirdPartyURL &&
						thirdPartyURL !== '' &&
						thirdPartyButtonLabel &&
						thirdPartyButtonLabel !== '' && (
							<a
								className="button-link primary third-party-link-mb"
								href={thirdPartyURL}
								target="_blank"
								rel="noopener noreferrer"
							>
								{thirdPartyButtonLabel}
							</a>
						)}

					{isManagerRole(userRole, userRoleType) && <ManagerNotificationsToggle />}

					{!isAccountlessRole(userRoleType) && <SwitchDashboard />}
					<Popup
						content="Open your accessibility toolbar"
						trigger={
							<button
								disabled={accessibilitySaving}
								className="button accessibility-btn"
								type="button"
								onClick={changeAccessibilityHandlerBtn}
							>
								<i className="fas fa-universal-access accessibility-icon" />
							</button>
						}
					/>
					<div className="user-box">
						<div className="avatar" style={{ backgroundImage: user.photo ? `url(${user.photo})` : '' }}>
							{!user.photo && <UserPhoto size="mini" user={user} />}
						</div>
						<div className={classNames('content', { impersonate: impersonate.active })}>
							<span className="greeting">
								<Greeting impersonate={impersonate.active} />
							</span>
							<Dropdown
								pointing="top right"
								className="name"
								text={`${user.first_name} ${user.last_name}`}
								icon={null}
							>
								<Dropdown.Menu>
									<Dropdown.Item
										onClick={e => {
											e.stopPropagation()
										}}
									>
										<FormComponent
											customlabel="Accessibility"
											name="active"
											type="radio"
											checked={!!user.accessibility}
											disabled={accessibilitySaving}
											onChange={changeAccessibilityHandler}
											toggle
										/>
									</Dropdown.Item>
									<Dropdown.Item onClick={signOutHandler}>Sign Out</Dropdown.Item>
									{impersonate.active && (
										<Dropdown.Item className="quit-impersonate" onClick={quitImpersonate}>
											Quit Impersonate Mode
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>

			{signOutLoading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
		</div>
	)
}

Header.propTypes = {
	openMenu: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	impersonate: PropTypes.object.isRequired,
	updateProfileSuccess: PropTypes.func.isRequired,
	signOutSuccess: PropTypes.func.isRequired,
	quitImpersonate: PropTypes.func.isRequired,
	openLeftNav: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

const ContextHeader = withContext(
	([
		{
			app: { openMenu },
			user,
		},
		dispatch,
	]) => ({
		// state
		openMenu,
		user: user.user,
		impersonate: user.impersonate,
		...resolveRole(user.user),
		// actions
		updateProfileSuccess: data => profileUpdateAction(data, dispatch),
		signOutSuccess: () => signOutSuccessAction(dispatch),
		quitImpersonate: () => quitImpersonateAction(dispatch),
		openLeftNav: data => openLeftNav(data, dispatch),
	}),
	Header,
)

export { ContextHeader as Header }
