import React, { useEffect, useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { GET_GROUPS_LIST } from 'Queries/groupsQueries'
import { EXPORT_GROUPS } from 'Mutations/exportMutations'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { PageMetaTags } from 'Common'
import FormComponent from 'Helpers/form'
import _ from 'lodash'
import { isReviewerRole } from 'Utils/roles'
import CreateUpdateGroup from 'Modals/groups/CreateUpdateGroup'
import { withContext } from 'Context/index'
import buildTree from 'Helpers/buildTree'
import useExport from 'Hooks/useExport'
import { compare } from 'Utils/index'
import Wrapper from './Wrapper'
import './style.scss'

const PAGE_NAME = 'Group Manager'

const Groups = ({ setBreadcrumbs, userRole, userRoleType }) => {
	const [open, setOpen] = useState(false)
	const [initialValues, setInitialValues] = useState({})
	const [query, setQuery] = useState(null)
	const [activeIds, setActiveIds] = useState([])
	const [allGroups, setAllGroups] = useState(data)
	const [formattedGroups, setFormattedGroups] = useState(data)

	const [loadParentGroup, { data, error, loading }] = useLazyQuery(GET_GROUPS_LIST, {
		fetchPolicy: 'network-only',
	})
	const [loadGroups, { data: loadedGroups, loading: lazyGroupLoading }] = useLazyQuery(GET_GROUPS_LIST)
	const [exg, exportLoading] = useExport(EXPORT_GROUPS)
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Group Manager',
			},
		])
	}, [])

	useEffect(() => {
		loadParentGroup()
	}, [])

	const setGroups = groups => {
		setAllGroups(groups)
		// Build groups tree
		setFormattedGroups(
			buildTree(
				_.map(groups.sort(compare('name')), item => ({
					...item,
					onClick: openModal,
					loadGroups,
					query,
					toggleActiveIds,
				})),
			),
		)
	}

	useEffect(() => {
		if (data) setGroups(data.all)
	}, [data])

	useEffect(() => {
		if (loadedGroups) setGroups([...allGroups, ...loadedGroups.all])
	}, [lazyGroupLoading, loadedGroups])

	const toggleActiveIds = (id, childIds) => {
		if (activeIds.includes(id)) {
			const copyActiveIds = [...activeIds].filter(activeId => !(activeId === id || childIds.includes(activeId)))
			setActiveIds(copyActiveIds)
		} else {
			setActiveIds([...activeIds, id])
		}
	}

	const closeModal = () => {
		if (initialValues) {
			setInitialValues({})
		}

		setOpen(false)
	}

	const openModal = item => {
		if (item) {
			setInitialValues(item)
		}

		setOpen(true)
	}

	const debounceSearch = useCallback(
		_.debounce(query => loadParentGroup({ variables: { search: query } }), 300),
		[],
	)

	const handleSearchChange = useCallback((e, { value }) => {
		setQuery(value.toLowerCase())
		if (value.length > 2) debounceSearch(value)
		else if (value.length < 1) loadParentGroup()
	}, [])

	if (error) return <span>Something went wrong :(</span>

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<div className="group-manager">
				<div className="page-title">
					<h1>Group Manager</h1>
					<div className="actions">
						<FormComponent
							icon="search"
							clearable
							value={query || ''}
							onChange={handleSearchChange}
							placeholder="Search"
						/>
						{!isReviewer && <Button size="large" content="Add Group" onClick={() => openModal()} />}
						<Button
							type="button"
							size="large"
							loading={exportLoading}
							className="mo"
							content="Export to CSV"
							onClick={exg}
						/>
					</div>
				</div>
				{loading ? (
					<Loader active />
				) : (
					<Wrapper
						groups={formattedGroups}
						loadGroups={loadGroups}
						loadParentGroup={loadParentGroup}
						toggleActiveIds={toggleActiveIds}
						activeIds={activeIds}
						openModal={openModal}
						isReviewer={isReviewer}
						query={query}
					/>
				)}
				{open && !isReviewer && (
					<CreateUpdateGroup
						open={open}
						initialValues={initialValues}
						onClose={closeModal}
						activeIds={activeIds}
						loadParentGroup={loadParentGroup}
						query={query}
					/>
				)}
			</div>
		</>
	)
}

Groups.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// State
		userRole: role,
		userRoleType: roleType,
	}),
	withBreadcrumbs(Groups),
)
