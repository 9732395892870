import { useCallback, useEffect } from 'react'

export default function useScormLearningExitIframe({ onMessage, message = '*', targetOrigin = '*' } = {}) {
	const notifyParentFrame = useCallback(() => {
		window.top.postMessage(message, targetOrigin)
	}, [message, targetOrigin])

	useEffect(() => {
		const onIframeMessage = event => {
			if (Object.is(event.data, message)) {
				onMessage(message)
			}
		}

		window.addEventListener('message', onIframeMessage)

		return () => {
			window.removeEventListener('message', onIframeMessage)
		}
	}, [onMessage, message])

	return { notifyParentFrame }
}
