import React from 'react'
import PropTypes from 'prop-types'
import { PageContent, Table } from 'Common'
import { useSortTable } from 'Hooks/useSortTable'
import CourseItem from './CourseItem'

const CoursesTable = ({ coursesList }) => {
	const { list, activeParam, handleSortTable } = useSortTable(coursesList)
	if (!coursesList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Due Date"
								sortParam="due"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Progress"
								sortParam="progress"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Signed off"
								sortParam="date_completed"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{list.map(course => (
						<CourseItem key={course.id} {...course} />
					))}
				</Table.Body>
			</Table>
		</>
	)
}

CoursesTable.propTypes = {
	coursesList: PropTypes.array.isRequired,
}

export default CoursesTable
