import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Routes } from 'Constants'

const MenuItem = ({ id, name, match }) => {
	const {
		params: { pivotId, sectionId },
	} = match

	return (
		<li className={classNames('menu-item', { active: parseInt(match.params.outcomeId) === id })}>
			<NavLink className="link" to={`${Routes.SUBJECTS.path}/${pivotId}/${sectionId}/learn/${id}`}>
				{name.length > 15 ? `${name.slice(0, 15)}...` : name}
			</NavLink>
		</li>
	)
}

MenuItem.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	match: PropTypes.shape().isRequired,
}

export default withRouter(MenuItem)
