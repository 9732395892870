import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader, Button } from 'semantic-ui-react'
import { PageContent } from 'Common'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useMutation } from '@apollo/react-hooks'
import { ADD_COURSES_MUTATION } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import AddCourseTable from './AddCoursesTable'
import './style.scss'

const AddCourseList = ({ match, toastManager, data, filter, onFilter, courseLoading }) => {
	const history = useHistory()
	const [courseList, setCourseList] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [stateloading, setStateLoading] = useState(false)
	const [addCoursesMutation] = useMutation(ADD_COURSES_MUTATION)
	const {
		params: { id },
	} = match

	const handleSetPage = useCallback(
		page => {
			onFilter(prevState => ({
				...prevState,
				page: page,
			}))
		},
		[onFilter],
	)

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter(prevState => ({
				...prevState,
				sort_param: newActiveParam.name,
				sort_direction: newActiveParam.order,
				page: 1,
			}))
		},
		[onFilter, filter],
	)

	const submitCoursesMutation = () => {
		setStateLoading(true)
		addCoursesMutation({
			variables: { params: { subjects_id: selectedUsers, tag_id: parseInt(id) } },
		})
			.then(({ data }) => {
				if (data.addSubjects) {
					setStateLoading(false)
					toastManager.add('Saved Successfully', { appearance: 'success', autoDismiss: true })
					history.push(`/settings/tags/${id}`)
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setStateLoading(false)
			})
			.finally(() => {
				setStateLoading(false)
			})
	}

	useEffect(() => {
		if (data) setCourseList(data.AccountSubjects.data.data)
	}, [data])
	courseLoading
	if (courseLoading || !courseList.length) return <Loader active />
	if (!courseList.length)
		return (
			<div className="msg-line">
				<PageContent.Message
					message="There are no Courses available.
				Please refer to the Help Centre for more information on courses."
				/>
			</div>
		)
	return (
		<div>
			<AddCourseTable
				courseList={courseList}
				page={data.AccountSubjects.data.current_page}
				setPage={handleSetPage}
				perPage={data.AccountSubjects.data.per_page}
				totalCount={data.AccountSubjects.data.total}
				length={data.AccountSubjects.data.total}
				handleSortTable={handleSortTable}
				selectedUsers={selectedUsers}
				setSelectedUsers={setSelectedUsers}
				activeSortParam={{
					name: filter.sort_param || '',
					order: filter.sort_direction || 'asc',
				}}
			/>
			<Button
				primary
				color="blue"
				floated="right"
				content="Add Course"
				loading={stateloading}
				disabled={selectedUsers.length > 0 ? false : true}
				onClick={submitCoursesMutation}
			/>
		</div>
	)
}

AddCourseList.propTypes = {
	toastManager: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	filter: PropTypes.object.isRequired,
	onFilter: PropTypes.func.isRequired,
	courseLoading: PropTypes.bool.isRequired,
}

export default withToastManager(AddCourseList)
