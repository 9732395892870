import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// import _ from 'lodash'
import { Button, Grid } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { withContext } from 'Context'
import { PageMetaTags } from 'Common'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import FormComponent from 'Helpers/form'
import { getFilteredUsers } from 'Actions/usersActions'
// import { TagsSelect } from 'Common/TagsSelect'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { CREATE_TOPICS_MUTATION, UPDATE_TOPIC_MUTATION } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import { Routes } from 'Constants'
import { GET_TOPIC_BYID } from 'Queries/settingQueries'

const validate = values => {
	const errors = {}
	const requiredFields = ['topicName']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const statusOptions = [
	{
		key: true,
		value: true,
		text: 'Visible',
	},
	{
		key: false,
		value: false,
		text: 'Inactive',
	},
]

const CreateTopic = ({ setBreadcrumbs, toastManager, match, onFilter }) => {
	const history = useHistory()
	const [submitting, setSubmitting] = useState(false)
	const [defaultValue] = useState(true)
	const [createTopicsMutation] = useMutation(CREATE_TOPICS_MUTATION)
	const [updateTopicsMutation] = useMutation(UPDATE_TOPIC_MUTATION)
	const [getTopicDataQuery, { data: getTopicDetails }] = useLazyQuery(GET_TOPIC_BYID, { fetchPolicy: 'network-only' })
	const id = parseInt(match.params.id)

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Topics',
				path: '/settings/topics',
			},
			{
				name: id ? 'Update Topic' : 'Create Topic',
			},
		])
		if (id) {
			getTopicDataQuery({ variables: { id: parseInt(id) } })
		}
	}, [id])

	useEffect(() => {
		if (getTopicDetails) {
			setValues({
				description: getTopicDetails.byId.description,
				topicName: getTopicDetails.byId.name,
				// group: _.map(getTopicDetails.byId.tags != undefined ?
				// getTopicDetails.byId.tags : [], ({ id }) => id),
				status: getTopicDetails.byId.is_enabled,
			})
		}
	}, [getTopicDetails])

	const {
		values: { topicName, description, group, status },
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
		setValues,
	} = useForm(() => {
		const variables = {
			name: topicName,
			description: description != undefined ? description : null,
			tag_ids: group != undefined ? group : [],
			is_enabled: status != undefined ? status : true,
		}
		id ? updateMutation(variables) : createMutation(variables)
	}, validate)

	const createMutation = variables => {
		setSubmitting(true)
		createTopicsMutation({
			variables: variables,
		})
			.then(({ data }) => {
				if (data.createTopic) {
					setSubmitting(false)
					toastManager.add('Saved Successfully', { appearance: 'success', autoDismiss: true })
					onFilter({ page: 1 })
					history.push(`${Routes.TOPICS}`)
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const updateMutation = variables => {
		console.log('Update API call')
		setSubmitting(true)
		updateTopicsMutation({
			variables: { id: parseInt(id), ...variables },
		})
			.then(({ data }) => {
				if (data.updateTopic) {
					setSubmitting(false)
					toastManager.add('Updated Successfully', { appearance: 'success', autoDismiss: true })
					onFilter({ page: 1 })
					history.push(`${Routes.TOPICS}`)
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	return (
		<div className="setting-list">
			<PageMetaTags title={id ? 'Update Topic' : 'Create Topic'} />
			<h2>{id ? 'Update Topic' : 'Create Topic'}</h2>
			<form autoComplete="off" onSubmit={handleSubmit}>
				<Grid>
					<Grid.Row>
						<Grid.Column tablet={16} computer={8}>
							<Grid.Row className="users-list">
								<FormComponent
									customlabel="Topic name"
									name="topicName"
									placeholder="Topic Name"
									value={topicName || ''}
									onChange={handleChange}
									error={errors.topicName}
								/>
								<FormComponent.Textarea
									rows={3}
									label="Description (Optional)"
									name="description"
									placeholder="Description"
									value={description || ''}
									onChange={handleChange}
									fluid
								/>
								{/* <TagsSelect
									label="Tags"
									value={group || []}
									onChange={handleSelectChange}
									selectOnBlur={false}
									clearable
									multiple
								/> */}
								<FormComponent.Dropdown
									label="Status"
									name="status"
									value={status}
									onChange={handleSelectChange}
									placeholder="Status"
									options={statusOptions}
									selectOnBlur={false}
									selection
									fluid
									error={errors.status}
									defaultValue={defaultValue}
								/>
							</Grid.Row>
							<Grid className="group-fields">
								<Grid.Column tablet={16} computer={8}>
									<Button
										className="reverse"
										type="button"
										disabled={submitting}
										content="Cancel"
										onClick={() => {
											history.push(`${Routes.TOPICS}`)
										}}
									/>
								</Grid.Column>
								<Grid.Column tablet={16} computer={8}>
									<Button
										type="submit"
										loading={submitting}
										disabled={submitting}
										content={id ? 'Update Topic' : 'Create Topic'}
										floated="right"
									/>
								</Grid.Column>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</form>
		</div>
	)
}

CreateTopic.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
}

CreateTopic.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { filter },
		},
		dispatch,
	]) => ({
		// state
		filter,
		// Actions
		onFilter: data => getFilteredUsers(data, dispatch),
	}),
	withBreadcrumbs(withToastManager(CreateTopic)),
)
