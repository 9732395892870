import React from 'react'
import PropTypes from 'prop-types'

import { withContext } from 'Context'
import { Greeting, UserPhoto } from 'Common'

const UserCart = ({ user, groups, userManager }) => {
	return (
		<div className="user-cart">
			<div className="left">
				<h3>
					<Greeting /> <span>{`${user.first_name} ${user.last_name}`}</span>
				</h3>
				<div className="info-item">
					<span className="custom-label">Account</span>
					<span>{user.account.name}</span>
				</div>
				{Boolean(groups.length) && (
					<div className="info-item">
						<span className="custom-label">Group</span>
						<span>{groups[0].name}</span>
					</div>
				)}
			</div>
			{userManager && (
				<div className="right text-center">
					<UserPhoto className="manager-photo" user={userManager} size="tiny" />
					<div>
						<span>{userManager ? `${userManager.first_name} ${userManager.last_name}` : ''}</span>
						<span className="custom-label">Your manager</span>
					</div>
					{userManager.account.email && userManager.account.allow_learner_contact && (
						<a
							className="button-link"
							href={`mailto:${userManager.account.email}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Contact
						</a>
					)}
				</div>
			)}
		</div>
	)
}

UserCart.defaultProps = {
	userManager: null,
}

UserCart.propTypes = {
	user: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	userManager: PropTypes.object,
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => ({
		// state
		user,
	}),
	UserCart,
)
