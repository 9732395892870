import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from 'Constants'
import SessionList from './SessionList'
import CreateSession from './CreateSession'
import SessionDetails from './SessionDetails'

const Topics = () => (
	<Switch>
		<Route exact path={Routes.SESSION.path} component={SessionList} />
		<Route exact path={Routes.SESSION.create} component={CreateSession} />
		<Route exact path={Routes.SESSION.edit} component={CreateSession} />
		<Route exact path={Routes.SESSION.view} component={SessionDetails} />
		<Route exact path={Routes.SESSION.copy} component={CreateSession} />
	</Switch>
)

export default Topics
