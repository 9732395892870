import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Grid, Button, Loader } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'

import './style.scss'

import { useForm } from 'Hooks'
import { withContext } from 'Context'
import { GET_SUBJECT_FOR_ASSESSMENT } from 'Queries/subjectsQueries'
import { GET_RANDOM_QUESTION } from 'Mutations/assessmentMutations'
import { Routes } from 'Constants'

import FormComponent from 'Helpers/form'
import { PageMetaTags, ProgressBar } from 'Common'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import QuestionType from './QuestionType'

const Questions = ({ history, selectedSection, toastManager, setBreadcrumbs }) => {
	const [loading, setLoading] = useState(false)
	const [question, setQuestion] = useState(null)
	const [showDependsField, setShowDependsField] = useState(false)
	const [notSure, setNotSure] = useState(false)

	const [getQuestion] = useMutation(GET_RANDOM_QUESTION)

	const validate = values => {
		const errors = {}

		if (values.answer && values.answer.length > 3000) errors.answer = true

		return errors
	}

	const {
		values: { answer, bool, multichoice, single, depends },
		handleSubmit,
		handleChange,
		handleChangeRadioGroup,
		handleChangeCheckboxGroup,
		clearValues,
		errors,
	} = useForm(() => _onSubmit(), validate, {
		answer: '',
		bool: null,
		multichoice: [],
		single: '',
		depends: '',
	})

	const handleGetQuestion = answerValue => {
		if (!answerValue.result && !answerValue.answer && !depends && !notSure) return null

		setLoading(true)
		setShowDependsField(false)
		getQuestion({
			variables: {
				subject_section_id: parseInt(selectedSection.id),
				question_id: parseInt(question.id),
				depends: depends ? depends : null,
				retake: selectedSection.retake,
				pivot_id: parseInt(selectedSection.pivotId),
				random: true,
				...answerValue,
			},
		})
			.then(({ data: { random } }) => {
				clearValues()
				setQuestion(random)
				if (!random)
					completingAssessment(
						'Thank you for completing the assessment, please view your results below',
						'success',
					)
			})
			.finally(() => setLoading(false))
	}

	const _onSubmit = () => {
		if (notSure) {
			setNotSure(false)
		}

		const typeHandlers = [
			() => {},
			() => handleGetQuestion({ result: bool, not_sure: notSure }),
			() => handleGetQuestion({ answer, not_sure: notSure }),
			() => handleGetQuestion({ result: multichoice.length ? multichoice : null, not_sure: notSure }),
			() => handleGetQuestion({ result: single, not_sure: notSure }),
		]

		if (typeHandlers[question.type]) {
			typeHandlers[question.type]()
		}
	}

	const completingAssessment = (completeMsg, appearance) => {
		history.push(`${Routes.SUBJECTS.path}/${selectedSection.pivotId}/${selectedSection.id}`)
		toastManager.add(completeMsg, {
			appearance,
			autoDismiss: true,
		})
	}

	useEffect(() => {
		if (!question) {
			getQuestion({
				variables: {
					subject_section_id: parseInt(selectedSection.id),
					retake: selectedSection.retake,
					pivot_id: parseInt(selectedSection.pivotId),
				},
			}).then(({ data: { random } }) => {
				setQuestion(random)
				if (!random)
					completingAssessment(
						'You have completing the assessment already, please view your results below',
						'info',
					)
			})
		}
	}, [])

	const { data: subject, loading: subjectLoading } = useQuery(GET_SUBJECT_FOR_ASSESSMENT, {
		variables: { id: parseInt(selectedSection.id) },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (subject && subject.byId && selectedSection)
			setBreadcrumbs([
				{
					name: 'Courses',
					path: Routes.SUBJECTS.path,
				},
				{
					name: subject.byId.subject.name,
					path: Routes.SUBJECTS.path + '/' + selectedSection.pivotId,
				},
				{
					name: selectedSection.name,
					path: Routes.SUBJECTS.path + '/' + selectedSection.pivotId + '/' + selectedSection.id,
				},
				{
					name: 'Assessment',
				},
			])
	}, [subjectLoading])

	if (!question || loading) return <Loader active />

	const auto_sign_off_enabled = subject ? subject.byId.subject.auto_sign_off_enabled : false
	const autoSingText = auto_sign_off_enabled ? 'Note' : 'It depends'
	const outcomeCountQuestions = question.outcomeCountQuestions > 0 ? question.outcomeCountQuestions : 1

	return (
		<div className="questions-wrap">
			<PageMetaTags title="Assessment" />
			<div className="page-title">
				<h1>{selectedSection.name}</h1>
			</div>
			<ProgressBar
				title="Completion"
				percents={Math.ceil(
					(question.countOutcomes > 0 ? question.completeOutcomes / question.countOutcomes : 0) * 100,
				).toString()}
			/>
			<div className="content">
				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<div className="outcome-questions-count">
							<span className="title">
								{/*Outcome {question.completeOutcomes + 1} of {question.countOutcomes}*/}
								Outcome {question.outcome && question.outcome.reference}
							</span>
							<span className="title">
								Question {question.outcomeCompleteQuestions + 1} of {outcomeCountQuestions}
							</span>
						</div>
						<p className="question">{question.name}</p>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<form onSubmit={handleSubmit}>
							<QuestionType
								answer={answer}
								bool={bool}
								multichoice={multichoice}
								single={single}
								type={question.type}
								options={question.options}
								handleChange={handleChange}
								handleChangeRadioGroup={handleChangeRadioGroup}
								handleChangeCheckboxGroup={handleChangeCheckboxGroup}
								errors={errors}
							/>

							{showDependsField && (
								<div className="depends-wrap">
									<span className="title">{autoSingText}</span>
									{auto_sign_off_enabled ? (
										<p>Make notes here which you can refer to later on.</p>
									) : (
										<p>Please enter some text to support your answer of “{autoSingText}”</p>
									)}
									<FormComponent.Textarea
										name="depends"
										value={depends}
										onChange={handleChange}
										rows={6}
										fluid
									/>
								</div>
							)}
							<div className="actions">
								<div>
									{!showDependsField && (
										<>
											{!subjectLoading && !auto_sign_off_enabled && question.type !== 2 && (
												<Button
													className="reverse"
													type="submit"
													size="large"
													content="Not sure"
													onClick={() => setNotSure(true)}
												/>
											)}
											<Button
												className="reverse"
												type="button"
												size="large"
												content={autoSingText}
												onClick={() => setShowDependsField(true)}
											/>
										</>
									)}
								</div>

								<Button
									size="large"
									type="submit"
									disabled={Boolean(!answer && !bool && !multichoice.length && !single && !depends)}
									content="Next"
								/>
							</div>
						</form>
					</Grid.Column>
				</Grid>
			</div>
		</div>
	)
}

Questions.propTypes = {
	history: PropTypes.object.isRequired,
	selectedSection: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(
	withContext(
		([
			{
				subject: { selectedSection },
			},
		]) => ({
			// state
			selectedSection,
		}),
		withToastManager(Questions),
	),
)
