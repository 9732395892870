import React, { useEffect, useState } from 'react'
import { Loader, Table } from 'semantic-ui-react'
import { GET_CSV_IMPORT_LOGS } from 'Queries/csvImportLogsQueries'
import { useQuery } from '@apollo/react-hooks'
import { PageMetaTags } from 'Common'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import _ from 'lodash'
import './style.scss'

const LeaversAndStartersAutomation = ({ setBreadcrumbs }) => {
	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	//const [logsData, setLogsData] = useState([])
	const [data, setData] = useState([])
	const { data: sessionData, loading: sessionLoading } = useQuery(GET_CSV_IMPORT_LOGS, {
		variables: {
			filters: {
				title: 1,
			},
		},
		fetchPolicy: 'no-cache',
	})
	useEffect(() => {
		if (sessionData) {
			if (sessionData) {
				setData(sessionData.csvImportLogs.full_data)
			}
		}
	}, [sessionData])

	const PAGE_NAME = 'Leavers and Starters CSV Import Logs'
	if (sessionLoading) return <Loader active />
	return (
		<div>
			<PageMetaTags title={PAGE_NAME} />
			<h2>{PAGE_NAME}</h2>
			{data.length > 0 ? (
				<Table className="sections-STable">
					<Table.Header className="sorting">
						<Table.Row>
							<Table.HeaderCell>ID</Table.HeaderCell>
							<Table.HeaderCell>Account ID</Table.HeaderCell>
							<Table.HeaderCell>Summary</Table.HeaderCell>
							<Table.HeaderCell>Date</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(data, item => (
							<>
								<Table.Row className="spacer" />
								<Table.Row key={item.id} className="custom-tr">
									<Table.Cell textAlign="center">{item.id}</Table.Cell>
									<Table.Cell>{item.account.name}</Table.Cell>
									<Table.Cell>
										<ul type="circle" key={'ul_' + item.id}>
											{_.map(item.details, (subItem, index) => {
												if (subItem !== 'CsvImportLogDetailsType') {
													return _.map(subItem, (subItem1, subIndex1) => (
														<li
															className={`${index}-cls`}
															key={subIndex1 + '_' + subItem1.key}
														>
															{` Row ID: ${subItem1.index}, ${subItem1.message}`}
														</li>
													))
												} else {
													return null
												}
											})}
										</ul>
									</Table.Cell>
									<Table.Cell>{item.created_at}</Table.Cell>
								</Table.Row>
							</>
						))}
					</Table.Body>
				</Table>
			) : (
				<p>No data found</p>
			)}
		</div>
	)
}
LeaversAndStartersAutomation.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}
export default withBreadcrumbs(LeaversAndStartersAutomation)
