import gql from 'graphql-tag'

export const GET_ALL_TAG_LIST = gql`
	query tags($filters: TagFiltersInput) {
		accountTags(filters: $filters) {
			data {
				data {
					id
					name
					description
					__typename
				}
				total
				per_page
				current_page
				__typename
			}
			__typename
		}
		__typename
	}
`

export const GET_TAG_BYID = gql`
	query tags($id: Int!) {
		byId(id: $id) {
			id
			name
			description
			__typename
		}
		__typename
	}
`

export const GET_ALL_SUBJECT = gql`
	query tags($filters: SubjectFiltersInput) {
		AccountSubjects(filters: $filters) {
			data {
				data {
					id
					name
					refresh_period_formatted
					refresh_period
					target_completion
					tags {
						id
						name
						__typename
					}
					__typename
				}
				total
				per_page
				current_page
				__typename
			}
			__typename
		}
		__typename
	}
`
export const GET_ALL_TOPICS = gql`
	query topics($filters: TopicsFiltersInput) {
		accountTopics(filters: $filters) {
			data {
				data {
					id
					name
					description
					tags {
						id
						name
						__typename
					}
					__typename
				}
				total
				per_page
				current_page
				__typename
			}
			__typename
		}
		__typename
	}
`
export const GET_ACCOUNT_ALL_TOPICS = gql`
	query topics {
		accountAllTopics {
			id
			name
			description
		}
	}
`
export const GET_TOPIC_BYID = gql`
	query topics($id: Int!) {
		byId(id: $id) {
			name
			description
			is_enabled
			tags {
				id
				name
			}
			__typename
		}
		__typename
	}
`
export const GET_SESSION_BY_TOPICID = gql`
	query sessions($filters: SessionsFiltersInput) {
		accountSessions(filters: $filters) {
			data {
				data {
					id
					title
					date
					session_type
					venue
					places
					status
					version
					notes
					users_count
					parent_session_id
					tags {
						id
						name
					}
				}
				total
				per_page
				current_page
				__typename
			}
			__typename
		}
		__typename
	}
`
export const GET_SESSION_BYID = gql`
	query sessions($id: Int!, $user_id: Int) {
		byId(id: $id, user_id: $user_id) {
			id
			title
			description
			venue
			date
			target_completion
			duration
			places
			start_time
			end_time
			location_type
			location_info
			default_refresher_period
			notes
			session_type
			status
			document
			document_link
			document_name
			uuid
			topic {
				id
				name
			}
			tags {
				id
				name
			}
			users_count
			users {
				id
				first_name
				last_name
				sessions {
					id
					title
					progress
					pivot_id
					is_booked
					refresh_period_formatted
				}
				roles {
					role
				}
				groups {
					id
					name
					role
				}
				__typename
			}
			progress
			is_start
			is_completed
			date_completed
			__typename
		}
		__typename
	}
`
export const ACCOUNT_SESSION_LIST = gql`
	query sessions($filters: SessionsFiltersInput) {
		accountSessions(filters: $filters) {
			data {
				data {
					id
					title
					date
					session_type
					venue
					default_refresher_period
					places
					status
					version
					parent_session_id
					topic {
						id
						name
					}
					tags {
						id
						name
					}
					users_count
				}
				total
				per_page
				current_page
				__typename
			}
			__typename
		}
		__typename
	}
`
export const GET_USER_SESSION_BY_ID = gql`
	query sessions($filters: SessionsFiltersInput) {
		delegateSessions(filters: $filters) {
			data {
				data {
					id
					title
					date
					status
					progress
					is_start
					is_completed
					date_completed
					session_type
					venue
					places
					notes
					pivot_id
					target_completion
					activity_status
					version
					user_last_active {
						id
						created_at
						__typename
					}
					user_due
					attendance_status
					user_date_completed
				}
			}
		}
	}
`
export const GET_ALL_EVIDENCE = gql`
	query evidence($session_id: Int!, $pivot_id: Int!, $user_id: Int!) {
		session(session_id: $session_id, pivot_id: $pivot_id, user_id: $user_id) {
			id
			evidence
			evidence_type_id
			created_at
			type {
				id
				name
			}
			user {
				id
				first_name
				last_name
			}
			documents {
				id
				document
				document_name
			}
		}
	}
`
export const EVIDENCE_DETAILS_BY_ID = gql`
	query evidence($evidence_id: Int!) {
		byId(evidence_id: $evidence_id) {
			id
			evidence
			evidence_type_id
			created_at
			type {
				id
				name
			}
			user {
				first_name
				last_name
			}
			documents {
				id
				document
				document_name
			}
		}
	}
`
export const DELEGATES_TASK_LIST = gql`
	query tasks($session_id: Int!, $user_id: Int) {
		delegateTasksQuery(session_id: $session_id, user_id: $user_id) {
			id
			name
			description
			due_date
			document
			document_name
			document_link
			is_complete
			evidence {
				id
				evidence
				user_id
				documents {
					id
					document
					document_name
				}
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`
export const SESSION_DELEGATES = gql`
	query sessions($session_id: Int!, $user_id: Int) {
		delegateSessionById(session_id: $session_id, user_id: $user_id) {
			id
			title
			date
			status
			progress
			is_start
			is_completed
			user_date_completed
			attendance_status
			pivot_id
			verified_by_name
			target_completion
			topics {
				id
				name
			}
			__typename
		}
	}
`
export const ACTIVITY_REPORT_SESSION = gql`
	query users($filters: ActivityFiltersInput) {
		sessionsActivityList(filters: $filters) {
			data {
				data {
					key
					id
					first_name
					last_name
					photo
					session {
						key
						pivot_id
						id
						title
						date_completed
						due
						attendance_status
						is_booked
						progress
						lastActive {
							id
							created_at
							__typename
						}
						topics {
							id
						}
						__typename
					}
					__typename
				}
				total
				per_page
				__typename
			}
			total_count
			__typename
		}
	}
`
export const GET_ALL_LOCATION_LIST = gql`
	query sessions($filters: LocationFiltersInput) {
		locationsList(filters: $filters) {
			data {
				data {
					id
					name
				}
			}
		}
	}
`
