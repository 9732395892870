import React, { useState } from 'react'
import FormComponent from 'Helpers/form'
import PropTypes from 'prop-types'
import _ from 'lodash'
import OutcomeItem from './OutcomeItem'

const OutcomesList = ({
	outcomes,
	subjectSectionType,
	selectedItems,
	manager,
	setSelectedItems,
	isExport,
	learning_stats,
}) => {
	const [expandItems, setExpandItems] = useState([])
	return (
		<ul className="list-outcomes">
			<li className="outcome-item title">
				<div className="actions">
					<FormComponent.Checkbox
						name="multichoice"
						checked={selectedItems.length === outcomes.length}
						onChange={
							selectedItems.length === outcomes.length
								? () => setSelectedItems([])
								: () => setSelectedItems(_.map(outcomes, outcome => outcome.id))
						}
						className="outcomeCheck"
					/>

					<button
						className="expand"
						type="button"
						onClick={
							expandItems.length === outcomes.length
								? () => setExpandItems([])
								: () => setExpandItems(_.map(outcomes, outcome => outcome.id))
						}
					>
						<i className={`fas fa-${expandItems.length === outcomes.length ? 'minus' : 'plus'}-square`} />
					</button>
				</div>
				<div className="ref">
					<span className="custom-label">Ref</span>
				</div>
				<div className="name">
					<span className="custom-label">
						<span className="hidden-smm">Outcome </span>name
					</span>
				</div>
				<div className="status learning-assestment-statuts">
					{learning_stats.learning_link_count > 0 && <p className="learning-status">Learning</p>}
					<p className="assessment-status">Assessment</p>
				</div>
			</li>
			{_.map(outcomes, outcome => {
				const isSelected = _.includes(selectedItems, outcome.id)
				return (
					<OutcomeItem
						key={outcome.id}
						isExpand={_.includes(expandItems, outcome.id)}
						isSelected={isSelected}
						subjectSectionType={subjectSectionType}
						openItem={() => setExpandItems([...expandItems, outcome.id])}
						closeItem={() => setExpandItems(_.filter(expandItems, expandItem => expandItem !== outcome.id))}
						manager={manager}
						isExport={isExport}
						selectItem={
							isSelected
								? () =>
										setSelectedItems(
											_.filter(selectedItems, selectedItem => selectedItem !== outcome.id),
										)
								: () => setSelectedItems([...selectedItems, outcome.id])
						}
						outcome={outcome}
						learning_stats={learning_stats}
					/>
				)
			})}
		</ul>
	)
}

OutcomesList.propTypes = {
	outcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
	setSelectedItems: PropTypes.func.isRequired,
	manager: PropTypes.bool.isRequired,
	subjectSectionType: PropTypes.string.isRequired,
	isExport: PropTypes.bool.isRequired,
	learning_stats: PropTypes.object,
}
OutcomesList.defaultProps = {
	learning_stats: {},
}
export default OutcomesList
