export const SUBJECT_ACTIVITY_STATUSES = {
	CURRENT: 1,
	PLANNED: 2,
	COMPLETED: 3,
	OPTIONAL: 4,
}

export const OBS_SUBJECT_PREFIX = 'obs'

export const OBS_EXTERNAL_SERVICE_ORIGIN = '//learn-mediafiles.s3.amazonaws.com/magento'
