import moment from 'moment'
import { useEffect, useState } from 'react'
import { getRegistrationProgress } from 'Utils/scorm'

export default function useScormSubjectStats({
	progress,
	is_completed,
	date_completed,
	userSubjectSectionId,
	userSubjectId,
	user,
	isLearnerView,
}) {
	const [scormRegistrationProgress, setScormRegistrationProgress] = useState(null)

	const dateCompleted = is_completed ? moment(date_completed).format('DD/MM/YYYY') : null

	useEffect(() => {
		const requestParameters = {
			userSubjectId,
			userSubjectSectionId,
		}

		const setRegistrationProgress = ({ data: registrationProgress }) => {
			setScormRegistrationProgress(registrationProgress)
		}

		if (!isLearnerView) {
			const userId = user.id

			if (userId) {
				requestParameters.userId = userId
				getRegistrationProgress(requestParameters).then(setRegistrationProgress)
			}
			return
		}

		getRegistrationProgress(requestParameters).then(setRegistrationProgress)
	}, [userSubjectId, userSubjectSectionId, user.id, isLearnerView])

	return {
		progress,
		dateCompleted,
		scormRegistrationProgress,
	}
}
