import React, { useCallback, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { Button, Icon, Confirm } from 'semantic-ui-react'
import { REMOVE_EVIDENCE } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import { withContext } from '../../../../context'

const TaskItem = ({
	id,
	name,
	due_date,
	document,
	document_link,
	document_name,
	addEviTask,
	evidence,
	toastManager,
	taskRefetch,
	updateTaskEvidence,
	is_complete,
	delegateSessionById,
	authUser,
	description,
	showEviModalOpen,
}) => {
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [currentTaskId, setCurrentTaskId] = useState(0)

	const [removeEvidence] = useMutation(REMOVE_EVIDENCE)

	let tempDateHold
	if (moment(due_date, 'DD-MM-YYYY', true).isValid()) {
		tempDateHold = due_date
	} else {
		tempDateHold = moment(due_date).format('DD-MM-YYYY')
	}
	const tableData = {
		Name: name ? name : '-',
		Date: tempDateHold,
		Document: document ? document : '-',
		// DocumentLink: document_link ? document_link : '-',
		// DocumentName: document_name ? document_name : '-',
		Description: description ? description : '-',
	}

	const onConfirm = useCallback(() => {
		removeEvidence({
			variables: { evidence_document_id: parseInt(currentTaskId) },
		})
			.then(({ data }) => {
				if (data.deleteEvidenceDocument) {
					toastManager.add('Deleted Successfully.', { appearance: 'success', autoDismiss: true })
					taskRefetch()
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(error => {
				console.log('error', error)
			})
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const onCancel = useCallback(() => {
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const truncate = input => (input.length > 280 ? `${input.substring(0, 280)}...` : input)
	return (
		<div id={`task-${id}`} className="task-item">
			<div className="top-items">
				<span className="dark-text">
					<span className="task-name">
						<b>{tableData.Name}</b>
					</span>
					&nbsp;&nbsp;&emsp;
					{tableData.Date ? moment(tableData.Date, 'DD-MM-YYYY').format('DD/MM/YYYY') : '-'}
					{delegateSessionById && !delegateSessionById.is_completed ? (
						<Button
							size="small"
							onClick={() => addEviTask(id)}
							style={{ width: '100px' }}
							fluid
							content="Add Task Evidence"
							className="task-add-evidence-btn"
						/>
					) : null}
					{is_complete ? (
						<div className="status">
							<i className="fas fa-check-circle" style={{ color: '#82c91e' }} />
						</div>
					) : (
						<div className="status">
							<i className="fas fa-question-square" style={{ color: '#90a6d5' }} />
						</div>
					)}
				</span>
			</div>
			<p>
				<b>Description:&nbsp;</b> <span className="clr-black">{truncate(tableData.Description)}</span>
			</p>
			<p>
				<b>Document:&nbsp;</b>{' '}
				<span className="blue-text">
					{document_name ? (
						<div
							role="button"
							tabIndex="0"
							onKeyDown={() => showEviModalOpen(tableData.Document)}
							onClick={() => showEviModalOpen(tableData.Document)}
							className="clr-blue"
						>
							{document_name}
						</div>
					) : (
						<div className="clr-black">-</div>
					)}
				</span>
			</p>
			<p>
				<b>URL:&nbsp;</b>
				<span className="dark-text">
					{document_link ? (
						<div
							role="button"
							tabIndex="0"
							onKeyDown={() => window.open(document_link)}
							onClick={() => window.open(document_link)}
							className="clr-blue"
						>
							{document_link}
						</div>
					) : (
						<div className="clr-black">-</div>
					)}
				</span>
			</p>

			{evidence.length > 0 ? (
				<div className="evidence-div">
					<b>Evidence:&nbsp;</b>
					{evidence.map(list => {
						return (
							<div key={list.id} className="evidence-item-div">
								<p className="evidance-name">{list.evidence}</p>
								{delegateSessionById && !delegateSessionById.is_completed
									? list.user /* eslint-disable no-mixed-spaces-and-tabs */ &&
									  authUser.id === list.user.id && (
											<div className="edit-div">
												<Icon
													className="cursor-pointer"
													name="edit outline"
													onClick={() => updateTaskEvidence(list.id, id)}
												/>
											</div>
									  ) /* eslint-enable no-mixed-spaces-and-tabs */
									: null}
								{list.documents.length > 0 ? (
									<div className="document-div">
										<div
											role="button"
											tabIndex="0"
											onKeyDown={() => showEviModalOpen(list.documents[0].document)}
											onClick={() => showEviModalOpen(list.documents[0].document)}
											className="clr-black"
										>
											<p className="clr-black"> {list.documents[0].document_name}</p>
										</div>
										{delegateSessionById && !delegateSessionById.is_completed
											? list.user /* eslint-disable no-mixed-spaces-and-tabs */ &&
											  authUser.id === list.user.id && (
													<Icon
														className="cursor-pointer"
														name="trash"
														onClick={() => {
															setOpenDeleteModal(true)
															setCurrentTaskId(list.documents[0].id)
														}}
													/>
											  ) /* eslint-enable no-mixed-spaces-and-tabs */
											: null}
									</div>
								) : null}
							</div>
						)
					})}
				</div>
			) : null}
			<Confirm
				open={openDeleteModal}
				size="mini"
				content="You are about to delete the Document?"
				cancelButton={<Button className="reverse" disabled={false} content="Cancel" />}
				confirmButton={<Button loading={false} disabled={false} content="Confirm" />}
				onCancel={onCancel}
				onConfirm={onConfirm}
			/>
		</div>
	)
}

TaskItem.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	due_date: PropTypes.string.isRequired,
	document: PropTypes.string,
	document_link: PropTypes.string,
	document_name: PropTypes.string,
	addEviTask: PropTypes.func,
	evidence: PropTypes.array,
	toastManager: PropTypes.object.isRequired,
	authUser: PropTypes.object.isRequired,
	taskRefetch: PropTypes.func,
	updateTaskEvidence: PropTypes.func,
	is_complete: PropTypes.bool,
	delegateSessionById: PropTypes.object,
	description: PropTypes.string.isRequired,
	showEviModalOpen: PropTypes.func.isRequired,
}

TaskItem.defaultProps = {
	addEviTask: () => {},
	taskRefetch: () => {},
	updateTaskEvidence: () => {},
	evidence: [],
	document: null,
	document_link: null,
	document_name: null,
	is_complete: null,
	delegateSessionById: null,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({ authUser: user }),
		withToastManager(TaskItem),
	),
)
