import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormComponent from 'Helpers/form'
import { Table, PageContent, CustomPaginator } from 'Common'
import AddCourseItem from './AddCourseItem'

const AddCourseTable = ({
	courseList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	selectedUsers,
	setSelectedUsers,
}) => {
	const selectAllUsersOnPage = () => {
		setSelectedUsers(courseList.length !== selectedUsers.length ? _.map(courseList, ({ id }) => id) : [])
	}

	const selectUser = userId => {
		setSelectedUsers(
			_.includes(selectedUsers, userId)
				? _.filter(selectedUsers, id => id !== userId)
				: [...selectedUsers, userId],
		)
	}

	if (!courseList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<FormComponent.Checkbox
								className="select-all customCheck"
								name="multichoice"
								label=""
								checked={courseList.length === selectedUsers.length || selectedUsers === 'all'}
								onChange={selectAllUsersOnPage}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Tag"
								sortParam="tag"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Refresher"
								sortParam="refresher"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(courseList, user => (
						<AddCourseItem
							key={user.id}
							user={user}
							isSelect={_.includes(selectedUsers, user.id) || selectedUsers === 'all'}
							selectUser={() => selectUser(user.id)}
						/>
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

AddCourseTable.propTypes = {
	courseList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
}

AddCourseTable.defaultProps = {
	activeSortParam: null,
}

export default AddCourseTable
