if (!window.ZohoHCAsapReady) {
	window.ZohoHCAsapReady = function(o) {
		window.ZohoHCAsap__asyncalls = window.ZohoHCAsap__asyncalls || []
		if (window.ZohoHCAsapReadyStatus) {
			o && window.ZohoHCAsap__asyncalls.push(o)
			for (let a = window.ZohoHCAsap__asyncalls, s = 0; s < a.length; s++) {
				const n = a[s]
				n && n()
			}
			window.ZohoHCAsap__asyncalls = null
		} else o && window.ZohoHCAsap__asyncalls.push(o)
	}
}

export const authWidget = setNode => {
	window.ZohoHCAsapSettings = {
		hideLauncherIcon: true,
	}
	window.ZohoHCAsap =
		window.ZohoHCAsap ||
		function(a, b) {
			window.ZohoHCAsap[a] = b
		}
	;(() => {
		var d = document
		var s = d.createElement('script')
		s.type = 'text/javascript'
		s.defer = true
		s.src = 'https://desk.zoho.eu/portal/api/web/inapp/31841000002956116?orgId=20066931950'
		d.getElementsByTagName('head')[0].appendChild(s)
		setNode(s)
	})()
}

export const notAuthWidget = setNode => {
	if (window.ZohoHCAsapSettings) {
		delete window.ZohoHCAsapSettings
	}
	window.ZohoHCAsap =
		window.ZohoHCAsap ||
		function(a, b) {
			window.ZohoHCAsap[a] = b
		}
	;(() => {
		var d = document
		var s = d.createElement('script')
		s.type = 'text/javascript'
		s.defer = true
		s.src = 'https://desk.zoho.eu/portal/api/web/inapp/31841000003555523?orgId=20066931950'
		d.getElementsByTagName('head')[0].appendChild(s)
		setNode(s)
	})()
}
