import React, { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { ACCOUNT_SESSION_LIST } from 'Queries/settingQueries'
import FormComponent from 'Helpers/form'
import PropTypes from 'prop-types'

const SessionSelect = ({ additionalOptions, selectedOptions, ...props }) => {
	const [loadGroups, { error, data }] = useLazyQuery(ACCOUNT_SESSION_LIST, {
		variables: {
			filters: {
				title: '',
			},
		},
		fetchPolicy: 'network-only',
	})

	const debounceSearch = useCallback(
		_.debounce(query => {
			loadGroups({ variables: { search: query } })
		}, 300),
		[],
	)

	const handleSearchChange = useCallback((e, { searchQuery }) => {
		debounceSearch(searchQuery)
	}, [])

	const options = useMemo(() => {
		const array = _.map(data ? data.accountSessions.data.data : [], ({ id, title }) => ({
			key: id,
			value: id,
			text: title,
		}))
			.concat(additionalOptions)
			.concat(selectedOptions)
		return [...new Set(array)]
	}, [additionalOptions, selectedOptions, data])

	useEffect(() => {
		// eslint-disable-next-line react/prop-types
		loadGroups({ variables: { require_id: props.value } })
	}, [selectedOptions])

	if (error) return null

	return (
		<FormComponent
			label="Sessions"
			name="session"
			type="dropdown"
			options={options}
			placeholder="Sessions"
			icon="angle down"
			selection
			search
			onSearchChange={handleSearchChange}
			clearable
			{...props}
			fluid
		/>
	)
}

SessionSelect.propTypes = {
	additionalOptions: PropTypes.arrayOf(PropTypes.object),
	selectedOptions: PropTypes.array,
}

SessionSelect.defaultProps = {
	additionalOptions: [],
	selectedOptions: [],
}

export { SessionSelect }
