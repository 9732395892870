import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table } from 'Common'
import FormComponent from 'Helpers/form'
import moment from 'moment'

const SubjectItem = ({ list, isSelect, selectSubject, handleSubjectsChange, refreshPeriodData, listIndex }) => {
	const tableData = {
		userName: list.name ? list.name : list.title,
		Tags: list.tags && list.tags.length > 0 ? _.map(list.tags, list => list.name).join(', ') : '-',
		TargetCompletion:
			list.target_completion && list.target_completion.length <= 2
				? moment()
						.add(list.target_completion, 'days')
						.format('DD-MM-YYYY')
				: list.target_completion,
		Refresher: list.refresh_period != undefined && list.refresh_period != null ? list.refresh_period : '-',
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell className="subject-checkbox-cell">
					<FormComponent.Checkbox checked={isSelect} onChange={selectSubject} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.userName}>{tableData.userName}</Table.Cell>
				<Table.Cell title={tableData.Tags}>{tableData.Tags}</Table.Cell>
				<Table.Cell title={tableData.TargetCompletion}>
					{isSelect && (
						<FormComponent.Date
							name="target_completion"
							icon="calendar alternate outline"
							minDate={moment()}
							dateFormat="DD-MM-YYYY"
							popupPosition="top right"
							clearable={false}
							clearIcon="calendar alternate outline"
							onChange={(e, data) => handleSubjectsChange(listIndex, e, data)}
							value={tableData.TargetCompletion}
							error={tableData.TargetCompletion}
						/>
					)}
				</Table.Cell>
				<Table.Cell title={tableData.Refresher} className="refresh-period-td">
					{isSelect && (
						<FormComponent.Dropdown
							name="refresh_period"
							type="dropdown"
							options={refreshPeriodData.refreshPeriodOptions}
							value={+tableData.Refresher}
							onChange={(e, data) => handleSubjectsChange(listIndex, e, data)}
							icon="angle down"
							selection
							selectOnBlur={false}
						/>
					)}
				</Table.Cell>
			</Table.Row>
		</>
	)
}
SubjectItem.propTypes = {
	list: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectSubject: PropTypes.func.isRequired,
	handleSubjectsChange: PropTypes.func.isRequired,
	refreshPeriodData: PropTypes.object.isRequired,
	listIndex: PropTypes.any.isRequired,
}

SubjectItem.defaultProps = {
	isSelect: false,
}

export default SubjectItem
