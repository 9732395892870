import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { Table, ProgressBar } from 'Common'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import moment from 'moment'

const CourseItem = ({
	pivot_id,
	name,
	due,
	date_completed,
	target_completion,
	is_completed,
	progress,
	img,
	title,
	version,
	obs_course_id,
}) => {
	const history = useHistory()
	const isCompleted = Boolean(date_completed) && is_completed
	const isNotStarted = progress === 0 && !isCompleted
	const isInProgress = progress > 0 && !isCompleted && !isNotStarted
	const targetCompletionExists = Boolean(target_completion)
	const targetCompletion = moment(target_completion)
	const targetCompletionToDisplay = targetCompletion.isAfter(moment()) ? targetCompletion.format('DD/MM/YYYY') : due

	const handleCourseClick = () => {
		const obsLinkExtension = obs_course_id ? [OBS_SUBJECT_PREFIX, obs_course_id] : []

		history.push([Routes.SUBJECTS.path, pivot_id, ...obsLinkExtension].join('/'))
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr customCursor" positive onClick={handleCourseClick}>
				<Table.Cell className="thumb">
					<div className="image" style={{ backgroundImage: `url('${img}')` }} />
				</Table.Cell>
				<Table.Cell>
					{name ? name : title} {version ? ` (VSN - ${version})` : null}
				</Table.Cell>
				<Table.Cell>{isCompleted ? '-' : targetCompletionExists ? targetCompletionToDisplay : '--'}</Table.Cell>
				<Table.Cell>
					{isNotStarted && 'Not started'}
					{isInProgress && (
						<ProgressBar percents={progress} style={{ textAlign: 'center' }} inactiveBg="#f4f6fc" />
					)}
					{isCompleted && 'Completed'}
				</Table.Cell>
				<Table.Cell>{isCompleted ? moment(date_completed).format('DD/MM/YYYY') : '-'}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" onClick={handleCourseClick}>
						{isNotStarted && 'Start'}
						{isInProgress && 'Continue'}
						{isCompleted && 'View'}
					</Button>
				</Table.Cell>
			</Table.Row>
		</>
	)
}

CourseItem.propTypes = {
	pivot_id: PropTypes.number,
	name: PropTypes.string,
	is_completed: PropTypes.bool.isRequired,
	date_completed: PropTypes.string,
	target_completion: PropTypes.string,
	progress: PropTypes.number.isRequired,
	due: PropTypes.string,
	img: PropTypes.string,
	title: PropTypes.string,
	version: PropTypes.number,
	obs_course_id: PropTypes.number,
}

CourseItem.defaultProps = {
	date_completed: null,
	target_completion: null,
	due: null,
	img: null,
	title: null,
	pivot_id: null,
	name: null,
	version: null,
	obs_course_id: null,
}

export default CourseItem
