import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import _ from 'lodash'

import { withContext } from 'Context'
import { signInSuccessAction } from 'Actions/authActions'
import { SIGN_IN_MUTATION } from 'Mutations/authMutations'
import { useForm } from 'Hooks'
import useTwoFactorAuth from 'Hooks/useTwoFactorAuth'
import FormComponent from 'Helpers/form'
import { PageMetaTags, Support } from 'Common'
import clickLogo from 'Assets/images/click_learning_logo.png'

const validate = values => {
	const errors = {}
	const requiredFields = ['login', 'password']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	if (values.password && values.password.length < 8) errors.password = true

	return errors
}

const LoginForm = ({ signInSuccess }) => {
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const isEmailExist = localStorage.getItem('isEmailExist')

	useEffect(() => {
		if (isEmailExist && isEmailExist != '') {
			setValues({
				login: String(isEmailExist),
			})
		}
	}, [isEmailExist])

	const { validateTwoFactorAuthResponse } = useTwoFactorAuth()

	const [signInMutation] = useMutation(SIGN_IN_MUTATION)

	const {
		values: { login, password },
		errors,
		handleSubmit,
		handleChange,
		setValues,
	} = useForm(() => {
		setLoading(true)

		signInMutation({ variables: { login, password } })
			.then(({ data: { signIn } }) => signInSuccess(signIn))
			.catch(validateTwoFactorAuthResponse)
			.catch(error => {
				const message = _.get(error, 'graphQLErrors.[0].message')

				if (message) {
					setErrorMessage(message)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}, validate)

	return (
		<React.Fragment>
			<PageMetaTags title="Sign in" />
			<img className="logo" src={clickLogo} alt="Click logo" />
			<span className="title">Welcome</span>

			<form onSubmit={handleSubmit}>
				<FormComponent
					autoCorrect="off"
					autoCapitalize="none"
					autoComplete="off"
					name="login"
					value={login || ''}
					onChange={handleChange}
					placeholder="Username or email address"
					size="large"
					error={errors.login}
				/>
				<FormComponent.Password
					name="password"
					value={password || ''}
					onChange={handleChange}
					placeholder="Password"
					size="large"
					error={errors.password}
				/>
				<Button size="big" loading={loading} disabled={loading} fluid content="Sign in" />
			</form>
			<Support
				actionSlot={
					<Link className="link" to="/reset-password" tabIndex="-1">
						Reset password
					</Link>
				}
			/>
			{errorMessage && (
				<div className="text-center">
					<span className="error-message">{errorMessage}</span>
				</div>
			)}
		</React.Fragment>
	)
}

LoginForm.propTypes = {
	signInSuccess: PropTypes.func.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
}

export default withContext(
	([, dispatch]) => ({
		// actions
		signInSuccess: data => signInSuccessAction(data, dispatch),
	}),
	LoginForm,
)
