import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from 'Constants'
import SessionDetails from './SessionDetails'

const ManagerSessionView = () => (
	<Switch>
		<Route exact path={Routes.MANAGER_SESSION_VIEW} component={SessionDetails} />
	</Switch>
)

export default ManagerSessionView
