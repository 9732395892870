import React from 'react'
import { Table } from 'Common'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormComponent from 'Helpers/form'
import LearnerRow from './LearnerRow'

const Learners = ({
	usersList,
	selectedUsers,
	setSelectedUsers,
	match,
	topic,
	setRefetchLoader,
	refetchSessionData,
}) => {
	const {
		params: { sessionId },
	} = match
	const users = _.map(usersList, user => ({
		name: `${user.first_name} ${user.last_name}`,
		role: _.get(user, 'groups[0].role') || _.get(user, 'account.role'),
		groupName: _.get(user, 'groups[0].name'),
		...user,
	}))
	const selectAllUsersOnPage = () => {
		setSelectedUsers(usersList.length !== selectedUsers.length ? _.map(usersList, ({ id }) => id) : [])
	}
	const selectUser = userId => {
		setSelectedUsers(
			_.includes(selectedUsers, userId)
				? _.filter(selectedUsers, id => id !== userId)
				: [...selectedUsers, userId],
		)
	}
	return (
		<div className="session-learners-list">
			<h3>Delegates</h3>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<FormComponent.Checkbox
								className="select-all customCheck"
								name="multichoice"
								checked={usersList.length === selectedUsers.length || selectedUsers === 'all'}
								onChange={selectAllUsersOnPage}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>NAME</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>TYPE</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>GROUP</div>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<div>PROGRESS</div>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(users, user => (
						<LearnerRow
							key={user.id}
							user={user}
							topic={topic}
							setRefetchLoader={setRefetchLoader}
							refetchSessionData={refetchSessionData}
							isSelect={_.includes(selectedUsers, user.id) || selectedUsers === 'all'}
							selectUser={() => selectUser(user.id)}
							currentSessionId={Number(sessionId)}
						/>
					))}
				</Table.Body>
			</Table>
		</div>
	)
}

Learners.propTypes = {
	usersList: PropTypes.array,
	selectedUsers: PropTypes.array,
	setSelectedUsers: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	topic: PropTypes.object.isRequired,
	setRefetchLoader: PropTypes.bool.isRequired,
	refetchSessionData: PropTypes.func.isRequired,
}

Learners.defaultProps = {
	usersList: [],
	selectedUsers: [],
}

export default Learners
