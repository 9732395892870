import React from 'react'
import Nestable from 'react-nestable'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'

import GroupItem from './GroupItem'

const Table = ({ groups, nestableHandleChange, isReviewer }) => {
	if (!groups.length) return <PageContent.Message message="List is empty" />

	return (
		<div>
			<div className="list-header">
				{!isReviewer && <div />}
				<div />
				<div>
					<span className="group-id">ID</span>
					<span className="group-name">Name</span>
					{/*<span className="group-user">Manager</span>*/}
					<span className="group-users-count">Learners</span>
				</div>
			</div>
			<Nestable
				items={groups}
				renderItem={GroupItem}
				onChange={nestableHandleChange}
				renderCollapseIcon={({ isCollapsed }) => (
					<button type="button" className="expand">
						<i className={`fas fa-${isCollapsed ? 'plus' : 'minus'}-square`} />
					</button>
				)}
				maxDepth={10}
				threshold={30}
				handler={
					<span className="drag-icon">
						<i className="fal fa-grip-vertical" />
					</span>
				}
			/>
		</div>
	)
}

Table.propTypes = {
	groups: PropTypes.array.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	nestableHandleChange: PropTypes.func,
}

Table.defaultProps = {
	nestableHandleChange: () => {},
}

export default Table
