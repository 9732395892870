import React, { useState, useRef } from 'react'
import { Input, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const DefaultField = ({ className, inputClassName, inputRef, ...field }) => {
	const popupRef = useRef()
	const [readOnly, setReadOnly] = useState(Boolean(field.disableAutocompleate))

	const fieldProps = { ...field }

	if (field.disableAutocompleate) {
		delete fieldProps.disableAutocompleate
		fieldProps.onFocus = () => setReadOnly(false)
	}

	return (
		<div className={classNames('field-wrap', className, { error: fieldProps.error })}>
			{fieldProps.tooltip ? (
				<Popup
					content={fieldProps.tooltip}
					trigger={
						<span ref={popupRef} className="field-label">
							{fieldProps.customlabel} {fieldProps.tooltip && <i className="fas fa-info-circle" />}
						</span>
					}
				/>
			) : (
				fieldProps.customlabel && <span className="field-label">{fieldProps.customlabel}</span>
			)}

			<Input ref={inputRef} className={inputClassName} type="text" {...fieldProps} readOnly={readOnly} />

			{fieldProps.errorMsg && <span className="error-msg">{fieldProps.errorMsg}</span>}
		</div>
	)
}

DefaultField.propTypes = {
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	inputRef: PropTypes.object,
}

DefaultField.defaultProps = {
	className: null,
	inputClassName: null,
	inputRef: null,
}

export default DefaultField
