import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import classNames from 'classnames'

const Repeater = ({ label, name, values: defaultValues, children, onChange, error, addButtonText, existingFields }) => {
	const getEmptyValues = () => {
		if (defaultValues[0]) {
			return _.mapValues(defaultValues[0], () => '')
		}

		const values = {}

		React.Children.forEach(children, ({ props: { name } }) => {
			values[name] = ''
		})

		return values
	}

	const [values, setValues] = useState(defaultValues || [getEmptyValues])

	const setValue = (index, fieldName, value) => {
		const newValues = [...values]

		if (!newValues[index]) {
			newValues[index] = {}
		}

		newValues[index][fieldName] = value

		setValues(newValues)

		onChange({ name, values: newValues })
	}

	const addValues = () => {
		setValues([...values, getEmptyValues()])
	}
	const removeValues = index => {
		const newValues = [...values]
		newValues.splice(index, 1)
		setValues(newValues)

		onChange({ name, values: newValues })
	}

	const renderChildField = (child, index) => {
		const props = { ...child.props }

		if (index !== 0) {
			// eslint-disable-next-line react/prop-types
			delete props.label
			// eslint-disable-next-line react/prop-types
			delete props.customlabel
		}

		props.value = values[index][child.props.name]

		// eslint-disable-next-line react/destructuring-assignment,react/prop-types
		if (existingFields.includes(props.value)) props.error = true

		props.onChange = ({ target: { name, value } }, semanticValues) => {
			if (semanticValues) {
				name = semanticValues.name
				value = semanticValues.value
			}
			setValue(index, name, value)
		}

		return React.createElement(child.type, props)
	}

	return (
		<div className={classNames('field-wrap repeater-field', { error })}>
			{Boolean(label) && <div className="field-label">{label}</div>}
			<table className="repeater-field--items">
				<tbody>
					{_.map(values, (items, index) => (
						<tr key={index}>
							{React.Children.map(children, child => (
								<td style={{ width: 99 / React.Children.count(children) + '%' }}>
									{renderChildField(child, index)}
								</td>
							))}
							{values.length > 1 && (
								<td className="repeater-field--item--button">
									<Icon
										name="trash alternate outline"
										link
										color="red"
										onClick={() => removeValues(index)}
									/>
								</td>
							)}
						</tr>
					))}
					<tr className="add-item-row">
						<td
							colSpan={
								values.length > 1 ? React.Children.count(children) + 1 : React.Children.count(children)
							}
						>
							<Button type="button" onClick={addValues} content={addButtonText} />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

Repeater.propTypes = {
	label: PropTypes.node,
	name: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.node.isRequired,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	addButtonText: PropTypes.string,
	existingFields: PropTypes.array,
}
Repeater.defaultProps = {
	label: null,
	values: [],
	existingFields: [],
	error: false,
	addButtonText: 'Add',
	onChange: () => {},
}

export default Repeater
