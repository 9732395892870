import { LEARNER_TYPE, MANAGER_TYPE, NO_SECTION_SIGN_OFF_TYPE, REVIEWER_TYPE, SYSTEM_ADMIN } from './rolesSubtypes'

const userRoles = [
	{
		id: 1,
		role: 'Manager',
		type: [MANAGER_TYPE],
	},
	{
		id: 2,
		role: 'Learner',
		type: [LEARNER_TYPE],
	},
	// {
	// 	id: 3,
	// 	role: 'Admin',
	// 	type: [MANAGER_TYPE],
	// },
	{
		id: 4,
		role: 'Reporting Manager',
		type: [MANAGER_TYPE, REVIEWER_TYPE, NO_SECTION_SIGN_OFF_TYPE],
	},
	{
		id: 5,
		role: 'Reviewer sign off',
		type: [MANAGER_TYPE, REVIEWER_TYPE],
	},
	{
		id: 6,
		role: 'System Admin',
		type: [MANAGER_TYPE, SYSTEM_ADMIN],
	},
]

export { userRoles }
