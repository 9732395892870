import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router-dom'

import { withContext } from 'Context'
import { Routes } from 'Constants'
import { restoreUserAction } from 'Actions/authActions'
import { PrivateRoute, GuestRoute } from 'Helpers/routes'

import { CustomToast, Loader } from 'Common'
import Login from 'Pages/Login'
import Main from 'Pages/Main'
import { ToastProvider } from 'react-toast-notifications'
import Print from 'Pages/Print'
import SamlLogin from 'Pages/SamlLogin'
import { authWidget, notAuthWidget } from 'Constants/zohoWidgets'
import { ScormLearningExitIframe } from 'Pages/Subjects/ScormLearning'
import SideEffects from '../common/SideEffects'

const RootRoute = ({ location, appLoading, loggedIn, restoreUser }) => {
	const [scriptNode, setScriptNode] = useState(null)

	useEffect(() => {
		setTimeout(() => {
			restoreUser()
		}, 700)
	}, [])

	useEffect(() => {
		loggedIn ? authWidget(setScriptNode) : notAuthWidget(setScriptNode)

		return () => {
			scriptNode && document.head.removeChild(scriptNode)
		}
	}, [loggedIn])

	if (appLoading) return <Loader />

	return (
		<ToastProvider components={{ ToastContainer: CustomToast }}>
			<SideEffects />
			<Switch>
				<GuestRoute path={Routes.LOGIN} loggedIn={loggedIn} location={location} component={Login} />
				<GuestRoute path={Routes.REGISTRATION} loggedIn={loggedIn} location={location} component={Login} />
				<GuestRoute path={Routes.RESET_PASSWORD} loggedIn={loggedIn} location={location} component={Login} />
				<GuestRoute
					path={Routes.RESET_PASSWORD_CONFIRM}
					loggedIn={loggedIn}
					location={location}
					component={Login}
				/>
				<GuestRoute path={Routes.LOGIN_WITH_TOKEN} loggedIn={loggedIn} location={location} component={Login} />
				<GuestRoute
					path={Routes.SCORM_LEARNING_IFRAME_EXIT}
					location={location}
					component={ScormLearningExitIframe}
				/>
				<GuestRoute path={Routes.TWO_FACTOR_AUTH} loggedIn={loggedIn} location={location} component={Login} />
				<PrivateRoute path="/print" loggedIn={loggedIn} location={location} component={Print} />
				<PrivateRoute path="/saml/login" exact loggedIn={loggedIn} location={location} component={SamlLogin} />
				<PrivateRoute path="/" loggedIn={loggedIn} location={location} component={Main} />
			</Switch>
		</ToastProvider>
	)
}

RootRoute.propTypes = {
	location: PropTypes.shape().isRequired,
	appLoading: PropTypes.bool.isRequired,
	loggedIn: PropTypes.bool.isRequired,
	restoreUser: PropTypes.func.isRequired,
}

export default withRouter(
	withContext(
		([
			{
				app: { loading },
				user: { loggedIn },
			},
			dispatch,
		]) => ({
			// state
			loggedIn,
			appLoading: loading,
			// actions
			restoreUser: () => restoreUserAction(localStorage.getItem('user'), dispatch),
		}),
		RootRoute,
	),
)
