import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { compare } from 'Utils'
import NewDropdown from './CustomDropdown'

const Dropdown = ({ sortBy, sort, className, ...field }) => {
	const popupRef = useRef()
	const { options, tooltip } = field
	let sortedGroups = options
	if (sort) {
		sortedGroups = options.sort(compare(sortBy))
	}

	return (
		<div className={classNames('field-wrap', className, { error: field.error })}>
			{tooltip && field.label ? (
				<Popup
					content={tooltip}
					trigger={
						<span ref={popupRef} className="field-label">
							{field.label} {tooltip && <i className="fas fa-info-circle" />}
						</span>
					}
				/>
			) : (
				field.label && <span className="field-label">{field.label}</span>
			)}
			<NewDropdown {...field} options={sortedGroups} />
		</div>
	)
}

Dropdown.propTypes = {
	className: PropTypes.string,
	sortBy: PropTypes.string,
	sort: PropTypes.bool,
}

Dropdown.defaultProps = {
	className: null,
	sortBy: 'text',
	sort: true,
}

export default Dropdown
