import useFetchResource from 'Hooks/useFetchResource'
import { getObsLearningResource, removeAssessmentLesson } from 'Utils/obs'

export default function useObsLessons({ moduleId }) {
	const { resource: lessons, ...rest } = useFetchResource(() => {
		return getObsLearningResource('modules', moduleId, 'lessons')
	}, [moduleId])

	const getData = () => {
		if (Array.isArray(lessons)) {
			return lessons.map(lessonOutcome => {
				return {
					...lessonOutcome,
					content: removeAssessmentLesson(lessonOutcome.content),
				}
			})
		}

		return lessons
	}

	return {
		...rest,
		lessons: getData(),
	}
}
