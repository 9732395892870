import React from 'react'

import Checkbox from './Fields/Checkbox'
import Date from './Fields/Date'
import DefaultField from './Fields/DefaultField'
import Dropdown from './Fields/Dropdown'
import Password from './Fields/Password'
import Radio from './Fields/Radio'
import RadioGroup from './Fields/RadioGroup'
import Repeater from './Fields/Repeater'
import Textarea from './Fields/Textarea'

const FormComponent = field => {
	const fields = {
		textarea: Textarea,
		password: Password,
		'radio-group': RadioGroup,
		radio: Radio,
		checkbox: Checkbox,
		dropdown: Dropdown,
		date: Date,
		repeater: Repeater,
		default: DefaultField,
	}

	const FieldComponent = fields[field.type || 'default']

	return <FieldComponent {...field} />
}

FormComponent.Textarea = Textarea
FormComponent.Password = Password
FormComponent.RadioGroup = RadioGroup
FormComponent.Radio = Radio
FormComponent.Checkbox = Checkbox
FormComponent.Dropdown = Dropdown
FormComponent.Date = Date
FormComponent.Repeater = Repeater

export default FormComponent
