import React, { useState, useCallback, useEffect } from 'react'
import { Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import _ from 'lodash'
import { withContext } from 'Context'
import { getFilteredSettings } from 'Actions/usersActions'
import SessionTable from './SessionTable'

const SessionList = ({
	customFilter,
	onFilter,
	sessionData,
	sessionLoading,
	confirmModal,
	viewLearner,
	onConfirm,
	onCancel,
}) => {
	const [sessionList, setSessionList] = useState([])

	useEffect(() => {
		if (sessionData) {
			const tempArr = sessionData.accountSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			setSessionList(tempArr)
		}
	}, [sessionData])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	if (sessionLoading) return <Loader active />
	if (!sessionList.length)
		return (
			<div className="msg-line">
				<PageContent.Message
					message="There are no sessions available.
				Please refer to the Help Centre for more information on sessions."
				/>
			</div>
		)

	return (
		<>
			<SessionTable
				sessionList={sessionList}
				handleSortTable={handleSortTable}
				activeSortParam={{
					name: customFilter.sort_param || '',
					order: customFilter.sort_direction || 'asc',
				}}
				confirmModal={confirmModal}
				viewLearner={viewLearner}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</>
	)
}

SessionList.propTypes = {
	customFilter: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	sessionData: PropTypes.object.isRequired,
	sessionLoading: PropTypes.bool.isRequired,
	confirmModal: PropTypes.bool.isRequired,
	viewLearner: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

SessionList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter, learnerDetails },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		learnerDetails,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	SessionList,
)
