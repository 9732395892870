import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Icon, Loader } from 'semantic-ui-react'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { Routes } from 'Constants'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { withContext } from 'Context'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { isScorm } from 'Utils/scorm'
import { PageMetaTags, ProgressBar, Table } from 'Common'
import { setExportToPdf } from 'Actions/appActions'
import { reactToPdf } from 'Utils/index'
import EditSubjectData from 'Modals/subjects/EditSubjectData'
import { GET_SUBJECT_BY_ID } from './queries'

import SectionItem from '../SectionItem'
import UserCard from '../UserCard'

import Certificate from './Certificate'

import './style.scss'

const SingleSubject = ({
	match,
	location,
	setBreadcrumbs,
	history,
	userRole,
	userRoleType,
	exportToPdf,
	setExportPdf,
}) => {
	const [editSubjectModal, setEditSubjectModal] = useState({ open: false })
	const [user, setUser] = useState(null)

	const {
		params: { pivotId, userId },
	} = match

	const { data, error, loading } = useQuery(GET_SUBJECT_BY_ID, {
		variables: { pivot_id: parseInt(pivotId), user_id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (userId) {
			if (user && name)
				setBreadcrumbs([
					{
						name: 'Learners',
						path: Routes.USERS,
					},
					{
						name: `${user.first_name} ${user.last_name}`,
						path: Routes.USERS + '/' + user.id,
					},
					{
						name: name,
					},
				])
		} else {
			if (name)
				setBreadcrumbs([
					{
						name: 'Courses',
						path: Routes.SUBJECTS.path,
					},
					{
						name: name,
					},
				])
		}
	}, [loading, user])

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const {
		name,
		description,
		created_at,
		img,
		target_completion,
		refresh_period,
		refresh_period_formatted,
		progress,
		last_viewed,
		sections,
		is_completed,
		feedback_link,
		date_completed,
		certificate,
	} = data.byId

	if (sections.length === 1 && (!certificate || !is_completed) && !userId) {
		history.push(`${Routes.SUBJECTS.path}/${pivotId}/${sections[0].id}`)
	}

	const isSubjectHasScormSection = () => sections.some(isScorm)

	const targetCompletion = moment(target_completion)
	const period = targetCompletion.diff(moment(created_at), 'days')
	const daysLeft = targetCompletion.diff(moment(), 'days')
	const dateCompleted = is_completed ? moment(date_completed).format('DD/MM/YYYY') : null
	const remainingInPercents = ((period - daysLeft) / period) * 100

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<PageMetaTags title={name} />
			<div className="single-subject">
				<div className="title">
					<div className={classNames('info', { flex: userId })}>
						{userId && <UserCard setUser={setUser} />}
						<h1>{name}</h1>
						{!userId && <p>{description}</p>}
					</div>
					<div className="subject-progress">
						<ProgressBar
							title="Course progress"
							tooltip="Course progress based on assessments completed for all course sections."
							percents={progress}
						/>
						<ProgressBar
							title={
								is_completed ? (
									`Signed Off on ${dateCompleted}`
								) : (
									<>
										<span>
											{`Target end date: ${
												targetCompletion.isValid()
													? targetCompletion.format('DD/MM/YYYY')
													: 'not set'
											}`}
										</span>
										{userId && !isReviewer && (
											<button
												className="edit_end_date"
												type="button"
												onClick={() =>
													setEditSubjectModal({
														open: true,
														field: 'target_completion',
														initialValue: targetCompletion.isValid()
															? targetCompletion.format('DD-MM-YYYY')
															: '',
													})
												}
											>
												<i className="fas fa-pen" />
											</button>
										)}
									</>
								)
							}
							percents={target_completion ? (remainingInPercents < 100 ? remainingInPercents : 100) : 0}
							inDays
							color={is_completed ? 'primary' : 'secondary'}
						/>
						<div className="progress-bar">
							<div className="label-bar">Refresh period: {refresh_period_formatted}</div>
							{userId && !isReviewer && (
								<button
									className="edit_end_date"
									type="button"
									onClick={() =>
										setEditSubjectModal({
											open: true,
											field: 'refresh_period',
											initialValue: +refresh_period,
										})
									}
								>
									<i className="fas fa-pen" />
								</button>
							)}
						</div>
						{!isSubjectHasScormSection() && (
							<Button
								className="export-button"
								basic
								loading={exportToPdf.loading}
								onClick={() => {
									setExportPdf({ loading: true })
									reactToPdf(Routes.PRINT + location.pathname, () => {
										setExportPdf({ loading: false })
									})
								}}
							>
								Download PDF
								{!exportToPdf.loading && <Icon name="file pdf" />}
							</Button>
						)}
					</div>
				</div>

				{Boolean(!userId) && (
					<div className="actions">
						{last_viewed ? (
							<Link className="button-link" to={`${Routes.SUBJECTS.path}/${pivotId}/${last_viewed.id}`}>
								{`Continue ${last_viewed.name}`}
							</Link>
						) : (
							<Link className="button-link" to={`${Routes.SUBJECTS.path}/${pivotId}/${sections[0].id}`}>
								{`Start ${sections[0].name}`}
							</Link>
						)}
						<span>Or choose a section below</span>
					</div>
				)}

				{!_.isEmpty(sections) && (
					<Table className="sections-table">
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Section name</Table.HeaderCell>
								<Table.HeaderCell>
									<span>Progress</span>
								</Table.HeaderCell>
								<Table.HeaderCell>
									<span>Status</span>
								</Table.HeaderCell>
								<Table.HeaderCell />
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(sections, section => (
								<SectionItem key={section.id} pivotId={parseInt(pivotId)} {...section} />
							))}
						</Table.Body>
					</Table>
				)}

				{is_completed && certificate && (
					<Certificate {...certificate} isManager={Boolean(userId)} subject={{ img, feedback_link }} />
				)}
			</div>

			{userId && !isReviewer && editSubjectModal.open && (
				<EditSubjectData {...editSubjectModal} onClose={() => setEditSubjectModal({ open: false })} />
			)}
		</>
	)
}

SingleSubject.propTypes = {
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	setExportPdf: PropTypes.func.isRequired,
	exportToPdf: PropTypes.shape({
		loading: PropTypes.bool.isRequired,
	}).isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
			app: { export_to_pdf },
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		exportToPdf: export_to_pdf,
		setExportPdf: data => setExportToPdf(data, dispatch),
	}),
	withBreadcrumbs(SingleSubject),
)
