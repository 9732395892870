import React, { useMemo, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { Button } from 'semantic-ui-react'
import { withContext } from 'Context'

import { Routes } from 'Constants'

import { UserPhoto, Table } from 'Common'
import { determineLastActivityLabel } from 'Utils'
import { setAccountAction } from 'Actions/authActions'

const UserItem = ({ user, onSetAccountAction }) => {
	const history = useHistory()
	const tableData = {
		userName: `${user.first_name} ${user.last_name}`,
		accountName: user.account.name,
		groupName: user.group_name ? user.group_name : '-',
		lastActive: user.last_active ? determineLastActivityLabel(user.last_active) : '-',
		created: moment(user.created_at).format('DD/MM/YYYY'),
	}

	const userLink = useMemo(
		() =>
			generatePath(Routes.SINGLE_USER, {
				id: user.id,
			}),
		[user.id],
	)

	const navigateToUser = useCallback(() => {
		onSetAccountAction(user.account)
		history.push(userLink)
	}, [user.account, userLink])

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell title={tableData.userName}>
					<UserPhoto className="user-photo" user={user} size="mini" />
					<div
						role="button"
						tabIndex="0"
						onKeyDown={() => navigateToUser()}
						onClick={() => navigateToUser()}
						className="link user-link"
					>
						{tableData.userName}
					</div>
				</Table.Cell>
				<Table.Cell title={tableData.accountName}>{tableData.accountName}</Table.Cell>
				<Table.Cell title={tableData.groupName}>{tableData.groupName}</Table.Cell>
				<Table.Cell title={tableData.lastActive}>{tableData.lastActive}</Table.Cell>
				<Table.Cell title={tableData.created}>{tableData.created}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={navigateToUser} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

UserItem.propTypes = {
	user: PropTypes.object.isRequired,
	onSetAccountAction: PropTypes.func,
}

UserItem.defaultProps = {
	onSetAccountAction: () => {},
}

export default withContext(
	([, dispatch]) => ({
		onSetAccountAction: data => setAccountAction(data, dispatch),
	}),
	UserItem,
)
