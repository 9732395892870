import useFetchResource from 'Hooks/useFetchResource'
import { getObsLearningResource } from 'Utils/obs'

export default function useObsModule({ courseId, moduleId }) {
	const { resource: module, ...rest } = useFetchResource(() => {
		return getObsLearningResource('courses', courseId, 'modules', moduleId)
	}, [courseId, moduleId])

	return {
		...rest,
		module,
	}
}
