import useFetchResource from 'Hooks/useFetchResource'
import { getObsLearningResource } from 'Utils/obs'

export default function useObsCourse({ courseId }) {
	const { resource: subject, ...rest } = useFetchResource(() => getObsLearningResource('courses', courseId), [
		courseId,
	])

	return {
		...rest,
		subject,
	}
}
