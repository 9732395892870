import _ from 'lodash'

export const buildCardData = (valuesArray, fieldsData) => {
	const result = []
	_.map(valuesArray, value => {
		_.map(value, (name, index) => {
			const obj = {}
			obj['rowName'] = fieldsData[index].rowName
			obj['value'] = `${name}`
			obj['id'] = `${Math.floor(Math.random() * 10000)}_${name}_${fieldsData[index].rowName}`
			result.push(obj)
		})
	})
	return result
}

export const evidenceDataFields = {
	evidence_actions: {
		rowName: 'actions',
		value: [],
	},
	evidence_date: {
		rowName: 'date',
	},
	evidence_duration: {
		rowName: 'duration',
		value: '',
	},
	evidence_submitted_by: {
		rowName: 'submitted by',
	},
	evidence_text: {
		rowName: 'evidence',
	},
}

export const learningDataFields = {
	date_viewed: {
		rowName: 'Data',
	},
	learning_provider: {
		rowName: 'Learning',
	},
}

export const observationDataFields = {
	observation_date: {
		rowName: 'Data',
	},
	observation_reference: {
		rowName: 'Outcome',
	},
	observation_submitted_by: {
		rowName: 'Submitted by',
	},
	observation_text: {
		rowName: 'Observatoin',
	},
}
