import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Button, Confirm, Grid, Modal, Icon, Popup } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'
//import { Link } from 'react-router-dom'
//import _ from 'lodash'

//import { userGroupRoles } from 'Constants/userGroupRoles'
// { StateContext } from 'Context/index'
//import { useForm } from 'Hooks/useForm'

//import FormComponent from 'Helpers/form'
import { withContext } from 'Context'
import { IMPORT_CSV } from 'Mutations/importMutations'
import { post } from '../../../../store/queries/rest/client'

import './style.scss'

const ImportUsers = ({ open, onClose, toastManager }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [showcsv, setShowcsv] = useState(false)
	const instQuoteOne = 'The Help Centre has a detailed explanation of the import process.'
	const instQuoteTwo = 'This includes finding the group ID numbers and what codes to put in the different columns.'
	const instQuoteThree = instQuoteOne + instQuoteTwo
	//const [existingUsersEmail, setExistingUsersEmail] = useState([])
	//const [file, setFile] = useState()
	//const fileReader = new FileReader()
	//const [billingIncreaseConfirmationMessage, setBillingIncreaseConfirmationMessage] = useState(null)

	const [importCSV] = useMutation(IMPORT_CSV)
	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (!skipConfirm) {
				setConfirmOpen(true)
			} else {
				onClose()
			}
		},
		[setConfirmOpen, onClose],
	)

	const handleShowcsv = () => {
		setShowcsv(true)
	}

	const handleImageChange = e => {
		const body = new FormData()
		body.append('import_sheet', e.target.files[0])
		//console.log(e.target.files[0])
		setSubmitting(true)
		post('/import-user', body)
			.then(response => {
				return response.json()
			})
			.then(data => {
				const variables = { file: data.url }
				importCSV({ variables: variables })
					.then(res => {
						onClose()
						toastManager.add(res.data.importCSV, { appearance: 'success', autoDismiss: true })
					})
					.catch(error => {
						console.log('value in error', error)
						onClose()
					})
					.finally(() => {
						setSubmitting(false)
					})
			})
	}
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Import Learners</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}>
											<a
												rel="noopener noreferrer"
												href={require('../../../../assets/learners_import_template_2022.csv')}
												target="_blank"
												className="ui fluid button"
											>
												Download import template <i className="fas fa-download btn-color" />
											</a>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="button"
												disabled={submitting}
												content="Upload import data"
												fluid
												onClick={handleShowcsv}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										{showcsv && (
											<div className="field-wrap">
												<span className="field-label">Upload CSV</span>
												<label className="upload" htmlFor="Upload">
													<input
														name="upload"
														id="upload"
														type="file"
														accept=".csv, text/csv"
														onChange={handleImageChange}
													/>
													<span className="upload-custom"></span>
												</label>
											</div>
										)}
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<div style={{ marginTop: '-30px' }}>
											<span>{instQuoteThree}</span>
											<br />
											<br />
											<span>Profile Type(Role) </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={4}>
													<Grid.Column textAlign="center">
														<h4>Manager</h4>
														<p>1</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>Learner</h4>
														<p>2</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>Reporting Manager</h4>
														<p>3</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>Reviewer Sign Off</h4>
														<p>4</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Group ID </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={2}>
													<Grid.Column textAlign="center">
														<h4>Group Id</h4>
														<p>1</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>Instruction</h4>
														<p>Must match the group ID numbers in Click.</p>
														<p>To download the ID numbers go to the groups page</p>
														<p>and click the export to CSV button.</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Profile status </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={2}>
													<Grid.Column textAlign="center">
														<h4>Active</h4>
														<p>1</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>Inactive</h4>
														<p>0</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Delete </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={2}>
													<Grid.Column textAlign="center">
														<h4>Yes</h4>
														<p>Yes</p>
													</Grid.Column>
													<Grid.Column textAlign="center">
														<h4>No</h4>
														<p>No</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Date </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Date format</h4>
														<p>dd-mm-yyyy</p>
													</Grid.Column>
												</Grid>
											</Popup>
										</div>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}></Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Close"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				className="confirm"
				header="Closing add learners window"
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>
		</>
	)
}

ImportUsers.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}
ImportUsers.defaultProps = {
	open: false,
}

// export default

export default withToastManager(
	withContext(
		([
			{
				user: {
					user: {
						account: { role },
					},
				},
			},
		]) => ({ currentUserRole: role }),
		ImportUsers,
	),
)
