import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { allRoles, isAssessorRole, resolveRole } from 'Utils/roles'
import { withContext } from 'Context'

import { PageContent, PageMetaTags } from 'Common'
import { getFilteredUsers } from 'Actions/usersActions'

import List from './List'
import AssessorList from './Assessor/List'
import Filter from './Filter'

import './style.scss'

const PAGE_NAME = 'Learners'

const UsersList = ({ setBreadcrumbs, userRole, userRoleType, onFilter, filter }) => {
	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, filter],
	)

	const isUserHasAssessorRole = useMemo(() => {
		return isAssessorRole(userRole, userRoleType)
	}, [])

	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="users-page">
				<PageContent.Content>
					{isUserHasAssessorRole ? (
						<AssessorList handleSetPage={handleSetPage} handleSortTable={handleSortTable} />
					) : (
						<List handleSetPage={handleSetPage} handleSortTable={handleSortTable} />
					)}
				</PageContent.Content>

				{!isUserHasAssessorRole && (
					<PageContent.RightBar>
						<Filter />
					</PageContent.RightBar>
				)}
			</PageContent>
		</>
	)
}

UsersList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	filter: PropTypes.object.isRequired,
	onFilter: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			users: { filter },
			user: { user },
		},
		dispatch,
	]) => ({
		// state
		filter,
		...resolveRole(user),
		// Actions
		onFilter: data => getFilteredUsers(data, dispatch),
	}),
	withBreadcrumbs(UsersList),
)
