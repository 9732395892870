import React from 'react'
import PropTypes from 'prop-types'
import Table from './Table'

const Wrapper = ({ groups, isReviewer, checked }) => {
	// Drag and Drop functions
	return <Table groups={groups} isReviewer={isReviewer} checked={checked} />
}

Wrapper.propTypes = {
	groups: PropTypes.array,
	//loadParentGroup: PropTypes.func.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	//query: PropTypes.string,
	//activeIds: PropTypes.arrayOf(PropTypes.number),
	//selectedGroupIds: PropTypes.array.isRequired,
	//selectGroups: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
}

Wrapper.defaultProps = {
	groups: [],
}

export default Wrapper
