import React from 'react'
import Nestable from 'react-nestable'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import GroupItem from './GroupItem'

const Table = ({ groups, isReviewer, checked }) => {
	if (!groups.length) return <PageContent.Message message="List is empty" />

	return (
		<div>
			<div className="list-header2">
				{!isReviewer && <div />}
				<div />
				<div className="fullwidth-top">
					<span className="comp-group-name">Group</span>
					<span className="comp-group-elearning">eLearning compliance</span>
					<span className="comp-group-sessions">Sessions compliance</span>
				</div>
				<div className="fullwidth-bottom">
					<span className="comp-group-name"></span>
					<span className="comp-group-elearning-sub">
						<span className="">Overdue | </span>
						<span className="">Total Allocated | </span>
						<span className="">% Compliant</span>
					</span>
					<span className="comp-group-sessions-sub">
						<span className="">Overdue | </span>
						<span className="">Total Allocated | </span>
						<span className="">% Compliant</span>
					</span>
				</div>
			</div>
			<Nestable
				items={groups}
				renderItem={props => <GroupItem {...props} checked={checked} />}
				//onChange={nestableHandleChange}
				renderCollapseIcon={({ isCollapsed }) => (
					<button type="button" className="expand">
						<i className={`fas fa-${isCollapsed ? 'plus' : 'minus'}-square`} />
					</button>
				)}
				maxDepth={10}
			/>
		</div>
	)
}

Table.propTypes = {
	groups: PropTypes.array.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	//nestableHandleChange: PropTypes.func,
	//selectedGroupIds: PropTypes.array.isRequired,
	//selectGroups: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
}

Table.defaultProps = {
	//nestableHandleChange: () => {},
}

export default Table
