import { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { GET_SUBJECT_SECTION_BY_ID } from 'Pages/Subjects/SubjectSectionInfo/queries'
import { viewLesson } from 'Utils/obs'
import { OBS_EXTERNAL_SERVICE_ORIGIN } from 'Constants/subject'
import { FeedbackManager, markViewedContentPages, modifyLearningContent } from './helpers'

export default function useObsLessonMaterials({ content, activePage, setActivePage }) {
	const pageContentRef = useRef(null)
	const paginatorContainerRef = useRef(null)

	const { userSubjectId, subjectSectionId, obsModuleLessonId } = useParams()

	const { data, loading, error } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: {
			id: Number.parseInt(subjectSectionId),
			pivot_id: Number.parseInt(userSubjectId),
			limit: 1,
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (!data) {
			return
		}

		const { id, obs_learning_stats } = data.byId
		const lessonContentId = activePage

		viewLesson({
			userSubjectId,
			subjectSectionId: id,
			lessonId: obsModuleLessonId,
			lessonContentId: `${lessonContentId}`,
		})

		markViewedContentPages({
			activePage,
			container: paginatorContainerRef.current,
			learningStats: obs_learning_stats,
			obsModuleLessonId,
		})

		modifyLearningContent({
			container: pageContentRef.current,
			cssSelector: 'a',
			attributes: {
				target: '_blank',
				rel: 'noopener noreferrer',
			},
		})

		modifyLearningContent({
			container: pageContentRef.current,
			cssSelector: 'img',
			attributes: img => {
				const src = img.getAttribute('src')
				const isExternal = src.startsWith('http') || src.includes(OBS_EXTERNAL_SERVICE_ORIGIN)

				if (!isExternal && img instanceof HTMLImageElement) {
					img.setAttribute('src', `${OBS_EXTERNAL_SERVICE_ORIGIN}${src}`)
				}
			},
		})

		FeedbackManager.boot()

		return () => {
			FeedbackManager.destruct()
		}
	}, [data, activePage, obsModuleLessonId, userSubjectId, content])

	const onPageChange = (_, { activePage }) => setActivePage(activePage - 1)

	return {
		onPageChange,
		loading,
		error,
		paginatorContainerRef,
		pageContentRef,
	}
}
