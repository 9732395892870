import React from 'react'
import { Radio as SRadio } from 'semantic-ui-react'
import classNames from 'classnames'

const Radio = field => (
	<div className={classNames('field-wrap', { error: field.error })}>
		{field.customlabel && <span className="field-label">{field.customlabel}</span>}
		<SRadio {...field} />
	</div>
)

export default Radio
