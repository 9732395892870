import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table } from 'Common'
import { Icon, Confirm, Button } from 'semantic-ui-react'
import moment from 'moment'

const SessionItem = ({ list, confirmModal, viewLearner, onConfirm: onConfirmModal, onCancel }) => {
	let sessionType, sessionStatus
	if (list.session_type && list.session_type === 1) {
		sessionType = 'Group session'
	} else if (list.session_type && list.session_type === 2) {
		sessionType = '1:1 session'
	} else if (list.session_type && list.session_type === 3) {
		sessionType = 'Self Directed learning'
	} else {
		sessionType = '-'
	}
	if (list.status && list.status === 1) {
		sessionStatus = 'Active'
	} else if (list.status && list.status === 2) {
		sessionStatus = 'Cancelled'
	} else if (list.status && list.status === 3) {
		sessionStatus = 'Complete'
	} else if (list.status && list.status === 4) {
		sessionStatus = 'Planned'
	} else if (list.status && list.status === 5) {
		sessionStatus = 'Pending'
	} else {
		sessionStatus = '-'
	}
	const tableData = {
		Id: list.id,
		userName: list.name ? list.name : list.title,
		Tags: list.tags && list.tags.length > 0 ? _.map(list.tags, list => list.name).join(', ') : '-',
		ShowDate: list.date ? moment(list.date).format('DD/MM/YYYY') : '-',
		Venue: list.venue ? list.venue : '-',
		Places: list.places ? list.places : '-',
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell title={tableData.userName}>
					{tableData.userName} {list.version ? `(VSN - ${list.version})` : null}
				</Table.Cell>
				<Table.Cell title={tableData.ShowDate}>{tableData.ShowDate}</Table.Cell>
				<Table.Cell title={tableData.Tags}>{tableData.Tags}</Table.Cell>
				<Table.Cell>{sessionType}</Table.Cell>
				<Table.Cell title={tableData.Venue}>{tableData.Venue}</Table.Cell>
				<Table.Cell>{sessionStatus}</Table.Cell>
				<Table.Cell title={tableData.Places}>{tableData.Places}</Table.Cell>
				<Table.Cell onClick={() => viewLearner(tableData.Id)}>
					<Icon name="trash" />
				</Table.Cell>
			</Table.Row>
			<Confirm
				open={confirmModal}
				size="mini"
				content="You are about to delete the Session?"
				cancelButton={<Button className="reverse" disabled={false} content="Cancel" />}
				confirmButton={<Button loading={false} disabled={false} content="Confirm" />}
				onCancel={onCancel}
				onConfirm={onConfirmModal}
			/>
		</>
	)
}
SessionItem.propTypes = {
	list: PropTypes.object.isRequired,
	confirmModal: PropTypes.bool.isRequired,
	viewLearner: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

export default SessionItem
