import React, { useState } from 'react'
import { Loader, Button, Grid } from 'semantic-ui-react'
import AddTask from 'Modals/session/AddTask'
import _ from 'lodash'
import PropTypes from 'prop-types'
import TaskItem from './TaskItem'
import './style.scss'
import { allRoles, isManagerRole } from '../../../../utils/roles'
import { getFilteredUsers } from '../../../../store/actions/usersActions'
import { withContext } from '../../../../context'

const TaskList = ({
	loading,
	error,
	data,
	sessionId,
	manager,
	addEviTask,
	taskRefetch,
	updateTaskEvidence,
	delegateSessionById,
	showEviModalOpen,
	match,
	selectedUsersId,
	userRoleType,
	userRole,
}) => {
	const [openModal, setOpenModal] = useState(false)
	const isManager = isManagerRole(userRole, userRoleType)
	const openModalHandler = () => {
		setOpenModal(true)
	}
	const createTaskModalCloseHandler = () => {
		setOpenModal(false)
	}
	if (loading) return <Loader active inline="centered" style={{ marginTop: '30px' }} />
	if (error) return null
	const { delegateTasksQuery } = data

	const isTasksAvailable = delegateTasksQuery && delegateTasksQuery.length > 0

	if (!isTasksAvailable && !isManager) {
		return null
	}
	return (
		<div className="task-list-wrap">
			<Grid columns={4}>
				<Grid.Row>
					<Grid.Column>
						<h2>Tasks</h2>
					</Grid.Column>
					<Grid.Column />
					<Grid.Column />
					<Grid.Column>
						{delegateSessionById && !delegateSessionById.is_completed ? (
							<>
								{manager ? (
									<Button size="medium" fluid content="Add Task" onClick={openModalHandler} />
								) : null}
							</>
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<ul className="task-list">
				{delegateTasksQuery && delegateTasksQuery.length > 0 ? (
					<>
						{_.map(delegateTasksQuery, task => (
							<TaskItem
								sectionId={parseInt(sessionId)}
								isManager={manager}
								key={task.id}
								addEviTask={addEviTask}
								taskRefetch={taskRefetch}
								updateTaskEvidence={updateTaskEvidence}
								delegateSessionById={delegateSessionById}
								showEviModalOpen={showEviModalOpen}
								{...task}
							/>
						))}
					</>
				) : (
					<div className="task-item" style={{ textAlign: 'center', marginBottom: '40px' }}>
						No Task Found.
					</div>
				)}
			</ul>
			{openModal && (
				<AddTask
					open={openModal}
					onClose={createTaskModalCloseHandler}
					selectedUsersId={selectedUsersId}
					match={match}
					taskRefetch={taskRefetch}
				/>
			)}
		</div>
	)
}

TaskList.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	data: PropTypes.object,
	manager: PropTypes.bool.isRequired,
	sessionId: PropTypes.string.isRequired,
	addEviTask: PropTypes.func,
	taskRefetch: PropTypes.func,
	updateTaskEvidence: PropTypes.func,
	delegateSessionById: PropTypes.object,
	showEviModalOpen: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	selectedUsersId: PropTypes.number.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

TaskList.defaultProps = {
	error: null,
	data: { session: [] },
	addEviTask: () => {},
	taskRefetch: () => {},
	updateTaskEvidence: () => {},
	delegateSessionById: null,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	TaskList,
)
