import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

const LearningActivityItem = ({ user, created_at, causer }) => {
	const formattedDate = moment(created_at).format('DD/MM/YYYY')
	const formattedTime = moment(created_at).format('HH:mm')

	return (
		<div className="evidence-item">
			<div className="top-items">
				<span className="dark-text">
					{`${user.first_name} ${user.last_name} viewed learning for 
					${causer.name} on ${formattedDate} at ${formattedTime}`}
				</span>
			</div>
		</div>
	)
}

LearningActivityItem.propTypes = {
	created_at: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	causer: PropTypes.object.isRequired,
}

export default LearningActivityItem
