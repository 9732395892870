import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageContent, PageMetaTags } from 'Common'
import { withContext } from 'Context'
import './style.scss'
import { generatePath, useHistory } from 'react-router-dom'
import { Button, Grid, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Routes } from '../../../../constants'
import { GET_CURRENT_ACCOUNT } from '../../../../store/queries/usersQueries'

const PAGE_NAME = 'Course Bundles'

const CourseBundles = ({ setBreadcrumbs }) => {
	const history = useHistory()
	const state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
	const user = state ? state.user : null
	const { data, loading } = useQuery(GET_CURRENT_ACCOUNT, {
		variables: { id: parseInt(user.id) },
		fetchPolicy: 'network-only',
	})

	let subscriptions = []
	if (data && data.byId && data.byId.currentAccount && data.byId.currentAccount.subscriptions) {
		subscriptions = data.byId.currentAccount.subscriptions
	}
	let courseBundleOptions = []
	if (subscriptions && Array.isArray(subscriptions)) {
		courseBundleOptions = subscriptions
			.filter(subscription => subscription && subscription.courseBundle && subscription.courseBundle.name)
			.map(subscription => {
				return {
					key: subscription.id,
					max_users_count: subscription.max_users_count || 0,
					current_users_count: subscription.current_users_count || 0,
					value: subscription.courseBundle.name,
					text: subscription.courseBundle.name,
				}
			})
	}
	const onBundleSelection = accountSubscriptionId => {
		history.push({
			pathname: generatePath(Routes.LERANER_ADD_COURSES, {
				accountSubscriptionId: accountSubscriptionId,
			}),
		})
	}
	useEffect(() => {
		if (courseBundleOptions.length === 1) {
			onBundleSelection(courseBundleOptions[0].key)
		}
	}, [courseBundleOptions])
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Learners',
				path: '/learners',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="users-page">
				<PageContent.Content>
					<div className="users-list">
						<div className="page-title">
							<h1>Course Bundles</h1>
						</div>
						<p>Choose bundle to add course</p>
						{loading ? (
							<Loader active />
						) : courseBundleOptions.length > 1 ? (
							<div className="tabs-view">
								<Grid columns={5}>
									<Grid.Row className="course-bundle-row">
										{_.map(courseBundleOptions, bundle => (
											<>
												<Grid.Column className="custom-column-div">
													<div
														key={bundle.key}
														onKeyPress={() => {
															onBundleSelection(bundle.key)
														}}
														onClick={() => onBundleSelection(bundle.key)}
														role="button"
														tabIndex="0"
														className="custom-column"
													>
														<div className="tag-listing">{bundle.value}</div>
														<div className="license-subscription">
															<h3>License used:</h3>
															<p>{bundle.current_users_count}</p>
														</div>
														<div className="license-subscription">
															<h3>Total License:</h3>
															<p>{bundle.max_users_count}</p>
															<Button
																className="bundle-proceed"
																size="small"
																content="Proceed"
															/>
														</div>
													</div>
												</Grid.Column>
											</>
										))}
									</Grid.Row>
								</Grid>
							</div>
						) : (
							<p>You dont have any subscription or course bundle</p>
						)}
					</div>
				</PageContent.Content>
			</PageContent>
		</>
	)
}

CourseBundles.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			users: { customFilter },
		},
	]) => ({
		// state
		customFilter,
	}),
	withBreadcrumbs(CourseBundles),
)
