import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags } from 'Common'
import FormComponent from 'Helpers/form'
import { useForm } from 'Hooks'
import '../style.scss'
import { withContext } from 'Context'
import { API_ACCOUNT_MUTATION } from 'Mutations/settingMutations'
import { GET_CURRENT_ACCOUNT } from 'Queries/usersQueries'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'

const PAGE_NAME = 'Api'
const validate = values => {
	const errors = {}
	const requiredFields = ['webhook_url', 'webhook_secret_key']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const SettingApi = ({ userId, setBreadcrumbs, toastManager }) => {
	const [loading, setLoading] = useState(false)
	const [apiAccountMutation] = useMutation(API_ACCOUNT_MUTATION)
	const [initialFormData, setInitialFormData] = useState({ webhook_url: '', webhook_secret_key: '' })
	const { data } = useQuery(GET_CURRENT_ACCOUNT, {
		variables: { id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (data) {
			let picked = (({ webhook_url, webhook_secret_key }) => ({ webhook_url, webhook_secret_key }))(
				data.byId.currentAccount,
			)
			setInitialFormData(picked)
			setValues(picked)
		}
	}, [data])

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const {
		values: { webhook_url, webhook_secret_key },
		errors,
		handleSubmit,
		handleChange,
		setValues,
	} = useForm(
		() => {
			setLoading(true)
			apiAccountMutation({
				variables: { webhook_url, webhook_secret_key },
			})
				.then(({ data }) => {
					if (data.update) {
						setLoading(false)
						toastManager.add('Saved Successfully', { appearance: 'success', autoDismiss: true })
					}
				})
				.catch(error => {
					console.log('value in error', error)
					setLoading(false)
				})
				.finally(() => {
					setLoading(false)
				})
		},
		validate,
		initialFormData,
	)
	return (
		<div className="setting-list">
			<PageMetaTags title={PAGE_NAME} />
			<h2>{PAGE_NAME}</h2>
			<div className="app-setting-form">
				<form onSubmit={handleSubmit}>
					<FormComponent
						autoCorrect="off"
						autoCapitalize="none"
						autoComplete="off"
						name="webhook_url"
						value={webhook_url || ''}
						onChange={handleChange}
						placeholder="Api URL"
						size="large"
						error={errors.webhook_url}
						selection
					/>
					<FormComponent
						name="webhook_secret_key"
						autoCorrect="off"
						autoCapitalize="none"
						autoComplete="off"
						value={webhook_secret_key || ''}
						onChange={handleChange}
						placeholder="Secret Key"
						size="large"
						error={errors.webhook_secret_key}
						selection
					/>
					<Button size="big" loading={loading} disabled={loading} fluid content="Submit" />
				</form>
			</div>
			<p className="padding-top-30">
				<b>Instructions:</b> <br></br>
				<br></br>API stands for Application Programming Interface. It allows two applications to talk to each
				other. To enable your system to talk with Click please enter the URL and secret key which have been
				configured on your system. Click will then send course completion information to this URL when a learner
				successfully completes a course:
			</p>
			<br></br>
			<p>
				<b>Learner fields:</b> <br></br>
				<ul>
					<li>
						<b>id =</b> Click user profile ID
					</li>
					<br></br>
					<li>
						<b>first_name =</b> user first name on Click
					</li>
					<br></br>
					<li>
						<b>last_name =</b> user last name on Click
					</li>
					<br></br>
					<li>
						<b>primary_email =</b> user primary email address
					</li>
					<br></br>
					<li>
						<b>uid = </b>user unique identifying data which is set by editing the user profile within Click.
						This could be an HR or payroll number or the user ID from your system which Click is sending
						information to.
					</li>
				</ul>
			</p>
			<br></br>
			<p>
				<b>Subject fields:</b>

				<br></br>
				<ul>
					<li>
						<b>id =</b> Click course ID. Contact support@click-learning.co.uk for an up to date list of
						course ID&#39;s
					</li>
					<br></br>
					<li>
						<b>name = </b> Click course title
					</li>
					<br></br>
					<li>
						<b>completed_on =</b> date course completed by the system user in ISO 8601 format Sample data:
					</li>
				</ul>
			</p>
			<br></br>
			<p>
				<b>Sample data:</b>
				<br></br>&nbsp;&nbsp;&#10100;<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&quot;type&quot; :
				&quot;subject.completed&quot;, <br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&quot;data&quot;: &#10100;<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&quot;learner&quot;: &#10100;<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&quot;id&quot;: 12345,<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&quot;first_name&quot;: &quot;Joe&quot;,
				<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&quot;last_name&quot;: &quot;Bloggs&quot;,<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&quot;primary_email&quot;: &quot;joe.bloggs@anytestemail.com&quot;,
				<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&quot;uid&quot;: &quot;UIDis9876&quot;<br></br>
				&nbsp;&nbsp;&nbsp;&emsp;&#10101;,
				<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&quot;subject&quot;: &#10100;<br></br>&emsp; &emsp;&emsp;&quot;id&quot;:
				44, <br></br>
				&emsp;&nbsp;&emsp;&emsp;&quot;name&quot;: &quot;Infection Prevention and Control&quot;<br></br>
				&nbsp;&nbsp;&nbsp;&emsp;&#10101;,
				<br></br>
				&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&quot;completed_on&quot;: 2021-07-29 16:48:46 <br></br>
				&nbsp;&nbsp;&nbsp;&emsp;&#10101;
				<br></br>
				&nbsp;&nbsp;&#10101;
			</p>
		</div>
	)
}

SettingApi.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	userId: PropTypes.number.isRequired,
}

export default withBreadcrumbs(
	withContext(
		([
			{
				user: {
					user: { id },
				},
			},
		]) => ({
			// State
			userId: id,
		}),
		withToastManager(SettingApi),
	),
)
