import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_USER_ACTIVITY } from 'Queries/usersQueries'

import ActivityItem from './ActivityItem'

const Activity = ({ match, onLogsEmpty }) => {
	const {
		params: { id },
	} = match

	const { loading, error, data } = useQuery(GET_USER_ACTIVITY, {
		variables: { user_id: parseInt(id), limit: 30 },
		fetchPolicy: 'network-only',
	})

	if (loading) return <Loader active />

	if (!data.logs.length) {
		onLogsEmpty()
	}

	if (error || !data.logs.length) return null

	return (
		<div className="user-activity">
			<div className="header">
				<span className="custom-label">Activity</span>
			</div>
			<ul className="activity-list">
				{_.map(_.reverse(data.logs), log => (
					<ActivityItem key={log.id} {...log} />
				))}
			</ul>
		</div>
	)
}

Activity.propTypes = {
	match: PropTypes.object.isRequired,
	onLogsEmpty: PropTypes.func.isRequired,
}

export default withRouter(Activity)
