const buildTree = (array, parent_id = null) => {
	const output = []

	if (array.length === 1) return array

	array.forEach(val => {
		if (val.parent_id === parent_id) {
			const parent = {
				...val,
				children: [],
			}
			const searchable = buildTree(array, val.id)
			if (searchable.length) {
				parent.children.push(...searchable)
			}
			output.push(parent)
		}
	})

	return output
}

export default buildTree
