import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Routes } from 'Constants'

export default function useTwoFactorAuth() {
	const history = useHistory()

	const validateTwoFactorAuthResponse = error => {
		const message = _.get(error, 'graphQLErrors.[0].message')

		try {
			const { userId } = JSON.parse(message)

			history.push({
				pathname: Routes.TWO_FACTOR_AUTH,
				state: { userId },
			})
		} catch {
			throw error
		}
	}

	const sentTwoFactorCode = ({ userId, code }) => {
		return fetch(`${process.env.REACT_APP_API_PATH}/2fa/${userId}`, {
			method: 'POST',
			body: JSON.stringify({ code }),
			headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		}).then(async response => {
			const data = await response.json()
			if (response.ok) {
				return data
			}

			throw data
		})
	}

	return { validateTwoFactorAuthResponse, sentTwoFactorCode }
}
