import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

import { ACCEPT_INVITE } from 'Mutations/inviteMutations'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { withContext } from 'Context/index'
import { signUpSuccessAction } from 'Actions/authActions'
import { PageMetaTags } from 'Common'

const validate = values => {
	let errors = {}
	const requiredFields = ['password', 'password_confirmation']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	if (values.password && values.password.length < 8) errors.password = true
	if (values.password !== values.password_confirmation) errors.password_confirmation = true

	return errors
}

const RegisterForm = ({ match, signUpSuccess }) => {
	const [acceptInviteRequest] = useMutation(ACCEPT_INVITE)
	const {
		params: { token },
	} = match
	const {
		values: { username, password, password_confirmation },
		errors,
		handleSubmit,
		handleChange,
	} = useForm(
		() =>
			acceptInviteRequest({ variables: { username, password, password_confirmation, token } }).then(
				({ data: { accept } }) => {
					signUpSuccess(accept)
				},
			),
		validate,
	)

	return (
		<React.Fragment>
			<PageMetaTags title="Accept invite" />
			<span className="title">Accept invite</span>
			<form onSubmit={handleSubmit} noValidate>
				<FormComponent
					name="password"
					type="password"
					value={password || ''}
					onChange={handleChange}
					placeholder="Password"
					size="large"
					error={errors && errors.password}
				/>
				<FormComponent
					name="password_confirmation"
					type="password"
					value={password_confirmation || ''}
					onChange={handleChange}
					placeholder="Confirm Password"
					size="large"
					error={errors && errors.password_confirmation}
				/>
				<Button type="submit" size="big" fluid>
					Accept
				</Button>
			</form>
		</React.Fragment>
	)
}

RegisterForm.propTypes = {
	match: PropTypes.object.isRequired,
	signUpSuccess: PropTypes.func.isRequired,
}

export default withContext(
	([, dispatch]) => ({
		// actions
		signUpSuccess: data => signUpSuccessAction(data, dispatch),
	}),
	RegisterForm,
)
