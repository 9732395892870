import React from 'react'
import _ from 'lodash'

import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'

const answerMarks = [
	{
		text: 'You should select this answer',
		icon: 'fas fa-circle',
		condition: isRightAnswer => isRightAnswer,
	},
	{
		text: 'You should not select this answer',
		icon: 'far fa-circle',
		condition: isRightAnswer => !isRightAnswer,
	},
]

const yourAnswerMarks = [
	{
		text: 'You selected this answer',
		icon: 'fas fa-circle',
		condition: itWasSelected => itWasSelected,
	},
	{
		text: 'You did not select this answer',
		icon: 'far fa-circle',
		condition: itWasSelected => !itWasSelected,
	},
]

const MultichoiceAnswer = ({ options, answerIds }) => {
	return (
		<ul>
			{_.map(options, option => {
				const isAnswerId = _.find(answerIds, answerId => answerId === option.id)
				return (
					<li key={option.id}>
						<p>
							{option.answer === '0' || option.answer === '1'
								? Boolean(parseInt(option.answer)).toString()
								: option.answer}
						</p>
						<div className="answer-icon">
							{yourAnswerMarks.map(item =>
								item.condition(isAnswerId) ? (
									<Popup
										key={option.id}
										position="right center"
										hoverable
										content={item.text}
										trigger={<i className={item.icon} />}
									/>
								) : null,
							)}
						</div>
						<div className="answer-icon">
							{answerMarks.map(item =>
								item.condition(option.right) ? (
									<Popup
										key={option.id}
										position="right center"
										hoverable
										content={item.text}
										trigger={<i className={item.icon} />}
									/>
								) : null,
							)}
						</div>
					</li>
				)
			})}
		</ul>
	)
}

MultichoiceAnswer.propTypes = {
	options: PropTypes.array.isRequired,
	answerIds: PropTypes.array.isRequired,
}

export default MultichoiceAnswer
