import { useEffect } from 'react'
import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'

export default function useBreadcrumbs({
	userId,
	userSubjectId,
	obsCourseId,
	subjectName,
	name,
	setBreadcrumbs,
	user,
}) {
	const { id, first_name, last_name } = user || {}

	useEffect(() => {
		let breadcrumbsCollection = [
			{
				name: 'Courses',
				path: Routes.SUBJECTS.path,
			},
			{
				name: subjectName,
				path: [Routes.SUBJECTS.path, userSubjectId, OBS_SUBJECT_PREFIX, obsCourseId].join('/'),
			},
			{
				name,
			},
		]

		if (userId && first_name && last_name) {
			breadcrumbsCollection = [
				{
					name: 'Learners',
					path: Routes.USERS,
				},
				{
					name: [first_name, last_name].join(' '),
					path: [Routes.USERS, id].join('/'),
				},
				{
					name: subjectName,
					path: [Routes.MANAGER, id, 'courses', userSubjectId, OBS_SUBJECT_PREFIX, obsCourseId].join('/'),
				},
				{
					name,
				},
			]
		}

		setBreadcrumbs(breadcrumbsCollection)
	}, [userId, name, id, first_name, last_name, subjectName, userSubjectId, obsCourseId])
}
