import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'

import { withContext } from 'Context'
import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import { profileUpdateAction } from 'Actions/profileActions'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'

const SKIP_EMAIL_COOKIE = 'skipEmailConfirm=true'
const SKIP_SECONDARY_EMAIL_COOKIE = 'skipSecondaryEmailConfirm=true'

const cookies = {
	email: SKIP_EMAIL_COOKIE,
	secondary_email: SKIP_SECONDARY_EMAIL_COOKIE,
}

const validate = values => {
	let errors = {}
	const requiredFields = [values.field_name]

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	if (!/\S+@\S+\.\S+/.test(values[values.field_name])) errors[values.field_name] = true

	return errors
}

const EmailConfirmation = ({ user, updateProfileSuccess, fieldName, content }) => {
	const [modalVisible, setModalVisible] = useState(false)
	const [updateProfileRequest] = useMutation(UPDATE_PROFILE_MUTATION)
	const {
		values: { [fieldName]: fieldValue },
		errors,
		handleSubmit,
		handleChange,
	} = useForm(
		() =>
			updateProfileRequest({
				variables: {
					id: parseInt(user.id),
					first_name: user.first_name,
					last_name: user.last_name,
					email: user.email,
					secondary_email: user.secondary_email,
					[fieldName]: fieldValue,
				},
			}).then(({ data: { updateProfile } }) => {
				if (Boolean(updateProfile) === true) {
					updateProfileSuccess({ ...user, [fieldName]: fieldValue })
					setModalVisible(false)
				}
			}),
		validate,
		{
			field_name: fieldName,
		},
	)

	const checkSkipLogin = () => {
		return document.cookie.split(';').filter(item => item.includes(cookies[fieldName])).length
	}

	useEffect(() => {
		if (user.token && !user[fieldName] && !checkSkipLogin()) {
			setTimeout(() => {
				setModalVisible(true)
			}, 2000)
		}
	}, [user])

	const handleSkipModal = () => setModalVisible(false, (document.cookie = cookies[fieldName]))

	return (
		<Modal open={modalVisible} size="tiny">
			<Modal.Content>
				<p>{content}</p>
				<form onSubmit={handleSubmit}>
					<FormComponent
						name={fieldName}
						value={fieldValue || ''}
						onChange={handleChange}
						placeholder="Enter email"
						size="large"
						error={errors[fieldName]}
					/>
				</form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={handleSkipModal}>Skip</Button>
				<Button onClick={handleSubmit}>Save</Button>
			</Modal.Actions>
		</Modal>
	)
}

EmailConfirmation.propTypes = {
	user: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	updateProfileSuccess: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			user: { user },
		},
		dispatch,
	]) => ({
		// state
		user,
		// actions
		updateProfileSuccess: data => profileUpdateAction(data, dispatch),
	}),
	EmailConfirmation,
)
