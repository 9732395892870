import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags } from 'Common'
import { useHistory } from 'react-router-dom'
import { Routes } from 'Constants'
import './style.scss'

const PAGE_NAME = 'Settings'

const Setting = ({ setBreadcrumbs }) => {
	const history = useHistory()
	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])
	const handleChange = role => {
		console.log('Tag click', role)
		if (role === 'API') history.push(location.pathname + '/api')
		if (role === 'Tags') history.push(location.pathname + '/tags')
		if (role === 'Topics') history.push(Routes.TOPICS)
		if (role === 'LEAVERS_STARTERS_AUTOMATION') history.push(Routes.LEAVERS_STARTERS_AUTOMATION)
	}
	return (
		<div className="setting-list">
			<PageMetaTags title={PAGE_NAME} />
			<h2>{PAGE_NAME}</h2>
			<div
				role="button"
				className="roles"
				onClick={() => handleChange('Topics')}
				onKeyDown={() => handleChange('Topics')}
				tabIndex={0}
			>
				<h4>Topics</h4>
			</div>
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('Tags')}
				onKeyDown={() => handleChange('Tags')}
				tabIndex={0}
			>
				<h4>Tags</h4>
			</div>
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('API')}
				onKeyDown={() => handleChange('API')}
				tabIndex={0}
			>
				<h4>API</h4>
			</div>
			<div
				role="button"
				className="roles padding-top-30"
				onClick={() => handleChange('LEAVERS_STARTERS_AUTOMATION')}
				onKeyDown={() => handleChange('LEAVERS_STARTERS_AUTOMATION')}
				tabIndex={0}
			>
				<h4>Leavers & Starters Automation</h4>
			</div>
		</div>
	)
}

Setting.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(Setting)
