import React from 'react'
import PropTypes from 'prop-types'

const Question = ({ label, children }) => (
	<>
		<span className="label">{label}</span>
		{children}
	</>
)

Question.propTypes = {
	label: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
}

export default Question
