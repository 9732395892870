import React, { useEffect, useRef } from 'react'
import { samlRequest } from 'Queries/rest/saml'
import PropTypes from 'prop-types'
import { Loader } from 'Common/Loader'

const SamlLogin = ({ location: { search } }) => {
	const ref = useRef()
	useEffect(() => {
		const queryParams = new URLSearchParams(search)

		samlRequest(
			encodeURIComponent(queryParams.get('SAMLRequest')),
			encodeURIComponent(queryParams.get('RelayState')),
		).then(resp => {
			resp.text()
				.then(html => (ref.current.innerHTML = html))
				.then(() => {
					const form = ref.current.querySelector('form')
					form.submit()
				})
		})
	}, [])

	return (
		<div>
			<Loader />
			<div ref={ref} />
		</div>
	)
}

SamlLogin.propTypes = {
	location: PropTypes.object.isRequired,
}

export default SamlLogin
