import React from 'react'
// import { Link } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'

// import { Routes } from 'Constants'
import { PageMetaTags, CoursesTabsNav } from 'Common'

// import ProgressSubjectCart from 'Pages/Subjects/ProgressSubjectCart'
// import SubjectCart from './SubjectCard'
import UserCart from './UserCart'

import './style.scss'

const Learner = ({ groups, userManager }) => {
	return (
		<div className="dashboard-page learner">
			<PageMetaTags title="Dashboard" />
			<Grid>
				<Grid.Row>
					<Grid.Column className="cart-wrap" tablet={16} computer={8}>
						<UserCart groups={groups} userManager={userManager} />
					</Grid.Column>
					{/* TODO: once business logic clarified for it
					<Grid.Column className="cart-wrap" tablet={16} computer={8}>
						{!subject && (
							<div className="dashboard-subject-card empty-card">
								<i className="fas fa-graduation-cap" />
								<Link className="button-link" to={`${Routes.SUBJECTS.path}`}>
									Courses - view
								</Link>
							</div>
						)}
						{subject && !subject.last_viewed && <SubjectCart {...subject} />}
						{subject && subject.last_viewed && <ProgressSubjectCart isLastViewed={false} {...subject} />}
					</Grid.Column> */}
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<div className="development-spacer">
							<i className="fas fa-graduation-cap" />
							<h2>My Development Plan</h2>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<CoursesTabsNav />
		</div>
	)
}

Learner.propTypes = {
	groups: PropTypes.array.isRequired,
	userManager: PropTypes.object,
	// subject: PropTypes.object,
}
Learner.defaultProps = {
	userManager: null,
	// subject: {},
}

export default Learner
