import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Table } from 'Common'
import { Button } from 'semantic-ui-react'
import FormComponent from 'Helpers/form'
import { Routes } from 'Constants'
import moment from 'moment'

const SessionsItem = ({ user, isSelect, selectUser }) => {
	const history = useHistory()
	let sessionType, sessionStatus
	if (user.session_type && user.session_type === 1) {
		sessionType = 'Group session'
	} else if (user.session_type && user.session_type === 2) {
		sessionType = '1:1 session'
	} else if (user.session_type && user.session_type === 3) {
		sessionType = 'Self Directed learning'
	} else {
		sessionType = '-'
	}

	if (user.status && user.status === 1) {
		sessionStatus = 'Active'
	} else if (user.status && user.status === 2) {
		sessionStatus = 'Cancelled'
	} else if (user.status && user.status === 3) {
		sessionStatus = 'Complete'
	} else if (user.status && user.status === 4) {
		sessionStatus = 'Planned'
	} else if (user.status && user.status === 5) {
		sessionStatus = 'Pending'
	} else {
		sessionStatus = '-'
	}
	const tableData = {
		id: user.id,
		userTitle: user.title,
		Date: user.date ? moment(user.date).format('DD/MM/YYYY') : '-',
		Venue: user.venue ? user.venue : '-',
		Places: user.places ? user.places : '-',
		UserCount: user.users_count ? user.users_count : '-',
	}

	const viewTopic = () => {
		history.push(`${Routes.SESSION.path}/${user.topic.id}/view/${user.id}`)
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.id}>{tableData.id}</Table.Cell>
				<Table.Cell title={tableData.userTitle}>
					{tableData.userTitle} {user.version ? `(VSN - ${user.version})` : null}
				</Table.Cell>
				<Table.Cell title={tableData.Date}>{tableData.Date}</Table.Cell>
				<Table.Cell>{sessionType}</Table.Cell>
				<Table.Cell title={tableData.Venue}>{tableData.Venue}</Table.Cell>
				<Table.Cell>{sessionStatus}</Table.Cell>
				<Table.Cell>{tableData.UserCount}</Table.Cell>
				<Table.Cell title={tableData.Places}>{tableData.Places}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={viewTopic} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}
SessionsItem.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
}

SessionsItem.defaultProps = {
	isSelect: false,
}

export default SessionsItem
