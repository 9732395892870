import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import { getRequestHeaders } from '.'

const getObsLearningServiceUrl = (...rest) => {
	return [process.env.REACT_APP_API_PATH, OBS_SUBJECT_PREFIX, ...rest].filter(Boolean).join('/')
}

export const getObsLearningResource = async (...params) => {
	const obsLearningServiceUrl = getObsLearningServiceUrl(...params)
	const requestOptions = {
		headers: getRequestHeaders(),
	}

	const response = await fetch(obsLearningServiceUrl, requestOptions)

	if (!response.ok) {
		throw response
	}

	return response.json()
}

export const answerAssessmentQuestion = async ({ userSubjectSectionId, questionId, answerIds }) => {
	const obsLearningServiceUrl = getObsLearningServiceUrl(userSubjectSectionId, 'questions', questionId)
	const requestOptions = {
		method: 'POST',
		headers: getRequestHeaders(),
		body: JSON.stringify({
			answerIds,
		}),
	}

	const response = await fetch(obsLearningServiceUrl, requestOptions)

	if (!response.ok) {
		throw response
	}
}

export const viewLesson = async ({ userSubjectId, subjectSectionId, lessonId, lessonContentId }) => {
	const obsLearningServiceUrl = getObsLearningServiceUrl(
		userSubjectId,
		subjectSectionId,
		'lessons',
		lessonId,
		'contents',
		lessonContentId,
		'view',
	)
	const requestOptions = {
		method: 'PUT',
		headers: getRequestHeaders(),
	}

	const response = await fetch(obsLearningServiceUrl, requestOptions)

	if (!response.ok) {
		throw response
	}
}

export const reportBrokenLink = async ({ learningOutcomeName, page, subjectName, sectionName, feedback }) => {
	const response = await fetch(getObsLearningServiceUrl('user-feedback'), {
		method: 'POST',
		headers: getRequestHeaders(),
		body: JSON.stringify({
			subject: `${subjectName} - ${sectionName}`,
			learning_outcome_name: learningOutcomeName,
			page,
			feedback: feedback.trim() || undefined,
		}),
	})

	if (!response.ok) {
		throw response
	}
}

export const getObsLearningStats = ({ obsModuleLessonId, learningStats, separator = ':' }) => {
	const initialValue = new Map()

	try {
		const obsLearningStats = JSON.parse(learningStats)

		return Object.entries(obsLearningStats).reduce((lessonConentMap, [key, value]) => {
			const [lessonId, contentId] = key.split(separator)
			if (String(lessonId) !== String(obsModuleLessonId)) {
				return lessonConentMap
			}

			return lessonConentMap.set(Number(contentId), value)
		}, initialValue)
	} catch {
		return initialValue
	}
}

/** Solution is a fucking crap, but we need to ask OBS content providers remove this */
// /*!content.includes('multichoice_') || */
export const removeAssessmentLesson = (lessons = []) => {
	return lessons.filter(({ content }) => {
		if (!content) {
			return false
		}

		return !content.toLocaleLowerCase().includes('check answers')
	})
}
