export const getHeaders = ({ storageToken = 'user' } = {}) => {
	try {
		let authData = localStorage.getItem(storageToken)

		authData = JSON.parse(authData)

		const {
			user: { account, token },
		} = authData

		return {
			Authorization: `Bearer ${token}`,
			accountId: account.id,
		}
	} catch {
		return {}
	}
}
