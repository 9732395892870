import moment from 'moment'

export default values => {
	let errors = {}

	if (!!values.target_completion && !moment(values.target_completion, 'DD-MM-YYYY', null, true).isValid()) {
		errors.target_completion = true
	}
	return errors
}
