import { mainMiddleware } from '../middleware'
import userReducer from './userReducer'
import appReducer from './appReducer'
import subjectReducer from './subjectReducer'
import usersReducer from './usersReducer'

export const mainReducer = ({ app, user, subject, users }, action) => {
	const { appMiddleware, subjectMiddleware, authMiddleware } = mainMiddleware(action)
	return {
		app: appReducer(app, appMiddleware || action),
		user: userReducer(user, action),
		subject: subjectReducer(subject, subjectMiddleware || action),
		users: usersReducer(users, authMiddleware || action),
	}
}
