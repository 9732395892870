import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Loader, Button } from 'semantic-ui-react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import classNames from 'classnames'
import { SESSION_DELEGATES } from 'Queries/settingQueries'
import { userGroupRoles, userRoles } from 'Constants'
import { ProgressBar, UserPhoto, Table } from 'Common'
import FormComponent from 'Helpers/form'
import { StateContext } from 'Context/index'
import BookSession from '../../../../modals/session/Book'

const LearnerRow = ({ user, isSelect, selectUser, currentSessionId, topic, refetchSessionData, setRefetchLoader }) => {
	const [getPivotId, { loading, data: getPivotIdData }] = useLazyQuery(SESSION_DELEGATES, {
		fetchPolicy: 'network-only',
	})
	const [sessionModal, setSessionModal] = useState(false)
	const history = useHistory()
	const userRole = user.groupName ? _.find(userGroupRoles, ['id', user.role]) : _.find(userRoles, ['id', user.role])
	const tableData = {
		userName: user.name,
		userRole: userRole && userRole.role,
		groupName: user.groupName,
		Progress: user.sessions.length > 0 ? user.sessions[0].progress : null,
		PivotId: user.sessions.length > 0 ? user.sessions[0].pivot_id : null,
		isBooked: user.sessions.length > 0 ? user.sessions[0].is_booked : false,
	}
	const [
		{
			user: {
				user: {
					account: { name: accountName },
				},
			},
		},
	] = useContext(StateContext)

	useEffect(() => {
		if (getPivotIdData && getPivotIdData.delegateSessionById) {
			history.push(
				`/learners/${user.id}/session/${currentSessionId}/${getPivotIdData.delegateSessionById.pivot_id}`,
			)
		}
	}, [getPivotIdData])

	const userLink = () => {
		getPivotId({ variables: { session_id: currentSessionId, user_id: user.id } })
	}
	const showSessionModal = () => {
		setSessionModal(true)
	}
	const closeSessionModal = (flag = false) => {
		setSessionModal(false)
		if (flag) {
			setRefetchLoader(true)
		}
		refetchSessionData()
	}
	if (loading) return <Loader active inline="centered" style={{ marginTop: '30px' }} />
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.userName}>
					<UserPhoto className="user-photo" user={user} size="mini" />
					<div
						role="button"
						tabIndex="0"
						onClick={userLink}
						onKeyDown={userLink}
						style={{ cursor: 'pointer' }}
					>
						{user.name}
					</div>
				</Table.Cell>
				<Table.Cell title={tableData.userRole || 'Manager'}>{tableData.userRole || 'Manager'}</Table.Cell>
				<Table.Cell title={tableData.groupName || accountName}>{tableData.groupName || accountName}</Table.Cell>
				<Table.Cell>
					<ProgressBar
						percents={tableData.Progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				<Table.Cell>
					{tableData.isBooked ? (
						<Button fluid size="small" onClick={showSessionModal} content="Confirm Booking" />
					) : (
						<Button fluid size="small" onClick={userLink} content="View" />
					)}
				</Table.Cell>
			</Table.Row>
			{sessionModal && (
				<BookSession
					open={sessionModal}
					topicId={topic.id}
					currentSessionId={currentSessionId}
					onClose={closeSessionModal}
				/>
			)}
		</>
	)
}

LearnerRow.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
	currentSessionId: PropTypes.number.isRequired,
	topic: PropTypes.object.isRequired,
	setRefetchLoader: PropTypes.bool.isRequired,
	refetchSessionData: PropTypes.func.isRequired,
}

LearnerRow.defaultProps = {
	isSelect: false,
}

export default LearnerRow
