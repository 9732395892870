import React, { useEffect } from 'react'
import { Loader, Popup, Icon } from 'semantic-ui-react'
import FormComponent from 'Helpers/form'
import moment from 'moment'
import _ from 'lodash'
import useSelectAll from 'Hooks/useSelectAll'
import { useQuery } from '@apollo/react-hooks'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'
import PropTypes from 'prop-types'
import { GET_SUBJECTS_FOR_INVITES } from './queries'
import './styles.scss'

const AttachSubjectList = ({ subjects, errors, handleSubjectsChange, changeGlobalSubjectValue }) => {
	const { error: refreshPeriodError, loading: refreshPeriodLoading, data: refreshPeriodData } = useQuery(
		GET_REFRESH_PERIOD_OPTIONS,
	)

	const { error: subjectsError, loading: subjectsLoading, data: currentAccountSubjectsData } = useQuery(
		GET_SUBJECTS_FOR_INVITES,
		{
			fetchPolicy: 'network-only',
		},
	)

	const selectAllCheckbox = useSelectAll(subjects, changeGlobalSubjectValue)

	useEffect(() => {
		if (currentAccountSubjectsData) {
			const subjects = _(currentAccountSubjectsData.currentAccount)
				.map(({ id, name, refresh_period, target_completion }) => ({
					id,
					name,
					target_completion: moment()
						.add(target_completion, 'days')
						.format('DD-MM-YYYY'),
					refresh_period,
				}))
				.sortBy(item => _.toLower(item.name))
				.value()

			changeGlobalSubjectValue(subjects)
		}
	}, [currentAccountSubjectsData])

	if (refreshPeriodLoading || subjectsLoading || !subjects) return null
	if (refreshPeriodError || subjectsError) return 'Error'

	return (
		<>
			{!subjects ? (
				<div className="field-wrap">
					<Loader active inline="centered" />
				</div>
			) : (
				<div className="subjects">
					<div className="header row">
						<div className="cell checkbox-column">{selectAllCheckbox}</div>
						<div className="cell field-label subject-column">Courses</div>
						<div className="cell field-label">
							Target
							<Popup
								flowing
								hoverable
								position="bottom center"
								trigger={<Icon name="info circle" size="large" />}
							>
								<Popup.Content>
									<div>
										Click on the calendar icon to delete the date.
										<br />
										This will make the course appear in the learners
										<br />
										optional section and prevent it from being tracked
										<br />
										on the training matrix.
									</div>
								</Popup.Content>
							</Popup>
						</div>
						<div className="cell field-label">Refresh</div>
					</div>
					<div className="body">
						{subjects.map((subject, index) => (
							<div className={`row ${subject.selected ? '' : 'disabled'}`} key={subject.id}>
								<div className="cell checkbox-column">
									<FormComponent.Checkbox
										name="selected"
										checked={subject.selected}
										onChange={(e, data) => handleSubjectsChange(index, e, data)}
									/>
								</div>
								<div className="cell subject-column">{subject.name}</div>
								<div className="cell">
									{subject.selected && (
										<FormComponent.Date
											name="target_completion"
											icon="calendar alternate outline"
											minDate={moment()}
											dateFormat="DD-MM-YYYY"
											popupPosition="top right"
											clearable={false}
											clearIcon="calendar alternate outline"
											onChange={(e, data) => handleSubjectsChange(index, e, data)}
											value={subject.target_completion}
											error={errors.target_completion}
										/>
									)}
								</div>
								<div className="cell">
									{refreshPeriodData && subject.selected && (
										<FormComponent.Dropdown
											name="refresh_period"
											type="dropdown"
											options={refreshPeriodData.refreshPeriodOptions}
											value={+subject.refresh_period}
											onChange={(e, data) => handleSubjectsChange(index, e, data)}
											icon="angle down"
											selection
											selectOnBlur={false}
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	)
}

AttachSubjectList.propTypes = {
	subjects: PropTypes.array,
	handleSubjectsChange: PropTypes.func.isRequired,
	changeGlobalSubjectValue: PropTypes.func.isRequired,
	errors: PropTypes.object,
}
AttachSubjectList.defaultProps = {
	errors: {},
	subjects: [],
}

export { AttachSubjectList }
