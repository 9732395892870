import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PageMetaTags } from 'Common'
import EvidenceShow from 'Modals/session/EvidenceShow'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { useQuery } from '@apollo/react-hooks'
import { GET_SESSION_BYID } from 'Queries/settingQueries'
import { Grid, Button, Table, Loader } from 'semantic-ui-react'
import moment from 'moment'
import AddTask from 'Modals/session/AddTask'
import SendEmail from 'Modals/session/SendEmail'
import LearnerAddEvidence from 'Modals/session/LearnerAddEvidence'
import { Routes } from 'Constants'
import { withRouter, useHistory } from 'react-router-dom'
import { allRoles, isSystemAdminRole, resolveRole } from 'Utils/roles'
import { withContext } from 'Context'
import Learners from './Learners/Learners'
import GroupSignoff from './GroupSignoff'
import './style.scss'

const PAGE_NAME = 'Session Details'

const SessionDetails = ({ setBreadcrumbs, match, userRole, userRoleType }) => {
	const [openSignModal, setOpenSignModal] = useState(false)
	const [openShowEviModal, setOpenShowEviModal] = useState(false)
	const [currentVideoEvidence, setCurrentVideoEvidence] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const [openEmailModal, setOpenEmailModal] = useState(false)
	const [openEvidanceModal, setOpenEvidanceModal] = useState(false)
	const history = useHistory()
	const [selectedUsers, setSelectedUsers] = useState([])
	const [refetchLoader, setRefetchLoader] = useState(false)
	const [sessionData, setSessionData] = useState({
		title: '-',
		description: '-',
		tags: [],
		users: [],
		users_count: 0,
		status: '-',
		date: '-',
		session_type: '-',
		venue: '-',
		notes: '-',
		places: '-',
		document: '-',
		document_link: '-',
		document_name: '-',
		topic: null,
	})
	const {
		params: { id, sessionId },
	} = match

	//query to get Session details
	const { data: sessionDetails, loading: sessionLoading, refetch: refetchSessionData } = useQuery(GET_SESSION_BYID, {
		variables: {
			id: parseInt(sessionId),
		},
		fetchPolicy: 'no-cache',
	})

	useEffect(() => {
		if (sessionDetails && !sessionLoading) {
			const {
				title,
				description,
				tags,
				users,
				users_count,
				status,
				date,
				session_type,
				venue,
				notes,
				places,
				document,
				document_link,
				document_name,
				topic,
			} = sessionDetails.byId
			let sessionType, sessionStatus
			if (session_type && session_type === 1) {
				sessionType = 'Group session'
			} else if (session_type && session_type === 2) {
				sessionType = '1:1 session'
			} else if (session_type && session_type === 3) {
				sessionType = 'Self Directed learning'
			} else {
				sessionType = '-'
			}

			if (status && status === 1) {
				sessionStatus = 'Active'
			} else if (status && status === 2) {
				sessionStatus = 'Cancelled'
			} else if (status && status === 3) {
				sessionStatus = 'Complete'
			} else if (status && status === 4) {
				sessionStatus = 'Planned'
			} else if (status && status === 5) {
				sessionStatus = 'Pending'
			} else {
				sessionStatus = '-'
			}
			setSessionData({
				title: title ? title : '-',
				description: description ? description : '-',
				tags: tags ? tags : [],
				users: users ? users : [],
				topic: topic ? topic : null,
				users_count: users ? users_count : [],
				status: sessionStatus,
				date: date ? moment(date).format('DD/MM/YYYY') : '-',
				session_type: sessionType,
				venue: venue ? venue : '-',
				notes: notes ? notes : '-',
				places: places ? places : '-',
				document: document ? document : '-',
				document_link: document_link ? document_link : '-',
				document_name: document_name ? document_name : '-',
			})
			setRefetchLoader(false)
		}
	}, [sessionDetails, sessionLoading])

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Topics',
				path: '/settings/topics',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const editTopic = () => {
		history.push(`${Routes.SESSION.path}/${id}/edit/${sessionId}`)
	}

	const openSignOffModal = () => setOpenSignModal(true)

	const openModalHandler = () => {
		setOpenModal(true)
	}

	const createTaskModalCloseHandler = () => {
		setOpenModal(false)
	}

	const openEmailModalHandler = () => {
		setOpenEmailModal(true)
	}

	const createEmailModalCloseHandler = () => {
		setOpenEmailModal(false)
	}

	const openEvidanceModalHandler = () => {
		setOpenEvidanceModal(true)
	}

	const evidanceModalCloseHandler = () => {
		setOpenEvidanceModal(false)
	}

	const createShowEviCloseHandler = () => {
		setOpenShowEviModal(false)
	}

	const isVideo = filename => {
		var ext = filename.split('.').pop()
		switch (ext.toLowerCase()) {
			case 'm4v':
			case 'oog':
			case 'mp4':
			case 'webm':
			case '3gp':
				// etc
				return true
		}
		return false
	}
	const showEviModalOpen = evidenceValue => {
		var ext = evidenceValue.split('.').pop()
		if (isVideo(evidenceValue)) {
			setOpenShowEviModal(true)
			setCurrentVideoEvidence(evidenceValue)
		} else {
			setOpenShowEviModal(false)
			setCurrentVideoEvidence(null)
			let a = document.createElement('a')
			a.href = evidenceValue
			a.target = '_blank'
			a.download = `Evidence. ${ext}`
			a.click()
		}
	}
	const handleRefetchLoader = value => {
		setRefetchLoader(value)
	}
	const systemAdmin = isSystemAdminRole(userRole, userRoleType)
	return (
		<div className="session-details-list">
			<PageMetaTags title={PAGE_NAME} />
			{sessionLoading || refetchLoader ? (
				<Loader className="session-topic-loader" active inline size="small" />
			) : (
				<div className="page-title">
					<Grid columns={4}>
						<Grid.Row>
							<Grid.Column>
								<h2>{sessionData.title}</h2>
							</Grid.Column>
							<Grid.Column />
							<Grid.Column />
							<Grid.Column>
								{systemAdmin ? <Button size="big" onClick={editTopic} fluid content="Edit" /> : null}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			)}
			<Table celled className="description-table">
				<Table.Body>
					<Table.Row>
						<Table.Cell colSpan="2">{sessionData.description}</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Date:</b>
							<span>{sessionData.date}</span>
						</Table.Cell>
						<Table.Cell>
							<b>Type:</b>
							<span>{sessionData.session_type}</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Tags:</b>
							<span>
								{sessionData.tags && sessionData.tags.length > 0
									? _.map(sessionData.tags, list => list.name).join(', ')
									: '-'}{' '}
							</span>
						</Table.Cell>
						<Table.Cell>
							<b>Status:</b>
							<span>{sessionData.status}</span>
						</Table.Cell>
					</Table.Row>

					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Attendees booked:</b>
							<span>{sessionData.users_count}</span>
						</Table.Cell>
						<Table.Cell>
							<b>Spaces:</b>
							<span>{sessionData.places}</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Location:</b>
							<span>{sessionData.venue}</span>
						</Table.Cell>
						<Table.Cell>
							<b>Document:</b>
							<span>
								<div
									role="button"
									tabIndex="0"
									onKeyDown={() => showEviModalOpen(sessionData.document)}
									onClick={() => showEviModalOpen(sessionData.document)}
									className="clr-black"
								>
									{sessionData.document_name}
								</div>
							</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell colSpan="2">
							<b>Notes:</b>
							<span>{sessionData.notes}</span>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<Grid columns={4}>
				<Grid.Row>
					<Grid.Column computer={12}>
						<Learners
							usersList={sessionData.users}
							topic={sessionData.topic}
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
							setRefetchLoader={handleRefetchLoader}
							refetchSessionData={refetchSessionData}
							match={match}
						/>
					</Grid.Column>
					<Grid.Column>
						<div className="padding-top-30">
							<Button
								size="medium"
								fluid
								content="Set Task"
								onClick={openModalHandler}
								disabled={!selectedUsers.length}
							/>
						</div>
						<div className="padding-top-30">
							<Button
								size="medium"
								fluid
								content="Add evidence"
								onClick={openEvidanceModalHandler}
								disabled={!selectedUsers.length}
							/>
						</div>
						<div className="padding-top-30">
							<Button
								size="medium"
								fluid
								content="Send Email"
								onClick={openEmailModalHandler}
								disabled={!selectedUsers.length}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<GroupSignoff
				{...sessionData}
				isOpen={openSignModal}
				onOpenChange={setOpenSignModal}
				selectedUserIds={selectedUsers}
			/>
			{openModal && (
				<AddTask
					open={openModal}
					onClose={createTaskModalCloseHandler}
					selectedUsersId={selectedUsers}
					match={match}
				/>
			)}
			{openEmailModal && (
				<SendEmail
					open={openEmailModal}
					onClose={createEmailModalCloseHandler}
					selectedUsersId={selectedUsers}
					sessionId={sessionId}
					match={match}
				/>
			)}
			{openEvidanceModal && (
				<LearnerAddEvidence
					open={openEvidanceModal}
					onClose={evidanceModalCloseHandler}
					selectedUsersIds={selectedUsers}
					match={match}
					opentSignModal={openSignOffModal}
				/>
			)}
			{openShowEviModal && (
				<EvidenceShow
					open={openShowEviModal}
					onClose={createShowEviCloseHandler}
					videoEvidence={currentVideoEvidence}
				/>
			)}
		</div>
	)
}

SessionDetails.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({
			...resolveRole(user),
		}),
		withBreadcrumbs(SessionDetails),
	),
)
