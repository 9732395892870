import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'

import { UPDATE_GROUPS_ON_DRAG } from 'Mutations/groupsMutations'
import Table from './Table'

const Wrapper = ({ groups, loadParentGroup, activeIds, openModal, isReviewer, query }) => {
	const [rebuildTree, setRebuildTree] = useState(null)

	const [updateGroups] = useMutation(UPDATE_GROUPS_ON_DRAG)

	useEffect(() => {
		if (rebuildTree) {
			updateGroups({ variables: { rebuild_tree: rebuildTree } }).then(() => {
				loadParentGroup({ variables: { with_child: activeIds, search: query } })
			})
			setRebuildTree(null)
		}
	}, [rebuildTree])

	// Drag and Drop functions
	const getObject = (array, key, value) => {
		let parentObj

		array.some(function iter(item) {
			const isChild = _.find(item.children, [key, value])

			if (isChild) {
				parentObj = item

				return true
			}

			return Array.isArray(item.children) && item.children.some(iter)
		})

		return parentObj
	}

	const nestableHandleChange = (items, dragItem) => {
		const parent = getObject(items, 'id', dragItem.id)

		dragItem.parent_id = parent ? parent.id : null

		setRebuildTree({
			id: dragItem.id,
			parent_id: parent ? parent.id : 0,
			drag: true,
		})
	}

	return (
		<Table
			groups={groups}
			nestableHandleChange={nestableHandleChange}
			openModal={openModal}
			isReviewer={isReviewer}
		/>
	)
}

Wrapper.propTypes = {
	groups: PropTypes.array,
	loadParentGroup: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	isReviewer: PropTypes.bool.isRequired,
	query: PropTypes.string,
	activeIds: PropTypes.arrayOf(PropTypes.number),
}

Wrapper.defaultProps = {
	groups: [],
	activeIds: null,
	query: null,
}

export default Wrapper
