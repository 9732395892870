import React from 'react'
import PropTypes from 'prop-types'

import { PageContent, Table, CustomPaginator } from 'Common'
import _ from 'lodash'
import { Popup } from 'semantic-ui-react'
import FormComponent from 'Helpers/form'
import UserItem from './UserItem'

const UsersTable = ({
	usersList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	selectedUsers,
	setSelectedUsers,
}) => {
	const selectAllUsersOnPage = () => {
		setSelectedUsers(
			usersList.length !== selectedUsers.length ? _.map(usersList, ({ subject: { pivot_id } }) => pivot_id) : [],
		)
	}

	const selectAllMatchingUsers = () => {
		setSelectedUsers(selectedUsers === 'all' ? [] : 'all')
	}

	const selectUser = userId => {
		setSelectedUsers(
			_.includes(selectedUsers, userId)
				? _.filter(selectedUsers, id => id !== userId)
				: [...selectedUsers, userId],
		)
	}

	if (!usersList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Popup
								content={
									<div className="select-all-popup">
										<FormComponent.Checkbox
											className="select-all customCheck"
											name="multichoice"
											label="Select all on page"
											checked={
												usersList.length === selectedUsers.length || selectedUsers === 'all'
											}
											onChange={selectAllUsersOnPage}
										/>
										<FormComponent.Checkbox
											className="select-all customCheck"
											name="multichoice"
											label="Select all matching"
											checked={selectedUsers === 'all'}
											onChange={selectAllMatchingUsers}
										/>
									</div>
								}
								on="click"
								pinned
								trigger={
									<FormComponent.Checkbox
										className="select-all customCheck"
										name="multichoice"
										checked={usersList.length === selectedUsers.length || selectedUsers === 'all'}
									/>
								}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Course"
								sortParam="subjectName"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Last active"
								sortParam="last_active"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								className="center"
								label="Assessment progress"
								sortParam="progress"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Learning progress</Table.HeaderCell>
						<Table.HeaderCell>Observation progress</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Due"
								sortParam="due"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Complete"
								sortParam="signed_off"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{usersList.map(user => (
						<UserItem
							key={user.key}
							user={user}
							isSelect={_.includes(selectedUsers, user.subject.pivot_id) || selectedUsers === 'all'}
							selectUser={() => selectUser(user.subject.pivot_id)}
						/>
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

UsersTable.propTypes = {
	usersList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
}

UsersTable.defaultProps = {
	activeSortParam: null,
}

export default UsersTable
