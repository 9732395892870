import * as Sentry from '@sentry/react'
import { DSN, TRACE_PROPAGATION_TARGETS } from 'Constants/sentry'

const init = (config = {}) => {
	return Sentry.init({
		dsn: DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		//  Capture 100% of the transactions
		tracesSampleRate: 1.0,
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: TRACE_PROPAGATION_TARGETS,
		// Session Replay
		// This sets the sample rate at 10%. You may want to change it to
		// 100% while in development and then sample at a lower rate in production.
		replaysSessionSampleRate: 0.1,
		// If you're not already sampling the entire session, change the sample
		// rate to 100% when sampling sessions where errors occur.
		replaysOnErrorSampleRate: 1.0,
		...config,
	})
}

export default {
	init,
}
