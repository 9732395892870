import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'Common'
import SessionItem from './SessionItem'

const SessionTable = ({
	sessionList,
	activeSortParam,
	handleSortTable,
	confirmModal,
	viewLearner,
	onConfirm,
	onCancel,
}) => {
	return (
		<div className="courses-list">
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Date"
								sortParam="date"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Tag</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="type"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Status"
								sortParam="status"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Spaces"
								sortParam="places"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sessionList.map((list, index) => (
						<SessionItem
							key={list.id}
							list={list}
							listIndex={index}
							confirmModal={confirmModal}
							viewLearner={viewLearner}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					))}
				</Table.Body>
			</Table>
		</div>
	)
}

SessionTable.propTypes = {
	sessionList: PropTypes.array.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	confirmModal: PropTypes.bool.isRequired,
	viewLearner: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

SessionTable.defaultProps = {
	activeSortParam: null,
}

export default SessionTable
