import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Routes } from 'Constants'
import { ProgressBar, Table } from 'Common'
import { Button } from 'semantic-ui-react'
import { determineLastActivityLabel } from 'Utils/index'
// import CircleStatus from '../../../Activity/CircleStatus'

const SessionItem = ({ match, subject }) => {
	const { params } = match
	const history = useHistory()
	// const status =
	// 	subject.activity_status !== 'success' && !subject.target_completion ? 'default' : subject.activity_status

	// const daysLeft = subject.target_completion
	// 	? Math.abs(moment(subject.target_completion).diff(moment(), 'days'))
	// 	: 'Not Set'

	// const date =
	// 	status === 'success'
	// 		? moment(subject.date_completed).format('DD/MM/YYYY')
	// 		: typeof daysLeft === 'string'
	// 		? daysLeft
	// 		: daysLeft === 0
	// 		? 'Today'
	// 		: `${daysLeft} day${daysLeft > 1 ? 's' : ''}`

	const tableData = {
		userName: subject.name ? subject.name : subject.title,
		userTitle: subject.title,
		date: subject.date ? moment(subject.date).format('DD/MM/YYYY') : '-',
		venue: subject.venue ? subject.venue : '-',
		due: subject.user_due ? subject.user_due : '-',
		attendance_status: subject.attendance_status
			? subject.attendance_status == 1
				? 'Complete'
				: 'Not Complete'
			: null,
		signed_off: subject.user_date_completed ? moment(subject.user_date_completed).format('DD/MM/YYYY') : null,
	}

	const viewCourse = () => {
		history.push(`${Routes.USERS}/${params.id}/session/${subject.id}/${subject.pivot_id}`)
	}

	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell title={tableData.userName}>
					{tableData.userName} {subject.version ? ` (VSN - ${subject.version})` : null}
				</Table.Cell>
				<Table.Cell>
					{subject.user_last_active ? determineLastActivityLabel(subject.user_last_active.created_at) : '-'}
				</Table.Cell>

				<Table.Cell>
					<ProgressBar
						percents={subject.progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				{/* <Table.Cell>
					<div className="subject-status-wrap">
						<CircleStatus status={status} />
						<span>{date}</span>
					</div>
				</Table.Cell> */}
				<Table.Cell title={tableData.due}>{tableData.due}</Table.Cell>
				<Table.Cell title={tableData.attendance_status}>
					{tableData.attendance_status ? tableData.attendance_status : '-'}
				</Table.Cell>
				<Table.Cell title={tableData.signed_off}>
					{tableData.signed_off ? tableData.signed_off : '-'}
				</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={viewCourse} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

SessionItem.propTypes = {
	match: PropTypes.object.isRequired,
	subject: PropTypes.object.isRequired,
}

export default withRouter(SessionItem)
