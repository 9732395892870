import { useEffect } from 'react'
import { Routes } from 'Constants'

export default function useBreadcrumbs({ userId, name, setBreadcrumbs, user }) {
	const { id, first_name, last_name } = user || {}

	useEffect(() => {
		let breadcrumbsCollection = [
			{
				name: 'Courses',
				path: Routes.SUBJECTS.path,
			},
			{
				name,
			},
		]

		if (userId && first_name && last_name) {
			breadcrumbsCollection = [
				{
					name: 'Learners',
					path: Routes.USERS,
				},
				{
					name: [first_name, last_name].join(' '),
					path: [Routes.USERS, id].join('/'),
				},
				{
					name,
				},
			]
		}

		setBreadcrumbs(breadcrumbsCollection)
	}, [userId, name, id, first_name, last_name])
}
