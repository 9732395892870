import React from 'react'
import { Dropdown, Label } from 'semantic-ui-react'
import _ from 'lodash'

const getKeyOrValue = (key, value) => (_.isNil(key) ? value : key)

class CustomDropdown extends Dropdown {
	constructor(props) {
		super(props)
	}

	renderLabels = () => {
		const { multiple, renderLabel } = this.props
		const { selectedLabel, value } = this.state
		if (!multiple || _.isEmpty(value)) {
			return
		}
		const selectedItems = _.map(value, this.getItemByValue)

		// if no item could be found for a given state value the selected item will be undefined
		// compact the selectedItems so we only have actual objects left
		return (
			<div className="labelsWrap">
				{_.map(_.compact(selectedItems), (item, index) => {
					const defaultProps = {
						active: item.value === selectedLabel,
						as: 'a',
						key: getKeyOrValue(item.key, item.value),
						onClick: this.handleLabelClick,
						onRemove: this.handleLabelRemove,
						value: item.value,
					}

					return Label.create(renderLabel(item, index, defaultProps), { defaultProps })
				})}
			</div>
		)
	}
}

export default CustomDropdown
