import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'
import { withToastManager } from 'react-toast-notifications'

import { USER_ADD_SUBJECT } from 'Mutations/usersMutations'
import { useForm } from 'Hooks'

import FormComponent from 'Helpers/form'
import { GET_SUBJECT_BY_ID } from 'Pages/Subjects/SingleSubject/queries'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'

const EditSubjectData = ({ match, open, initialValue, field, onClose, toastManager }) => {
	const [loading, setLoading] = useState(false)

	const {
		params: { pivotId, userId },
	} = match

	const [editTargetDate] = useMutation(USER_ADD_SUBJECT, {
		refetchQueries: [
			{
				query: GET_SUBJECT_BY_ID,
				variables: { pivot_id: parseInt(pivotId), user_id: parseInt(userId) },
			},
		],
	})

	const [getRefreshPeriodOptions, { called, data: refreshPeriodData }] = useLazyQuery(GET_REFRESH_PERIOD_OPTIONS)

	useEffect(() => {
		if (field === 'refresh_period') getRefreshPeriodOptions()
	}, [field])

	const {
		values: { [field]: fieldValue },
		errors,
		handleSubmit,
		handleSelectChange,
	} = useForm(
		() => {
			setLoading(true)

			editTargetDate({
				variables: {
					id: parseInt(userId),
					pivot_id: parseInt(pivotId),
					[field]: fieldValue,
					update: true,
				},
			})
				.then(() => {
					toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
				})
				.catch(() => {
					toastManager.add('Failed', { appearance: 'error' })
				})
				.finally(() => {
					onClose()
					setLoading(false)
				})
		},
		null,
		{ [field]: initialValue },
	)

	const fieldsSettings = {
		target_completion: {
			label: 'target completion',
			input: (
				<FormComponent.Date
					name={field}
					value={fieldValue || null}
					placeholder="Choose date"
					icon={false}
					clearIcon="close"
					dateFormat="DD-MM-YYYY"
					popupPosition="bottom left"
					clearable
					closable
					error={errors[field]}
					onChange={handleSelectChange}
				/>
			),
		},
		refresh_period: {
			label: 'refresh period',
			input: (
				<FormComponent.Dropdown
					name={field}
					value={fieldValue}
					placeholder="Choose date"
					options={called && refreshPeriodData ? refreshPeriodData.refreshPeriodOptions : []}
					selectOnBlur={false}
					selection
					fluid
					error={errors[field]}
					onChange={handleSelectChange}
				/>
			),
		},
	}

	return (
		<Modal className="form-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>Edit {fieldsSettings[field].label}</Modal.Header>
			<Modal.Content>
				<form onSubmit={handleSubmit}>
					{fieldsSettings[field].input}
					<div className="actions text-right">
						<Button loading={loading} disabled={loading} content="Save" />
						<Button
							className="reverse"
							type="button"
							disabled={loading}
							content="Cancel"
							onClick={onClose}
						/>
					</div>
				</form>
			</Modal.Content>
		</Modal>
	)
}

EditSubjectData.propTypes = {
	open: PropTypes.bool,
	initialValue: PropTypes.any.isRequired,
	field: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	match: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
}
EditSubjectData.defaultProps = {
	open: false,
	onClose: () => {},
}

export default withRouter(withToastManager(EditSubjectData))
