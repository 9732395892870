import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { Table } from 'Common'
import FormComponent from 'Helpers/form'

const AddCourseItem = ({ user, isSelect, selectUser }) => {
	const tableData = {
		name: user.name,
		tags: user.tags && user.tags.length > 0 ? _.map(user.tags, list => list.name).join(', ') : '-',
		refresher:
			user.refresh_period_formatted != undefined && user.refresh_period_formatted != null
				? user.refresh_period_formatted
				: '-',
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={tableData.name}>{tableData.name}</Table.Cell>
				<Table.Cell title={tableData.tags}>{tableData.tags}</Table.Cell>
				<Table.Cell title={tableData.refresher}>{tableData.refresher}</Table.Cell>
			</Table.Row>
		</>
	)
}

AddCourseItem.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
}

AddCourseItem.defaultProps = {
	isSelect: false,
}

export default AddCourseItem
