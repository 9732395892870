import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Confirm, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { withContext } from 'Context'
import { GET_USERS_LIST } from 'Queries/usersQueries'
import { USERS_ACTIONS } from 'Mutations/usersMutations'
import { EXPORT_USERS } from 'Mutations/exportMutations'
import { Routes } from 'Constants'
import { getFilteredUsers, getLearnerDetails } from 'Actions/usersActions'

import { AttachSubjectList, CustomDropdown, GroupSelect } from 'Common'
import AddUsers from 'Modals/users/AddUsers'
import ImportUsers from 'Modals/users/ImportUsers'

import useExport from 'Hooks/useExport'
import { filterSubjectList } from 'Utils'
import { allRoles, isReviewerRole, resolveRole } from 'Utils/roles'
import UsersTable from './UsersTable'

const dropdownOptions = [
	{
		key: 'delete',
		text: 'Delete',
		value: 1,
	},
	{
		key: 'make_active',
		text: 'Make active',
		value: 2,
	},
	{
		key: 'make_inactive',
		text: 'Make inactive',
		value: 3,
	},
	{
		key: 'change_group',
		text: 'Change group',
		value: 4,
	},
	{
		key: 'attach_subjects',
		text: 'Add Courses',
		value: 5,
	},
	{
		key: 'set_notifications',
		text: 'Set notifications',
		value: 6,
	},
	{
		key: 'pause_notifications',
		text: 'Pause notifications',
		value: 7,
	},
]
const confirmInitial = {
	open: false,
	confirmed: false,
}

const List = ({ filter, userRole, userRoleType, learnerDetails, handleSetPage, handleSortTable }) => {
	const history = useHistory()
	const [dropdownValue, setDropdownValue] = useState(null)
	const [groupId, setGroupId] = useState(null)
	const [subjects, setSubjects] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [activeActionsSelect, setActiveActionsSelect] = useState(true)
	const [openModal, setOpenModal] = useState(false)
	const [openImportModal, setOpenImportModal] = useState(false)
	const [confirmModal, setConfirmModal] = useState(confirmInitial)
	const [confirmModalLoading, setConfirmModalLoading] = useState(false)
	const handleSubjectsChange = (index, e, { name, checked, value = checked }) => {
		const newSubjects = _.cloneDeep(subjects)

		newSubjects[index][name] = value

		setSubjects(newSubjects)
	}

	const actions = {
		1: {
			params: {
				delete: true,
			},
			modalSize: 'mini',
			content: 'Are you sure?',
		},
		2: {
			params: {
				active: true,
			},
			modalSize: 'mini',
			content: 'Are you sure?',
		},
		3: {
			params: {
				active: false,
			},
			modalSize: 'mini',
			content: 'Are you sure?',
		},
		4: {
			params: {
				group_id: groupId,
			},
			modalSize: 'mini',
			content: (
				<div className="content">
					<GroupSelect
						onChange={(e, { value }) => {
							setGroupId(value)
						}}
					/>
				</div>
			),
		},
		5: {
			params: {
				subjects_id: subjects,
			},
			modalSize: 'small',
			beforeRequest: filterSubjectList,
			content: (
				<div className="content">
					<AttachSubjectList
						subjects={subjects}
						handleSubjectsChange={handleSubjectsChange}
						changeGlobalSubjectValue={setSubjects}
					/>
				</div>
			),
		},
		6: {
			params: {
				learner_notifications: true,
			},
			modalSize: 'mini',
			content: 'Are you sure?',
		},
		7: {
			params: {
				learner_notifications: false,
			},
			modalSize: 'mini',
			content: 'Are you sure?',
		},
	}

	const [usersMutations] = useMutation(USERS_ACTIONS, {
		refetchQueries: [
			{
				query: GET_USERS_LIST,
				variables: {
					filters: filter,
				},
			},
		],
	})

	const { data, error, loading, refetch: refetchUsers } = useQuery(GET_USERS_LIST, {
		variables: {
			filters: filter,
		},
		fetchPolicy: 'network-only',
	})

	const exportFilters = useMemo(() => ({ ...filter, page: null }), [filter])
	const [exportUsers, exportLoading] = useExport(EXPORT_USERS, exportFilters)

	const createUserModalCloseHandler = () => {
		refetchUsers()

		setOpenModal(false)
	}
	const createUserImportModalCloseHandler = () => {
		refetchUsers()

		setOpenImportModal(false)
	}
	const confirmModalCancelHandler = () => {
		setConfirmModal(confirmInitial)
		setDropdownValue(null)
	}
	const confirmModalConfirmHandler = () => {
		setConfirmModalLoading(true)

		Object.keys(actions[dropdownValue].params).forEach(param => {
			if (!actions[dropdownValue].beforeRequest) return param

			actions[dropdownValue].params[param] = actions[dropdownValue].beforeRequest(
				actions[dropdownValue].params[param],
			)
		})

		const isSelectedAll = selectedUsers === 'all'

		usersMutations({
			variables: {
				actions: {
					...actions[dropdownValue].params,
					users_id: isSelectedAll ? null : selectedUsers,
					all: isSelectedAll,
				},
				filters: filter,
			},
		}).finally(() => {
			setConfirmModal({ open: false, confirmed: true })
			setDropdownValue(null)
			setConfirmModalLoading(false)
		})
	}

	useEffect(() => {
		if (dropdownValue) setConfirmModal({ ...confirmInitial, open: true })
		if (dropdownValue === 5) {
			learnerDetails({ selectedUsers })
			history.push(`${Routes.LERANER_COURSE_BUNDLES}`)
		}
	}, [dropdownValue])

	useEffect(() => {
		if (!!selectedUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedUsers])

	useEffect(() => {
		if (!!selectedUsers.length && confirmModal.confirmed) setSelectedUsers([])
	}, [confirmModal.confirmed])

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const currentDate = moment()
	const usersList = _.map(data.allForManager.data.data, user => ({
		name: `${user.first_name} ${user.last_name}`,
		role: _.get(user, 'groups[0].role') || _.get(user, 'account.role'),
		groupName: _.get(user, 'groups[0].name'),
		last_active: user.lastActive ? currentDate.diff(user.lastActive.created_at, 'days') : null,
		...user,
	}))
	const isReviewer = isReviewerRole(userRole, userRoleType)
	return (
		<>
			<div className="users-list">
				<div className="page-title">
					<h1>Learners ({data.allForManager.total_count})</h1>

					<div className="actions">
						{!isReviewer && (
							<CustomDropdown
								selection
								icon="angle down"
								placeholder="Actions"
								value={dropdownValue}
								options={dropdownOptions}
								selectOnBlur={false}
								disabled={activeActionsSelect}
								onChange={(e, { value }) => setDropdownValue(value)}
							/>
						)}

						{!isReviewer && (
							<Button size="medium" content="Add learners" onClick={() => setOpenModal(true)} />
						)}
						{!isReviewer && (
							<Button size="medium" content="Import from CSV" onClick={() => setOpenImportModal(true)} />
						)}
						{!isReviewer && (
							<Button
								type="button"
								size="medium"
								content="Export to CSV"
								loading={exportLoading}
								onClick={exportUsers}
							/>
						)}
					</div>
				</div>

				<UsersTable
					usersList={usersList}
					page={filter.page}
					setPage={handleSetPage}
					perPage={data.allForManager.data.per_page}
					totalCount={data.allForManager.total_count}
					length={data.allForManager.data.total}
					handleSortTable={handleSortTable}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
					activeSortParam={{
						name: filter.sort_param || '',
						order: filter.sort_direction || 'asc',
					}}
				/>
			</div>

			{!isReviewer && openModal && <AddUsers open={openModal} onClose={createUserModalCloseHandler} />}
			{openImportModal && <ImportUsers open={openImportModal} onClose={createUserImportModalCloseHandler} />}
			{!isReviewer && (
				<Confirm
					open={confirmModal.open}
					size={actions[dropdownValue] ? actions[dropdownValue].modalSize : 'mini'}
					content={actions[dropdownValue] ? actions[dropdownValue].content : ''}
					cancelButton={<Button className="reverse" disabled={confirmModalLoading} content="Cancel" />}
					confirmButton={
						<Button loading={confirmModalLoading} disabled={confirmModalLoading} content="Confirm" />
					}
					onCancel={confirmModalCancelHandler}
					onConfirm={confirmModalConfirmHandler}
				/>
			)}
		</>
	)
}

List.propTypes = {
	filter: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
	learnerDetails: PropTypes.func,
	handleSetPage: PropTypes.func.isRequired,
	handleSortTable: PropTypes.func.isRequired,
}

List.defaultProps = {
	learnerDetails: () => {},
}
// confirmModal.open
export default withContext(
	([
		{
			users: { filter },
			user: { user },
		},
		dispatch,
	]) => ({
		// state
		filter,
		...resolveRole(user),
		// Actions
		onFilter: data => getFilteredUsers(data, dispatch),
		learnerDetails: data => getLearnerDetails(data, dispatch),
	}),
	List,
)
