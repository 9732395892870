import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'

import { GET_EVIDENCE_TYPES } from 'Queries/evidenceQueries'

import FormComponent from 'Helpers/form'

const EvidenceType = ({ evidenceType, error, handleSelectChange, manager }) => {
	const { data, loading } = useQuery(GET_EVIDENCE_TYPES, {
		fetchPolicy: 'network-only',
	})

	let options =
		data && !_.isEmpty(data.all)
			? _.map(data.all, item => ({
					key: item.id,
					text: item.name,
					value: item.id,
			  })) // eslint-disable-line
			: []

	if (!manager) options = options.filter(item => item.text !== 'Observation')

	return (
		<FormComponent.Dropdown
			name="evidence_type"
			label="Evidence Type"
			placeholder="Select type"
			icon="angle down"
			selection
			value={evidenceType}
			options={options}
			loading={loading}
			disabled={loading}
			error={error}
			onChange={handleSelectChange}
		/>
	)
}

EvidenceType.propTypes = {
	evidenceType: PropTypes.number,
	error: PropTypes.bool,
	manager: PropTypes.bool.isRequired,
	handleSelectChange: PropTypes.func,
}
EvidenceType.defaultProps = {
	evidenceType: null,
	error: false,
	handleSelectChange: () => {},
}

export { EvidenceType }
