import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { PageContent, PageMetaTags } from 'Common'

import UsersList from './UsersList'
import Filter from './UsersList/Filter'

import './style.scss'

const PAGE_NAME = 'Activity'

const ActivityList = ({ setBreadcrumbs }) => {
	const [subjectsFilter, setSubjectFilter] = useState({ page: 1 })
	const [tabIndex, setTabIndex] = useState(0)

	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const updateTabIndex = useCallback(
		index => {
			setTabIndex(index)
		},
		[setTabIndex],
	)

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<PageContent className="activity-page">
				<PageContent.Content>
					<UsersList filter={subjectsFilter} onFiltered={setSubjectFilter} updateTabIndex={updateTabIndex} />
				</PageContent.Content>

				<PageContent.RightBar>
					<Filter onFiltered={setSubjectFilter} currentTabIndex={tabIndex} />
				</PageContent.RightBar>
			</PageContent>
		</>
	)
}

ActivityList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(ActivityList)
