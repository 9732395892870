import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withContext } from 'Context/index'
import { Routes } from 'Constants/Routes'
import { getFilteredUsers } from 'Actions/usersActions'

import { determineLastActivityLabel } from 'Utils/index'
import ActivityItemWrap from './ActivityItemWrap'

const ActivityItem = ({
	log_name,
	description,
	created_at,
	subject_id: actionUserId,
	causer,
	getUsers,
	user_subjects_id,
}) => {
	const timeLabel = determineLastActivityLabel(created_at)

	if (!causer) {
		causer = {}
	}

	let content = null

	switch (log_name) {
		case 'user-auth':
			content = (
				<>
					<span>{description}</span>
					<span className="custom-label">{timeLabel}</span>
				</>
			)
			break
		case 'user-profile':
			content = (
				<>
					<span>{`${description} ${causer.first_name} ${causer.last_name}`}</span>
					<span className="custom-label">{timeLabel}</span>
				</>
			)
			break
		case 'user-began':
			content = (
				<>
					<span>{description}</span>
					&nbsp;
					<Link
						to={`${Routes.MANAGER}/${actionUserId}${Routes.SUBJECTS.path}/${user_subjects_id}/${
							causer.subject_id ? causer.id : ''
						}`}
						onClick={() => getUsers({ status_evidence: '' })}
					>
						{causer.name}
					</Link>
					<span className="custom-label">{timeLabel}</span>
				</>
			)
			break
		default:
			content = (
				<>
					<span>{`${description} ${causer.name}`}</span>
					<span className="custom-label">{timeLabel}</span>
				</>
			)
			break
	}

	return <ActivityItemWrap logName={log_name}>{content}</ActivityItemWrap>
}

ActivityItem.propTypes = {
	log_name: PropTypes.string.isRequired,
	subject_id: PropTypes.number.isRequired,
	description: PropTypes.string.isRequired,
	created_at: PropTypes.string.isRequired,
	causer: PropTypes.object.isRequired,
	getUsers: PropTypes.func.isRequired,
	user_subjects_id: PropTypes.number,
}

ActivityItem.defaultProps = {
	user_subjects_id: null,
}

export default withContext(
	([, dispatch]) => ({
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	ActivityItem,
)
