import React, { useEffect } from 'react'
import { withToastManager } from 'react-toast-notifications'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withContext } from 'Context'
import { signOutSuccessAction } from 'Actions/authActions'
import ExceptionFallback from 'Common/ExceptionFallback'
import AppVersionChange from './AppVersionChange'

import './style.scss'

const errorTypesToIgnore = ['confirmation modal needed']

const _AppNotifications = ({ toastManager, signOut }) => {
	const handleParseMessage = errorMessage => {
		try {
			return JSON.parse(errorMessage).message
		} catch {
			return errorMessage
		}
	}

	useEffect(() => {
		const onAppVersionChanged = () => {
			const Component = <AppVersionChange />

			toastManager.add(Component, { appearance: 'warning', autoDismiss: false })
		}

		window.addEventListener('AppVersionChanged', onAppVersionChanged)
		window.addEventListener('onError', onError)

		return () => {
			window.addEventListener('AppVersionChanged', onAppVersionChanged)
			window.removeEventListener('onError', onError)
		}
	}, [])

	const onError = ({ error }) => {
		if (typeof error === 'object' && !_.isEmpty(error)) {
			let errors = null

			switch (error.statusCode) {
				case 401:
				case 403:
					signOut()
					break
				default:
					if (errorTypesToIgnore.includes(error.message)) break

					if (!_.isEmpty(error.validation) && error.message === 'validation') {
						errors = (
							<ul>
								{_.map(error.validation, (item, key) => {
									return Array.isArray(item) ? (
										<li key={key}>{item[0]}</li>
									) : (
										<li key={key}>{item}</li>
									)
								})}
							</ul>
						)
					} else if (error.validation) {
						errors = handleParseMessage(error.message)
					}

					toastManager.add(errors || error.debugMessage || <ExceptionFallback />, {
						appearance: 'error',
					})
			}
		}
	}

	return null
}

_AppNotifications.propTypes = {
	toastManager: PropTypes.object.isRequired,
	signOut: PropTypes.func.isRequired,
}

const AppNotifications = withToastManager(
	withContext(
		([, dispatch]) => ({
			// Actions
			signOut: () => signOutSuccessAction(dispatch),
		}),
		_AppNotifications,
	),
)

export { AppNotifications }
