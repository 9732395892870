import { getObsLearningStats } from 'Utils/obs'

export default function useObsModuleContent({ obsLearningStats: learningStats, lessons }) {
	const withLessons = fn => () => fn(!Array.isArray(lessons) ? [] : lessons)

	const getTotalLessonsCount = withLessons(lessons => {
		return lessons.reduce((counter, { content }) => {
			const outcomeLessonsCount = content.length + 1

			return counter + outcomeLessonsCount
		}, 0)
	})

	const getTotalViewedLessonsCount = withLessons(lessons => {
		return lessons.reduce((counter, { id: obsModuleLessonId }) => {
			const { size } = getObsLearningStats({ learningStats, obsModuleLessonId })

			return Math.min(counter, getTotalLessonsCount()) + size
		}, 0)
	})

	const getLearningProgress = withLessons(() => {
		const totalLessonsCount = getTotalLessonsCount()
		const totalViewedLessonsCount = getTotalViewedLessonsCount()

		return Math.round((totalViewedLessonsCount / totalLessonsCount) * 100)
	})

	const isLearningCompleted = withLessons(() => {
		const totalLessonsCount = getTotalLessonsCount()
		const totalViewedLessonsCount = getTotalViewedLessonsCount()

		return totalLessonsCount === totalViewedLessonsCount
	})

	return {
		getTotalLessonsCount,
		getTotalViewedLessonsCount,
		getLearningProgress,
		isLearningCompleted,
	}
}
