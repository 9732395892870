import * as Types from 'Types'

export const signInSuccessAction = (data, dispatch) => dispatch({ type: Types.SIGN_IN, payload: data })
export const signUpSuccessAction = (data, dispatch) => dispatch({ type: Types.SIGN_UP, payload: data })
export const signOutSuccessAction = dispatch => dispatch({ type: Types.SIGN_OUT })
export const restoreUserAction = (data, dispatch) =>
	dispatch({ type: Types.RESTORE_USER, payload: data ? JSON.parse(data) : data })
export const impersonateSuccessAction = (data, dispatch) => dispatch({ type: Types.IMPERSONATE, payload: data })
export const quitImpersonateAction = dispatch => dispatch({ type: Types.IMPERSONATE_QUIT })
export const setAccountAction = (data, dispatch) => dispatch({ type: Types.SET_ACCOUNT, payload: data })
