import React from 'react'
import PropTypes from 'prop-types'

import SubjectsRoutes from 'Routes/SubjectsRoutes'
import SubjectsManagerRoutes from 'Routes/SubjectsManagerRoutes'

import './style.scss'

const Subjects = ({
	match: {
		params: { userId },
	},
}) => (
	<div className="subject-page">
		{!userId && <SubjectsRoutes />}
		{userId && <SubjectsManagerRoutes />}
	</div>
)

Subjects.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Subjects
