import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'

import { GET_SUBJECTS_FOR_FILTER } from 'Queries/usersQueries'
import FormComponent from 'Helpers/form'

const SubjectSelect = props => {
	const { loading, error, data } = useQuery(GET_SUBJECTS_FOR_FILTER)

	if (loading) {
		return (
			<div className="field-wrap">
				<Loader active inline />
			</div>
		)
	}
	if (error) return null

	const options = _.map(data.currentAccount, subject => ({
		key: subject.name,
		value: subject.id,
		text: subject.name,
	}))

	return (
		<FormComponent
			label="Course"
			name="subject"
			type="dropdown"
			options={options}
			placeholder="Course"
			icon="angle down"
			selection
			search
			clearable
			{...props}
		/>
	)
}

export { SubjectSelect }
