import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, PageContent, CustomPaginator } from 'Common'
import TopicSessionItem from './TopicSessionItem'

const TopicSessionTable = ({
	topicSessionData,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	match,
}) => {
	if (!topicSessionData.length) return <PageContent.Message message="List is empty" />
	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Id"
								sortParam="id"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="title"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Start Date"
								sortParam="date"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="type"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>Notes</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Status"
								sortParam="status"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Attendees Booked"
								sortParam="attendees"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Spaces"
								sortParam="places"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(topicSessionData, dataList => (
						<TopicSessionItem key={dataList.id} dataList={dataList} match={match} />
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

TopicSessionTable.propTypes = {
	topicSessionData: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
}

TopicSessionTable.defaultProps = {
	activeSortParam: null,
}

export default TopicSessionTable
