import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// import _ from 'lodash'
import { Loader } from 'semantic-ui-react'
import { withContext } from 'Context'
import { signInSuccessAction } from 'Actions/authActions'
import { SIGNIN_USING_TOKEN } from 'Mutations/settingMutations'
import { Routes } from '../../../../constants'

const LoginWithToken = ({ signInSuccess, match }) => {
	const history = useHistory()
	const [signInTokenMutation] = useMutation(SIGNIN_USING_TOKEN)
	const [loading, setLoading] = useState(true)
	const {
		params: { emailId, authToken, pivotId },
	} = match

	useEffect(() => {
		signInTokenMutation({ variables: { token: authToken, email: emailId } })
			.then(({ data: { tokenSignIn } }) => {
				localStorage.setItem('isSigninToken', true)
				if (pivotId) {
					signInSuccess(tokenSignIn)
					history.push({
						pathname: Routes.SUBJECTS.path + '/' + pivotId,
					})
				} else {
					signInSuccess(tokenSignIn)
				}
				setLoading(false)
			})
			.catch(() => {
				localStorage.setItem('isEmailExist', emailId)
				history.push(`/login`)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [authToken])

	return <React.Fragment>{loading ? <Loader active inline="centered" /> : null}</React.Fragment>
}

LoginWithToken.propTypes = {
	signInSuccess: PropTypes.func.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	match: PropTypes.shape().isRequired,
}

export default withContext(
	([, dispatch]) => ({
		// actions
		signInSuccess: data => signInSuccessAction(data, dispatch),
	}),
	LoginWithToken,
)
