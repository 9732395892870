import React, { useState } from 'react'
import { Input, Loader } from 'semantic-ui-react'

import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { GET_USER_LEARNING_ARCHIVE_BY_ID } from './queries'
import Table from './Table'

const UserLearningArchive = ({ match, switchCoursesView }) => {
	const [search, setSearch] = useState('')

	const {
		params: { id },
	} = match

	const { loading, error, data } = useQuery(GET_USER_LEARNING_ARCHIVE_BY_ID, {
		variables: { id: parseInt(id) },
		fetchPolicy: 'cache-and-network',
	})

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const { learningArchive } = data.byId

	return (
		<div className="user-learning-archive">
			<div className="header">
				<div className="left-part">
					<h3>Learner courses</h3>
					{/* eslint-disable-next-line max-len */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<span className="link" onClick={switchCoursesView}>
						View courses
					</span>
				</div>
				<div className="right-part">
					<Input
						icon="search"
						value={search}
						onChange={(e, { value }) => {
							setSearch(value)
						}}
						placeholder="Search for Course name"
					/>
				</div>
			</div>

			<Table list={learningArchive} search={search} />
		</div>
	)
}

UserLearningArchive.propTypes = {
	switchCoursesView: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
}

export default withRouter(UserLearningArchive)
