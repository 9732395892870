import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal, Loader } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import FormComponent from 'Helpers/form'
import moment from 'moment'
import { COMPLETE_SESSION } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import './style.scss'

const attendenceTypes = [
	{
		key: 1,
		value: 1,
		text: 'Complete',
	},
	{
		key: 2,
		value: 2,
		text: 'Not Completed',
	},
]

const SignOff = ({ pivotId, groupPivotIds, open, onClose, toastManager, dRefetch, sessionDate }) => {
	const [submitting, setSubmitting] = useState(false)
	const [signOffMutation] = useMutation(COMPLETE_SESSION)

	const validate = values => {
		const errors = {}
		const requiredFields = attendance_status == 2 ? ['attendance_status'] : ['attendance_status', 'date']
		requiredFields.forEach(field => {
			if (!values[field]) errors[field] = true
		})
		return errors
	}

	// SignOff mutation
	const signOffMutationSubmit = variables => {
		return signOffMutation({
			variables,
		})
			.then(({ data }) => {
				if (data.completeSession) {
					setSubmitting(false)
					onClose()
					dRefetch()
					toastManager.add('Successful.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
				onClose()
			})
			.finally(onClose)
	}

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	const {
		values: { attendance_status, date },
		errors,
		handleSubmit,
		handleSelectChange,
	} = useForm(() => {
		setSubmitting(true)

		const mutationRequests = [...groupPivotIds, pivotId].filter(Boolean).map(pivotId => {
			const variables = {
				attendance_status: parseInt(attendance_status),
				pivot_id: parseInt(pivotId),
				// Author of this crap Sourabh Chourasiya, not me
				date_completed: date
					? attendance_status == 2
						? null
						: moment(moment(date, 'DD/MM/YYYY')).format('YYYY-MM-DD hh:mm:ss')
					: null,
			}

			return signOffMutationSubmit(variables)
		})

		return Promise.all(mutationRequests).finally(() => setSubmitting(false))
	}, validate)

	if (submitting) return <Loader active />
	return (
		<div className="task-form">
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Confirm SignOff</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<FormComponent.Dropdown
											label={<b>Status</b>}
											name="attendance_status"
											value={attendance_status}
											onChange={handleSelectChange}
											placeholder="Session type"
											options={attendenceTypes}
											selectOnBlur={false}
											selection
											fluid
											error={errors.attendance_status}
										/>
									</Grid.Row>
									<Grid.Row className="users-list">
										<Grid className="group-fields">
											<Grid.Column computer={8}>
												<span>
													<b>Session Date</b>
												</span>
												<div className="session-date">
													{sessionDate
														? moment(moment(sessionDate, 'YYYY-MM-DD')).format('DD/MM/YYYY')
														: null}
												</div>
											</Grid.Column>
											<Grid.Column computer={8}>
												<FormComponent.Date
													label={
														<b style={{ color: attendance_status == 2 ? '#928f8f' : '' }}>
															Session Completed Date
														</b>
													}
													name="date"
													value={attendance_status == 2 ? null : date || null}
													placeholder="Date"
													// minDate={moment()}
													icon={false}
													clearIcon="close"
													dateFormat="DD/MM/YYYY"
													popupPosition="bottom left"
													clearable
													closable
													error={errors.date}
													onChange={handleSelectChange}
													disabled={attendance_status == 2 ? true : false}
												/>
											</Grid.Column>
										</Grid>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column computer={8}>
											<Button
												className="reverse"
												size="medium"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										<Grid.Column computer={8}>
											<Button
												type="button"
												size="medium"
												content="Sign off"
												loading={submitting}
												disabled={submitting}
												onClick={() => {
													handleSubmit()
												}}
												fluid
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</div>
	)
}

SignOff.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	pivotId: PropTypes.number,
	groupPivotIds: PropTypes.arrayOf([PropTypes.number]),
	dRefetch: PropTypes.func,
	sessionDate: PropTypes.any,
}

SignOff.defaultProps = {
	open: false,
	dRefetch: () => {},
	sessionDate: null,
	groupPivotIds: [],
	pivotId: null,
}

export default withToastManager(SignOff)
