import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import LearnerAddEvidence from 'Modals/session/LearnerAddEvidence'
import EvidenceShow from 'Modals/session/EvidenceShow'
import { withRouter } from 'react-router-dom'
import { withContext } from 'Context'
import { Routes } from 'Constants'
import { Session } from 'Common/Session'
import EvidencesList from 'Common/Session/EvidencesList'
import { Loader } from 'semantic-ui-react'
import { isManagerRole, allRoles, resolveRole } from 'Utils/roles'
import SignOff from 'Modals/session/SignOff'
import moment from 'moment'
import { GET_ALL_EVIDENCE, DELEGATES_TASK_LIST, SESSION_DELEGATES, GET_SESSION_BYID } from 'Queries/settingQueries'
import TaskList from 'Common/Session/TaskList'
import { useQuery } from '@apollo/react-hooks'

const SessionDetails = ({ match, setBreadcrumbs, user, userRole, userRoleType }) => {
	const [openEviModal, setOpenEviModal] = useState(false)
	const [openSignModal, setOpenSignModal] = useState(false)
	const [currentVideoEvidence, setCurrentVideoEvidence] = useState(null)
	const [openShowEviModal, setOpenShowEviModal] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [taskEviId, setTaskEviId] = useState(null)
	const [userName, setUserName] = useState(null)
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Courses',
				path: Routes.SUBJECTS.path,
			},
			{
				name: 'Session Details',
			},
		])
		if (!manager) {
			setUserName(user.first_name + ' ' + user.last_name)
		}
	}, [])

	const {
		params: { pivotId, sessionId, id },
	} = match
	const manager = isManagerRole(userRole, userRoleType)
	const { loading, error, data, refetch: evidenceRefetch } = useQuery(GET_ALL_EVIDENCE, {
		variables: {
			pivot_id: parseInt(pivotId),
			session_id: parseInt(sessionId),
			user_id: manager ? parseInt(id) : parseInt(user.id),
		},
		fetchPolicy: 'network-only',
	})
	const { loading: taskLoading, error: taskError, data: taskData, refetch: taskRefetch } = useQuery(
		DELEGATES_TASK_LIST,
		{
			variables: {
				session_id: parseInt(sessionId),
				user_id: manager ? parseInt(id) : parseInt(user.id),
			},
			fetchPolicy: 'network-only',
		},
	)
	const createUserModalCloseHandler = () => {
		setOpenEviModal(false)
		setTaskEviId(null)
		setTaskId(null)
	}

	const addEviTask = id => {
		setTaskId(id)
		setTaskEviId(null)
		setOpenEviModal(true)
	}

	const updateTaskEvidence = (eviId, taskId) => {
		setTaskId(taskId)
		setTaskEviId(eviId)
		setOpenEviModal(true)
	}

	const createSignModalCloseHandler = () => {
		setOpenSignModal(false)
	}

	const opentSignModal = () => {
		setOpenSignModal(true)
	}

	const createShowEviCloseHandler = () => {
		setOpenShowEviModal(false)
	}

	const { loading: dLoading, error: dError, data: dData, refetch: dRefetch } = useQuery(SESSION_DELEGATES, {
		variables: {
			session_id: parseInt(sessionId),
			user_id: parseInt(manager ? id : user.id),
		},
		fetchPolicy: 'network-only',
	})

	//query to get Session details
	const { data: sessionDetails, loading: sessionLoading } = useQuery(GET_SESSION_BYID, {
		variables: {
			id: parseInt(sessionId),
			user_id: parseInt(manager ? id : user.id),
		},
		fetchPolicy: 'network-only',
	})

	if (loading || taskLoading || dLoading || sessionLoading) return <Loader active />
	if (error || taskError || dError) return <span>Something went wrong :(</span>
	const { session } = data
	const { delegateSessionById } = dData

	const isVideo = filename => {
		var ext = filename.split('.').pop()
		switch (ext.toLowerCase()) {
			case 'm4v':
			case 'oog':
			case 'mp4':
			case 'webm':
			case '3gp':
				// etc
				return true
		}
		return false
	}
	const showEviModalOpen = evidenceValue => {
		var ext = evidenceValue.split('.').pop()
		if (isVideo(evidenceValue)) {
			setOpenShowEviModal(true)
			setCurrentVideoEvidence(evidenceValue)
		} else {
			setOpenShowEviModal(false)
			setCurrentVideoEvidence(null)
			let a = document.createElement('a')
			a.href = evidenceValue
			a.target = '_blank'
			a.download = `Evidence. ${ext}`
			a.click()
		}
	}
	console.log('value in sesssion details (learner)', sessionDetails)
	return (
		<>
			<Session
				match={match}
				manager={manager}
				learnerSignOffStatus={delegateSessionById.is_completed}
				learnerTargetCompletionDate={delegateSessionById.target_completion}
				showEviModalOpen={showEviModalOpen}
				userName={userName}
				sessionDetails={sessionDetails}
				sessionLoading={sessionLoading}
			/>
			{/* <Grid columns={4}>
				<Grid.Row>
					<Grid.Column />
					<Grid.Column />
					<Grid.Column />
					<Grid.Column>
						{delegateSessionById && !delegateSessionById.is_completed ? (
							<Button
								size="small"
								onClick={() => setOpenEviModal(true)}
								fluid
								content="Add Session Evidence"
							/>
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid> */}
			<TaskList
				loading={taskLoading}
				error={taskError}
				data={taskData}
				manager={manager}
				sessionId={sessionId}
				addEviTask={addEviTask}
				taskRefetch={taskRefetch}
				updateTaskEvidence={updateTaskEvidence}
				delegateSessionById={delegateSessionById}
				showEviModalOpen={showEviModalOpen}
				match={match}
				selectedUsersId={Number(manager ? id : user.id)}
			/>
			<EvidencesList
				loading={loading}
				error={error}
				data={data}
				manager={manager}
				sessionId={sessionId}
				pivotId={pivotId}
				evidenceRefetch={evidenceRefetch}
				delegateSessionById={delegateSessionById}
				showEviModalOpen={showEviModalOpen}
				setOpenEviModal={setOpenEviModal}
			/>
			{session.length > 0 ? (
				<>
					{manager && delegateSessionById && !delegateSessionById.is_completed ? (
						// <div className="signoff-box">
						// 	<h2>SignOff</h2>
						// 	<p>
						// 		Please take a moment to review the evidence above and think about the competence. If you
						// 		are satisfied they meet the requirements please click the Sign Off button below
						// 		<p>
						// 			{' '}
						// 			<Button size="large" content="Sign Off" onClick={opentSignModal} />
						// 		</p>{' '}
						// 	</p>
						// </div>
						<></>
					) : delegateSessionById && delegateSessionById.is_completed ? (
						<div className="signoff-box">
							<h2>SignOff</h2>
							<p>
								Signed off by{' '}
								{delegateSessionById.verified_by_name ? (
									<b>{delegateSessionById.verified_by_name}</b>
								) : (
									'-'
								)}
								&nbsp;on{' '}
								{delegateSessionById.user_date_completed
									? moment(delegateSessionById.user_date_completed).format('DD/MM/YYYY')
									: '-'}
								, &nbsp;status{' '}
								{delegateSessionById.attendance_status && delegateSessionById.attendance_status == 1 ? (
									<b>Complete</b>
								) : (
									<b>Not Complete</b>
								)}
								.
							</p>
						</div>
					) : null}
				</>
			) : null}
			{openSignModal && (
				<SignOff
					open={openSignModal}
					onClose={createSignModalCloseHandler}
					pivotId={parseInt(pivotId)}
					dRefetch={dRefetch}
				/>
			)}
			{openEviModal && (
				<LearnerAddEvidence
					open={openEviModal}
					onClose={createUserModalCloseHandler}
					evidenceRefetch={evidenceRefetch}
					taskRefetch={taskRefetch}
					evidenceId={taskEviId}
					taskId={taskId}
					opentSignModal={opentSignModal}
				/>
			)}
			{openShowEviModal && (
				<EvidenceShow
					open={openShowEviModal}
					onClose={createShowEviCloseHandler}
					videoEvidence={currentVideoEvidence}
				/>
			)}
		</>
	)
}

SessionDetails.propTypes = {
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({
			user,
			...resolveRole(user),
		}),
		withBreadcrumbs(SessionDetails),
	),
)
