import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import _ from 'lodash'
import { ADD_SESSION_TO_TAG } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import { withContext } from 'Context'
import { getFilteredSettings } from 'Actions/usersActions'
import SessionTable from './SessionTable'

const SessionList = ({ customFilter, onFilter, toastManager, sessionData, sessionLoading, match }) => {
	const history = useHistory()
	const [selectedSessions, setSelectedSessions] = useState([])
	const [sessionList, setSessionList] = useState([])
	const [sessionToLearner] = useMutation(ADD_SESSION_TO_TAG)
	const [stateloading] = useState(false)
	const [loader, setLoader] = useState(false)

	const {
		params: { id },
	} = match

	const handleSessionsChange = (index, e, { name, checked, value = checked }) => {
		const newSessions = _.cloneDeep(sessionList)
		newSessions[index][name] = value
		setSessionList(newSessions)
	}

	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	useEffect(() => {
		if (sessionData) {
			const tempArr = sessionData.accountSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			setSessionList(tempArr)
		}
	}, [sessionData])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	const submitCoursesMutation = () => {
		let selectedFinalSession = sessionList.filter(o1 => selectedSessions.some(o2 => o1.id === o2.id))
		let tempSelectedSession = selectedFinalSession.map(item => item.id)
		if (tempSelectedSession.length > 0) {
			setLoader(true)
			sessionToLearner({
				variables: {
					params: {
						sessions_id: tempSelectedSession,
						tag_id: parseInt(id),
					},
				},
			})
				.then(({ data }) => {
					setLoader(false)
					if (data.addSessions) {
						toastManager.add('Saved Successfully', {
							appearance: 'success',
							autoDismiss: false,
						})
						setSelectedSessions([])
						history.push(`/settings/tags/${id}`)
					}
				})
				.catch(error => {
					setLoader(false)
					console.log('value in error', error)
					setSelectedSessions([])
				})
				.finally(() => {
					setLoader(false)
				})
		}
	}

	if (sessionLoading || loader || !sessionList.length) return <Loader active />
	if (!sessionList.length)
		return (
			<div className="msg-line">
				<PageContent.Message
					message="There are no sessions available.
				Please refer to the Help Centre for more information on sessions."
				/>
			</div>
		)

	return (
		<>
			<SessionTable
				sessionList={sessionList}
				setPage={handleSetPage}
				handleSortTable={handleSortTable}
				selectedSessions={selectedSessions}
				setSelectedSessions={setSelectedSessions}
				activeSortParam={{
					name: customFilter.sort_param || '',
					order: customFilter.sort_direction || 'asc',
				}}
				handleSessionsChange={handleSessionsChange}
			/>
			<div className="course-btn">
				<Button
					primary
					color="blue"
					floated="right"
					content="Add Session"
					loading={stateloading}
					disabled={selectedSessions.length > 0 ? false : true}
					onClick={submitCoursesMutation}
				/>
			</div>
		</>
	)
}

SessionList.propTypes = {
	customFilter: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	toastManager: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	sessionLoading: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
}

SessionList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	withToastManager(SessionList),
)
