import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormComponent from 'Helpers/form'
import { Table } from 'Common'
import { useQuery } from '@apollo/react-hooks'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'
import SessionItem from './SessionItem'

const SessionTable = ({
	sessionList,
	activeSortParam,
	handleSortTable,
	selectedSessions,
	setSelectedSessions,
	handleSessionsChange,
}) => {
	const { error: refreshPeriodError, loading: refreshPeriodLoading, data: refreshPeriodData } = useQuery(
		GET_REFRESH_PERIOD_OPTIONS,
	)

	const selectAllSessionsOnPage = () => {
		setSelectedSessions(sessionList.length !== selectedSessions.length ? _.map(sessionList, list => list) : [])
	}

	const selectSession = list => {
		setSelectedSessions(
			_.some(selectedSessions, list)
				? selectedSessions.filter(element => element.id !== list.id)
				: [...selectedSessions, list],
		)
	}

	if (refreshPeriodLoading) return null
	if (refreshPeriodError) return 'Error'
	return (
		<div className="courses-list">
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<FormComponent.Checkbox
								className="select-all customCheck"
								name="multichoice"
								checked={sessionList.length === selectedSessions.length || selectedSessions === 'all'}
								onChange={selectAllSessionsOnPage}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Date"
								sortParam="date"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Tag</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="type"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Status"
								sortParam="status"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Spaces"
								sortParam="places"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sessionList.map((list, index) => (
						<SessionItem
							key={list.id}
							list={list}
							isSelect={selectedSessions.some(e => e.id == list.id) || selectedSessions === 'all'}
							selectSession={() => selectSession(list)}
							handleSessionsChange={handleSessionsChange}
							refreshPeriodData={refreshPeriodData}
							listIndex={index}
						/>
					))}
				</Table.Body>
			</Table>
		</div>
	)
}

SessionTable.propTypes = {
	sessionList: PropTypes.array.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedSessions: PropTypes.array.isRequired,
	setSelectedSessions: PropTypes.func.isRequired,
	handleSessionsChange: PropTypes.func.isRequired,
}

SessionTable.defaultProps = {
	activeSortParam: null,
}

export default SessionTable
