import React, { useEffect, useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Loader, Checkbox, Label, Grid, Icon, Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { GET_GROUPS_COMPLIANCE_REPORT } from 'Queries/groupsQueries'
import { EXPORT_GROUPS_REPORTS } from 'Mutations/exportMutations'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'

import { PageMetaTags } from 'Common'
import FormComponent from 'Helpers/form'
import _ from 'lodash'
import { isReviewerRole } from 'Utils/roles'
import { withContext } from 'Context/index'
import buildTree from 'Helpers/buildTree'
import useExport from 'Hooks/useExport'
import { compare } from 'Utils/index'
import Wrapper from './Wrapper'
import './style.scss'

const PAGE_NAME = 'Compliance report by Groups'

const Groups = ({ setBreadcrumbs, userRole, userRoleType }) => {
	const [query, setQuery] = useState(null)
	const [activeIds, setActiveIds] = useState([])
	const [allGroups, setAllGroups] = useState(data)
	const [formattedGroups, setFormattedGroups] = useState(data)

	const [loadParentGroup, { data, error, loading }] = useLazyQuery(GET_GROUPS_COMPLIANCE_REPORT, {
		fetchPolicy: 'network-only',
	})
	const [loadGroups, { data: loadedGroups, loading: lazyGroupLoading }] = useLazyQuery(GET_GROUPS_COMPLIANCE_REPORT)
	const [exg, exportLoading] = useExport(EXPORT_GROUPS_REPORTS)
	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Compliance report',
			},
		])
	}, [])

	useEffect(() => {
		loadParentGroup({ variables: { flag: 'course' } })
	}, [])

	const setGroups = groups => {
		setAllGroups(groups)
		// Build groups tree
		setFormattedGroups(
			buildTree(
				_.map(groups.sort(compare('name')), item => ({
					...item,
					loadGroups,
					query,
					toggleActiveIds,
				})),
			),
		)
	}

	useEffect(() => {
		if (data) setGroups(data.reports)
	}, [data])

	useEffect(() => {
		if (loadedGroups) setGroups([...allGroups, ...loadedGroups.reports])
	}, [lazyGroupLoading, loadedGroups])

	const toggleActiveIds = (id, childIds) => {
		if (activeIds.includes(id)) {
			const copyActiveIds = [...activeIds].filter(activeId => !(activeId === id || childIds.includes(activeId)))
			setActiveIds(copyActiveIds)
		} else {
			setActiveIds([...activeIds, id])
		}
	}

	const debounceSearch = useCallback(
		_.debounce(query => loadParentGroup({ variables: { search: query, flag: 'course' } }), 300),
		[],
	)

	const debounceLernerSearch = useCallback(
		_.debounce(query => loadParentGroup({ variables: { search: query } }), 300),
		[],
	)

	const [checked, setChecked] = useState(false)
	const handleChange = () => {
		setChecked(!checked)
		if (checked === false) {
			loadParentGroup()
		} else {
			loadParentGroup({ variables: { flag: 'course' } })
		}
	}

	const oneText = 'Select from Learners or Courses to check your compliance.'
	const twoText = 'This calculation can give very different results so we suggest checking both.'

	const handleSearchChange = useCallback((e, { value }) => {
		setQuery(value.toLowerCase())
		if (value.length > 2) {
			if (checked === false) {
				debounceSearch(value)
			} else {
				debounceLernerSearch(value)
			}
		} else if (value.length < 1) {
			loadParentGroup()
		}
	}, [])

	if (error) return <span>Something went wrong :(</span>

	const isReviewer = isReviewerRole(userRole, userRoleType)

	return (
		<>
			<PageMetaTags title={PAGE_NAME} />
			<div className="group-compliance">
				<div className="page-title">
					<div>
						<Checkbox toggle checked={checked} onChange={handleChange} />
						<Label>{checked ? 'Learners' : 'Courses'}</Label>
						<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
							<Grid centered divided columns={1}>
								<Grid.Column>
									<p>{oneText}</p>
									<p>{twoText}</p>
									<p>Example:</p>
									<p>Team Eddie has 10 learners with 40 courses allocated.</p>
									<p>
										<ul>
											<li>Each learner has at least one course that is overdue</li>
											<li>The learners have completed a total of 30 courses between them.</li>
										</ul>
									</p>
									<p>Compliant learners = 0% (0/10) </p>
									<p>Compliant courses = 75% (30/40)</p>
								</Grid.Column>
							</Grid>
						</Popup>
					</div>
				</div>
				<div className="minh"></div>
				<div className="page-title">
					<h1>
						Compliance report by Groups
						<Popup trigger={<Icon name="info circle" size="small" />} flowing hoverable>
							<Grid centered divided columns={1}>
								<Grid.Column>
									<p>
										Please note this report only includes active learner profiles which are<br></br>
										located within a group.Active learners are counted within this report<br></br>
										even if they do not have any courses or sessions allocated to them.<br></br>
										If your learners have been set to the top level of the organisation<br></br>
										then they will not be included in this report. The Help Centre has a<br></br>
										guide on how to change a learners group.
									</p>
								</Grid.Column>
							</Grid>
						</Popup>
					</h1>
					<div className="actions">
						<FormComponent
							icon="search"
							clearable
							value={query || ''}
							onChange={handleSearchChange}
							placeholder="Search"
						/>
						<Button
							type="button"
							size="large"
							loading={exportLoading}
							className="mo"
							content="Export to CSV"
							onClick={exg}
						/>
					</div>
				</div>
				{loading ? (
					<Loader active />
				) : (
					<Wrapper
						groups={formattedGroups}
						loadGroups={loadGroups}
						loadParentGroup={loadParentGroup}
						toggleActiveIds={toggleActiveIds}
						activeIds={activeIds}
						isReviewer={isReviewer}
						query={query}
						checked={checked}
					/>
				)}
			</div>
		</>
	)
}

Groups.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.string.isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// State
		userRole: role,
		userRoleType: roleType,
	}),
	withBreadcrumbs(Groups),
)
