import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import Cookie from 'js-cookie'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

import { withContext } from 'Context/index'
import { profileUpdateAction } from 'Actions/profileActions'
import { getAllManagerRoles } from 'Utils/roles'
import useRoles from 'Hooks/useRoles'

/** Original author Pratim, I just integrated ROADMAP-39 ;) */
const SwitchDashboard = ({ user, changeRole }) => {
	const {
		loading,
		switchDashboardLoading,
		getGroups,
		getUserRoles,
		isManager,
		isGroupUser,
		switchDashboard,
	} = useRoles({ user, changeRole })

	const effectHelpers = {
		isManager,
		switchDashboard,
	}
	const effectHelpersRef = useRef(effectHelpers)
	effectHelpersRef.current = effectHelpers

	useEffect(() => {
		const { isManager, switchDashboard } = effectHelpersRef.current

		if (!loading && isManager() && !Cookie.get('dashboard')) {
			switchDashboard()
		}
	}, [loading])

	if (loading) return null

	const allGroupManagerRoles = getAllManagerRoles()['group']
	const allAccountManagerRoles = getAllManagerRoles()['account']

	const canBeGroupManager = Boolean(
		isGroupUser() && getGroups().some(item => _.find(allGroupManagerRoles, ['id', item.role])),
	)

	const canBeTopLevelManager =
		canBeGroupManager || getUserRoles().some(item => _.find(allAccountManagerRoles, ['id', item.role]))

	if (!canBeGroupManager && !canBeTopLevelManager) return null

	const renderDashboardSwitchToType = () => (isManager() ? 'Learner' : 'Manager')

	const onClick = () => {
		Cookie.set('dashboard', renderDashboardSwitchToType().toLocaleLowerCase())

		switchDashboard()
	}

	return (
		<div className="actions">
			<Button
				className="switch-role"
				size="large"
				icon
				loading={switchDashboardLoading}
				disabled={switchDashboardLoading}
				onClick={onClick}
			>
				<Icon name="refresh" />
				Switch To {renderDashboardSwitchToType()} Dashboard
			</Button>
		</div>
	)
}

SwitchDashboard.propTypes = {
	user: PropTypes.object.isRequired,
	changeRole: PropTypes.func.isRequired,
}

export default withContext(
	([
		{
			user: { user },
		},
		dispatch,
	]) => ({
		user,
		changeRole: data => profileUpdateAction(data, dispatch),
	}),
	SwitchDashboard,
)
