import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Routes } from 'Constants'
import { OBS_SUBJECT_PREFIX } from 'Constants/subject'
import SubjectsList from 'Pages/Subjects/SubjectsList'
import SingleSubject from 'Pages/Subjects/SingleSubject'
import SubjectSectionInfo from 'Pages/Subjects/SubjectSectionInfo'
import LearnSection from 'Pages/Subjects/LearnSection'
import LearningArchive from 'Pages/Subjects/LearningArchive'
import ScormLearning from 'Pages/Subjects/ScormLearning'

import { ObsCourse, ObsModule, ObsLesson, ObsAssessmentInstructions, ObsAssessment } from 'Pages/Subjects/ObsLearning'

const SubjectsRoutes = () => {
	const obsCoursePath = `${Routes.SUBJECTS.path}/:userSubjectId/${OBS_SUBJECT_PREFIX}/:obsCourseId`

	const obsModulePath = [
		Routes.SUBJECTS.path,
		':userSubjectId',
		':subjectSectionId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
		':obsModuleId',
	].join('/')

	const obsModuleLessonsPath = [
		Routes.SUBJECTS.path,
		':userSubjectId',
		':subjectSectionId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
		':obsModuleId',
		'lesson',
		':obsModuleLessonId',
	].join('/')

	const obsAssessmentIntroPath = [
		Routes.SUBJECTS.path,
		':userSubjectId',
		':subjectSectionId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
		':obsModuleId',
		'assessment-instructions',
	].join('/')

	const obsAssessmentPath = [
		Routes.SUBJECTS.path,
		':userSubjectId',
		':subjectSectionId',
		OBS_SUBJECT_PREFIX,
		':obsCourseId',
		':obsModuleId',
		'assessment',
	].join('/')

	return (
		<Switch>
			<Route exact path={Routes.SUBJECTS.path} component={SubjectsList} />
			<Route exact path={obsCoursePath} component={ObsCourse} />
			<Route exact path={obsModulePath} component={ObsModule} />
			<Route exact path={obsModuleLessonsPath} component={ObsLesson} />
			<Route exact path={obsAssessmentIntroPath} component={ObsAssessmentInstructions} />
			<Route exact path={obsAssessmentPath} component={ObsAssessment} />
			<Route exact path={`${Routes.SUBJECTS.path}/:pivotId`} component={SingleSubject} />
			<Route exact path={`${Routes.SUBJECTS.path}/:pivotId/:sectionId`} component={SubjectSectionInfo} />
			<Route exact path={`${Routes.SUBJECTS.path}/:pivotId/:sectionId/e-learn`} component={ScormLearning} />
			<Route exact path={Routes.SUBJECTS.learningArchive} component={LearningArchive} />
			<Route exact path={Routes.SUBJECTS.fullPath} component={LearnSection} />
			<Redirect to={Routes.SUBJECTS.path} />
		</Switch>
	)
}

export default SubjectsRoutes
