import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import './styles.scss'

const ArchiveInputs = ({ data }) => (
	<div className="archive-inputs">
		<h2 className="page-title">Input / Observations / Outcomes / Examples / Files</h2>
		<div className="archive-inputs-table">
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Input</Table.HeaderCell>
						<Table.HeaderCell>Submitted By</Table.HeaderCell>
						<Table.HeaderCell>Date</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data.map(item => (
						<Table.Row>
							<Table.Cell>{item.text}</Table.Cell>
							<Table.Cell>{item.manager_name ? item.manager_name : '-'}</Table.Cell>
							<Table.Cell>{item.date}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</div>
	</div>
)

ArchiveInputs.propTypes = {
	data: PropTypes.array.isRequired,
}

export default ArchiveInputs
