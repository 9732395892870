import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal, Loader } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import FormComponent from 'Helpers/form'
import { SEND_EMAIL } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'

const validate = values => {
	const errors = {}
	const requiredFields = ['subjectTitle', 'description']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const SendEmail = ({ selectedUsersId, open, onClose, toastManager, type, sessionId }) => {
	const [submitting, setSubmitting] = useState(false)
	const [addEmailMutation] = useMutation(SEND_EMAIL)

	// email mutation
	const emailAddMutation = variables => {
		setSubmitting(true)
		addEmailMutation({
			variables,
		})
			.then(({ data }) => {
				if (data.sendMail) {
					setSubmitting(false)
					onClose()
					toastManager.add('Successful.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
				onClose()
			})
			.finally(() => {
				setSubmitting(false)
				onClose()
			})
	}

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	const {
		values: { subjectTitle, description },
		errors,
		handleSubmit,
		handleChange,
	} = useForm(() => {
		const variables = {
			type: type ? type : 'user',
			type_ids: selectedUsersId,
			email_subject: subjectTitle != undefined ? subjectTitle : '',
			email_body: description != undefined ? description : '',
			session_id: parseInt(sessionId),
		}
		emailAddMutation(variables)
	}, validate)

	if (submitting) return <Loader active />
	return (
		<div className="task-form">
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Send Email</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<FormComponent
											customlabel="Subject Title"
											name="subjectTitle"
											placeholder="Subject Title"
											value={subjectTitle || ''}
											onChange={handleChange}
											error={errors.subjectTitle}
										/>
										<FormComponent.Textarea
											rows={6}
											label="Subject Body"
											name="description"
											placeholder="Subject Body"
											value={description || ''}
											onChange={handleChange}
											fluid
										/>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column computer={8}>
											<Button
												className="reverse"
												size="medium"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										<Grid.Column computer={8}>
											<Button
												type="button"
												size="medium"
												content="Send"
												loading={submitting}
												disabled={submitting}
												onClick={() => {
													handleSubmit()
												}}
												fluid
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</div>
	)
}

SendEmail.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	selectedUsersId: PropTypes.array.isRequired,
	type: PropTypes.string,
	sessionId: PropTypes.bool,
}

SendEmail.defaultProps = {
	open: false,
	type: null,
	sessionId: null,
}

export default withToastManager(SendEmail)
