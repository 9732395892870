import React from 'react'
import { Button } from 'semantic-ui-react'
import { getFilteredSettings } from 'Actions/usersActions'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withContext } from 'Context'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { TagsSelect } from 'Common/TagsSelect'
import { LocationsSelect } from 'Common/LocationsSelect'

const statusOptions = [
	{
		key: 1,
		value: 1,
		text: 'Active',
	},
	// {
	// 	key: 2,
	// 	value: 2,
	// 	text: 'Cancelled',
	// },
	{
		key: 3,
		value: 3,
		text: 'Complete',
	},
	// {
	// 	key: 4,
	// 	value: 4,
	// 	text: 'Planned',
	// },
]

const Filter = ({ customFilter, getUsers, type }) => {
	const {
		values: { username, group, venue, status },
		handleSubmit,
		handleChange,
		handleSelectChange,
		clearValues,
	} = useForm(() => getUsers({ username, tag_id: group, venue: venue, page: 1, status }), null, {
		username: customFilter.username,
		group: customFilter.group,
		venue: customFilter.venue,
		status: customFilter.status,
	})

	const clearValuesHandler = () => {
		clearValues(['username', 'group', 'venue', 'status'])
	}

	return (
		<div className="users-filter">
			<span className="custom-label">Filter</span>
			<form onSubmit={handleSubmit}>
				<FormComponent
					inputClassName="search"
					label={
						<button
							className={classNames({ active: !!username.length })}
							type="button"
							onClick={() => clearValues(['username'])}
						>
							<i className="fal fa-times-circle" />
						</button>
					}
					name="username"
					value={username || ''}
					onChange={handleChange}
					placeholder="Search"
					labelPosition="right"
				/>
				<TagsSelect value={group} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<LocationsSelect value={venue} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<FormComponent.Dropdown
					name="status"
					value={status}
					onChange={handleSelectChange}
					placeholder="Status"
					options={statusOptions}
					selectOnBlur={false}
					selection
					clearable
					fluid
				/>
				<div className="actions text-right">
					<Button type="button" basic content="Clear" onClick={clearValuesHandler} />
					<Button type={type} content="Submit" />
				</div>
			</form>
		</div>
	)
}

Filter.propTypes = {
	customFilter: PropTypes.object.isRequired,
	getUsers: PropTypes.func,
	type: PropTypes.string,
}

Filter.defaultProps = {
	getUsers: () => {},
	type: 'submit',
}

export default withContext(
	([
		{
			users: { customFilter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		// Actions
		getUsers: data => getFilteredSettings(data, dispatch),
	}),
	Filter,
)
