import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal, Loader } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import FormComponent from 'Helpers/form'
import { ADD_EVIDENCE, UPDATE_EVIDENCE } from 'Mutations/settingMutations'
import { EVIDENCE_DETAILS_BY_ID } from 'Queries/settingQueries'
import { withToastManager } from 'react-toast-notifications'
import { withContext } from 'Context/index'
import { withRouter } from 'react-router-dom'
import { EvidenceType } from 'Common'
import { allRoles, isManagerRole, isAssessorRole, resolveRole } from 'Utils/roles'
import './style.scss'

const validate = values => {
	const errors = {}
	const requiredFields = ['evidence_type', 'evidence']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const LearnerAddEvidence = ({
	match,
	open,
	onClose,
	toastManager,
	authUser,
	evidenceId,
	evidenceRefetch,
	selectedUsersIds,
	taskId,
	taskRefetch,
	opentSignModal,
	userRole,
	userRoleType,
}) => {
	const [submitting, setSubmitting] = useState(false)
	const [signOff, setSignOff] = useState(false)
	const [documentName, setDocumentName] = useState(null)
	const [eviDocumentId, setEviDocumentId] = useState(null)
	const isManager = isManagerRole(authUser.account.role, authUser.account.roleType)
	const isAssessor = isAssessorRole(userRole, userRoleType)
	const [addEvidenceMutation] = useMutation(ADD_EVIDENCE)
	const [updateEvidenceMutation] = useMutation(UPDATE_EVIDENCE)

	const {
		params: { id, sessionId },
	} = match

	const [getEvidenceDataQuery, { data: getEvidenceDetails, loading: evidenceLoading }] = useLazyQuery(
		EVIDENCE_DETAILS_BY_ID,
		{
			fetchPolicy: 'network-only',
		},
	)

	useEffect(() => {
		if (evidenceId) {
			getEvidenceDataQuery({ variables: { evidence_id: evidenceId } })
		}
	}, [evidenceId])

	useEffect(() => {
		if (getEvidenceDetails) {
			setValues({
				evidence: getEvidenceDetails.byId.evidence,
				evidence_type: getEvidenceDetails.byId.evidence_type_id,
			})
			setDocumentName(
				getEvidenceDetails.byId.documents.length > 0
					? getEvidenceDetails.byId.documents[0].document_name
					: null,
			)
			setEviDocumentId(
				getEvidenceDetails.byId.documents.length > 0 ? getEvidenceDetails.byId.documents[0].id : null,
			)
		}
	}, [getEvidenceDetails])

	// add evidence mutation
	const evendenceAddMutation = variables => {
		setSubmitting(true)
		addEvidenceMutation({
			variables,
		})
			.then(({ data }) => {
				if (data.createEvidence) {
					setSubmitting(false)
					toastManager.add('Added successfully.', { appearance: 'success', autoDismiss: true })
					evidenceRefetch()
					taskRefetch()
					signOff ? opentSignModal() : null
					onClose()
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	const {
		values: { evidence, evidence_type, upload },
		errors,
		handleSubmit,
		handleChange,
		handleImageChange,
		handleSelectChange,
		setValues,
	} = useForm(() => {
		if (upload != undefined) {
			if (upload.file.type) {
				var imgAllowed = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'image/gif', '.docx']
				var docAllowed = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
				var pptAllowed = ['.doc', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']
				var videoAllowed = ['application/msword', 'video/mp4', 'audio/mpeg']
				var allowedTypes = imgAllowed.concat(docAllowed, pptAllowed, videoAllowed)
				if (!allowedTypes.includes(upload.file.type)) {
					var fileErr = 'Please upload a JPEG, PNG, GIF, PDF, MP3, MP4, PPT, DOC, DOCX file.'
					toastManager.add(fileErr, { appearance: 'error', autoDismiss: true })
					return false
				}
			}
			if (upload.file.size > 5 * 1000 * 1024) {
				toastManager.add('Maximum size of 5MB is allowed', { appearance: 'error', autoDismiss: true })
				return false
			}
		}
		const variables = {
			type: taskId ? 'task' : 'session',
			task_ids: taskId ? [parseInt(taskId)] : [],
			user_ids: selectedUsersIds ? selectedUsersIds : isManager || isAssessor ? [parseInt(id)] : authUser.id,
			session_id: parseInt(sessionId),
			evidence: evidence != undefined ? evidence : '',
			evidence_type: parseInt(evidence_type),
			document: upload ? upload.file : undefined,
			sign_off: signOff,
		}
		evidenceId ? evendenceUpdateMutation(variables) : evendenceAddMutation(variables)
	}, validate)

	// update evidence mutation
	const evendenceUpdateMutation = variables => {
		setSubmitting(true)
		updateEvidenceMutation({
			variables: {
				evidence_id: parseInt(evidenceId),
				evidence,
				document: variables.document,
				evidence_document_id: upload ? parseInt(eviDocumentId) : null,
			},
		})
			.then(({ data }) => {
				if (data.updateEvidence) {
					setSubmitting(false)
					toastManager.add('Updated successfully.', { appearance: 'success', autoDismiss: true })
					evidenceRefetch()
					taskRefetch()
					onClose()
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	if (evidenceLoading) return <Loader active />
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>{evidenceId ? 'Edit' : 'Add'} Evidence</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										{evidenceId ? null : (
											<EvidenceType
												evidenceType={evidence_type}
												handleSelectChange={handleSelectChange}
												error={errors.evidence_type}
												manager={isManager}
											/>
										)}
										<FormComponent.Textarea
											rows={6}
											label="Evidence"
											name="evidence"
											placeholder="Evidence"
											value={evidence || ''}
											onChange={handleChange}
											fluid
											error={errors.evidence}
										/>
										<div className="field-wrap">
											<span className="field-label">Upload Document</span>
											<label className="upload" htmlFor="Upload">
												<input
													name="upload"
													id="upload"
													type="file"
													accept=".png,.jpg,.gif,.doc, .docx,.ppt,
													.pptx,video/mp4,.mp4,.mp3,.jpeg"
													onChange={handleImageChange}
												/>
												<span className="upload-custom"></span>
												{upload && upload.file ? (
													<b>File name: {upload.file.name}</b>
												) : documentName ? (
													<b>File name: {documentName}</b>
												) : (
													'-'
												)}
											</label>
										</div>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column
											computer={(isManager || isAssessor) && !evidenceId && !taskId ? 5 : 8}
										>
											<Button
												className="reverse"
												size="medium"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										<Grid.Column
											computer={(isManager || isAssessor) && !evidenceId && !taskId ? 5 : 8}
										>
											<Button
												type="button"
												size="medium"
												content={
													taskId ? 'Add' : evidenceId ? 'Update Evidence' : 'Add Evidence'
												}
												loading={submitting && !signOff}
												disabled={submitting}
												onClick={() => {
													setSignOff(false)
													handleSubmit()
												}}
												fluid
											/>
										</Grid.Column>
										{(isManager || isAssessor) && !evidenceId && !taskId && (
											<Grid.Column computer={6}>
												<Button
													type="button"
													size="medium"
													content="Add & Sign Off"
													loading={submitting && signOff}
													disabled={submitting}
													onClick={() => {
														setSignOff(true)
														handleSubmit()
													}}
													fluid
												/>
											</Grid.Column>
										)}
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</>
	)
}

LearnerAddEvidence.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	authUser: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	evidenceId: PropTypes.number,
	evidenceRefetch: PropTypes.func,
	selectedUsersIds: PropTypes.array,
	taskRefetch: PropTypes.func,
	taskId: PropTypes.number,
	opentSignModal: PropTypes.func,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

LearnerAddEvidence.defaultProps = {
	open: false,
	evidenceId: null,
	evidenceRefetch: () => {},
	selectedUsersIds: null,
	taskRefetch: () => {},
	taskId: null,
	opentSignModal: () => {},
}

export default withToastManager(
	withRouter(
		withContext(
			([
				{
					user: { user },
				},
			]) => ({
				...resolveRole(user),
				authUser: user,
			}),
			LearnerAddEvidence,
		),
	),
)
