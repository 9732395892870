import React, { useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Routes } from 'Constants/Routes'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import { PageMetaTags } from 'Common'
import { GET_SUBJECT_SECTION_LEARNING_ARCHIVE_BY_ID, GET_USER_BY_ID } from './queries'
import ArchiveInfo from './ArchiveInfo'
import ArchiveOutcomes from './ArchiveOutcomes'
import ArchiveInputs from './ArchiveInputs'

import './styles.scss'

const LearningArchive = ({ match, setBreadcrumbs }) => {
	const {
		params: { pivotId, sectionId, userId },
	} = match

	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_LEARNING_ARCHIVE_BY_ID, {
		variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId), limit: 1, user_id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})
	const [loadUser, { data: user }] = useLazyQuery(GET_USER_BY_ID, {
		variables: { id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (userId) loadUser()
	}, [])

	useEffect(() => {
		if (userId) {
			if (user && pivotId && name && subjectName)
				setBreadcrumbs([
					{
						name: 'Learners',
						path: Routes.USERS,
					},
					{
						name: `${user.byId.first_name} ${user.byId.last_name}`,
						path: Routes.USERS + '/' + userId,
					},
					{
						name: subjectName,
						path: Routes.MANAGER + '/' + userId + '/courses/' + pivotId,
					},
					{
						name: name,
						path: Routes.MANAGER + '/' + userId + '/courses/' + pivotId + '/' + sectionId,
					},
					{
						name: 'Learning Archive',
					},
				])
		} else {
			if (name && pivotId && subjectName)
				setBreadcrumbs([
					{
						name: 'Courses',
						path: Routes.SUBJECTS.path,
					},
					{
						name: subjectName,
						path: Routes.SUBJECTS.path + '/' + pivotId + '/' + sectionId,
					},
					{
						name: name,
					},
				])
		}
	}, [user, loading])

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const {
		name,
		subject: { name: subjectName },
		learningArchive: { legacy_results },
	} = data.byId

	const legacyResults = JSON.parse(legacy_results)
	const { results_overview, outcomes, inputs } = legacyResults

	return (
		<div className="learning-archive">
			<PageMetaTags title="Learning Archive Record" />
			<ArchiveInfo data={results_overview} />
			<ArchiveOutcomes data={outcomes} />
			{inputs && inputs.length && <ArchiveInputs data={inputs} />}
		</div>
	)
}

LearningArchive.propTypes = {
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(LearningArchive)
