import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Radio from './Radio'

const RadioGroup = ({ name, value, options, onChange }) => (
	<div className="radio-group">
		{_.map(options, option => (
			<div key={option.id || option.value} className="custom-radio">
				<Radio {...option} name={name} checked={value === option.value} onChange={onChange} />
			</div>
		))}
	</div>
)

RadioGroup.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.node,
			value: PropTypes.any.isRequired,
		}),
	).isRequired,
	onChange: PropTypes.func.isRequired,
}
RadioGroup.defaultProps = {
	value: '',
}

export default RadioGroup
