import _ from 'lodash'
import { userRoles } from '../constants/userRoles'
import { userGroupRoles } from '../constants/userGroupRoles'
import {
	LEARNER_TYPE,
	MANAGER_TYPE,
	REVIEWER_TYPE,
	NO_SECTION_SIGN_OFF_TYPE,
	SYSTEM_ADMIN,
	ASSESSOR,
} from '../constants/rolesSubtypes'
import { accountlessRoles } from '../constants/accountlessRoles'
import { rolesTypes } from '../constants/rolesTypes'

export const allRoles = {
	[rolesTypes.ACCOUNT]: userRoles,
	[rolesTypes.GROUP]: userGroupRoles,
	[rolesTypes.ACCOUNTLESS]: accountlessRoles,
}

export const isLearnerRole = (roleId, roleType) => isRoleType(getAllLearnerRoles(), roleId, roleType)

export const isManagerRole = (roleId, roleType) => isRoleType(getAllManagerRoles(), roleId, roleType)

export const isReviewerRole = (roleId, roleType) => isRoleType(getAllReviewerRoles(), roleId, roleType)

export const isNoSignOffRole = (roleId, roleType) => isRoleType(getNoSignOffRoles(), roleId, roleType)

export const isAssessorRole = (roleId, roleType) => isRoleType(getAssessorRoles(), roleId, roleType)

export const isSystemAdminRole = (roleId, roleType) => isRoleType(getAllSystemAdminRoles(), roleId, roleType)

export const isAccountlessRole = roleType => roleType === rolesTypes.ACCOUNTLESS

export const getNoSignOffRoles = () => {
	return filterRoleByType(NO_SECTION_SIGN_OFF_TYPE)
}
export const getAllManagerRoles = () => {
	return filterRoleByType(MANAGER_TYPE)
}

export const getAllReviewerRoles = () => {
	return filterRoleByType(REVIEWER_TYPE)
}

export const getAllLearnerRoles = () => {
	return filterRoleByType(LEARNER_TYPE)
}

export const getAllSystemAdminRoles = () => {
	return filterRoleByType(SYSTEM_ADMIN)
}

export const getAssessorRoles = () => {
	return filterRoleByType(ASSESSOR)
}

export const getAllRolesWithAccount = () => {
	const filteredRoles = _.cloneDeep(allRoles)

	delete filteredRoles[rolesTypes.ACCOUNTLESS]

	return filteredRoles
}

export const resolveRole = user => {
	const { role, roleType } = user.account ? (user.account.roleType ? user.account : user) : user

	return { userRole: role, userRoleType: roleType }
}

const filterRoleByType = neededType => {
	const filteredRoles = _.cloneDeep(allRoles)

	for (const type in filteredRoles) {
		filteredRoles[type] = filteredRoles[type].filter(role => {
			return role.type.includes(neededType)
		})

		if (filteredRoles[type].length < 1) {
			delete filteredRoles[type]
		}
	}

	return filteredRoles
}

const isRoleType = (correctRoles, roleId, roleType) => {
	return !!_.find(correctRoles[roleType], ['id', roleId])
}
