import React, { useMemo } from 'react'
import _ from 'lodash'
import { useSortTable } from 'Hooks/useSortTable'
import PropTypes from 'prop-types'
import { PageContent, Table as CTable } from 'Common'
import { usePagination } from 'Hooks/usePagination'
import ArchiveItem from './ArchiveItem'

const Table = ({ list: learningList, search }) => {
	const { list, activeParam, handleSortTable } = useSortTable(learningList)
	const filteredList = useMemo(() => {
		return list.filter(item => item.course_name.toLowerCase().includes(search.toLowerCase()))
	}, [list, search])
	const { list: paginatedList, paginator } = usePagination(filteredList, list.length)

	if (!paginatedList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<CTable className="user-learning-archive-table">
				<CTable.Header className="sorting">
					<CTable.Row>
						<CTable.HeaderCell>
							<CTable.SortToggle
								label="Course Name"
								sortParam="course_name"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</CTable.HeaderCell>
						<CTable.HeaderCell>
							<CTable.SortToggle
								label="Module Name"
								sortParam="module_name"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</CTable.HeaderCell>
						<CTable.HeaderCell>
							<CTable.SortToggle
								label="Activity"
								sortParam="type"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</CTable.HeaderCell>
						<CTable.HeaderCell>
							<CTable.SortToggle
								label="Completion date"
								sortParam="completion_date"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</CTable.HeaderCell>
						<CTable.HeaderCell>
							<CTable.SortToggle
								label="Signed off"
								sortParam="signed_off"
								activeParam={activeParam}
								onSort={handleSortTable}
							/>
						</CTable.HeaderCell>
					</CTable.Row>
				</CTable.Header>
				<CTable.Body>
					{_.map(paginatedList, item => (
						<ArchiveItem key={item.id} item={item} />
					))}
				</CTable.Body>
			</CTable>
			{paginator}
		</>
	)
}

Table.propTypes = {
	list: PropTypes.array.isRequired,
	search: PropTypes.string.isRequired,
}

export default Table
