import React from 'react'
import { withRouter } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal, Button, Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { USER_ADD_SUBJECT } from 'Mutations/usersMutations'
import FormComponent from 'Helpers/form'
import { useForm } from 'Hooks/useForm'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'
import { GET_USER_SUBJECTS_BY_ID } from 'Pages/Users/SingleUser/UserSubjects/queries'

const validate = values => {
	const errors = {}

	const requiredFields = ['subject_id', 'refresh_period']

	requiredFields.forEach(field => {
		if (typeof values[field] !== 'number' && !values[field]) errors[field] = true
	})

	return errors
}

const AddSubject = ({ match, open, subjectsList, onClose }) => {
	const {
		params: { id },
	} = match

	const [addSubject] = useMutation(USER_ADD_SUBJECT, {
		refetchQueries: [
			{
				query: GET_USER_SUBJECTS_BY_ID,
				variables: { id: parseInt(id) },
			},
		],
	})

	const {
		values,
		values: { subject_id, target_completion, refresh_period },
		errors,
		handleSubmit,
		handleSelectChange,
		setValues,
	} = useForm(
		() =>
			addSubject({
				variables: {
					id: parseInt(id),
					subject_id: parseInt(subject_id),
					target_completion,
					refresh_period: parseInt(refresh_period),
				},
			})
				.then(() => onClose())
				.catch(() => onClose()),
		validate,
	)
	const subjectOptions = _.map(subjectsList, item => ({
		key: item.name,
		value: item.id,
		text: item.name,
	}))

	const { loading, error, data } = useQuery(GET_REFRESH_PERIOD_OPTIONS)

	if (loading || error) return null

	const courseChangeHandler = (e, input) => {
		const defaultRefreshPeriod = subjectsList.find(item => item.id === input.value).default_refresh_period

		setValues({ ...values, refresh_period: defaultRefreshPeriod })

		handleSelectChange(e, input)
	}

	return (
		<Modal className="form-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>Add Course</Modal.Header>
			<Modal.Content>
				<form onSubmit={handleSubmit}>
					<FormComponent
						label="Course"
						name="subject_id"
						type="dropdown"
						values={subject_id}
						options={subjectOptions}
						onChange={courseChangeHandler}
						placeholder="Choose Course"
						selectOnBlur={false}
						selection
						fluid
						error={errors.subject_id}
					/>
					<div className="target-tooltip-parent">
						<Popup
							flowing
							hoverable
							position="bottom center"
							trigger={<Icon name="info circle" className="target-tooltip" size="large" />}
						>
							<Popup.Content>
								<div>
									To make a course optional please leave the date blank.
									<br />
									This will make the course appear in the learners
									<br />
									optional section and prevent it from being tracked
									<br />
									on the training matrix.
								</div>
							</Popup.Content>
						</Popup>
						<FormComponent
							label="Target completion"
							name="target_completion"
							type="date"
							value={target_completion || ''}
							onChange={handleSelectChange}
							placeholder="Choose date"
							icon={false}
							clearIcon="close"
							dateFormat="DD-MM-YYYY"
							popupPosition="bottom left"
							clearable
							closable
							error={errors.target_completion}
						/>
					</div>
					<FormComponent.Dropdown
						label="Refresh period"
						name="refresh_period"
						value={refresh_period}
						onChange={handleSelectChange}
						placeholder="Refresh period"
						options={data.refreshPeriodOptions}
						selectOnBlur={false}
						selection
						fluid
						error={errors.refresh_period}
					/>
					<div className="actions text-right">
						<Button type="submit">Save</Button>
						<Button className="reverse" type="button" onClick={onClose}>
							Cancel
						</Button>
					</div>
				</form>
			</Modal.Content>
		</Modal>
	)
}

AddSubject.propTypes = {
	match: PropTypes.object.isRequired,
	open: PropTypes.bool,
	subjectsList: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
}

AddSubject.defaultProps = {
	open: false,
}

export default withRouter(AddSubject)
