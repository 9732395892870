import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, PageContent, CustomPaginator } from 'Common'
import TagItem from './TagItem'

const TagListing = ({
	usersList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	confirmModal,
	viewLearner,
	onConfirm,
	onCancel,
}) => {
	if (!usersList.length) return <PageContent.Message message="List is empty" />
	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Tag"
								sortParam="tag"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Refresher"
								sortParam="refresher"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(usersList, user => (
						<TagItem
							key={user.id}
							user={user}
							confirmModal={confirmModal}
							viewLearner={viewLearner}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

TagListing.propTypes = {
	usersList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	confirmModal: PropTypes.bool.isRequired,
	viewLearner: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

TagListing.defaultProps = {
	activeSortParam: null,
}

export default TagListing
