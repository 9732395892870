import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Table } from 'Common'

const ArchiveItem = ({ item }) => {
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className="custom-tr">
				<Table.Cell>{item.course_name}</Table.Cell>
				<Table.Cell>{item.module_name}</Table.Cell>
				<Table.Cell>{item.type}</Table.Cell>
				<Table.Cell>{moment(item.completion_date).format('DD/MM/YYYY')}</Table.Cell>
				<Table.Cell>{item.signed_off ? 'Yes' : 'No'}</Table.Cell>
			</Table.Row>
		</>
	)
}

ArchiveItem.propTypes = {
	item: PropTypes.object.isRequired,
}

export default withRouter(ArchiveItem)
