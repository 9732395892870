import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { Routes } from 'Constants'
import RebrandingMigrate from 'Layouts/RebrandingMigrate'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ResetPasswordForm from './ResetPasswordForm'
import CreateNewPasswordForm from './CreateNewPasswordForm'
import LoginWithToken from './LoginWithToken'
import TwoFactorForm from './TwoFactorForm'

import './style.scss'

const Login = () => (
	<RebrandingMigrate>
		<div className="login-page">
			<div className="inner">
				<div className="forms-wrap">
					<Switch>
						<Route exact path="/login" component={LoginForm} />
						<Route exact path="/registration/:token" component={RegisterForm} />
						<Route exact path="/reset-password" component={ResetPasswordForm} />
						<Route exact path="/password/reset/:token" component={CreateNewPasswordForm} />
						<Route exact path="/loginToken/:emailId/:authToken/:pivotId?" component={LoginWithToken} />
						<Route exact path={Routes.TWO_FACTOR_AUTH} component={TwoFactorForm} />
						<Redirect to="/login" />
					</Switch>
				</div>
			</div>
		</div>
	</RebrandingMigrate>
)

export default withRouter(Login)
