import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import _ from 'lodash'

import { withContext } from 'Context'
import { Routes } from 'Constants'
import { getFilteredUsers } from 'Actions/usersActions'
import { allRoles, isReviewerRole } from 'Utils/roles'
import { Button, Icon } from 'semantic-ui-react'

const GroupItem = ({ item, handler, getUsers, userRole, userRoleType, collapseIcon }) => {
	const isReviewer = isReviewerRole(userRole, userRoleType)

	const handleClick = useCallback(() => {
		getUsers({ group: item.id })
	}, [item, getUsers])

	const handleFirstExpand = useCallback(() => {
		item.loadGroups({ variables: { parent_id: item.id } })
	}, [item])

	const handleEachExpand = useCallback(() => {
		item.toggleActiveIds(item.id, _.map(item.children, 'id'))
	}, [item])

	return (
		<>
			<div
				className={classNames(
					'group-item',
					{ 'not-active': item.query && item.query.length > 2 && !item.search_match },
					{ 'no-control': isReviewer },
				)}
			>
				{!isReviewer && handler}
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
				<div className="expand-wrapper" onClick={handleEachExpand}>
					{(item.children.length && collapseIcon) ||
						(item.is_children_exists && (
							<button type="button" className="expand first-handler" onClick={handleFirstExpand}>
								<i className="fas fa-plus-square" />
							</button>
						))}
				</div>
				<div className="group-item-content">
					<span className="group-users-count" title={item.id}>
						{item.id}
					</span>
					<span className="group-name" title={item.name}>
						{item.name}
					</span>
					{/*<span className="group-user" title={`${item.manager.first_name} ${item.manager.last_name}`}>*/}
					{/*	{`${item.manager.first_name} ${item.manager.last_name}`}*/}
					{/*</span>*/}
					<span className="group-users-count">
						<Link className="blue-text" to={Routes.USERS} onClick={handleClick}>
							{item.users_count}
						</Link>
					</span>
					{!isReviewer && (
						<Button type="button" size="small" onClick={() => item.onClick(item)}>
							<Icon name="edit outline" style={{ color: 'white' }} />
							<span>Edit</span>
						</Button>
					)}
				</div>
			</div>
		</>
	)
}

GroupItem.propTypes = {
	item: PropTypes.object.isRequired,
	handler: PropTypes.object.isRequired,
	getUsers: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	collapseIcon: PropTypes.object,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

GroupItem.defaultProps = {
	collapseIcon: null,
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { role, roleType },
				},
			},
		},
		dispatch,
	]) => ({
		userRole: role,
		userRoleType: roleType,
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	GroupItem,
)
