import React, { useRef, useEffect, useState } from 'react'
import { DateInput } from 'semantic-ui-calendar-react'

const Date = field => {
	const wrapRef = useRef()
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	return (
		<div className="field-wrap date-input-wrap" ref={wrapRef}>
			{mounted && (
				<DateInput
					{...field}
					value={field.value || ''}
					duration={0}
					type="text"
					mountNode={field.mountNode || wrapRef.current}
					autoComplete="off"
					closeOnMouseLeave={false}
					clearable
					closable
				/>
			)}
		</div>
	)
}

export default Date
