import React from 'react'
import { Link, generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Routes } from 'Constants'
import { UserPhoto, Table, ProgressBar } from 'Common'
import FormComponent from 'Helpers/form'
import { determineLastActivityLabel } from 'Utils/index'
import { Button } from 'semantic-ui-react'

const UserItem = ({ user, selectUser, isSelect }) => {
	const history = useHistory()
	const subjectLink = `${Routes.MANAGER}/${user.id}${Routes.SUBJECTS.path}/${user.subject.pivot_id}`
	const redirectSubLink = () => {
		history.push(`${Routes.MANAGER}/${user.id}${Routes.SUBJECTS.path}/${user.subject.pivot_id}`)
	}
	return (
		<>
			<Table.Row className="spacer" />
			<Table.Row className={classNames('custom-tr', { inactive: user.account && !user.account.is_active })}>
				<Table.Cell>
					<FormComponent.Checkbox checked={isSelect} onChange={selectUser} className="customCheck" />
				</Table.Cell>
				<Table.Cell title={user.name}>
					<UserPhoto className="user-photo" user={user} size="mini" />
					<Link
						className="link"
						to={generatePath(Routes.SINGLE_USER, {
							id: user.id,
						})}
						title={user.name}
					>
						{user.name}
					</Link>
				</Table.Cell>
				<Table.Cell>
					<Link className="link" to={subjectLink} title={user.subjectName}>
						{user.subjectName}
					</Link>
					<br></br>
					<span>Retake: {user.retake}</span>
				</Table.Cell>
				<Table.Cell>
					{user.subject.lastActive ? determineLastActivityLabel(user.subject.lastActive.created_at) : '-'}
				</Table.Cell>
				<Table.Cell>
					<ProgressBar
						percents={user.subject.progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				<Table.Cell>
					<ProgressBar
						percents={user.subject.learning_progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				<Table.Cell>
					<ProgressBar
						percents={user.subject.observation_progress.toString()}
						position="bottom"
						inactiveBg="#F4F6FC"
						style={{ textAlign: 'center' }}
						titleStyle={{ fontSize: '11px' }}
					/>
				</Table.Cell>
				<Table.Cell>{user.due ? user.due : '-'}</Table.Cell>
				<Table.Cell>{user.signed_off ? user.signed_off : '-'}</Table.Cell>
				<Table.Cell>
					<Button type="button" size="small" content="View" onClick={redirectSubLink} />
				</Table.Cell>
			</Table.Row>
		</>
	)
}

UserItem.propTypes = {
	user: PropTypes.object.isRequired,
	isSelect: PropTypes.bool,
	selectUser: PropTypes.func.isRequired,
}

UserItem.defaultProps = {
	isSelect: false,
}

export default UserItem
