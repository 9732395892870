import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Routes } from 'Constants/Routes'
import SectionsToExport from 'Pages/Print/SectionsToExport'

const Print = () => {
	return (
		<Switch>
			<Route
				exact
				path={`${Routes.PRINT}${Routes.MANAGER}/:userId${Routes.SUBJECTS.path}/:pivotId`}
				component={SectionsToExport}
			/>
			<Route exact path={`${Routes.PRINT}${Routes.SUBJECTS.path}/:pivotId`} component={SectionsToExport} />
		</Switch>
	)
}

export default Print
