import * as Types from 'Types'

const authMiddleware = ({ type }) => {
	switch (type) {
		case Types.RESTORE_USER:
			return { type: Types.APP_LOADED, payload: { loading: false } }
		default:
			break
	}
}

export default authMiddleware
