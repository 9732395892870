import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { GroupSelect, SubjectSelect } from 'Common'
import { SelectOneTag } from 'Common/TagsSelect/SelectOneTag'
import { TopicSelect } from 'Common/TopicSelect'
import { SessionSelect } from 'Common/SessionSelect'
import { checkBoolDefaultFilters } from 'Utils'
import {
	dueOptions,
	progressOptions,
	// , YesNoOptions
} from 'Constants'
import moment from 'moment'

const Filter = ({ location, onFiltered, currentTabIndex }) => {
	const {
		due: defaultDue,
		active: defaultActive,
		target_date_set: defaultTargetDateSet,
		progress: defaultProgress,
		signed_off: defaultSignedOff,
	} = queryString.parse(location.search)

	const YesNoOptions =
		currentTabIndex == 0
			? [
					{
						key: 1,
						value: true,
						text: 'Yes',
					},
					{
						key: 2,
						value: false,
						text: 'No',
					} /* eslint-disable no-mixed-spaces-and-tabs */,
			  ]
			: [
					{
						key: 1,
						value: 1,
						text: 'Complete',
					},
					{
						key: 2,
						value: 2,
						text: 'Not Completed',
					},
					{
						key: 0,
						value: 0,
						text: 'Not Signed Off',
					},
			  ] /* eslint-enable no-mixed-spaces-and-tabs */

	const {
		values: {
			group,
			subject,
			session,
			due = Number(defaultDue),
			username,
			active = checkBoolDefaultFilters(defaultActive),
			target_date_set = checkBoolDefaultFilters(defaultTargetDateSet),
			progress = Number(defaultProgress),
			signed_off = checkBoolDefaultFilters(defaultSignedOff),
			tag,
			attendance_status,
			topic,
			signed_off_from,
			signed_off_to,
		},
		handleSubmit,
		handleChange,
		handleSelectChange,
		clearValues,
		setValues,
	} = useForm(() => {
		let filterValue =
			currentTabIndex == 0
				? {
						group,
						subjects: subject,
						username,
						active,
						target_date_set,
						progress,
						signed_off,
						due,
						page: 1,
						tag,
						signed_off_from,
						signed_off_to,
						topic /* eslint-disable no-mixed-spaces-and-tabs */,
				  }
				: {
						group,
						session,
						username,
						active,
						target_date_set,
						progress,
						due,
						page: 1,
						tag,
						attendance_status:
							attendance_status != undefined && attendance_status != null ? attendance_status : null,
						topic,
						signed_off_from,
						signed_off_to,
				  } /* eslint-enable no-mixed-spaces-and-tabs */
		onFiltered(filterValue)
	})

	useEffect(() => {
		const defaultValues = [defaultDue, defaultActive, defaultTargetDateSet, defaultProgress, defaultSignedOff]
		if (defaultValues.some(item => typeof item !== 'undefined')) {
			onFiltered({
				group,
				subjects: subject,
				session,
				username,
				active,
				target_date_set,
				progress,
				signed_off,
				due,
				page: 1,
				tag,
				topic,
				attendance_status,
			})
		}
	}, [])

	useEffect(() => {
		if (currentTabIndex == 0) {
			setValues({
				group,
				subjects: subject,
				session,
				due,
				username,
				active,
				target_date_set,
				progress,
				signed_off: null,
				tag,
				attendance_status,
				topic,
			})
		} else {
			setValues({
				group,
				subjects: subject,
				session,
				due,
				username,
				active,
				target_date_set,
				progress,
				signed_off,
				tag,
				attendance_status: null,
				topic,
			})
		}
	}, [currentTabIndex])

	return (
		<div className="users-filter">
			<span className="custom-label">Filter</span>
			<form onSubmit={handleSubmit}>
				<FormComponent
					customlabel="Name"
					name="username"
					value={username || ''}
					onChange={handleChange}
					placeholder="Name"
				/>
				<GroupSelect value={group} onChange={handleSelectChange} selectOnBlur={false} clearable />
				{currentTabIndex == 0 ? (
					<SubjectSelect
						value={subject}
						onChange={handleSelectChange}
						selectOnBlur={false}
						clearable
						multiple
					/>
				) : (
					<SessionSelect value={session} onChange={handleSelectChange} selectOnBlur={false} clearable />
				)}

				{currentTabIndex == 0 ? (
					<FormComponent
						label="Signed off"
						name="signed_off"
						type="dropdown"
						value={typeof signed_off === 'boolean' ? signed_off : ''}
						options={YesNoOptions}
						onChange={handleSelectChange}
						placeholder="Signed off"
						icon="angle down"
						selection
						selectOnBlur={false}
						clearable
					/>
				) : (
					<FormComponent
						label="Signed off"
						name="attendance_status"
						type="dropdown"
						// value={typeof attendance_status === 'boolean' ? attendance_status : null}
						value={attendance_status}
						options={YesNoOptions}
						onChange={handleSelectChange}
						placeholder="Signed off"
						icon="angle down"
						selection
						selectOnBlur={false}
						clearable
					/>
				)}

				<FormComponent
					label="Due"
					name="due"
					type="dropdown"
					value={due || ''}
					options={dueOptions}
					onChange={handleSelectChange}
					placeholder="Due"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				<FormComponent
					label="Active"
					name="active"
					type="dropdown"
					value={typeof active === 'boolean' ? active : ''}
					options={YesNoOptions}
					onChange={handleSelectChange}
					placeholder="Active"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				{currentTabIndex == 0 && (
					<FormComponent
						label="Target date set"
						name="target_date_set"
						type="dropdown"
						value={typeof target_date_set === 'boolean' ? target_date_set : ''}
						options={YesNoOptions}
						onChange={handleSelectChange}
						placeholder="Target date set"
						icon="angle down"
						selection
						selectOnBlur={false}
						clearable
					/>
				)}
				<FormComponent
					label="Progress"
					name="progress"
					type="dropdown"
					value={progress || ''}
					options={progressOptions}
					onChange={handleSelectChange}
					placeholder="Progress"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				<SelectOneTag value={tag} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<TopicSelect value={topic} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<FormComponent.Date
					name="signed_off_from"
					value={signed_off_from || null}
					placeholder="signed off from"
					icon="calendar alternate outline"
					dateFormat="DD-MM-YYYY"
					clearIcon="close"
					label="Signed-Off From"
					mountNode={document.body}
					clearable
					closable
					onChange={handleSelectChange}
				/>
				<FormComponent.Date
					name="signed_off_to"
					value={signed_off_to || null}
					placeholder="signed off to"
					icon="calendar alternate outline"
					dateFormat="DD-MM-YYYY"
					clearIcon="close"
					label="Signed-Off To"
					mountNode={document.body}
					clearable
					closable
					maxDate={moment()}
					onChange={handleSelectChange}
				/>
				<div className="actions text-right">
					<Button type="button" basic onClick={clearValues}>
						Clear
					</Button>
					<Button type="submit">Submit</Button>
				</div>
			</form>
		</div>
	)
}

Filter.propTypes = {
	location: PropTypes.object.isRequired,
	onFiltered: PropTypes.func.isRequired,
	currentTabIndex: PropTypes.bool,
}

Filter.defaultProps = {
	currentTabIndex: 0,
}

export default withRouter(Filter)
