import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'

import CreateUserModal from 'Modals/users/CreateUserModal'
import { GET_USER_BY_ID } from 'Pages/Users/SingleUser/queries'

const EditProfile = ({ match, open, initialValues, onClose }) => {
	const [updateUser] = useMutation(UPDATE_PROFILE_MUTATION, {
		refetchQueries: [
			{
				query: GET_USER_BY_ID,
				variables: { id: parseInt(match.params.id) },
			},
		],
	})
	return (
		<CreateUserModal
			open={open}
			userId={parseInt(match.params.id)}
			initialFormData={initialValues}
			mutation={updateUser}
			onClose={onClose}
		/>
	)
}

EditProfile.propTypes = {
	match: PropTypes.object.isRequired,
	open: PropTypes.bool,
	initialValues: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

EditProfile.defaultProps = {
	open: false,
}

export default withRouter(EditProfile)
