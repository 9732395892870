import React from 'react'
import { Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'

import { userGroupRoles } from 'Constants'
import { withContext } from 'Context'
import { getFilteredUsers } from 'Actions/usersActions'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'

import { GroupSelect, SubjectSelect } from 'Common'

const activeOptions = [
	{
		key: 'yes',
		text: 'Yes',
		value: true,
	},
	{
		key: 'no',
		text: 'No',
		value: false,
	},
]
const statusOptions = [
	{
		key: 'requires evidence',
		value: 'requires',
		text: 'Requires evidence',
	},
	{
		key: 'remaining',
		value: 'remaining',
		text: 'In progress',
	},
	{
		key: 'over',
		value: 'over',
		text: 'Overdue',
	},
]
const rolesOptions = _.map(userGroupRoles, ({ id, role }) => ({
	key: id,
	value: id,
	text: role,
}))

const Filter = ({ filter, getUsers, type }) => {
	const {
		values: { username, role, group, subject, status_evidence, active },
		handleSubmit,
		handleChange,
		handleSelectChange,
		clearValues,
	} = useForm(() => getUsers({ username, role, group, subject, status_evidence, active, page: 1 }), null, {
		username: filter.username,
		role: filter.role,
		group: filter.group,
		subject: filter.subject,
		status_evidence: filter.status_evidence,
		active: filter.active,
	})

	const clearValuesHandler = () => {
		clearValues(['username', 'role', 'group', 'subject', 'status_evidence', 'active'])
	}

	return (
		<div className="users-filter">
			<span className="custom-label">Filter</span>
			<form onSubmit={handleSubmit}>
				<FormComponent
					inputClassName="search"
					label={
						<button
							className={classNames({ active: !!username.length })}
							type="button"
							onClick={() => clearValues(['username'])}
						>
							<i className="fal fa-times-circle" />
						</button>
					}
					name="username"
					value={username || ''}
					onChange={handleChange}
					placeholder="Search"
					labelPosition="right"
				/>
				<FormComponent
					label="Role"
					name="role"
					type="dropdown"
					value={role || ''}
					options={rolesOptions}
					onChange={handleSelectChange}
					placeholder="Role"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				<GroupSelect value={group} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<SubjectSelect value={subject} onChange={handleSelectChange} selectOnBlur={false} clearable />
				<FormComponent
					label="status"
					name="status_evidence"
					type="dropdown"
					value={status_evidence || ''}
					options={statusOptions}
					onChange={handleSelectChange}
					placeholder="Status"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				<FormComponent
					label="active"
					name="active"
					type="dropdown"
					value={active !== null ? active : ''}
					options={activeOptions}
					onChange={handleSelectChange}
					placeholder="Yes"
					icon="angle down"
					selection
					selectOnBlur={false}
					clearable
				/>
				<div className="actions text-right">
					<Button type="button" basic content="Clear" onClick={clearValuesHandler} />
					<Button type={type} content="Submit" />
				</div>
			</form>
		</div>
	)
}

Filter.propTypes = {
	filter: PropTypes.object.isRequired,
	getUsers: PropTypes.func,
	type: PropTypes.string,
}

Filter.defaultProps = {
	getUsers: () => {},
	type: 'submit',
}

export default withContext(
	([
		{
			users: { filter },
		},
		dispatch,
	]) => ({
		// state
		filter,
		// Actions
		getUsers: data => getFilteredUsers(data, dispatch),
	}),
	Filter,
)
