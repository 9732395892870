import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Routes } from 'Constants'
import UsersList from './UsersList'
import SingleUser from './SingleUser'
import UsersSessions from './SingleUser/UserSessions'
import './style.scss'

const Users = () => (
	<Switch>
		<Route exact path={Routes.USERS} component={UsersList} />
		<Route exact path={Routes.SINGLE_USER} component={SingleUser} />
		<Route exact path={Routes.SESSION_USER} component={UsersSessions} />
	</Switch>
)

export default Users
