import React from 'react'
import PropTypes from 'prop-types'

const ActivityItemWrap = ({ logName, children }) => {
	const iconWrapClass = ['icon']
	const iconClass = ['far']

	switch (logName) {
		case 'user-auth':
			iconWrapClass.push('auth')
			iconClass.push('fa-ellipsis-h')
			break
		case 'user-began':
			iconWrapClass.push('began')
			iconClass.push('fa-chart-line')
			break
		case 'user-viewed':
			iconWrapClass.push('viewed')
			iconClass.push('fa-chart-line')
			break
		default:
			iconWrapClass.push('completed')
			iconClass.push('fa-check')
			break
	}

	return (
		<li className="activity-item">
			<div className={iconWrapClass.join(' ')}>
				<i className={iconClass.join(' ')} />
			</div>
			<div>{children}</div>
		</li>
	)
}

ActivityItemWrap.propTypes = {
	logName: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default ActivityItemWrap
