import React from 'react'
import { SUBJECT_ACTIVITY_STATUSES } from 'Constants/subject'

export const HELP_TEXT = {
	[SUBJECT_ACTIVITY_STATUSES.CURRENT]: (
		<p>
			These courses are Due Now or within the next 12 weeks. You can check the Due Date to see which ones you need
			to do first. There may be other courses in the Optional section which you can take.
		</p>
	),
	[SUBJECT_ACTIVITY_STATUSES.PLANNED]: (
		<p>
			<span className="red">You do not need to complete these courses yet!</span> They have been added to your
			Development Plan and you can check when they are due here. They will automatically be moved to the Due Now
			section 12 weeks before you need to complete them.
		</p>
	),
	[SUBJECT_ACTIVITY_STATUSES.OPTIONAL]: (
		<p>
			These are Optional courses which do not have a date when you need to complete them by. Before taking them,
			please check your Completed courses to see if you have already completed them.
		</p>
	),
	[SUBJECT_ACTIVITY_STATUSES.COMPLETED]: <p>Check your Completed courses below.</p>,
}
