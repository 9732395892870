import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Confirm, Grid, Modal, Icon, Popup, Loader } from 'semantic-ui-react'

import { withToastManager } from 'react-toast-notifications'
import { withContext } from 'Context'
import { IMPORT_PLAN_CSV } from 'Mutations/importMutations'

import { post } from '../../../../store/queries/rest/client'
import './style.scss'
import FormComponent from '../../../helpers/form'
import { GET_CURRENT_ACCOUNT } from '../../../../store/queries/usersQueries'

const ImportSubjects = ({ open, onClose, toastManager }) => {
	const [submitting, setSubmitting] = useState(false)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const [showcsv, setShowcsv] = useState(false)
	const instQuoteOne = 'The Help Centre has a detailed explanation of the import process. '
	const instQuoteTwo = 'This includes what codes to put in the different columns. '
	const instQuoteThree = instQuoteOne + instQuoteTwo
	const [importPlanCSV] = useMutation(IMPORT_PLAN_CSV)
	const [accountSubscriptionId, setAccountSubscriptionId] = useState(0)
	const [bundleTemplatePath, setBundleTemplatePath] = useState(null)
	const [bundleValue, setBundleValue] = useState(null)
	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (!skipConfirm) {
				setConfirmOpen(true)
			} else {
				setBundleValue(null)
				setBundleTemplatePath(null)
				onClose()
			}
		},
		[setConfirmOpen, onClose],
	)

	const handleShowcsv = () => {
		setShowcsv(true)
	}

	const handleImageChange = e => {
		const body = new FormData()
		body.append('import_sheet', e.target.files[0])
		//console.log(e.target.files[0])
		setSubmitting(true)
		post('/import-user', body)
			.then(response => {
				return response.json()
			})
			.then(data => {
				const variables = { file: data.url, accountSubscriptionId: parseInt(accountSubscriptionId) || 0 }
				importPlanCSV({ variables: variables })
					.then(msg => {
						onClose()
						console.log(msg)
						setAccountSubscriptionId(0)
						toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
					})
					.catch(error => {
						console.log('value in error', error)
						setAccountSubscriptionId(0)
						onClose()
					})
					.finally(() => {
						setSubmitting(false)
					})
			})
	}
	const state = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
	const user = state ? state.user : null
	const { data, loading } = useQuery(GET_CURRENT_ACCOUNT, {
		variables: { id: parseInt(user.id) },
		fetchPolicy: 'network-only',
	})
	let subscriptions = []
	if (data && data.byId && data.byId.currentAccount && data.byId.currentAccount.subscriptions) {
		subscriptions = data.byId.currentAccount.subscriptions
	}
	let courseBundleOptions = []
	if (subscriptions && Array.isArray(subscriptions)) {
		courseBundleOptions = subscriptions
			.filter(subscription => subscription && subscription.courseBundle && subscription.courseBundle.name)
			.map(subscription => {
				return {
					key: subscription.id,
					max_users_count: subscription.max_users_count || 0,
					current_users_count: subscription.current_users_count || 0,
					value: subscription.courseBundle.name,
					text: subscription.courseBundle.name,
					bundle_template_path: subscription.courseBundle.bundle_template_path || null,
				}
			})
	}
	const handleSelectChange = (e, { value, options }) => {
		if (value) {
			setBundleValue(value)
			setBundleTemplatePath(null)
			const accountSubscriptionId = options.find(option => option.value === value).key
			const bundleTemplatePath = options.find(option => option.value === value).bundle_template_path
			setAccountSubscriptionId(accountSubscriptionId)
			setBundleTemplatePath(bundleTemplatePath)
		} else {
			setBundleValue(null)
			setAccountSubscriptionId(0)
			setBundleTemplatePath(null)
		}
	}
	useEffect(() => {
		if (courseBundleOptions.length === 1) {
			handleSelectChange(null, { value: courseBundleOptions[0].value, options: courseBundleOptions })
		}
	}, [courseBundleOptions])
	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Import learning plan</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						{loading ? (
							<Loader active />
						) : courseBundleOptions && courseBundleOptions.length ? (
							<div className="bundle-dropdown-cls">
								<FormComponent
									type="dropdown"
									label="Course Bundles"
									name="course_bundles"
									placeholder="Select Course Bundle"
									options={courseBundleOptions}
									value={bundleValue}
									onChange={handleSelectChange}
									disabled={submitting}
									selectOnBlur={false}
									clearable
									selection
									fluid
								/>
							</div>
						) : (
							<p>You dont have any subscription or course bundle</p>
						)}
						{bundleValue && !bundleTemplatePath && <p>Bundle does not have any template</p>}
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields group-fields-pt">
										{accountSubscriptionId && !submitting && bundleTemplatePath ? (
											<Grid.Column tablet={16} computer={8}>
												<a
													rel="noopener noreferrer"
													href={`${process.env.REACT_APP_BUCKET_URL}
													${bundleTemplatePath}`}
													target="_blank"
													className="ui fluid button"
												>
													Download import template <i className="fas fa-download btn-color" />
												</a>
											</Grid.Column>
										) : (
											<Grid.Column tablet={16} computer={8}>
												<Button
													type="button"
													disabled={
														!accountSubscriptionId || submitting || !bundleTemplatePath
													}
													fluid
												>
													Download import template <i className="fas fa-download btn-color" />
												</Button>
											</Grid.Column>
										)}
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="button"
												disabled={submitting || !accountSubscriptionId || !bundleTemplatePath}
												content="Upload learning plan"
												fluid
												onClick={handleShowcsv}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										{showcsv && accountSubscriptionId && (
											<div className="field-wrap">
												<span className="field-label">Upload CSV</span>
												<label className="upload" htmlFor="Upload">
													<input
														name="upload"
														id="upload"
														type="file"
														accept=".csv, text/csv"
														disabled={submitting}
														onChange={handleImageChange}
													/>
													<span className="upload-custom"></span>
												</label>
											</div>
										)}
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<div style={{ marginTop: '-30px' }}>
											<span>{instQuoteThree}</span>
											<br />
											<br />
											<span>On CSV row 2, add subject id and in next column for Refresher</span>
											<br />
											<span>Learner Email </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Primary Email</h4>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Course Name </span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>Example: Activities provision</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Course ID</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<p>These are set within Click and should not be changed.</p>
														<p>Example: Activities provision have ID 49</p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
											<span>Refresher</span>
											<Popup trigger={<Icon name="info circle" size="large" />} flowing hoverable>
												<Grid centered divided columns={1}>
													<Grid.Column textAlign="center">
														<h4>Below are the options</h4>
														<p>3 years = 36 </p>
														<p>2 years = 24 </p>
														<p>1 year = 12 </p>
														<p>6 months = 6 </p>
														<p>3 months = 3 </p>
														<p>None = 0 </p>
														<p>Required</p>
													</Grid.Column>
												</Grid>
											</Popup>
										</div>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}></Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Close"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>

			<Confirm
				open={confirmOpen}
				size="mini"
				className="confirm"
				header="Closing add learning plan window"
				content="Are you sure you want to close this window?"
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => modalCloseHandler(true)}
			/>
		</>
	)
}

ImportSubjects.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}
ImportSubjects.defaultProps = {
	open: false,
}

// export default

export default withToastManager(
	withContext(
		([
			{
				user: {
					user: {
						account: { role },
					},
				},
			},
		]) => ({ currentUserRole: role }),
		ImportSubjects,
	),
)
