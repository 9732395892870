import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormComponent from 'Helpers/form'
import { Table, PageContent, CustomPaginator } from 'Common'
import SessionsItem from './SessionsItem'

const SessionsTable = ({
	sessionList,
	page,
	setPage,
	totalCount,
	length,
	perPage,
	activeSortParam,
	handleSortTable,
	selectedUsers,
	setSelectedUsers,
	setselectedTopic,
}) => {
	const selectAllUsersOnPage = () => {
		setSelectedUsers(sessionList.length !== selectedUsers.length ? _.map(sessionList, ({ id }) => id) : [])
	}

	const selectUser = userId => {
		setSelectedUsers(
			_.includes(selectedUsers, userId)
				? _.filter(selectedUsers, id => id !== userId)
				: [...selectedUsers, userId],
		)
	}

	const selectTopic = topicId => {
		setselectedTopic(topicId)
	}

	if (!sessionList.length) return <PageContent.Message message="List is empty" />
	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<FormComponent.Checkbox
								className="select-all customCheck"
								name="multichoice"
								checked={sessionList.length === selectedUsers.length}
								onChange={selectAllUsersOnPage}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Id"
								sortParam="id"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="title"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Date"
								sortParam="date"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Type"
								sortParam="type"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Location</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Status"
								sortParam="status"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Attendees</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Spaces"
								sortParam="places"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(sessionList, user => (
						<SessionsItem
							key={user.id}
							user={user}
							isSelect={_.includes(selectedUsers, user.id) || selectedUsers === 'all'}
							selectUser={() => {
								selectUser(user.id)
								selectTopic(user.topic.id)
							}}
						/>
					))}
				</Table.Body>
			</Table>

			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

SessionsTable.propTypes = {
	sessionList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedUsers: PropTypes.array.isRequired,
	setSelectedUsers: PropTypes.func.isRequired,
	setselectedTopic: PropTypes.func.isRequired,
}

SessionsTable.defaultProps = {
	activeSortParam: null,
}

export default SessionsTable
