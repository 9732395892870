import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'

import { Routes } from 'Constants'
import { withContext } from 'Context'
import { restoreSelectedSection } from 'Actions/subjectActions'

import Instructions from './Instructions'
import Questions from './Questions'

const Assessment = ({ history, selectedSection, _restoreSelectedSection }) => {
	useEffect(() => {
		if (selectedSection.isFetching) _restoreSelectedSection()
	}, [])

	if (selectedSection.isFetching) return <Loader active />
	if (!selectedSection.isFetching && !selectedSection.id) {
		history.goBack()
		return null
	}

	return (
		<Switch>
			<Route
				exact
				path={Routes.ASSESSMENT.instructions}
				render={props => <Instructions pageTitle="Assessment Instructions" {...props} />}
			/>
			<Route exact path={Routes.ASSESSMENT.questions} component={Questions} />
			<Redirect to={Routes.ASSESSMENT.instructions} />
		</Switch>
	)
}

Assessment.propTypes = {
	history: PropTypes.object.isRequired,
	selectedSection: PropTypes.object,
	_restoreSelectedSection: PropTypes.func,
}

Assessment.defaultProps = {
	selectedSection: {},
	_restoreSelectedSection: () => {},
}

export default withContext(
	([
		{
			subject: { selectedSection },
		},
		dispatch,
	]) => ({
		// state
		selectedSection,
		// actions
		_restoreSelectedSection: () => restoreSelectedSection(dispatch),
	}),
	Assessment,
)
