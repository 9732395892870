import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StatusIcon from 'Pages/Subjects/ManageOutcomes/StatusIcon'
import MultichoiceAnswer from './MultichoiceAnswer'

const UserAnswer = ({ question, dont_know, answer_ids, answer, result }) => {
	const [status, setStatus] = useState('error')
	useEffect(() => {
		if (result) {
			setStatus('success')
		}
	}, [result])
	return (
		<>
			<div className="info-item">
				<h5>
					<span className="icon">
						<i className="fas fa-angle-down" />
					</span>
					Question
				</h5>
				<ul>
					<li>
						<p className="dark-text">{question.name}</p>
						<div className="answer-status">
							<StatusIcon status={status} />
						</div>
					</li>
				</ul>
			</div>
			<div className="info-item">
				<h5>
					<span className="icon">
						<i className="fas fa-angle-down" />
					</span>
					Answers {dont_know && <span className="blue-text">[Learner selected &quot;Not sure&quot;]</span>}
					<div className="answer-label">Correct answer</div>
					<div className="answer-label">Your answer</div>
				</h5>
				{question.type !== 2 && <MultichoiceAnswer options={question.options} answerIds={answer_ids} />}
				{question.type === 2 && answer}
			</div>
		</>
	)
}

UserAnswer.propTypes = {
	question: PropTypes.object.isRequired,
	dont_know: PropTypes.bool.isRequired,
	answer_ids: PropTypes.array,
	answer: PropTypes.string,
	result: PropTypes.bool,
}
UserAnswer.defaultProps = {
	answer_ids: null,
	answer: null,
	result: false,
}

export default UserAnswer
