import { useEffect, useRef } from 'react'
import { Routes } from 'Constants'

export default function useBreadcrumbs({
	isLearnerView,
	setBreadcrumbs,
	subject: { name: subjectName },
	name,
	user,
	userSubjectId,
}) {
	const setBreadcrumbsRef = useRef(setBreadcrumbs)

	const userFullName = `${user.first_name} ${user.last_name}`
	const userId = user.id

	useEffect(() => {
		setBreadcrumbsRef.current = setBreadcrumbs
	}, [setBreadcrumbs])

	useEffect(() => {
		if (!userId) {
			return
		}

		let breadcrumbs = [
			{
				name: 'Courses',
				path: Routes.SUBJECTS.path,
			},
			{
				name: subjectName,
				path: [Routes.SUBJECTS.path, userSubjectId].join('/'),
			},
			{
				name,
			},
		]

		if (!isLearnerView) {
			breadcrumbs = [
				{
					name: 'Learners',
					path: Routes.USERS,
				},
				{
					name: userFullName,
					path: [Routes.USERS, userId].join('/'),
				},
				{
					name: subjectName,
					path: [Routes.MANAGER, user.id, 'courses', userSubjectId].join('/'),
				},
				{
					name,
				},
			]
		}

		setBreadcrumbsRef.current(breadcrumbs)
	}, [isLearnerView, userFullName, userSubjectId, userId, subjectName, name])
}
