import gql from 'graphql-tag'

export const EXPORT_TRAINING_MATRIX = gql`
	mutation export($filters: TrainingMatrixFilters) {
		createTopicTrainingMatrixExport(filters: $filters) {
			id
		}
	}
`

export const EXPORT_ACTIVITY = gql`
	mutation export($filters: ActivityFiltersInput) {
		createActivityExport(filters: $filters) {
			id
		}
	}
`

export const EXPORT_USERS = gql`
	mutation export($filters: UserFiltersInput) {
		createUsersExport(filters: $filters) {
			id
		}
	}
`

export const EXPORT_GROUPS = gql`
	mutation export($filters: GroupFiltersInput) {
		createGroupsExport(filters: $filters) {
			id
		}
	}
`
export const EXPORT_LEARNING_TIME_TRACKING_ACTIVITY = gql`
	mutation export($filters: ActivityFiltersInput) {
		createLearningTimeTrackingExport(filters: $filters) {
			id
		}
	}
`
export const EXPORT_GROUPS_REPORTS = gql`
	mutation export($filters: GroupFiltersInput) {
		createGroupsExportReports(filters: $filters) {
			id
		}
	}
`
