import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'

import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import { useForm } from 'Hooks/useForm'

import FormComponent from 'Helpers/form'
import { GET_USER_BY_ID } from 'Pages/Users/SingleUser/queries'

const ChangeStatus = ({ match, open, is_active, initialValues, onClose }) => {
	const [loading, setLoading] = useState(false)

	const {
		params: { id },
	} = match

	const [changeStatus] = useMutation(UPDATE_PROFILE_MUTATION, {
		refetchQueries: [
			{
				query: GET_USER_BY_ID,
				variables: { id: parseInt(id) },
			},
		],
	})

	const {
		values: { active = is_active },
		handleSubmit,
		handleChangeToggle,
	} = useForm(
		() => {
			setLoading(true)

			changeStatus({
				variables: {
					id: parseInt(id),
					active,
					...initialValues,
				},
			}).finally(() => {
				setLoading(false)
				onClose()
			})
		},
		null,
		initialValues,
	)

	return (
		<Modal className="form-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>Change status</Modal.Header>
			<Modal.Content>
				<form onSubmit={handleSubmit}>
					<FormComponent.Radio
						customlabel="Inactive / Active"
						name="active"
						defaultChecked={active === true}
						onChange={handleChangeToggle}
						toggle
					/>

					<div className="actions text-right">
						<Button type="submit" content="Save" loading={loading} disabled={loading} />
						<Button
							className="reverse"
							type="button"
							content="Cancel"
							disabled={loading}
							onClick={onClose}
						/>
					</div>
				</form>
			</Modal.Content>
		</Modal>
	)
}

ChangeStatus.propTypes = {
	match: PropTypes.object.isRequired,
	open: PropTypes.bool,
	is_active: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

ChangeStatus.defaultProps = {
	open: false,
}

export default withRouter(ChangeStatus)
