import gql from 'graphql-tag'

export const GET_SUBJECT_SECTION_LEARNING_ARCHIVE_BY_ID = gql`
	query subjectSection($id: Int!, $pivot_id: Int!, $limit: Int, $user_id: Int) {
		byId(id: $id, limit: $limit, user_id: $user_id, pivot_id: $pivot_id) {
			id
			name
			subject {
				name
			}
			learningArchive {
				legacy_results
			}
		}
	}
`
export const GET_USER_BY_ID = gql`
	query users($id: Int!) {
		byId(id: $id) {
			id
			first_name
			last_name
		}
	}
`
