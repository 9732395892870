import React from 'react'
import { Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const ProgressInfoModalContent = ({ title }) => (
	<Modal.Content>
		<b>{title} Info:</b>
		{title === 'Learning Progress' && (
			<p>Shows the percentage of outcomes which you have viewed the learning for.</p>
		)}
		{title === 'Assessment Progress' && (
			<p>
				Shows the percentage progress for the knowledge element of the course. This is based on the total number
				of questions for the course. For example if a course has 10 outcomes with a total of 15 questions then
				your percentage will be based on progress against the 15 questions.
			</p>
		)}
	</Modal.Content>
)
ProgressInfoModalContent.propTypes = {
	title: PropTypes.string.isRequired,
}
export default ProgressInfoModalContent
