import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Table, PageContent, CustomPaginator } from 'Common'

import UserItem from './UserItem'

const UsersTable = ({ usersList, page, setPage, totalCount, length, perPage, activeSortParam, handleSortTable }) => {
	if (!usersList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Account"
								sortParam="account_name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Group"
								sortParam="group_name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Last Active"
								sortParam="last_active"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Created"
								sortParam="created_at"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell />
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(usersList, user => (
						<UserItem key={user.id} user={user} />
					))}
				</Table.Body>
			</Table>
			<CustomPaginator
				totalCount={totalCount}
				perPage={perPage}
				pageChangeHandler={setPage}
				length={length}
				activePage={page}
			/>
		</>
	)
}

UsersTable.propTypes = {
	usersList: PropTypes.array.isRequired,
	length: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
}

UsersTable.defaultProps = {
	activeSortParam: null,
}

export default UsersTable
