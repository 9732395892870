import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import FormComponent from 'Helpers/form'
import { Table, PageContent } from 'Common'
import { useQuery } from '@apollo/react-hooks'
import { GET_REFRESH_PERIOD_OPTIONS } from 'Queries/subjectsQueries'
import SubjectItem from './SubjectItem'

const SubjectTable = ({
	dataList,
	activeSortParam,
	handleSortTable,
	selectedSubjects,
	setSelectedSubjects,
	handleSubjectsChange,
}) => {
	const { error: refreshPeriodError, loading: refreshPeriodLoading, data: refreshPeriodData } = useQuery(
		GET_REFRESH_PERIOD_OPTIONS,
	)

	const selectAllSubjectsOnPage = () => {
		setSelectedSubjects(dataList.length !== selectedSubjects.length ? _.map(dataList, list => list) : [])
	}

	const selectSubject = list => {
		setSelectedSubjects(
			_.some(selectedSubjects, list)
				? selectedSubjects.filter(element => element.id !== list.id)
				: [...selectedSubjects, list],
		)
	}

	if (refreshPeriodLoading) return null
	if (refreshPeriodError) return 'Error'
	if (!dataList.length) return <PageContent.Message message="List is empty" />
	return (
		<div className="courses-list">
			<Table className="sections-STable">
				<Table.Header className="sorting">
					<Table.Row>
						<Table.HeaderCell>
							<FormComponent.Checkbox
								className="select-all customCheck"
								name="multichoice"
								checked={dataList.length === selectedSubjects.length || selectedSubjects === 'all'}
								onChange={selectAllSubjectsOnPage}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Name"
								sortParam="name"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Tag</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Target completion"
								sortParam="target_completion"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<Table.SortToggle
								label="Refresher"
								sortParam="refresher"
								activeParam={activeSortParam}
								onSort={handleSortTable}
							/>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{dataList.map((list, index) => (
						<SubjectItem
							key={list.id}
							list={list}
							isSelect={selectedSubjects.some(e => e.id == list.id) || selectedSubjects === 'all'}
							selectSubject={() => selectSubject(list)}
							handleSubjectsChange={handleSubjectsChange}
							refreshPeriodData={refreshPeriodData}
							listIndex={index}
						/>
					))}
				</Table.Body>
			</Table>
		</div>
	)
}

SubjectTable.propTypes = {
	dataList: PropTypes.array.isRequired,
	activeSortParam: PropTypes.object,
	handleSortTable: PropTypes.func.isRequired,
	selectedSubjects: PropTypes.array.isRequired,
	setSelectedSubjects: PropTypes.func.isRequired,
	handleSubjectsChange: PropTypes.func.isRequired,
}

SubjectTable.defaultProps = {
	activeSortParam: null,
}

export default SubjectTable
