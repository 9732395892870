import gql from 'graphql-tag'

export const GET_SUBJECT_BY_ID = gql`
	query subjects($pivot_id: Int!, $user_id: Int) {
		byId(pivot_id: $pivot_id, user_id: $user_id) {
			id
			name
			img
			description
			is_completed
			is_start
			feedback_link
			date_completed
			target_completion
			refresh_period
			refresh_period_formatted
			created_at
			progress
			obs_course_id
			obs_module_id
			default_force_learning
			last_viewed {
				id
				name
				obs_module_id
			}
			certificate {
				id
				issuer
				subject_name
				created_at
			}
			sections {
				id
				name
				progress
				is_start
				is_verified
				scorm_course_id
				obs_module_id
			}
		}
	}
`
