import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Modal } from 'semantic-ui-react'
import FormComponent from 'Helpers/form'
import { useForm } from 'Hooks'
import validate from './validate'
import UpdateConfirmationDialog from './UpdateConfirmationDialog'

import styles from './UpdateDueDateModal.module.scss'

const UpdateDueDateModal = ({ total, opened, loading, disabled, onCancel, onUpdate }) => {
	const [confirming, setConfirming] = useState(false)
	const handleCancelConfirm = useCallback(() => {
		setConfirming(false)
		onCancel()
	}, [setConfirming, onCancel])
	const { values, errors, handleSubmit, handleSelectChange } = useForm(
		() => {
			setConfirming(true)
		},
		validate,
		{
			target_completion: moment().format('DD-MM-YYYY'),
		},
	)

	const handleConfirm = useCallback(() => {
		setConfirming(false)
		onUpdate({ subject: values })
	}, [onUpdate, values])

	return confirming ? (
		<UpdateConfirmationDialog
			className={styles.confirm}
			total={total}
			opened={confirming}
			loading={loading}
			disabled={disabled}
			onCancel={handleCancelConfirm}
			onUpdate={handleConfirm}
		/>
	) : (
		<Modal open={opened} size="mini">
			<Modal.Header>Update Due Date</Modal.Header>
			<Modal.Content>
				<FormComponent.Date
					name="target_completion"
					value={values.target_completion || null}
					placeholder="Choose date"
					icon={false}
					clearIcon="close"
					dateFormat="DD-MM-YYYY"
					popupPosition="bottom left"
					clearable
					closable
					error={errors.target_completion}
					onChange={handleSelectChange}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button className="reverse" disabled={disabled} content="Cancel" onClick={onCancel} />
				<Button loading={loading} disabled={disabled} content="Update" onClick={handleSubmit} />
			</Modal.Actions>
		</Modal>
	)
}

UpdateDueDateModal.propTypes = {
	total: PropTypes.number,
	opened: PropTypes.bool,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
}

UpdateDueDateModal.defaultProps = {
	total: 0,
	opened: false,
	loading: false,
	disabled: false,
}

export default memo(UpdateDueDateModal)
