import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'

import './style.scss'

const icons = {
	observation_required: {
		tooltip: 'Observation Required',
		icon: <i className="fas fa-user-friends" style={{ color: '#90a6d5' }} />,
	},
	observation_done: {
		tooltip: 'Observation Complete',
		icon: <i className="fas fa-user-check" style={{ color: '#82c91e' }} />,
	},
	success: {
		tooltip: 'Signed Off',
		icon: <i className="fas fa-check-circle" style={{ color: '#82c91e' }} />,
	},
	danger: {
		tooltip: 'Sign Off Required',
		icon: <i className="fas fa-comment-dots" style={{ color: '#f36523' }} />,
	},
	error: {
		tooltip: 'Requires Action',
		icon: <i className="fas fa-times-circle" style={{ color: '#dd3333' }} />,
	},
	minus: {
		tooltip: 'No Questions',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 512 512">
				<path
					d={
						'M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 ' +
						'64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm88 ' +
						'200H296c13.3 0 24 10.7 24 24s-10.7 24-24 ' +
						' 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z '
					}
					style={{ fill: '#90a6d5' }}
				/>
			</svg>
		),
	},
	no_learning: {
		tooltip: 'No Learning',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
				<path
					d={
						'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 ' +
						'24.6-9.4 33.9 0l47 47 47-47 c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 ' +
						'33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 ' +
						' 0 l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 ' +
						' 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'
					}
					style={{ fill: '#8aa2d3' }}
				/>
			</svg>
		),
	},
	no_learning_file: {
		tooltip: 'No Learning',
		icon: (
			<svg className="margin-props" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 384 512">
				<style>{`svg{fill:#0067ac}`}</style>
				<path
					d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7
				64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"
				/>
			</svg>
		),
	},
	start_learning: {
		tooltip: 'Start Learning',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
				<path
					className="fa-fade"
					d={
						'M470.6 278.6c12.5-12.5 12.5-32.8 ' +
						'0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 ' +
						'0s-12.5 32.8 0 45.3L402.7 256 265.4 ' +
						'393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 ' +
						'12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 ' +
						'12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 ' +
						' 0s-12.5 32.8 0 45.3L210.7 256 73.4 ' +
						' 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z '
					}
					style={{ fill: '#ff9500' }}
				/>
			</svg>
		),
	},
	start_learning_file: {
		tooltip: 'Start Learning',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
				<path
					d={
						'M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 ' +
						'64H320c35.3 0 64-28.7 64-64V160H256c-17.7 ' +
						'0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 ' +
						'256H272c8.8 0 16 7.2 16 16s-7.2 16-16 ' +
						'16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 ' +
						'64H272c8.8  0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 ' +
						'0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 ' +
						'16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z '
					}
					style={{ fill: '#0062aa' }}
				/>
			</svg>
		),
	},
	learning_viewed: {
		tooltip: 'Learning Viewed',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 512 512">
				<path
					d={
						'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 ' +
						'512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 ' +
						'0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 ' +
						'47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'
					}
					style={{ fill: '#82c91e' }}
				/>
			</svg>
		),
	},
	learning_viewed_file: {
		tooltip: 'Learning Viewed',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
				<path
					d={
						'M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 ' +
						'64H320c35.3 0 64-28.7 64-64V160H256c-17.7 ' +
						'0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 ' +
						'0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 ' +
						'16-16zm0 64H272c8.8  0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 ' +
						'0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 ' +
						'16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z '
					}
					style={{ fill: '#0062aa' }}
				/>
			</svg>
		),
	},
	default: {
		tooltip: 'Question Not Answered',
		icon: <i className="fas fa-question-square" style={{ color: '#90a6d5' }} />,
	},
}

const StatusIcon = ({ status, tooltip }) => {
	let data = icons[status] ? icons[status] : icons['default']
	let iconToolTip = tooltip ? tooltip : data.tooltip
	return <Popup content={iconToolTip} flowing hoverable position="bottom center" trigger={data.icon} />
}

StatusIcon.propTypes = {
	status: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
}

StatusIcon.defaultProps = {
	tooltip: null,
}

export default StatusIcon
