import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from 'Constants'

export default function useFetchResource(apiHandler, deps = []) {
	const history = useHistory()
	const [isLoading, setLoading] = useState(false)
	const [resource, setResource] = useState({})

	const isLoadingRef = useRef(isLoading)
	const apiHandlerRef = useRef(apiHandler)
	apiHandlerRef.current = apiHandler

	useEffect(() => {
		if (isLoadingRef.current) {
			return
		}

		let resource = {}
		let isComponentMounted = true

		setLoading(true)
		isLoadingRef.current = true

		apiHandlerRef
			.current()
			.then(({ data }) => {
				resource = data
			})
			.catch(({ status }) => {
				if ([401].includes(status)) {
					localStorage.clear()
					history.push(Routes.LOGIN)
				}
			})
			.finally(() => {
				isLoadingRef.current = false

				if (!isComponentMounted) {
					return
				}

				setLoading(false)
				setResource(resource)
			})
		return () => {
			isComponentMounted = false
		}
	}, deps)

	return {
		isLoading,
		resource,
	}
}
