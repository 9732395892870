import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './styles.scss'

const ArchiveInfo = ({ data }) => (
	<>
		<h2 className="page-title">Learning Archive Record</h2>
		<div className="archive-info">
			{_.map(data, (item, index) => {
				const name = Object.keys(item)[0]
				const value = item[name]
				return (
					<div key={index} className="info-row">
						<span>{name}</span>
						<span>{value}</span>
					</div>
				)
			})}
		</div>
	</>
)

ArchiveInfo.propTypes = {
	data: PropTypes.array.isRequired,
}

export default ArchiveInfo
