import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import FormComponent from 'Helpers/form'
import Question from './Question'

const QuestionType = ({
	answer,
	bool,
	multichoice,
	single,
	type,
	options,
	handleChange,
	handleChangeRadioGroup,
	handleChangeCheckboxGroup,
	errors,
}) => {
	const types = [
		null,
		{
			label: 'True or False',
			children: (
				<FormComponent.RadioGroup
					name="bool"
					value={bool || ''}
					options={_.map(options, ({ id, answer }) => ({
						value: id,
						label: _.upperFirst(Boolean(parseInt(answer)).toString()),
					}))}
					onChange={handleChangeRadioGroup}
				/>
			),
		},
		{
			label: 'Scenario answer',
			children: (
				<>
					<FormComponent.Textarea
						name="answer"
						error={errors.answer}
						value={answer}
						onChange={handleChange}
						rows={18}
						fluid
					/>
					{errors.answer && (
						<span className="error-message">Your answer is too long. The limit is 3,000 characters.</span>
					)}
				</>
			),
		},
		{
			label: 'Multiple choice - select more than one answer',
			children: (
				<div>
					{_.map(options, option => (
						<FormComponent.Checkbox
							key={option.id}
							className="custom-checkbox"
							label={option.answer}
							name="multichoice"
							value={option.id}
							checked={_.includes(multichoice, option.id)}
							onChange={(e, data) => handleChangeCheckboxGroup(e, data, multichoice)}
						/>
					))}
				</div>
			),
		},
		{
			label: 'There is only one correct answer for this question',
			children: (
				<FormComponent.RadioGroup
					name="single"
					value={single || ''}
					options={_.map(options, ({ id, answer }) => ({
						value: id,
						label: answer,
					}))}
					onChange={handleChangeRadioGroup}
				/>
			),
		},
	]

	return types[type] ? <Question label={types[type].label}>{types[type].children}</Question> : null
}

QuestionType.propTypes = {
	answer: PropTypes.string,
	bool: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	multichoice: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	single: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.number.isRequired,
	options: PropTypes.array,
	errors: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	handleChangeRadioGroup: PropTypes.func.isRequired,
	handleChangeCheckboxGroup: PropTypes.func.isRequired,
}

QuestionType.defaultProps = {
	answer: '',
	bool: '',
	multichoice: [],
	single: '',
	options: [],
	errors: {},
}

export default QuestionType
