import { SUBJECT_ACTIVITY_STATUSES } from 'Constants/subject'

export default [
	{
		key: 'total_current',
		icon: 'far fa-calendar-exclamation',
		label: 'Due Now',
		activityStatus: SUBJECT_ACTIVITY_STATUSES.CURRENT,
	},
	{
		key: 'total_planned',
		icon: 'far fa-calendar-alt',
		label: 'Planned',
		activityStatus: SUBJECT_ACTIVITY_STATUSES.PLANNED,
	},
	{
		key: 'total_optional',
		icon: 'far fa-calendar',
		label: 'Optional',
		activityStatus: SUBJECT_ACTIVITY_STATUSES.OPTIONAL,
	},
	{
		key: 'total_completed',
		icon: 'far fa-calendar-check',
		label: 'Completed',
		activityStatus: SUBJECT_ACTIVITY_STATUSES.COMPLETED,
	},
]
