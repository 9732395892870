import { getObsLearningStats } from 'Utils/obs'

const _markViewedContentPages = ({
	activePage,
	obsModuleLessonId,
	container,
	learningStats,
	pagesCssSelector = '.pagination [type="pageItem"].item',
}) => {
	const contentPages = [...container.querySelectorAll(pagesCssSelector)]
	const obsLearningStats = getObsLearningStats({ learningStats, obsModuleLessonId })

	const setContentViewedFlag = (index = 0, isViewed = true) =>
		(contentPages[index].dataset.obsContentViewed = isViewed)

	if (activePage <= 0) {
		contentPages.forEach((_, index) => {
			if (obsLearningStats.has(index)) {
				setContentViewedFlag(index)
			}
		})
	}

	setContentViewedFlag(Math.max(0, activePage))
}

export const markViewedContentPages = withContainer(_markViewedContentPages)

const _modifyLearningContent = ({ container, cssSelector, attributes = {} }) => {
	for (const element of container.querySelectorAll(cssSelector)) {
		if (typeof attributes === 'function') {
			attributes(element)
			continue
		}

		Object.entries(attributes).forEach(([attribute, value]) => {
			element.setAttribute(attribute, value)
		})
	}
}

export const modifyLearningContent = withContainer(_modifyLearningContent)

export class FeedbackManager {
	static _startParent = null

	static cssClasses = class {
		static get feedback() {
			return 'obs-lesson-feedback'
		}

		static get feedbackToggleTrigger() {
			return `${this.feedback}_toggle-trigger`
		}

		static get feedbackToggleTriggerActive() {
			return `${this.feedbackToggleTrigger}_active`
		}

		static get feedbackToggleTriggerVisited() {
			return `${this.feedbackToggleTrigger}_visited`
		}

		static withClassSelector(selector) {
			return `.${selector}`
		}
	}

	static boot() {
		const feedbackElements = window.jQuery(`p strong:contains("Feedback")`)

		if (!feedbackElements.length) {
			return
		}

		const [start, end] = feedbackElements
		const startParent = (this._startParent = start.closest('p'))
		const feedbackClassSelector = this.cssClasses.withClassSelector(this.cssClasses.feedback)

		startParent.classList.add(this.cssClasses.feedbackToggleTrigger, 'button-link', 'primary')

		if (end) {
			window.$(start).text('Show Feedback')
			window
				.jQuery(startParent)
				.nextUntil(window.$(end.parentElement).hide())
				.wrapAll(`<div class="${this.cssClasses.feedback}"></div>`)
			window.jQuery(feedbackClassSelector).hide()
		}

		window.jQuery(startParent).on('click', () => {
			const { classList } = startParent
			classList.toggle(this.cssClasses.feedbackToggleTriggerActive)
			classList.add(this.cssClasses.feedbackToggleTriggerVisited)

			window.jQuery(feedbackClassSelector).toggle()

			const isFeedbackOpen = classList.contains(this.cssClasses.feedbackToggleTriggerActive)
			if (isFeedbackOpen) {
				startParent.innerHTML = '<strong>Hide Feedback</strong>'
			}

			if (classList.contains(this.cssClasses.feedbackToggleTriggerVisited) && !isFeedbackOpen) {
				startParent.innerHTML = '<strong>Show Feedback</strong>'
			}
		})

		return this
	}

	static destruct() {
		window.jQuery(this._startParent).off('click')
		this._startParent = null
	}
}

function withContainer(fn) {
	return ({ container, ...rest }) => {
		if (!container) {
			return
		}

		return fn({ container, ...rest })
	}
}
