import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'
import { Grid, Icon, Button } from 'semantic-ui-react'
import classNames from 'classnames'

import FormComponent from 'Helpers/form'
import { UserPhoto } from 'Common'
import { determineLastActivityLabel, scrollTo } from 'Utils'

import StatusIcon from 'Pages/Subjects/ManageOutcomes/StatusIcon'

const OutcomeItem = ({ isExpand, isSelected, selectItem, manager, subjectSectionType, outcome, isExport }) => {
	const history = useHistory()
	const managerEvidenceDaysLeft = outcome.managerEvidence
		? determineLastActivityLabel(outcome.managerEvidence.created_at)
		: null
	const learnerEvidenceDaysLeft = outcome.userEvidence
		? determineLastActivityLabel(outcome.userEvidence.created_at)
		: null
	const learningActiveDaysLeft = outcome.lastActive ? determineLastActivityLabel(outcome.lastActive.created_at) : null

	const scrollToEvidence = evidenceId => {
		const evidenceEl = document.getElementById(`evidence-${evidenceId}`)

		if (!evidenceEl) {
			return false
		}

		evidenceEl.classList.add('flesh')
		setTimeout(() => {
			evidenceEl.classList.remove('flesh')
		}, 150)

		const containerEl = document.querySelector('.main-content > .container')

		if (!containerEl) {
			return false
		}

		scrollTo(evidenceEl.offsetTop + evidenceEl.offsetHeight - containerEl.offsetHeight / 2, containerEl)
	}

	const viewLearning = () => {
		history.push({
			pathname: location.pathname + '/learn',
			state: {
				learning_link: outcome.learning_link,
			},
		})
	}

	return (
		<li className={classNames('outcome-wrap', { open: isExport || isExpand })}>
			<Button
				onClick={e => {
					e.preventDefault()
					selectItem()
				}}
				className="outcome-select"
			>
				<div className="outcome-item">
					<div className="actions">
						<FormComponent.Checkbox name="multichoice" checked={isSelected} className="outcomeCheck" />
					</div>
					<div className="ref">
						<span>{outcome.reference}</span>
					</div>
					<div className="name">
						<p>{outcome.name}</p>
					</div>
					<div className="status">
						<StatusIcon status={outcome.status} />
						{outcome.observable && <StatusIcon status={outcome.observationStatus} />}
					</div>
				</div>
			</Button>

			<div className="outcome-info">
				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={7}>
						{(outcome.lastActive || outcome.learning_link) && (
							<div className="info-item">
								<h5>
									<span className="icon">
										<i className="fas fa-angle-down" />
									</span>
									Learning
								</h5>
								{outcome.lastActive && (
									<span>
										Last viewed: <span className="blue-text">{learningActiveDaysLeft}</span>
									</span>
								)}
								{!manager && outcome.learning_link && subjectSectionType === 'Iframe' && (
									<span className="learning-link">
										<Button type="button" size="small" onClick={viewLearning}>
											<Icon name="eye" style={{ color: 'white' }} />
											<span>View learning</span>
										</Button>
									</span>
								)}
							</div>
						)}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={7} floated="right">
						{(outcome.userEvidence || outcome.managerEvidence) && (
							<div className="info-item">
								<h5>
									<span className="icon">
										<i className="fas fa-angle-down" />
									</span>
									Evidence
								</h5>
								{outcome.userEvidence && (
									<div className="evidence-item">
										<span className="dark-text">
											Learner Evidence{' '}
											<span className="blue-text">{learnerEvidenceDaysLeft}</span>
										</span>
										<div>
											<span className="icon">
												<i className="far fa-eye" />
											</span>
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												className="blue-text"
												href="javascript:void(0)"
												onClick={() => scrollToEvidence(outcome.userEvidence.id)}
											>
												View evidence
											</a>
										</div>
									</div>
								)}

								{outcome.managerEvidence && (
									<div className="evidence-item">
										<div className="manager-evidence">
											<span className="dark-text">
												Manager Evidence{' '}
												<span className="blue-text">{managerEvidenceDaysLeft}</span>
											</span>
											<div className="manager">
												{outcome.managerEvidence.user && (
													<>
														<UserPhoto
															className="photo"
															user={outcome.managerEvidence.user}
															size="extraMini"
															square
														/>
														<span>
															{`${outcome.managerEvidence.user.first_name} 
														${outcome.managerEvidence.user.last_name}`}
														</span>
													</>
												)}
											</div>
										</div>
										<div>
											<span className="icon">
												<i className="far fa-eye" />
											</span>
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												className="blue-text"
												href="javascript:void(0)"
												onClick={() => scrollToEvidence(outcome.managerEvidence.id)}
											>
												View evidence
											</a>
										</div>
									</div>
								)}
							</div>
						)}
					</Grid.Column>
				</Grid>
			</div>
		</li>
	)
}

OutcomeItem.propTypes = {
	isExpand: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	subjectSectionType: PropTypes.string.isRequired,
	manager: PropTypes.bool.isRequired,
	selectItem: PropTypes.func.isRequired,
	outcome: PropTypes.object.isRequired,
	isExport: PropTypes.bool.isRequired,
}
OutcomeItem.defaultProps = {
	isSelected: false,
}

export default withRouter(OutcomeItem)
