import { Icon, Popup } from 'semantic-ui-react'
import { ColourTooltip } from 'Common/ColourTooltip'
import { targetCompletionStatus } from 'Constants/colourInstructions'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSortTable } from 'Hooks/useSortTable'
import PropTypes from 'prop-types'
import { Table as CTable } from 'Common'
import SubjectItem from './SubjectItem'

const Table = React.memo(({ subjects }) => {
	const statuses = useMemo(
		() => ({
			success: 1,
			danger: 2,
			error: 3,
			default: 4,
		}),
		[subjects],
	)
	const currentDate = useMemo(() => moment(), [subjects])
	const subjectsList = useMemo(() => {
		return _.map(subjects, subject => ({
			last_active: subject.lastActive ? currentDate.diff(subject.lastActive.created_at, 'days') : null,
			remaining: currentDate.diff(subject.target_completion, 'days'),
			progress: subject.progress > 0 ? subject.progress : null,
			status: statuses[subject.activity_status],
			...subject,
		}))
	}, [subjects])

	const { list, activeParam, handleSortTable } = useSortTable(subjectsList)

	return (
		<CTable className="user-subjects-table">
			<CTable.Header className="sorting">
				<CTable.Row>
					<CTable.HeaderCell>
						<CTable.SortToggle
							label="Name"
							sortParam="name"
							activeParam={activeParam}
							onSort={handleSortTable}
						/>
					</CTable.HeaderCell>
					<CTable.HeaderCell>
						<CTable.SortToggle
							label="Last active"
							sortParam="last_active"
							activeParam={activeParam}
							onSort={handleSortTable}
						/>
					</CTable.HeaderCell>
					<CTable.HeaderCell>
						<CTable.SortToggle
							className="center"
							label="Progress"
							sortParam="progress"
							activeParam={activeParam}
							onSort={handleSortTable}
						/>
					</CTable.HeaderCell>
					<CTable.HeaderCell>
						<CTable.SortToggle
							className="center"
							sortParam="status"
							activeParam={activeParam}
							onSort={handleSortTable}
						>
							<span>Status</span>
							<Popup
								flowing
								hoverable
								position="bottom center"
								trigger={<Icon name="info circle" size="large" />}
							>
								<ColourTooltip header="Status" items={targetCompletionStatus} />
							</Popup>
						</CTable.SortToggle>
					</CTable.HeaderCell>
					<CTable.HeaderCell />
				</CTable.Row>
			</CTable.Header>
			<CTable.Body>
				{_.map(list, (subject, index) => (
					<SubjectItem key={index} subject={subject} />
				))}
			</CTable.Body>
		</CTable>
	)
})

Table.propTypes = {
	subjects: PropTypes.array.isRequired,
}

export default Table
